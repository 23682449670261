import React from 'react';
import { extractContent } from '../../utils/utils';

export default function ItemsTable(props) {
  const getTitle = (n) => {
    return n.title ? extractContent(n.title) : n.title;
  }

  return (
    <div className="tableBodyContainer">
      {props.items?.map((n) =>
        <div
          role="button"
          tabIndex={0}
          key={n._id}
          className={`tableBody${props.selectedItem?._id === n?._id ? ' tableBodySelected' : ''}`}
          onClick={(type) => props.selectItem(n, type)}
          onKeyDown={(event, type) => {
            if (event.key === "Enter") {
              props.selectItem(n, type)
            }
          }}
        >
          <p className="tableBodyTitle">{props.isNews ? getTitle(n) : n.sname}</p>
        </div>
      )}

      {!props.items || props.items?.length === 0 ?
        <div className="tableBody noResults">
          {props.placeholderTxt}
        </div>
        : <></>}
    </div>
  );
}