import React from 'react';
import { NavLink } from 'react-router-dom';

import { apiInfo } from '../../../api/api';
import { convertStringToUrl, extractContent } from '../../../utils/utils';

import DefaultImage from '../../../assets/img/homepage/no-image.jpg'

export default function NewsItemPicture({ journalName, news, layout, size, isMobile }) {
  const img = news?.attachments.length > 0 ? news?.attachments[0] : null;
  const title = extractContent(news?.title);

  const getThumbnail = (img, size, layout) => {
    if (!img) { return DefaultImage; }
        
    let thumbnail;
    if (size === 'big') {
      thumbnail = img.formats?.large?.url;
    } else if (size === 'slot') {
      thumbnail = img.formats?.small?.url;
    } else {
      thumbnail = img.formats?.medium?.url;
    }

    if (thumbnail && layout !== 2) {
      return `${apiInfo.base}${thumbnail}`;
    } else return `${apiInfo.base}${img.url}`;
  }

  return (
    <NavLink 
      className="newsItemImgLink"
      to={`/${journalName}/news/${convertStringToUrl(title)}?id=${news?._id}`}
    >
      <picture className={(isMobile === true && size === 'big' ? "newsImgContainerMobile" : "newsImgContainer")}>
        <img className="newsItemImg" src={getThumbnail(img, size, layout)} alt="Imagem manchete" />
      </picture>
    </NavLink>
  );
}