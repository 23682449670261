import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import Modal from '../../../templates/modal/Modal';
import Pagination from '../../../components/dashboard/pagination/Pagination';
import TableLoading from '../../../components/tableloading/TableLoading';

import HomepageIcon from '../../../assets/img/sideMenuIcons/homepage.svg';
import ItemsTable from '../ItemsTable';
import Search from '../../../components/dashboard/search/Search';
import usePrevious from '../../../components/utilities/UsePrevious';
import { getPublishedNews } from '../../../api/news';
import { SlotOptions } from '../../../models/enum';
import { ToastError } from '../../../models/enum';
import { getNewsParams } from '../../../utils/utils';

function ModalSelectNews({ school, currentlySelected, selectedItems, position, openModal, handleClose, handleSelectNews }) {
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(currentlySelected);
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);

  const previousPage = usePrevious(page);

  useEffect(() => {
    if (!school) return;
    if (previousPage === page) { setPage(1); }
    getNews(school, debouncedSearchValue, page);
  }, [school, debouncedSearchValue, page]);
  
  const getNews = async (school, search, page) => {
    setTableLoading(true);

    const { start, limit } = getNewsParams(page, school);
    const data = { items: selectedItems.map(i => i?.id).filter(item => item !== undefined) };

    let res = [];
    try {
      res = await getPublishedNews(school.id, data, search, start, limit);
    } catch (err) {
      console.error('[news] Get news error:', err);
      setTableLoading(false);

      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
    }

    let { news, count } = res;

    if (currentlySelected && page === 1) {
      if (search) {
        const currentTitle = currentlySelected.title?.toLowerCase();
        const selectedIncludesSearch = currentTitle?.includes(search.toLowerCase());
        if (selectedIncludesSearch) { news = [currentlySelected, ...news]; }
      } else {
        news = [currentlySelected, ...news];
      }
    }

    setTableLoading(false);
    setNews(news);
    setCount(count);
  }

  const selectItem = (news, type) => {
    console.log(` Selected item of type ${type} with the value`, news);
    setSelectedItem(news);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const changeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const loadingClass = tableLoading ? 'tableBodyContainer pageLoading' : 'tableBodyContainer';
  let placeholderMessage = 'Ainda não há notícias publicadas!';
  if (debouncedSearchValue !== '' && news.length === 0) {
    placeholderMessage = 'Sem resultados disponíveis...';
  }

  return (
    <Modal
      openModal={openModal}
      modalHeader
      modalIcon={<img src={HomepageIcon} alt="Homepage icon" />}
      modalTitle={`Selecionar Notícia - Posição ${position}`}
    >
      <div className="modalBodyContainer">
        <div className="optionsContainerModal">
          <Search
            imgAlt="Pesquisar notícias"
            placeholder="Pesquisar por palavras-chave da notícia"
            value={searchValue}
            onChange={changeHandler}
          />
        </div>
        <div className="tableHeader">
          <span className="tableHeaderTitle">
            Notícias Publicadas
          </span>
        </div>

        <div className={loadingClass}>
          <ItemsTable
            items={news}
            selectedItem={selectedItem}
            selectItem={(item) => selectItem(item, SlotOptions.News)}
            isNews={true}
            placeholderTxt={`${placeholderMessage}`}
          />

          {tableLoading ? <TableLoading /> : <></>}
        </div>

        <div className="paginationWrapper">
          <Pagination
            count={count}
            page={page}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>

      <div className="btnContainer tableButtons">
        <div className="attributeContainer">
          <button className="secondaryBtnBlue" onClick={() => handleClose()}>Cancelar</button>
        </div>
        <div className="attributeContainer">
          {selectedItem ?
            <button className="mainBtn" onClick={() => handleSelectNews(position, selectedItem)}>Adicionar</button> :
            <button className="mainBtn" disabled={true}>Adicionar</button>
          }
        </div>
      </div>
    </Modal>
  );

}

export default ModalSelectNews;