import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../../pages/formLogin';
// import CreateNews from '../../components/newsEditor/CreateNews';
// import EditNews from '../../components/newsEditor/EditNews';
import DashLayout from '../../templates/dashlayout/DashLayout';
import { refreshSession } from '../../api/users';
import { getSchoolById } from '../../api/schools';
import { ToastError } from '../../models/enum';
import { SchoolActions, UserActions } from '../../redux/actions';

class BackOfficeLayout extends Component {
  async componentDidMount() {
    let schoolId;
    try {
      const user = await refreshSession();
      this.props.updateUser(user);
      schoolId = user?.school;
    } catch (err) {
      console.log(' [auth] User is not authenticated.');

      const isLoggedIn = localStorage.getItem('userToken');
      if (isLoggedIn) {
        localStorage.removeItem('userToken');
        window.location.reload();
      }

      return;
    }

    if (!schoolId) {
      toast(ToastError.NO_SCHOOL, { className: "toastStyleDanger", toastId: "no_school", autoClose: 2000 });
      return;
    }

    try {
      const school = await getSchoolById(schoolId);
      this.props.updateSchool(school);
    } catch (err) {
      toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
      return;
    }
  }

  render() {
    const isLoggedIn = localStorage.getItem('userToken');

    return (
      <Switch>
        <Route
          path="/dashboard"
          exact
          render={(props) =>
            isLoggedIn ? <Redirect to="/dashboard/news" /> : <Login {...props} />
          }
        />

        <Route path="/dashboard">
          <DashLayout />
        </Route>

        <Redirect to="/dashboard" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  updateUser: UserActions.updateUser,
  updateSchool: SchoolActions.updateSchool,
};

export default connect(mapStateToProps, mapActionsToProps)(BackOfficeLayout);
