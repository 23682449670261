import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getSectionsBySchool = (schoolId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.SECTIONS}?school=${schoolId}&_limit=-1&_sort=sname:ASC`
  );
}

export const createNewSection = (schoolId, name) => {
  const data = {
    sname: name,
    school: schoolId
  };
    
  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.SECTIONS}`,
    data,
    true
  );
}

export const updateSection = (sectionId, data) => {
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SECTIONS}/${sectionId}`,
    data,
    true
  );
}