import React from 'react';
import { NavLink } from 'react-router-dom';

import Modal from '../../../templates/modal/Modal';
import SuccessIcon from '../../../assets/img/icons/success.svg';
import './SuccessModal.css';

export default function SuccessModal({ message, schoolLink, open, handleClose }) {
  return (
    <Modal
      openModal={open}
      centered={true}
    >
      <div className="modalBodyContainer">

        <div className="successContainer">
          <div className="imgBanner">
            <img
              src={SuccessIcon}
              alt="Sucesso"
            />
          </div>
          <p className="modalSuccess">
            {message}
          </p>
        </div>

        <div className="btnContainer">
          <div className="attributeContainer">
            <button
              className="secondaryBtnBlue"
              onClick={handleClose}
            >
                            Voltar ao painel
            </button>
          </div>
          <div className="attributeContainer">
            <NavLink
              className="mainBtn"
              target="_blank"
              to={`/${schoolLink}`}
            >
                            Aceder jornal
            </NavLink>
          </div>
        </div>
      </div>
    </Modal>
  );
}