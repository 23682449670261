import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import { NavLink } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';

import Filter from '../../templates/filter/Filter';
import Search from '../../components/dashboard/search/Search';
import { NewsFilters, NewsState } from '../../models/enum';

import AddNewsIcon from '../../assets/img/icons/addNews.svg';
import ArrowDown from '../../assets/img/icons/arrowDown.svg';
import ArrowUp from '../../assets/img/icons/arrowUp.svg';

import 'react-datepicker/dist/react-datepicker.css';

export default function NewsHeader({
  filtersState, checkboxValues, searchValue,
  toggleFilter, changeHandler, changeHandlerCheckbox, cleanFilter, submitDate
}) {
  const school = useSelector((state) => state.school);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState(null);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(school?.sections);
  }, [school]);

  const onChangeTwoDates = (dates) => {
    const [start, end] = dates;

    setError(null);
    setStartDate(start);
    setEndDate(end);
  };

  const showUserDate = (startDate, endDate) => {
    if (endDate === null) {
      const startDateFormat = new Date(startDate);
      return startDateFormat.toLocaleDateString();
    } else {
      const endDateFormat = new Date(endDate);
      return endDateFormat.toLocaleDateString();
    }
  };

  const submitDateFilter = () => {
    if (endDate === null) {
      setError('* Selecione segunda data');
    } else {
      setError(null);
      submitDate(startDate, endDate);
    }
  }

  const clean = (type) => {
    setError(null);

    if (type === NewsFilters.DATE) {
      setStartDate(null);
      setEndDate(null);
    }

    cleanFilter(type);
  }

  registerLocale('pt', pt);

  const sectionsName = sections ? sections.map((x) => x.sname) : [];
  const StateFilters = Object.keys(NewsState).map((s) => NewsState[s].NAME);

  return (
    <div className="optionsContainer big">
      <Search
        imgAlt="Pesquisar notícias"
        placeholder="Pesquisar por autor ou palavras-chave da notícia"
        value={searchValue}
        onChange={changeHandler}
      />

      <div className="filtersContainer">
        <div className="filterOptions">
          <p className="filterContainerTitle">Filtros:</p>

          <div className="setFilterWrapper">
            <div className="filterBox" onClick={() => toggleFilter(NewsFilters.STATE)}>
              <p className="filterTitle">Estado</p>

              <button type="button" className="accessibleBtn">
                <img
                  className="filterArrow"
                  src={filtersState[NewsFilters.STATE] ? ArrowUp : ArrowDown}
                  alt="Show filter"
                />
              </button>
            </div>

            {filtersState[NewsFilters.STATE] ? (
              <Filter
                filterOptions={StateFilters}
                filterType={NewsFilters.STATE}
                selectedValues={checkboxValues[NewsFilters.STATE]}
                changeHandlerCheckbox={(e) => changeHandlerCheckbox(e, NewsFilters.STATE)}
                cleanFilter={() => cleanFilter(NewsFilters.STATE)}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="setFilterWrapper">
            <div className="filterBox" onClick={() => toggleFilter(NewsFilters.SECTIONS)}>
              <p className="filterTitle">Secção</p>
              <button type="button" className="accessibleBtn">
                <img
                  className="filterArrow"
                  src={filtersState[NewsFilters.SECTIONS] ? ArrowUp : ArrowDown}
                  alt="Show filter"
                />
              </button>
            </div>

            {filtersState[NewsFilters.SECTIONS] ? (
              <Filter
                filterOptions={sectionsName}
                filterType={NewsFilters.SECTIONS}
                selectedValues={checkboxValues[NewsFilters.SECTIONS]}
                changeHandlerCheckbox={(e) => changeHandlerCheckbox(e, NewsFilters.SECTIONS)}
                cleanFilter={() => cleanFilter(NewsFilters.SECTIONS)}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="setFilterWrapperDate">
            <div className="filterBox" onClick={() => toggleFilter(NewsFilters.DATE)}>
              <p className="filterTitle">Data</p>
              <button type="button" className="accessibleBtn">
                <img
                  className="filterArrow"
                  src={filtersState[NewsFilters.DATE] ? ArrowUp : ArrowDown}
                  alt="Show filter"
                />
              </button>
            </div>

            {filtersState[NewsFilters.DATE] ? (
              <div className="setOptionsWrapperDate">
                <div className="setOptionsCalendar">
                  <div className="styleInputs">
                    <label>
                      <b className="labelStyle">De:</b>
                      <input
                        className="calendarInput"
                        value={showUserDate(startDate, null)}
                        disabled
                      />
                    </label>

                    <label className="labelStyle">
                      <b className="labelStyle" style={{ marginLeft: '10px' }}>
                        a:
                      </b>
                      <input
                        className="calendarInput"
                        value={
                          endDate === null
                            ? 'dd/mm/aaaa'
                            : showUserDate(null, endDate)
                        }
                        disabled
                      />
                    </label>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DatePicker
                      //selected={this.state.startDate}
                      onChange={(val) => onChangeTwoDates(val)}
                      startDate={startDate}
                      endDate={endDate}
                      locale="pt"
                      selectsRange
                      inline
                    />
                  </div>
                </div>
                <div className="setOptions">
                  {error && (
                    <p
                      style={{
                        color: 'red',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        marginLeft: '8px',
                      }}
                    >
                      {error}
                    </p>
                  )}

                  <div className="filterButtonsC">
                    <button
                      className="cleanFilterButton"
                      onClick={() => clean(NewsFilters.DATE)}
                    >
                      <b>Limpar</b>
                    </button>
                    <button
                      className="submitFilterButton"
                      onClick={() => submitDateFilter()}
                    >
                      <b>Aplicar</b>
                    </button>
                  </div>

                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="btnWrapper">
          <NavLink className="mainBtn" to="/dashboard/news/create">
            <img src={AddNewsIcon} alt="Criar notícia" />
            Criar Notícia
          </NavLink>
        </div>
      </div>
    </div>
  );
}