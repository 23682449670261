import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';
import { uploadImage } from './upload';

export const createReport = async (data) => {
  const { img } = data;
  try {
    if (img) {
      const responseFile = await uploadImage(img.file);
      return fetchRequest(
        HTTPMethod.POST,
        `${apiInfo.base}/${Resource.REPORT}`,
        { ...data, image: responseFile[0].id },
        true
      );
    }

    return fetchRequest(
      HTTPMethod.POST,
      `${apiInfo.base}/${Resource.REPORT}`,
      { ...data },
      true
    );
  } catch (error) {
    console.log(error);
  }
};
