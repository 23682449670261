import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { setGlobalState } from '../../utils/MyContext';
import Header from './Header';
import FormNews from './FormNews';
import TabsContainer from './TabsContainer';
import DashHeader from '../dashboard/dashheader/DashHeader';
import Loading from '../loading/Loading';
import MetaTags from '../metatags/MetaTags';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';

function CreateNews() {
  const dispatch = useDispatch();
  const school = useSelector((state) => state.school);
  const menu = useSelector((state) => state.menu);

  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);

  // close synonym modal when the user clicks outside the component
  function handleClick(e) {
    if (
      e.target.id === 'synonym_link' ||
      e.target.id === 'Div1_tooltip_editor'
    ) {
      return;
    }

    setGlobalState('synonymModal', []);
  }

  useEffect(() => {
    if (!school) return;

    dispatch(TextEditorValidationActions.cleanTextEditorValidation());
    dispatch(TextEditorActions.cleanTextEditor());

    setSections(school.sections);
    setLoading(false);
  }, [school]);

  return (
    <div>
      <MetaTags title="Criar Notícia &bull; Gestão de Notícias | Projeto TRUE" />

      <DashHeader
        title="Gestão de Notícias"
        subtitle="Criar Notícia"
        menu={menu}
      />

      {loading ? (
        <Loading />
      ) : (
        <Container fluid onClick={(e) => handleClick(e)}>
          <Header name="Adicionar Nova Notícia" />

          <Row className="padding-row">
            <Col xs={8} className="padding-form">
              <FormNews sections={sections} />
            </Col>

            <Col
              xs={4}
              className={
                menu === 'collapsed'
                  ? 'section-tab tabsDefault'
                  : 'section-tab tabsMenuOpen'
              }
            >
              <TabsContainer corrections={true} search={true} related={true} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default CreateNews;