import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

//Components
import DashHeader from '../../../components/dashboard/dashheader/DashHeader';
import DashError from '../../../components/dashboard/dasherror/DashError';
import Header from '../../../components/newsEditor/Header';
import MetaTags from '../../../components/metatags/MetaTags';
import Loading from '../../../components/loading/Loading';
import TableLoading from '../../../components/tableloading/TableLoading';
import EditHeadlight from './ModalEditHeadlight';
import HeadlightsNews from './HeadlightsNews';
import HeadlightsSidebar from './HeadlightsSidebar';
import SelectNews from './ModalSelectNews';
import SuccessModal from '../../../components/dashboard/successmodal/SuccessModal';
import { getHeadlightById, updateSchoolHeadlight } from '../../../api/headlights';
import { HeadlightScheme, ToastError, ToastSuccess } from '../../../models/enum';
import { SchoolActions } from '../../../redux/actions';

import '../../../assets/css/homepage.css';

class Headlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loading state
      error: false,
      loading: true,
      tableLoading: false,

      // modal open states
      modalNewsOpen: false,
      modalHeadlightOpen: false,

      // success modal
      modalSuccessOpen: false,
      successMessage: '',

      // select news modal information
      position: -1,
      selectedItem: null,

      // headlight information
      headlightId: '',
      layout: -1,
      selectedNews: [],

      // pre visualization
      preViz: false,

      // modal accessibility
      lastClickedElement: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSuccessClose = this.handleSuccessClose.bind(this);
    this.handleOpenSelectNews = this.handleOpenSelectNews.bind(this);
    this.handleOpenEditHeadlight = this.handleOpenEditHeadlight.bind(this);
    this.handleSelectNews = this.handleSelectNews.bind(this);
    this.increasePosition = this.increasePosition.bind(this);
    this.decreasePosition = this.decreasePosition.bind(this);
    this.updateHeadlight = this.updateHeadlight.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.handlePreViz = this.handlePreViz.bind(this);
    this.handleClosePreViz = this.handleClosePreViz.bind(this);
    this.headlightInfo = this.headlightInfo.bind(this);
    this.schoolInfo = this.schoolInfo.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.getHeadlight();

    // Handle modal accessibility
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleClick(event) {
    this.setState({ lastClickedElement: event.target });
  }

  componentDidUpdate(prevProps) {
    const { school } = this.props;
    const { loading } = this.state;
    if (school !== prevProps.school && loading) {
      this.getHeadlight();
    }
  }

  // Retrieve headlight and news list information from API
  async getHeadlight() {
    const { school } = this.props;

    if (!school) return;
    if (!school.headlight) {
      this.setState({
        loading: false,
        error: true,
      });
      return toast(ToastError.NO_HEADLIGHT, { className: "toastStyleDanger", toastId: "no_headlight", autoClose: 2000 });
    }

    let res;
    try {
      res = await getHeadlightById(school.headlight.id);
    } catch (err) {
      if (err?.statusCode === 403) {
        toast(ToastError.NO_PERMISSIONS, { className: "toastStyleDanger", toastId: "no_permissions", autoClose: 2000 });
      } else {
        toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
      }

      this.setState({
        loading: false,
        error: true,
      });
    }

    if (!res) {
      toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });

      this.setState({
        loading: false,
        error: true,
      });

      return;
    }

    const headlight = res;
    const selectedNews = [
      headlight.news1,
      headlight.news2,
      headlight.news3,
      headlight.news4,
      headlight.news5,
    ];

    this.setState({
      loading: false,
      headlightId: headlight._id,
      selectedNews: selectedNews,
      layout: headlight.layout,
    });
  }

  // Closes modals
  handleClose = () => {
    this.setState({
      modalNewsOpen: false,
      modalHeadlightOpen: false,
      selectedItem: null,
    });

    // Allows any user to use the escape key to dismiss modal
    if (this.state.lastClickedElement !== null) {
      this.state.lastClickedElement.focus();
    }
  };

  handleSuccessClose = () => {
    this.setState({
      modalSuccessOpen: false,
      successMessage: '',
    });
  };

  // Opens modal to select news item
  handleOpenSelectNews = (position, selectedItem) => {
    this.setState({
      modalNewsOpen: true,
      position: position,
      selectedItem: selectedItem,
    });
  };

  // Opens modal to update headlight
  handleOpenEditHeadlight = () => {
    this.setState({
      modalHeadlightOpen: true,
    });
  };

  // Handle news item selection on modal to select news item
  handleSelectNews = (position, selectedNews) => {
    this.setState((prevState) => {
      const currentlySelected = [...prevState.selectedNews];
      currentlySelected[position - 1] = selectedNews;

      return {
        ...prevState,
        modalNewsOpen: false,
        selectedNews: currentlySelected,
      };
    });
  };

  // Increase news item position on headlight
  increasePosition = (position) => {
    const newPosition = position + 1;
    const noRows = HeadlightScheme[this.state.layout]?.rows;

    // only update position if it is valid (below max number of rows)
    if (this.state.selectedNews[position] && newPosition <= noRows) {
      this.setState((prevState) => {
        let news = [...prevState.selectedNews];
        const current = news[position];
        const replacedNews = news[position + 1];
        news[newPosition] = current;
        news[position] = replacedNews;
        return {
          ...prevState,
          selectedNews: news,
        };
      });
    }
  };

  // Decrease news item position on headlight
  decreasePosition = (position) => {
    const newPosition = position - 1;

    // only update position if it is valid (above zero)
    if (this.state.selectedNews[position] && newPosition >= 0) {
      this.setState((prevState) => {
        let news = [...prevState.selectedNews];
        const current = news[position];
        const replacedNews = news[position - 1];
        news[newPosition] = current;
        news[position] = replacedNews;

        return {
          ...prevState,
          selectedNews: news,
        };
      });
    }
  };

  // Update school headlight news information
  updateHeadlight = async (e) => {
    this.setState({ tableLoading: true });

    const selectedNews = this.state.selectedNews;
    const headlightData = {
      news1: selectedNews[0],
      news2: selectedNews[1],
      news3: selectedNews[2],
      news4: selectedNews[3],
      news5: selectedNews[4],
    };

    let lastDefinedIndex;
    for (let index = selectedNews.length - 1; index >= 0; index--) {
      const element = selectedNews[index];
      if (element && !lastDefinedIndex) {
        lastDefinedIndex = index;
      }

      if (lastDefinedIndex && !element) {
        const msg = ToastError.NEWS_NOT_CONTINUOUS + lastDefinedIndex + '!';
        this.setState({ tableLoading: false });
        return toast(msg, { className: "toastStyleDanger", toastId: "news_not_continuous", autoClose: 2000 });
      }
    }

    try {
      await updateSchoolHeadlight(this.state.headlightId, headlightData);
    } catch (error) {
      this.setState({ tableLoading: false });
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
    }

    let school = { ...this.props.school };
    school.headlight = { ...school.headlight, ...headlightData };
    this.props.updateSchool(school);

    this.setState({
      tableLoading: false,
      modalSuccessOpen: true,
      successMessage: ToastSuccess.UPDATED_HEADLIGHT,
    });

    // toast(ToastSuccess.UPDATED_HEADLIGHT, { className: 'toastStyleSuccess',toastId: "updated_headlight", autoClose: 2000 });
  };

  // Update school headlight news layout and selected news
  updateLayout = async (layout) => {
    const selectedNews = [...this.state.selectedNews];
    this.setState({
      tableLoading: true,
      modalHeadlightOpen: false,
    });

    const noRows = HeadlightScheme[layout]?.rows;
    for (let i = noRows; i < selectedNews.length; i++) {
      selectedNews[i] = null;
    }

    const headlightData = {
      layout: layout,
      news1: selectedNews[0],
      news2: selectedNews[1],
      news3: selectedNews[2],
      news4: selectedNews[3],
      news5: selectedNews[4],
    };

    try {
      await updateSchoolHeadlight(this.state.headlightId, headlightData);
    } catch (error) {
      this.setState({ tableLoading: false });
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
    }

    let school = { ...this.props.school };
    school.headlight = { ...school.headlight, ...headlightData };
    this.props.updateSchool(school);

    this.setState({
      tableLoading: false,
      layout: layout,
      selectedNews: selectedNews,
      modalSuccessOpen: true,
      successMessage: ToastSuccess.UPDATED_LAYOUT,
    });

    // toast(ToastSuccess.UPDATED_LAYOUT, { className: 'toastStyleSuccess' ,toastId: "updated_layout", autoClose: 2000});
  };

  handlePreViz = () => {
    this.setState({
      preViz: true,
    });
  };

  handleClosePreViz = () => {
    this.setState({
      preViz: false,
    });
  };

  headlightInfo = () => {
    const selectedNews = this.state.selectedNews;
    const headlightData = {
      news1: selectedNews[0],
      news2: selectedNews[1],
      news3: selectedNews[2],
      news4: selectedNews[3],
      news5: selectedNews[4],
    };

    return headlightData;
  };

  schoolInfo = () => {
    const { school } = this.props;
    return school;
  };

  render() {
    const {
      loading,
      tableLoading,
      error,
      layout,
      selectedNews,
      modalNewsOpen,
      position,
      selectedItem,
      modalHeadlightOpen,
      successMessage,
      modalSuccessOpen,
      preViz,
    } = this.state;
    
    const { school } = this.props;
    const loadingClass = tableLoading ? 'bodyContainer pageLoading' : 'bodyContainer';

    return (
      <div>
        <MetaTags title="Manchete &bull; Gestão da Homepage | Projeto TRUE" />

        <DashHeader title="Gestão da Homepage" subtitle="Manchete" />
        <Header name={'help_headlight'} />

        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <DashError />
            ) : (
              <div className={loadingClass}>
                <div className="pageSectionContainer head">
                  <p className="pageHighlight">Ordenar Notícias</p>
                  <p className="highlightDescription">
                    Personaliza e ordena as notícias da manchete do jornal de acordo com o layout mostrado no lado direito
                  </p>
                </div>

                <div className="homepageWrapper">
                  <HeadlightsNews
                    layout={layout}
                    selectedNews={selectedNews}
                    handleOpen={this.handleOpenSelectNews}
                    increasePosition={this.increasePosition}
                    decreasePosition={this.decreasePosition}
                  />

                  <HeadlightsSidebar
                    layout={layout}
                    handleOpenEditHeadlight
                    updateHeadlight={this.updateHeadlight}
                    handleOpen={this.handleOpenEditHeadlight}
                    preViz={preViz}
                    handlePreViz={this.handlePreViz}
                    handleClosePreViz={this.handleClosePreViz}
                    headlightInfo={this.headlightInfo}
                    schoolInfo={this.schoolInfo}
                  />
                </div>

                {tableLoading ? <TableLoading /> : <></>}
              </div>
            )}
          </>
        )}

        {modalNewsOpen ? (
          <SelectNews
            school={school}
            currentlySelected={selectedItem}
            selectedItems={selectedNews}
            position={position}
            openModal={modalNewsOpen}
            handleClose={this.handleClose}
            handleSelectNews={this.handleSelectNews}
          />
        ) : (
          <></>
        )}

        {modalHeadlightOpen ? (
          <EditHeadlight
            layout={layout}
            openModal={modalHeadlightOpen}
            handleClose={this.handleClose}
            updateLayout={this.updateLayout}
          />
        ) : (
          <></>
        )}

        {modalSuccessOpen ? (
          <SuccessModal
            open={modalSuccessOpen}
            handleClose={this.handleSuccessClose}
            message={successMessage}
            schoolLink={school?.subdirectory}
          />
        ) : (
          <></>
        )}
        <div
          ref={(el) => {
            this.lastElement = el;
          }}
          tabIndex="-1"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  school: state.school,
});

const mapActionsToProps = {
  updateSchool: SchoolActions.updateSchool,
};

export default connect(mapStateToProps, mapActionsToProps)(Headlights);
