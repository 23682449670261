import React, { Component } from 'react';

//Icons
import ArrowDown from '../../../assets/img/icons/arrowDown.svg';
import ArrowUp from '../../../assets/img/icons/arrowUp.svg';
import EditIcon from '../../../assets/img/icons/editItem.svg';
import { extractContent } from '../../../utils/utils';

const SlotRows = [0, 1, 2, 3, 4];

class SlotsItems extends Component {

  checkIfSelected = (selectedSlots, value) => {
    return selectedSlots && (selectedSlots[value - 1]?.section || selectedSlots[value - 1]?.gallery);
  }

  getSlotValue = (slot) => {
    if (slot?.section) {
      return slot.section.sname;
    } else if (slot?.gallery) {
      return extractContent(slot.gallery.title);
    }

    return;
  }

  render() {
    const {
      selectedSlots,
      handleOpen, increasePosition, decreasePosition
    } = this.props;

    return (
      <div className="newsRowsContainer">
        {SlotRows.map((value) =>
          <div key={value} className="rowWrapper">
            <div className="blueSquare">
              <p className="squareNumber">
                {value + 1}
              </p>
            </div>

            {value === 0 ?
              <div className="greyRectangle noPointer">
                <div className="selectItem">
                  <p className="selectedItemTitle homepage">Últimas Notícias</p>
                </div>
              </div> :
              <button type='button' className="greyRectangle" onClick={() => handleOpen(value, selectedSlots[value - 1])}>
                <div className="selectItem">
                  {this.checkIfSelected(selectedSlots, value) ?
                    <>
                      <p className="selectedItemTitle homepage">{this.getSlotValue(selectedSlots[value - 1])}</p>
                      <img
                        className="editIcon"
                        src={EditIcon}
                        alt="Edit box"
                        height="22px"
                      />
                    </>
                    : <p className="chooseItemModal">Selecionar Caixa</p>
                  }
                </div>
              </button>
            }

            <div className="arrowWrapper">
              {value > 0 && value < 4 ? <img
                src={ArrowDown}
                alt="Arrow Down"
                onClick={() => increasePosition(value - 1)}
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    increasePosition(value - 1)
                  }
                }}
              /> : <></>}
              {value > 1 ? <img
                className={value === 4 ? "marginArrow" : ""}
                src={ArrowUp}
                alt="Arrow Up"
                onClick={() => decreasePosition(value - 1)}
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    decreasePosition(value - 1)
                  }
                }}
              /> : <></>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SlotsItems;