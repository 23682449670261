import React, { useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { UserActions } from '../../redux/actions';

import { useDispatch, useSelector } from 'react-redux';
import { Steps } from '../../models/enumHelpModal';

function ModalOnboarding(props) {
  const steps = props.steps;
  const indexProps = props.indexProps;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const updateUser = UserActions.updateUser;
  //const [currentStep, setCurrentStep] = useState(steps[0]);

  const [currentStep, setCurrentStep] = useState(
    indexProps ? steps[indexProps] : steps[0],
  );

  const [show, setShow] = useState(true);
  const lastIndex = steps[steps.length - 1].index;
  let btnNext = <></>;
  let btnPrevious = <></>;

  const previousStep = () => {
    if (currentStep.index === 0) return;
    setCurrentStep(steps[currentStep.index - 1]);
  };

  const nextStep = () => {
    if (currentStep.index === lastIndex) return;
    setCurrentStep(steps[currentStep.index + 1]);
  };

  const closeModal = () => {
    setShow(false);
    if (user?.onboarding === false) {
      const updatedUser = { ...user };
      updatedUser.onboarding = true;
      dispatch(updateUser(updatedUser));
    }
  };

  if (currentStep.index !== 0) {
    btnPrevious = (
      <Button className="px-5 mx-2 btnCancelNews" onClick={previousStep}>
        Anterior
      </Button>
    );
  } else {
    btnPrevious = (
      <Button
        className="px-5 mx-2 btnCancelNews"
        disabled
        onClick={previousStep}
      >
        Anterior
      </Button>
    );
  }

  if (currentStep.index !== lastIndex) {
    btnNext = (
      <Button className="px-5 mx-2 btnSubmitNews" onClick={nextStep}>
        Próximo
      </Button>
    );
  } else {
    btnNext = (
      <Button className="px-5 mx-2 btnSubmitNews" onClick={nextStep} disabled>
        Próximo
      </Button>
    );
  }

  return (
    <Modal
      id="modal-news-result"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={props.close ? props.close : closeModal}
    >
      <Modal.Header
        closeButton
        className="px-5 d-flex justify-content-between align-content-center"
      >
        <Modal.Title className="h5">{currentStep.header}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-3 pt-4">
        <>
          <img
            src={currentStep.img}
            className="gifInsideModal mx-auto d-block my-3 img-fluid"
            alt={currentStep.header}
          />
          <p className="text-left textModalMargin">{currentStep.text}</p>
        </>

        <div className="d-flex justify-content-center">
          <ProgressBar
            now={currentStep.progress}
            className="modalProgressBar"
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="pt-0">
        {btnPrevious} {btnNext}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOnboarding;
