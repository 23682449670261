import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { MenuActions } from '../../redux/actions';

import { ReactComponent as ArrowDownBlue } from '../../assets/img/icons/arrowDownBlue.svg';
import { ReactComponent as ArrowUpBlue } from '../../assets/img/icons/arrowUpBlue.svg';
import TrueLogo from '../../assets/logo/logo.png';
import PersonIcon from '../../assets/img/sideMenuIcons/person.svg';
import HomepageIcon from '../../assets/img/sideMenuIcons/homepage.svg';
import NewsIcon from '../../assets/img/sideMenuIcons/news.svg';
import PaintIcon from '../../assets/img/sideMenuIcons/brush.svg';
import SectionIcon from '../../assets/img/sideMenuIcons/sections.svg';
import SettingsIcon from '../../assets/img/sideMenuIcons/settings.svg';
import arrowLeftIcon from '../../assets/img/sideMenuIcons/arrow-left-circle.svg';

import EditorialIcon from '../../assets/img/sideMenuIcons/editorial.png';
import ReportIcon from '../../assets/img/sideMenuIcons/bug.png';
import JournalIcon from '../../assets/img/sideMenuIcons/journal.svg';

import { Roles } from '../../models/enum';
import './SideMenu.css';

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: true,
      position: -1,
      news: [],
      selectedNews: ['', '', '', '', ''],
      homeCollapsed: true,
      personalizationCollapsed: true,
      //menuExpand: true,
    };

    this.isActiveClass.bind(this);
    this.menuBehavior.bind(this);
    this.toggleHomeCollapse.bind(this);
    this.togglePersonalizationCollapse.bind(this);
  }

  isActiveClass = (pathnames) => {
    let isActive = false;
    for (let i = 0; i < pathnames.length; i++) {
      if (this.props.location?.pathname === pathnames[i]) {
        isActive = true;
        break;
      }
    }

    return `menuOption${isActive ? ' active' : ''}`;
  };

  menuBehavior = () => {
    if (this.props.menu === 'expanded' || !localStorage.getItem('menu_state')) {
      this.props.updateMenu('collapsed');
      localStorage.setItem('menu_state', 'collapsed');
    } else if (this.props.menu === 'collapsed') {
      this.props.updateMenu('expanded');
      localStorage.setItem('menu_state', 'expanded');
    }
  };

  toggleHomeCollapse = () => {
    if (this.state.homeCollapsed === false) {
      this.setState({ homeCollapsed: true });
    } else {
      this.setState({ homeCollapsed: false });
    }
  };

  togglePersonalizationCollapse = () => {
    if (this.state.personalizationCollapsed === false) {
      this.setState({ personalizationCollapsed: true });
    } else {
      this.setState({ personalizationCollapsed: false });
    }
  };

  render() {
    const { user, school } = this.props;

    return (
      <>
        {localStorage?.getItem('menu_state') === 'collapsed' ? (
          <div className="sideMenuCollapsed">
            <div className="sideMenuWrapepr">
              <div className="logoContainer">
                <button
                  onClick={this.menuBehavior}
                  className="bg-transparent border-0 arrowButton"
                >
                  <img src={arrowLeftIcon} alt="Abrir menu"></img>
                </button>
              </div>

              <div className="linksContainer">
                {user?.role?.name === Roles.Admin ? (
                  <NavLink
                    className="menuOption linkWrapper"
                    to="/dashboard/users"
                    title="Gestão Utilizadores"
                  >
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={PersonIcon} alt="Pessoa" />
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <NavLink
                    className="menuOption"
                    to="/dashboard/sections"
                    title="Gestão Secções"
                  >
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={SectionIcon} alt="Secção" />
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                <NavLink
                  className="menuOption"
                  to="/dashboard/news"
                  title="Gestão Notícias"
                >
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={NewsIcon} alt="Notícia" />
                    </div>
                  </div>
                </NavLink>

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <>
                    <div
                      className={this.isActiveClass([
                        '/dashboard/headlight',
                        '/dashboard/slots',
                      ])}
                      onClick={() => {
                        this.menuBehavior();
                        if (this.state.homeCollapsed) {
                          this.toggleHomeCollapse();
                        }
                      }}
                      title="Gestão Homepage"
                    >
                      <div className="linkWrapper menuTitle">
                        <div className="linkIcon">
                          <img src={HomepageIcon} alt="Página inicial" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <>
                    <div
                      className={this.isActiveClass([
                        '/dashboard/personalization/color',
                        '/dashboard/personalization/identity',
                      ])}
                      onClick={() => {
                        this.menuBehavior();
                        if (this.state.personalizationCollapsed) {
                          this.togglePersonalizationCollapse();
                        }
                      }}
                      title="Personalização Jornal"
                    >
                      <div className="linkWrapper menuTitle">
                        <div className="linkIcon">
                          <img src={PaintIcon} alt="Personalização" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <NavLink
                    className="menuOption"
                    to="/dashboard/editorial"
                    title="Ficha Técnica"
                  >
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={EditorialIcon} alt="Editorial" />
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                <NavLink
                  className="menuOption"
                  to="/dashboard/profile"
                  title="O meu perfil"
                >
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={SettingsIcon} alt="Definições" />
                    </div>
                  </div>
                </NavLink>

                <NavLink
                  className="menuOption"
                  to="/dashboard/report"
                  title="Reportar Problema"
                >
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={ReportIcon} alt="Reportar" />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="divLinkJournal">
              <a
                href={`/${school?.subdirectory}`}
                target="_blank"
                className="menuLinkJournal"
                rel="noreferrer"
              >
                <img src={JournalIcon} alt="Journal Icon" />
              </a>
            </div>
          </div>
        ) : (
          <div className="sideMenu">
            <div className="sideMenuWrapepr">
              <div className="logoContainer">
                <NavLink to="/dashboard/news">
                  <img src={TrueLogo} alt="Logótipo" height="70px" />
                </NavLink>
                <button
                  onClick={this.menuBehavior}
                  className="bg-transparent border-0 arrowButton"
                >
                  <img src={arrowLeftIcon} alt="Fechar menu"></img>
                </button>
              </div>

              <div className="linksContainer">
                {user?.role?.name === Roles.Admin ? (
                  <NavLink className="menuOption linkWrapper" to="/dashboard/users">
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={PersonIcon} alt="Pessoa" />
                      </div>
                      <span className="menuTxt">Gestão de Utilizadores</span>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <NavLink className="menuOption" to="/dashboard/sections">
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={SectionIcon} alt="Secção" />
                      </div>
                      <span className="menuTxt">Gestão de Secções</span>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                <NavLink className="menuOption" to="/dashboard/news">
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={NewsIcon} alt="Notícia" />
                    </div>
                    <span className="menuTxt">Gestão de Notícias</span>
                  </div>
                </NavLink>

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <>
                    <div
                      className={this.isActiveClass([
                        '/dashboard/headlight',
                        '/dashboard/slots',
                      ])}
                      style={{ cursor: 'pointer' }}
                      onClick={this.toggleHomeCollapse}
                    >
                      <div className="linkWrapper menuTitle">
                        <div className="linkIcon">
                          <img src={HomepageIcon} alt="Página inicial" />
                        </div>
                        <span className="menuTxt">Gestão de Homepage</span>
                      </div>
                      <div className="subMenuArrow" alt="Mostrar filtro">
                        {this.state.homeCollapsed ? <ArrowDownBlue /> : <ArrowUpBlue />}
                      </div>
                    </div>
                    {!this.state.homeCollapsed && (
                      <>
                        <NavLink
                          className="subMenuOption subMenuTxt"
                          to="/dashboard/headlight"
                        >
                          <span className="subMenuTitle">Manchete</span>
                        </NavLink>

                        <NavLink
                          className="subMenuOption subMenuTxt"
                          to="/dashboard/slots"
                        >
                          <span className="subMenuTitle">Caixas</span>
                        </NavLink>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <>
                    <div
                      className={this.isActiveClass([
                        '/dashboard/personalization/color',
                        '/dashboard/personalization/identity',
                      ])}
                      style={{ cursor: 'pointer' }}
                      onClick={this.togglePersonalizationCollapse}
                    >
                      <div className="linkWrapper menuTitle">
                        <div className="linkIcon">
                          <img src={PaintIcon} alt="Personalização" />
                        </div>
                        <span className="menuTxt">Personalização Jornal</span>
                      </div>
                      <div className="subMenuArrow" alt="Mostrar filtro">
                        {this.state.personalizationCollapsed ? (
                          <ArrowDownBlue />
                        ) : (
                          <ArrowUpBlue />
                        )}
                      </div>
                    </div>
                    {!this.state.personalizationCollapsed && (
                      <>
                        <NavLink
                          className="subMenuOption subMenuTxt"
                          to="/dashboard/personalization/color"
                        >
                          <span className="subMenuTitle">Paleta de cores</span>
                        </NavLink>

                        <NavLink
                          className="subMenuOption subMenuTxt"
                          to="/dashboard/personalization/identity"
                        >
                          <span className="subMenuTitle">Identidade do jornal</span>
                        </NavLink>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {user?.role?.name === Roles.Admin || user?.role?.name === Roles.Editor ? (
                  <NavLink className="menuOption" to="/dashboard/editorial">
                    <div className="linkWrapper">
                      <div className="linkIcon">
                        <img src={EditorialIcon} alt="Editorial" />
                      </div>
                      <span className="menuTxt">Ficha Técnica</span>
                    </div>
                  </NavLink>
                ) : (
                  <></>
                )}

                <NavLink className="menuOption" to="/dashboard/profile">
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={SettingsIcon} alt="Definições" />
                    </div>
                    <span className="menuTxt">O meu perfil</span>
                  </div>
                </NavLink>

                <NavLink className="menuOption" to="/dashboard/report">
                  <div className="linkWrapper">
                    <div className="linkIcon">
                      <img src={ReportIcon} alt="Reportar" />
                    </div>
                    <span className="menuTxt">Reportar Problema</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="divLinkJournal">
              <a
                href={`/${school?.subdirectory}`}
                target="_blank"
                className="menuLinkJournal"
                rel="noreferrer"
              >
                <img src={JournalIcon} alt="Jornal" />
                Ver Jornal
              </a>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  school: state.school,
  menu: state.menu,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (value) => dispatch(MenuActions.updateMenu(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));
