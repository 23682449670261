import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getConfigurationById = (cid) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.CONFIGURATION}/${cid}`
  );
}

export const finalizeConfiguration = (cid, sectionTemplate) => {
  const data = {
    sectionTemplate: sectionTemplate
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.CONFIGURATION}/finalize/${cid}`,
    data
  );
}

export const validateUser = (name, surname, email, role, emailcheck) => {
  const data = {
    name: name,
    surname: surname,
    email: email,
    emailcheck: emailcheck
  };

  if (role) { data.role = role; }

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.CONFIGURATION}/validate-user`,
    data
  );
}

export const validateSchool = (name, journal, subdirectory, district, municipality, logo) => {
  const data = {
    name: name,
    journal: journal,
    subdirectory: subdirectory,
    district: district,
    municipality: municipality,
    logo: logo
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.CONFIGURATION}/validate-school`,
    data
  );
}

export const updateConfigurationById = (cid, config) => {
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.CONFIGURATION}/${cid}`,
    config
  )
}