import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditorActions } from '../../redux/actions';
import InfoIcon from '../../assets/img/icons/info.svg';

function FormRelatedNews() {
  const dispatch = useDispatch();

  const addedNews = useSelector((state) => state.textEditor.relatedNews);
  const [render, setRender] = useState([]);

  function removeItem(item) {
    const relatedNews = addedNews.filter((news) => news.URL !== item.URL);
    dispatch(TextEditorActions.updateKey('relatedNews', relatedNews));
  }

  useEffect(() => {
    let items = [];

    addedNews?.forEach((news) => {
      items.push(
        <div className="related-news-item pill" key={news.URL}>
          <a
            href={news.URL}
            rel="noreferrer"
            target="_blank"
            className="related-news-title link-pill"
          >
            {news.Title}
          </a>
          <Button
            className="btn-closing-pill close-related"
            onClick={() => removeItem(news)}
          />
        </div>,
      );
    });

    setRender(items);
  }, [addedNews]);

  return (
    <Row id="inputRelatedNews">
      <Form.Group
        as={Col}
        className="form-bottom-margin"
        controlId="formInputRelatedNews"
      >
        <Form.Label className="font-title label-bottom-margin">
          Notícias relacionadas adicionadas (opcional)
        </Form.Label>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              São as notícias do jornal Público que selecionaste no separador
              “Relacionadas”. Estas ficam associadas à tua notícia e aparecerão
              como sugestão de leitura no jornal da tua escola. Podes apagar
              clicando no “X”. Caso queiras aceder à notícia indicada, basta
              clicar no título da mesma.
            </Tooltip>
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
        </OverlayTrigger>
        <div className="form-control no-input related-news-height">
          {render}
        </div>
      </Form.Group>
    </Row>
  );
}

export default FormRelatedNews;
