import { IP, PORT, PROTOCOL, PATH } from '../utils/globals';

export const Resource = {
  AUTH: 'auth',
  CONFIGURATION: 'configurations',
  EMAILS: 'emails',
  HEADLIGHTS: 'headlights',
  NEWS: 'news',
  SCHOOLS: 'schools',
  SEARCH: 'search',
  SEARCHPUBLICO: 'search/jpublico',
  SEARCHLASTNEWSPUBLICO: 'search/lastPublicoNews',
  SEARCHPOPULARNEWSPUBLICO: 'search/popularPublicoNews',
  SECTIONS: 'sections',
  UPLOAD: 'upload',
  UPLOAD_FILES: 'upload/files',
  USERS: 'users',
  USERS_PERMISSIONS: 'users-permissions',
  REPORT: 'report-errors',
  EDITORIALS: 'editorials',
  EMAILPASSWORD: 'email-password'
};

export const HTTPMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

export const UserStatus = {
  active: 'Ativo',
  inactive: 'Inativo',
  in_validation: 'Em validação',
};

class API {
  constructor(protocol, ip, port, path) {
    this.protocol = protocol ?? 'http';
    this.ip = ip ?? 'localhost';
    this.port = parseInt(port ?? '443');
    this.path = path ?? '';

    this.base = `${this.protocol}://${this.ip}:${this.port}${this.path}`;
  }
}

export const fetchRequest = (
  method,
  path,
  body = {},
  authenticated = false,
  formData = false,
  cors = true,
) => {
  let headers = {};

  const userToken = localStorage.getItem('userToken');
  if (userToken && authenticated) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  if (!formData) {
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
  }

  const options = { headers: headers };

  if (cors) options.mode = 'cors';

  if (method !== HTTPMethod.GET) {
    options.body = formData ? body : JSON.stringify(body);
    options.method = method;
  }

  // console.log("[api] fetchRequest path", path);

  return new Promise((res, rej) => {
    fetch(path, options)
      .then((result) => {
        if (result.ok)
          result
            .json()
            .then((final) => res(final))
            .catch((error) => rej(error));
        else {
          result
            .json()
            .then((error) => rej(error))
            .catch(() => rej(result.statusText));
        }
      })
      .catch((error) => rej(error));
  });
};

export const apiInfo = new API(PROTOCOL, IP, PORT, PATH);

export const searchApiInfo = new API(PROTOCOL, IP, PORT, '/searchengine');
