import React from 'react';

import SearchIcon from '../../../assets/img/icons/search.svg';

export default function Search(props) {
  return (
    <div className="searchFilterWrapper">
      <img src={SearchIcon} alt={props.imgAlt} height="20px"/>
      <input
        type="text"
        className={`searchFilter ${props.small ? 'small' : ''}`}
        name="searchValue"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}