import React, { useEffect, useState } from 'react';
import { isValidElement } from 'react';
import { Parser } from 'html-to-react';

import Gallery from '../../../templates/gallery/Gallery';
import Video from '../video/Video';
import { apiInfo } from '../../../api/api';

export default function ArticleBody({ newsItem, school }) {
  const mainColor = school?.palette?.color.main;

  const [nodes, setNodes] = useState(0);
  const [body, setBody] = useState(null);

  const journalName = school?.subdirectory;

  useEffect(() => {
    if (!newsItem) return;
    const htmlToReactParser = new Parser();
    const newBody = `<div>${newsItem?.body}</div>`;
    const newsBodyHtml = htmlToReactParser.parse(newBody);

    setBody(isValidElement(newsBodyHtml) ? newsBodyHtml : newsItem?.body);

    let index = 0;
    const countParagraphs = (node) => {
      const children = node?.props?.children;
      const isValid = children && Array.isArray(children);

      if (children && node?.type === 'p') {
        index++;
      }

      return isValid ? children.map(countParagraphs) : null;
    };
    countParagraphs(newsBodyHtml);
    setNodes(index);
  }, [newsItem]);

  const getMultimedia = (news) => {
    if (!news?.attachments && !news?.videoUrl) {
      return <></>;
    }

    const multipleImages = news?.attachments.length > 1;
    const imageAndVideo = news?.attachments.length === 1 && news?.videoUrl;

    if (multipleImages || imageAndVideo) {
      return (
        <div className="galleryArticleContainer galleryArticleWidth">
          <Gallery
            news={newsItem}
            isHomepage={false}
            journalName={journalName}
            color={mainColor}
          />
        </div>
      );
    } else if (news?.attachments.length === 1) {
      return (
        <div className="articleContent articleWidth imgContainer">
          <img
            className="articleImg"
            src={`${apiInfo.base}${news.attachments[0].url}`}
            alt={news.attachments[0].caption}
          />
          <span className="articleImgDesc">{news.attachments[0].caption}</span>
        </div>
      );
    } else if (news?.videoUrl) {
      return (
        <div className="articleContent articleWidth imgContainer">
          <Video title="" videoUrl={news?.videoUrl} />
        </div>
      );
    }

    return <></>;
  };


  return (
    <>
      {getMultimedia(newsItem)}

      <div className="articleContent articleWidth">
        <div className={`articleBody ${nodes > 1 ? 'multiple' : 'single'}`}>
          {newsItem?.body ? body : <></>}
        </div>
      </div>
    </>
  );
}
