import React, { useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { Steps } from '../../models/enumHelpModal';

function ModalHelp(props) {
  const steps = props.steps;
  console.log(steps);

  const [currentStep, setCurrentStep] = useState(steps[0]);
  const lastIndex = steps[steps.length - 1].index;
  let btnNext = <></>;
  let btnPrevious = <></>;

  const previousStep = () => {
    if (currentStep.index === 0) return;
    setCurrentStep(steps[currentStep.index - 1]);
  };

  const nextStep = () => {
    if (currentStep.index === lastIndex) return;
    setCurrentStep(steps[currentStep.index + 1]);
  };

  if (currentStep.index !== 0) {
    btnPrevious = (
      <Button className="px-5 mx-2 btnCancelNews" onClick={previousStep}>
        Anterior
      </Button>
    );
  } else {
    btnPrevious = (
      <Button
        className="px-5 mx-2 btnCancelNews"
        disabled
        onClick={previousStep}
      >
        Anterior
      </Button>
    );
  }

  if (currentStep.index !== lastIndex) {
    btnNext = (
      <Button className="px-5 mx-2 btnSubmitNews" onClick={nextStep}>
        Próximo
      </Button>
    );
  } else {
    btnNext = (
      <Button className="px-5 mx-2 btnSubmitNews" onClick={nextStep} disabled>
        Próximo
      </Button>
    );
  }

  return (
    <Modal
      id="modal-news-result"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show
      onHide={props.close}
    >
      <Modal.Header
        closeButton
        className="px-5 d-flex justify-content-between align-content-center"
      >
        <Modal.Title className="h5">{currentStep.header}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-3 pt-4">
        {(currentStep.index !== 0 && steps === Steps.MODAL_HELP_DEFAULT) ||
        steps !== Steps.MODAL_HELP_DEFAULT ? (
          <>
            <img
              src={currentStep.img}
              className="imageInsideModal mx-auto d-block my-3 img-fluid"
              alt={currentStep.header}
            />
            <p className="text-center textModalMargin">{currentStep.text}</p>
          </>
        ) : (
          <div className="d-flex justify-content-center pt-2 pb-4">
            <iframe
              width="672"
              height="378"
              src="https://www.youtube.com/embed/Na1fAxErb68"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <ProgressBar
            now={currentStep.progress}
            className="modalProgressBar"
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="pt-0">
        {btnPrevious} {btnNext}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalHelp;
