import { fetchRequest, HTTPMethod } from './api';
import { CORRECTOR_PATH, PROTOCOL, IP, PORT } from '../utils/globals';


const urlCheckErrors = `${PROTOCOL}://${IP}:${PORT}${CORRECTOR_PATH}/check-errors`;
const urlRelatedWords = `${PROTOCOL}://${IP}:${PORT}${CORRECTOR_PATH}/related-words`;
const urlPlagerism = `${PROTOCOL}://${IP}:${PORT}${CORRECTOR_PATH}/check-plagiarism`;

export const checkErrors = (data) =>
  fetchRequest(HTTPMethod.POST, urlCheckErrors, data, true, true, true);

export const checkPlagirism = (data) =>
  fetchRequest(HTTPMethod.POST, urlPlagerism, data, true, true, true);

export const relatedWords = (data) =>
  fetchRequest(HTTPMethod.POST, urlRelatedWords, data, true, true, true);
