import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '@mui/material/Modal';

//Components
import DashHeader from '../../../components/dashboard/dashheader/DashHeader';
import MetaTags from '../../../components/metatags/MetaTags';
import Header from '../../../components/newsEditor/Header';
import Loading from '../../../components/loading/Loading';
import SuccessModal from '../../../components/dashboard/successmodal/SuccessModal';
import SelectSlot from './ModalSelectSlot';
import SlotsItems from './SlotsItems';
import TableLoading from '../../../components/tableloading/TableLoading';
import { updateSchoolSlots } from '../../../api/schools';
import { SlotOptions, ToastError, ToastSuccess } from '../../../models/enum';
import { SchoolActions } from '../../../redux/actions';

import Homepage from '../../frontend/homepage/Homepage';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as SlotScheme } from '../../../assets/img/homepage/slots.svg';

class Slots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loading state
      loading: true,
      tableLoading: false,

      // modal information
      step: 1,
      position: -1,
      selectedItem: null, // selected item information
      selectedOption: '', // option selected by user: section or gallery

      // success modal
      modalSuccessOpen: false,
      successMessage: '',

      // slots information
      selectedSlots: ['', '', '', '', ''],

      // pre visualization
      preViz: false,
      open: true,

      //Handle modal accessibility
      lastClickedElement: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSuccessClose = this.handleSuccessClose.bind(this);
    this.updateSlots = this.updateSlots.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.increasePosition = this.increasePosition.bind(this);

    this.previzSlots = this.previzSlots.bind(this);
    this.handlePreViz = this.handlePreViz.bind(this);
    this.handleClosePreViz = this.handleClosePreViz.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.getSlots();

    //Handle modal accessibility
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleClick = (event) => {
    this.setState({ lastClickedElement: event.target });
  };

  componentDidUpdate(prevProps) {
    const { school } = this.props;
    const { loading } = this.state;
    if (school !== prevProps.school && loading) {
      this.getSlots();
    }
  }

  // Retrieve selected slots
  async getSlots() {
    const { school } = this.props;
    if (!school) return;

    this.setState({
      loading: false,
      selectedSlots: school.slots
    });
  }

  // Opens modal
  handleOpen = (position, selectedItem) => {
    let selectedOption;

    const isSelected = selectedItem && (selectedItem.section || selectedItem.gallery);
    if (isSelected) {
      selectedOption = selectedItem.section ? SlotOptions.Section : SlotOptions.Gallery;
    }

    this.setState({
      openModal: true,
      position: position,
      selectedItem: isSelected ? selectedItem : null,
      step: isSelected ? 2 : 1,
      selectedOption: selectedOption,
    });
  };

  // Closes modal
  handleClose = () => {
    this.setState({
      openModal: false,
      selectedItem: null,
      step: 1,
      selectedOption: '',
    });

    // Allows any user to use the escape key to dismiss modal
    if (this.state.lastClickedElement !== null) {
      this.state.lastClickedElement.focus();
    }
  };

  handleSuccessClose = () => {
    this.setState({
      modalSuccessOpen: false,
      successMessage: '',
    });
  };

  // Handle news item selection on modal
  handleSelectItem = (position, selectedItem) => {
    this.setState((prevState) => {
      const currentlySelected = [...prevState.selectedSlots];
      currentlySelected[position - 1] = selectedItem;
      return {
        ...prevState,
        openModal: false,
        selectedSlots: currentlySelected,
      };
    });
  };

  // Increase slot item position
  increasePosition = (position) => {
    const { selectedSlots } = this.state;

    // Swap the elements at index1 and index2
    let index1 = position;
    let index2 = position + 1;

    if (selectedSlots[position] && index2 <= 4) {
      this.setState((prevState) => {
        [selectedSlots[index1], selectedSlots[index2]] = [
          selectedSlots[index2],
          selectedSlots[index1],
        ];
        return {
          ...prevState,
          openModal: false,
          selectedSlots: selectedSlots,
        };
      });
    }
  };

  // Decrease slot item position
  decreasePosition = (position) => {
    const { selectedSlots } = this.state;

    // Swap the elements at index1 and index2
    let index1 = position;
    let index2 = position - 1;

    if (selectedSlots[position] && index2 >= 0) {
      this.setState((prevState) => {
        [selectedSlots[index1], selectedSlots[index2]] = [
          selectedSlots[index2],
          selectedSlots[index1],
        ];

        return {
          ...prevState,
          openModal: false,
          selectedSlots: selectedSlots,
        };
      });
    }
  };

  updateSlots = async (e) => {
    const { school } = this.props;
    const { selectedSlots } = this.state;
    this.setState({ tableLoading: true });

    let lastDefinedIndex;
    for (let index = selectedSlots.length - 1; index >= 0; index--) {
      const element = selectedSlots[index];
      const isSelected = element && (element.section || element.gallery);

      if (isSelected && !lastDefinedIndex) {
        lastDefinedIndex = index;
      }

      if (lastDefinedIndex && !isSelected) {
        const position = lastDefinedIndex + 1;
        const msg = ToastError.SLOTS_NOT_CONTINUOUS + position + '!';
        this.setState({ tableLoading: false });
        return toast(msg, { className: 'toastStyleDanger',toastId: 'slots_not_continuous', autoClose: 2000 });
      }
    }

    try {
      await updateSchoolSlots(school.id, selectedSlots);
    } catch (err) {
      this.setState({ tableLoading: false });
      toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger' ,toastId: 'general_error', autoClose: 2000 });
      return;
    }

    let newSchool = { ...school };
    newSchool = { ...newSchool, slots: selectedSlots };
    this.props.updateSchool(newSchool);

    this.setState({
      tableLoading: false,
      modalSuccessOpen: true,
      successMessage: ToastSuccess.UPDATED_SLOTS,
    });

    // toast(ToastSuccess.UPDATED_SLOTS, { className: 'toastStyleSuccess', toastId: 'updated_slots', autoClose: 2000 });
  };

  previzSlots = () => {
    const { selectedSlots } = this.state;
    return selectedSlots;
  };

  handlePreViz = () => {
    this.setState({
      preViz: true,
    });
  };

  handleClosePreViz = () => {
    this.setState({
      preViz: false,
    });
  };

  render() {
    const {
      loading,
      tableLoading,
      selectedSlots,
      news,
      openModal,
      position,
      selectedItem,
      step,
      selectedOption,
      successMessage,
      modalSuccessOpen,
      open,
      preViz,
    } = this.state;
    const { school } = this.props;

    const loadingClass = tableLoading ? 'bodyContainer pageLoading' : 'bodyContainer';

    return (
      <div>
        <MetaTags title="Caixas &bull; Gestão da Homepage | Projeto TRUE" />

        <DashHeader title="Gestão da Homepage" subtitle="Caixas" />
        <Header name={'help_slots'} />

        {loading ? (
          <Loading />
        ) : (
          <div className={loadingClass}>
            <div className="pageSectionContainer head">
              <p className="pageHighlight">Ordenar Caixas</p>
              <p className="highlightDescription">
                Personaliza e ordena o conteúdo da Homepage depois da manchete do jornal
              </p>
            </div>

            <div className="homepageWrapper">
              <SlotsItems
                selectedSlots={selectedSlots}
                handleOpen={this.handleOpen}
                increasePosition={this.increasePosition}
                decreasePosition={this.decreasePosition}
              />

              <div className="rightSidebar">
                <div className="schemeContainer">
                  <SlotScheme className="boxSquare" />

                  <div className="secondaryContainer btnMargin">
                    <button className="secondaryBtnBlue" onClick={this.handlePreViz}>
                      Pré-visualizar
                    </button>
                  </div>
                </div>

                <div className="btnContainer">
                  <button className="mainBtn" onClick={this.updateSlots}>
                    Guardar Alterações
                  </button>
                </div>
              </div>
            </div>

            {tableLoading ? <TableLoading /> : <></>}
          </div>
        )}

        {openModal ? (
          <SelectSlot
            news={news}
            selectedSlots={selectedSlots}
            position={position}
            selectedItem={selectedItem}
            openModal={openModal}
            handleClose={this.handleClose}
            handleSelectItem={this.handleSelectItem}
            step={step}
            selectedOption={selectedOption}
          />
        ) : (
          <></>
        )}

        {modalSuccessOpen ? (
          <SuccessModal
            open={modalSuccessOpen}
            handleClose={this.handleSuccessClose}
            message={successMessage}
            schoolLink={school?.subdirectory}
          />
        ) : (
          <></>
        )}

        {preViz === true ? (
          <Modal
            open={open}
            onClose={this.handleClosePreViz}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: 'scroll' }}
            disableScrollLock={true}
          >
            <div
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '5%',
                //marginBottom: "auto",
                width: '85%',
                transform: 'translate(0%, 0%)',
                backgroundColor: 'white',
                border: '15px solid #FFFFFF',
                borderRadius: '4px',
              }}
            >
              <IconButton sx={{ float: 'right' }} onClick={this.handleClosePreViz}>
                <CloseIcon />
              </IconButton>
              <Homepage school={school} slot={this.previzSlots()} />
            </div>
          </Modal>
        ) : (
          <></>
        )}
        <div
          ref={(el) => {
            this.lastElement = el;
          }}
          tabIndex="-1"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  school: state.school,
});

const mapActionsToProps = {
  updateSchool: SchoolActions.updateSchool,
};

export default connect(mapStateToProps, mapActionsToProps)(Slots);