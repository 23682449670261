import React from 'react';
import { connect } from 'react-redux';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { toast } from 'react-toastify';

import ModalAddUser from './ModalAddUser';
import ModalEditProfile from './ModalEditUser';
import Header from '../../components/newsEditor/Header';

import Filter from '../../templates/filter/Filter';
import DashError from '../../components/dashboard/dasherror/DashError';
import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Loading from '../../components/loading/Loading';
import TableLoading from '../../components/tableloading/TableLoading';
import MetaTags from '../../components/metatags/MetaTags';
import Pagination from '../../components/dashboard/pagination/Pagination';
import Search from '../../components/dashboard/search/Search';
import { getCurrentData, getNewCheckedValues, getStatusInfo } from '../../utils/utils';
import {
  getUsersBySchool,
  deleteUser,
  updateUserInfo,
  resendEmail,
} from '../../api/users';
import { getUserRoles } from '../../api/permissions';
import { USER_STATUS } from '../../utils/globals';
import { ToastError, ToastSuccess } from '../../models/enum';
//Icons
//import EditIcon from '../../assets/img/icons/edit.svg';
import ArrowDown from '../../assets/img/icons/arrowDown.svg';
import ArrowUp from '../../assets/img/icons/arrowUp.svg';

import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import { Roles } from '../../models/enum';

import ModalSetCoordinator from './ModalSetCoordinator';
import ModalDeleteUser from './ModalDeleteUser';

const UserFilters = {
  STATE: 'stateValues',
  TYPE: 'typeValues',
};

const StateFilters = [
  USER_STATUS.active,
  USER_STATUS.inactive,
  USER_STATUS.in_validation,
];

class UsersManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      tableLoading: false,
      users: [],
      usersSlice: [],
      searchValue: '',
      openModal: false,
      openCoordinatorModal: false,
      openDeleteModal: false,
      openFirstFilter: false,
      openSecondFilter: false,
      currentUser: '',
      stateValues: [],
      typeValues: [],
      roles: [],
      page: 1,
      lastClickedElement: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFirstFilter = this.handleFirstFilter.bind(this);
    this.handleSecondFilter = this.handleSecondFilter.bind(this);
    this.cleanFilter = this.cleanFilter.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenCoordinatorModal = this.handleOpenCoordinatorModal.bind(this);
    this.handleCloseCoordinatorModal = this.handleCloseCoordinatorModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.deleteUserFunc = this.deleteUserFunc.bind(this);
    this.assignCoordination = this.assignCoordination.bind(this);
  }

  componentDidMount() {
    this.getUsers();

    //handle filter accessibility
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      if (this.state.openFirstFilter) {
        this.setState({ openFirstFilter: false });
      }
      if (this.state.openSecondFilter) {
        this.setState({ openSecondFilter: false });
      }
    }
  };

  handleClick = (event) => {
    this.setState({ lastClickedElement: event.target });
  };

  componentDidUpdate(prevProps) {
    if (this.props.school !== prevProps.school && this.state.loading) {
      this.getUsers();
    }
  }

  getUsers = () => {
    const { school } = this.props;
    if (!school) return;

    let users_prom = getUsersBySchool(school.id);
    let roles_prom = getUserRoles();

    Promise.all([users_prom, roles_prom])
      .then((results) => {
        const usersList = results[0];
        let rolesList = results[1].roles;
        rolesList = rolesList.filter((rol) => rol.name !== 'Public');

        this.setState({
          users: usersList,
          usersSlice: usersList,
          roles: rolesList,
          tableLoading: false,
          loading: false,
        });
      })
      .catch((err) => {
        if (err?.statusCode === 403) {
          toast(ToastError.NO_PERMISSIONS, {
            className: 'toastStyleDanger',
            toastId: 'no_permissions',
            autoClose: 2000,
          });
        } else {
          toast(ToastError.GENERAL_ERROR, {
            className: 'toastStyleDanger',
            toastId: 'general_error',
            autoClose: 2000,
          });
        }

        this.setState({
          error: true,
          tableLoading: false,
          loading: false,
        });
      });
  };

  changeHandlerSearch = (e) => {
    const searchValue = e.target.value;

    this.setState(
      {
        searchValue: searchValue,
      },
      () => {
        this.applyFilters();
      },
    );
  };

  changeHandlerCheckbox = (e, type) => {
    const checkedValue = e.target.value;
    const newValue = getNewCheckedValues(checkedValue, this.state[type]);

    this.setState(
      {
        [type]: newValue,
      },
      () => {
        this.applyFilters();
      },
    );
  };

  //open modal
  handleOpen(currentUser, loggedUser) {
    if (currentUser?.id !== loggedUser?.id) {
      this.setState({
        openModal: true,
        currentUser: currentUser,
      });
    }
  }

  //open second modal
  handleOpenCoordinatorModal(user) {
    this.setState({
      openCoordinatorModal: true,
      currentUser: user,
    });
  }

  async handleResendEmail(user) {
    try {
      await resendEmail(user.name, user.surname, user.email, user.role.id);
    } catch (error) {
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    return toast(ToastSuccess.EMAIL_SENT, {
      className: 'toastStyleSuccess',
      toastId: 'send_email',
      autoClose: 2000,
    });
  }

  handleCloseCoordinatorModal() {
    this.setState({
      openCoordinatorModal: false,
    });
  }

  //open delete modal
  handleOpenDeleteModal(user) {
    this.setState({
      openDeleteModal: true,
      currentUser: user,
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      openDeleteModal: false,
    });
  }

  //close modal
  handleModalClose(openState) {
    this.setState({
      openModal: openState,
      currentUser: '',
    });
  }

  //limpar filtros de pesquisa
  cleanFilter(type) {
    let newState;
    if (type === UserFilters.STATE) {
      newState = {
        stateValues: [],
        openFirstFilter: false,
      };
    } else if (type === UserFilters.TYPE) {
      newState = {
        typeValues: [],
        openSecondFilter: false,
      };
    }

    if (newState) {
      this.setState(newState, () => {
        this.applyFilters();
      });
    }
  }

  getFullName(name, surname) {
    const fullName = name + ' ' + surname;
    return fullName.toLowerCase();
  }

  applyFilters() {
    const users = this.state.users.slice();
    let result = users;

    if (this.state.stateValues.length > 0) {
      result = result.filter((usr) => {
        const isInactive =
          usr.blocked && this.state.stateValues.includes(USER_STATUS.inactive);
        const isActive =
          usr.confirmed && this.state.stateValues.includes(USER_STATUS.active);
        const isInValidation =
          !usr.confirmed &&
          !usr.blocked &&
          this.state.stateValues.includes(USER_STATUS.in_validation);

        if (isInactive || isActive || isInValidation) {
          return true;
        } else return false;
      });
    }

    if (this.state.typeValues.length > 0) {
      result = result.filter((usr) => this.state.typeValues.includes(usr.role?.name));
    }

    const searchValue = this.state.searchValue.toLowerCase();
    if (searchValue !== '') {
      const searchByName = result.filter((x) =>
        this.getFullName(x.name, x.surname).includes(searchValue),
      );
      const searchBySurname = result.filter((x) => x.surname.includes(searchValue));
      const searchByEmail = result.filter((x) => x.email.includes(searchValue));

      const concatSearches = searchByName.concat(searchBySurname, searchByEmail);
      const setSearches = new Set(concatSearches);

      result = Array.from(setSearches);
    }

    this.setState({
      usersSlice: result,
    });
  }

  //abrir e fechar o filtro de estados
  handleFirstFilter() {
    this.setState({
      openFirstFilter: !this.state.openFirstFilter,
      openSecondFilter: false,
    });
  }

  //abrir e fechar o filtro de tipos de utilizador
  handleSecondFilter() {
    this.setState({
      openSecondFilter: !this.state.openSecondFilter,
      openFirstFilter: false,
    });
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage,
    });
  }

  addUser() {
    this.setState({ tableLoading: true, searchValue: '' });
    this.getUsers();
  }

  editUser() {
    this.setState({ tableLoading: true, searchValue: '' });
    this.getUsers();
  }

  deleteUserFunc = async () => {
    const { currentUser } = this.state;
    this.setState({ tableLoading: true });
    this.handleCloseDeleteModal();

    try {
      await deleteUser(currentUser.id);
    } catch (error) {
      this.setState({ tableLoading: false });
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    this.setState({ page: 1 });
    this.getUsers();
  };

  assignCoordination = async () => {
    const { currentUser } = this.state;
    const { user } = this.props;
    this.setState({ tableLoading: true, searchValue: '' });

    const ableCoordination = (currentUser.isCoordinator = true);

    const disableCoordination = (user.isCoordinator = false);

    const proms = [];

    const usrInfo = updateUserInfo(
      currentUser.id,
      currentUser.email,
      currentUser.name,
      currentUser.surname,
      currentUser.role,
      currentUser.confirmed,
      currentUser.blocked,
      ableCoordination,
    );

    proms.push(usrInfo);

    const userInfo = updateUserInfo(
      user.id,
      user.email,
      user.name,
      user.surname,
      user.role,
      user.confirmed,
      user.blocked,
      disableCoordination,
    );

    proms.push(userInfo);

    try {
      await Promise.all(proms);
    } catch (error) {
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    this.getUsers();

    this.handleCloseCoordinatorModal();
  };

  render() {
    const { searchValue } = this.state;
    const { user } = this.props;

    const currentData = getCurrentData(this.state.usersSlice, this.state.page);
    const loadingClass = this.state.tableLoading
      ? 'tableContainer pageLoading'
      : 'tableContainer';

    return (
      <div>
        <MetaTags title="Gestão de Utilizadores | Projeto TRUE" />

        <DashHeader title="Gestão de Utilizadores" />
        <Header name={'help_users'} />

        {this.state.loading ? (
          <Loading />
        ) : (
          <>
            {this.state.error ? (
              <DashError />
            ) : (
              <div className="bodyContainer">
                <div className="optionsContainer">
                  <Search
                    imgAlt="Pesquisar utilizadores"
                    placeholder="Pesquisar por utilizador"
                    value={searchValue}
                    onChange={this.changeHandlerSearch}
                    small={true}
                  />

                  <div className="filtersContainer">
                    <div className="filterOptions">
                      <p className="filterContainerTitle">Filtros:</p>

                      <div className="setFilterWrapper">
                        <div className="filterBox" onClick={this.handleFirstFilter}>
                          <p className="filterTitle">Estado</p>
                          <button type="button" className="accessibleBtn">
                            <img
                              className="filterArrow"
                              src={this.state.openFirstFilter ? ArrowUp : ArrowDown}
                              alt="Mostrar filtro"
                            />
                          </button>
                        </div>

                        {this.state.openFirstFilter ? (
                          <Filter
                            filterOptions={StateFilters}
                            filterType={UserFilters.STATE}
                            selectedValues={this.state[UserFilters.STATE]}
                            changeHandlerCheckbox={this.changeHandlerCheckbox}
                            cleanFilter={() => this.cleanFilter(UserFilters.STATE)}
                          />
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="setFilterWrapper">
                        <div className="filterBox" onClick={this.handleSecondFilter}>
                          <p className="filterTitle">Tipo de Utilizador</p>
                          <button type="button" className="accessibleBtn">
                            <img
                              className="filterArrow"
                              src={this.state.openSecondFilter ? ArrowUp : ArrowDown}
                              alt="Mostrar filtro"
                            />
                          </button>
                        </div>

                        {this.state.openSecondFilter ? (
                          <Filter
                            filterOptions={this.state.roles.map((rol) => rol.name)}
                            filterType={UserFilters.TYPE}
                            selectedValues={this.state[UserFilters.TYPE]}
                            changeHandlerCheckbox={this.changeHandlerCheckbox}
                            cleanFilter={() => this.cleanFilter(UserFilters.TYPE)}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <ModalAddUser roles={this.state.roles} addUser={this.addUser} />
                  </div>
                </div>

                <div className={loadingClass}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Tipo de Utilizador</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Coordenador</TableCell>
                        <TableCell>Opções</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentData.map((usr) => (
                        <TableRow
                          className="tableAttributesWrapper fill"
                          role="button"
                          key={usr.id}
                        >
                          <TableCell
                            className="attributeWidth"
                            onClick={this.handleOpen.bind(this, usr, user)}
                          >
                            <p className="attribute">
                              {usr.name} {usr.surname}
                            </p>
                          </TableCell>

                          <TableCell
                            className="attributeWidthEmail"
                            onClick={this.handleOpen.bind(this, usr, user)}
                          >
                            <p className="attribute">{usr.email}</p>
                          </TableCell>

                          <TableCell
                            className="attributeWidth"
                            onClick={this.handleOpen.bind(this, usr, user)}
                          >
                            <p className="attribute">{usr.role?.name}</p>
                          </TableCell>

                          <TableCell
                            className="attributeWidth"
                            onClick={this.handleOpen.bind(this, usr, user)}
                          >
                            <div className="tableStateWrapper">
                              <div className="active-buttons">
                                <img
                                  src={getStatusInfo(usr.confirmed, usr.blocked).color}
                                  alt="Cor do estado"
                                  height="9px"
                                />
                              </div>
                              <p className="attribute">
                                {getStatusInfo(usr.confirmed, usr.blocked).status}
                              </p>
                            </div>
                          </TableCell>

                          {user?.role?.name === Roles.Admin &&
                          user?.isCoordinator === true ? (
                            <TableCell className="attributeWidth">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginRight: '40%',
                                }}
                              >
                                {usr.isCoordinator === true ? (
                                  <FlagIcon sx={{ color: '#1f8598' }} />
                                ) : (
                                  <></>
                                )}
                                {usr.role?.name === Roles.Admin &&
                                usr.isCoordinator === false &&
                                usr.confirmed === true ? (
                                  <button
                                    className="accessibleBtn"
                                    type="button"
                                    onClick={this.handleOpenCoordinatorModal.bind(
                                      this,
                                      usr,
                                    )}
                                  >
                                    <FlagOutlinedIcon sx={{ color: '#1f8598' }} />
                                  </button>
                                ) : (
                                  //   <div
                                  //     onClick={this.handleOpenCoordinatorModal.bind(
                                  //       this,
                                  //       usr,
                                  //     )}
                                  //   >
                                  //     <FlagOutlinedIcon sx={{ color: '#1f8598' }} />
                                  //   </div>
                                  <></>
                                )}
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell className="attributeWidth">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginRight: '40%',
                                }}
                              >
                                {usr.isCoordinator === true ? (
                                  <FlagIcon sx={{ color: '#1f8598' }} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </TableCell>
                          )}

                          <TableCell className="attributeWidth optionsCell">
                            {getStatusInfo(usr.confirmed, usr.blocked).status ===
                            USER_STATUS.in_validation ? (
                              <button
                                className="accessibleBtn mx-1"
                                type="button"
                                title="Reenviar email"
                                onClick={this.handleResendEmail.bind(this, usr)}
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  padding: '0',
                                }}
                              >
                                <ReplayIcon sx={{ color: '#1f8598' }} />
                                {/* <img src={EditIcon} alt="pencil" height="19px" /> */}
                              </button>
                            ) : (
                              <div></div>
                            )}

                            {user?.id !== usr.id ? (
                              <button
                                className="accessibleBtn mx-1"
                                type="button"
                                title="Editar"
                                onClick={this.handleOpen.bind(this, usr, user)}
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  padding: '0',
                                }}
                              >
                                <EditIcon sx={{ color: '#1f8598' }} />
                                {/* <img src={EditIcon} alt="pencil" height="19px" /> */}
                              </button>
                            ) : (
                              <div></div>
                            )}

                            {usr.isCoordinator === false ? (
                              <button
                                className="accessibleBtn"
                                type="button"
                                title="Apagar"
                                onClick={this.handleOpenDeleteModal.bind(this, usr)}
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                              >
                                <DeleteIcon sx={{ color: '#1f8598' }} />
                              </button>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {currentData.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan="6">
                            <div className="noResults">Sem resultados disponíveis...</div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>

                  {this.state.tableLoading ? <TableLoading /> : <></>}
                </div>

                <div className="resultsWrapper">
                  <p className="results">
                    <b>{this.state.usersSlice.length} resultados disponíveis</b>
                  </p>
                </div>

                <Pagination
                  count={this.state.usersSlice?.length}
                  page={this.state.page}
                  handleChangePage={this.handleChangePage.bind(this)}
                />
                <div
                  ref={(el) => {
                    this.lastElement = el;
                  }}
                  tabIndex="-1"
                />
              </div>
            )}
          </>
        )}

        {this.state.openModal ? (
          <ModalEditProfile
            isOpen={this.state.openModal}
            usr={this.state.currentUser}
            roles={this.state.roles}
            handleModalClose={this.handleModalClose}
            editUser={this.editUser}
          />
        ) : (
          <></>
        )}

        {this.state.openCoordinatorModal ? (
          <ModalSetCoordinator
            //isOpen={this.state.openModal}
            usr={this.state.currentUser}
            user={user}
            open={this.state.openCoordinatorModal}
            hdlClose={this.handleCloseCoordinatorModal}
            assignCoordination={this.assignCoordination}
            //roles={this.state.roles}
            //handleModalClose={this.handleModalClose1}
            //editUser={this.editUser}
          />
        ) : (
          <></>
        )}

        {this.state.openDeleteModal ? (
          <ModalDeleteUser
            usr={this.state.currentUser}
            open={this.state.openDeleteModal}
            hdlClose={this.handleCloseDeleteModal}
            deleteUserFunc={this.deleteUserFunc}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  school: state.school,
});

export default connect(mapStateToProps)(UsersManagement);
