import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import './SlotSection.css';

import LogoPublico from '../../../assets/logo/p-logo.png';

import { getPublicoLastNews, getPublicoPopularNews } from '../../../api/news';

export default function SlotPublicoSection() {
  const [lastNewsPublico, setLastNewsPublico] = useState([]);
  const [popularNewsPublico, setPopularNewsPublico] = useState([]);

  useEffect(() => {
    const fetchLastData = async () => {
      try {
        const response = await getPublicoLastNews();
        setLastNewsPublico(response);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchPopularData = async () => {
      try {
        const response = await getPublicoPopularNews();
        setPopularNewsPublico(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLastData();
    fetchPopularData();
  }, []);

  return (
    <div className="slotSectionContainer">
      <div className="slotTitleContainer" style={{ borderBottom: `4px solid #D10019` }}>
        <span className="slotTitle">
          <img
            src={LogoPublico}
            alt="Logo Público"
            style={{ width: '30px', verticalAlign: 'baseline', marginRight: '10px' }}
          />
          Notícias PÚBLICO
        </span>
      </div>

      <div className="slotSection1 homepageWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <p className="publicoSubtitle">ÚLTIMAS</p>
            {lastNewsPublico && lastNewsPublico.length > 0 ? (
              lastNewsPublico?.map((n, idx) => (
                <div key={idx}>
                  <div key={idx + 1} className="pTitleContainer">
                    <p className="pDate">{n.date}</p>
                    <a className="pTitle" href={n.link} target="_blank">
                      {n.title}
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className="emptySlot">Sem notícias disponíveis.</div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p className="publicoSubtitle">MAIS POPULARES</p>
            {popularNewsPublico && popularNewsPublico.length > 0 ? (
              popularNewsPublico?.map((n, idx) => (
                <div key={idx}>
                  <div key={idx + 1} className="pTitleContainer">
                    <p className="pDate">{n.count}</p>
                    <a className="pTitle" href={n.link} target="_blank">
                      {n.title}
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className="emptySlot">Sem notícias disponíveis.</div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
