import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import usePrevious from '../../utilities/UsePrevious';
import { Pagination, Navigation } from 'swiper';
import { compareArrayProps } from '../../../utils/utils';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditorActions } from '../../../redux/actions';

function Layout({ input, previews, submitButton, dropzoneProps, files }) {
  const [swiperRef, setSwiperRef] = useState(null);
  const [previewsList, setPreviousList] = useState([]);
  const previousPreviews = usePrevious(previews);
  const activeIndex = swiperRef?.activeIndex;
  const captionVerif = useSelector(
    (state) => state?.textEditorValidation.caption,
  );
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );

  const dispatch = useDispatch();
  /* useEffect(() => {
    const captionIndex = captionVerif.findIndex(
      (caption) => caption.caption === false,
    );
    if (finalVerif === false && activeIndex !== captionIndex) {
    }
  }, [finalVerif, activeIndex]); */

  useEffect(() => {
    if (!previousPreviews || !previews) return;
    if (!compareArrayProps(previousPreviews, previews)) {
      setPreviousList(previews);
    }
  }, [previews]);

  useEffect(() => {
    dispatch(TextEditorActions.updateKey('swiperRef', swiperRef));
  }, [swiperRef]);

  return (
    <div>
      <div {...dropzoneProps}>
        <Swiper
          onSwiper={setSwiperRef}
          onUpdate={() => {
            swiperRef.slideTo(previews.length - 1);
          }}
          initialSlide={-1}
          slidesPerView="auto"
          centeredSlides
          allowTouchMove={false}
          spaceBetween={30}
          pagination={{
            type: 'progressbar',
          }}
          navigation
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {previewsList.map((element) => (
            <SwiperSlide key={element.props.meta.id}>{element}</SwiperSlide>
          ))}
        </Swiper>

        {input}
      </div>
      {files.length > 0 && submitButton}
    </div>
  );
}

export default Layout;
