import React from 'react';
import Gallery from '../../../templates/gallery/Gallery';
import './GallerySection.css';

export default function GallerySection({ newsItem, journalName, color }) {
  if (!newsItem) { return <div></div>; }

  return (
    <div className="gallerySectionContainer galleryArticleWidth">
      <Gallery
        news={newsItem}
        isHomepage={true}
        journalName={journalName}
        color={color}
      />
    </div>
  )
}