export const ITEMS_PER_PAGE = 8;
export const USER_STATUS = {
  active: "Ativo",
  inactive: "Inativo",
  in_validation: "Em validação",
};
export const PROTOCOL = window.PROTOCOL;
export const IP = window.API_IP;
export const PORT = window.API_PORT;
export const PATH = window.PATH;
export const CORRECTOR_PATH = window.CORRECTOR_PATH;
// export const PROTOCOL = "https";
// export const IP = "true-project.mog-technologies.com";
// export const PORT = "443";
// export const PATH = "/back-office";
// export const CORRECTOR_PATH = "/corrector/true";