import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '../../assets/img/icons/info.svg';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import DangerIcon from '../../assets/img/icons/alertTriangle.svg';
import { useEffect } from 'react';
import store from '../../redux/store';

function FormAuthor() {
  const dispatch = useDispatch();
  let value = useSelector((state) => state?.textEditor.author);
  let authorVerif = useSelector((state) => state?.textEditorValidation.author);
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );

  useEffect(() => {
    if (value) {
      dispatch(TextEditorValidationActions.updateKey('author', true));
    }
  }, []);

  const onChangeHandle = (val) => {
    dispatch(TextEditorActions.updateKey('author', val));

    const status = store.getState().textEditor.status;

    if (val === '' && status !== 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('author', false));
    } else if (!authorVerif) {
      dispatch(TextEditorValidationActions.updateKey('author', true));
    } else if (val === '' && status === 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('author', null));
    }
  };

  return (
    <Form.Group
      as={Col}
      className="col-6 form-bottom-margin"
      controlId="formInputNewsAuthor"
    >
      <Form.Label className="font-title label-bottom-margin">
        Autor(es) da notícia
      </Form.Label>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip" className="tooltip">
            Escreve o teu nome e das pessoas que possam ter escrito a notícia
            contigo.
          </Tooltip>
        }
      >
        <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
      </OverlayTrigger>

      <Form.Control
        className="input-height"
        defaultValue={value}
        type="text"
        placeholder="Nome do autor"
        onChange={(event) => onChangeHandle(event.target.value)}
      />
      {((authorVerif === false && value === '') ||
        (authorVerif === null && finalVerif === false)) && (
        <div className="errorVerifContainer mt-2">
          <img src={DangerIcon} className="errorVerifIcon" />
          <p className="errorVerifMessage">
            Por favor, insere o nome do autor da notícia.
          </p>
        </div>
      )}
    </Form.Group>
  );
}

export default FormAuthor;
