import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import ConfigInit from '../../pages/configuration/init/Init';
import UserProfile from '../../pages/configuration/userprofile/UserProfile';
import SchoolProfile from '../../pages/configuration/schoolprofile/SchoolProfile';
import Contacts from '../../pages/configuration/contacts/Contacts';
import Headlight from '../../pages/configuration/headlight/Headlight';
import Sections from '../../pages/configuration/sections/Sections';
import Finish from '../../pages/configuration/finish/Finish';
import Unsupported from '../../components/dashboard/unsupported/Unsupported';
import useParams from '../../utils/useParams';
import useResize from '../../components/utilities/Resize';
import { ConfigActions, ConfigStatusActions } from '../../redux/actions';
import { getConfigurationById } from '../../api/configuration';

export default function ConfigLayout() {
  const dispatch = useDispatch();
  const [ isUnsupported, setIsUnsupported ] = useState(false);
  const { cid } = useParams('/configuration/:cid');
  const { width } = useResize();

  useEffect(() => {
    if (width < 991) {
      setIsUnsupported(true);
    } else {
      setIsUnsupported(false);
    }
  }, [width]);

  useEffect(() => {
    getConfigurationById(cid)
      .then((result) => {
        dispatch(ConfigActions.updateConfiguration(result));
        dispatch(ConfigStatusActions.updateLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(ConfigStatusActions.updateLoading(false));
        dispatch(ConfigStatusActions.updateError(true));
      });
  }, [cid, dispatch]);

  if (isUnsupported) {
    return <Unsupported />;
  }

  return (
    <Switch>
      <Route path="/configuration/:cid/init" exact component={ConfigInit} />
      <Route path="/configuration/:cid/step-1" exact component={UserProfile} />
      <Route path="/configuration/:cid/step-2" exact component={SchoolProfile} />
      <Route path="/configuration/:cid/step-3" exact component={Contacts} />
      <Route path="/configuration/:cid/step-4" exact component={Headlight} />
      <Route path="/configuration/:cid/step-5" exact component={Sections} />
      <Route path="/configuration/:cid/finish" exact component={Finish} />
      <Redirect to="/dashboard" />
    </Switch>
  );
}
