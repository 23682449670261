import React from 'react';
import { Grid, MenuItem } from '@mui/material';

import TextInput from '../../../components/dashboard/textinput/TextInput';

export default function ModalContactForm(props) {
  const { user, onChange, userRoles } = props;

  return (
    <Grid container>
      <Grid className="attributeContainer" item xs={6}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="Nome"
          name="name"
          value={user?.name}
          placeholder="ex: João"
          setInputValue={onChange}
          required={true}
          maxLength={25}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={6}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="Apelido"
          name="surname"
          value={user?.surname}
          placeholder="ex: Gomes"
          setInputValue={onChange}
          required={true}
          maxLength={25}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={12}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="E-mail"
          name="email"
          value={user?.email}
          placeholder="ex: joao.gomes@mail.com"
          setInputValue={onChange}
          required={true}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={12}>
        <TextInput
          className="custom-user-input"
          label="Tipo de Utilizador"
          select
          name="role"
          value={user?.role}
          setInputValue={onChange}
          required={true}
        >
          <MenuItem value="" disabled selected>
                        Selecione o tipo de utilizador
          </MenuItem>
          {userRoles.map(role => <MenuItem key={role._id} value={role._id}>{role.name}</MenuItem>)}
        </TextInput>
      </Grid>
    </Grid>
  );
}