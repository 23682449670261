import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfigError from '../error/Error';
import FileUpload from '../../../components/dashboard/fileupload/FileUpload';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import SetupMenu from '../../../templates/setupmenu/SetupMenu';
import TextInput from '../../../components/dashboard/textinput/TextInput';
import { ConfigActions } from '../../../redux/actions';
import { defaultSchool } from '../../../redux/reducer';
import { deleteImage, uploadImage } from '../../../api/upload';
import { updateConfigurationById, validateSchool } from '../../../api/configuration';
import { ToastError } from '../../../models/enum';
import { ONE_MB } from '../../../utils/utils';

import locData from '../../../assets/data/distritos-concelhos-freguesias-Portugal.json';
const districts = locData.filter((item) => item.level === 1);

export default function SchoolProfile(props) {
  const [school, setSchool] = useState(defaultSchool);
  const [loading, setLoading] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);

  const cid = props.match.params.cid;
  const config = useSelector((state) => state.config);
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.statusConfig);

  useEffect(() => {
    setSchool(config.school);

    /*if (config.step < 2) {
            history.push(`/configuration/${cid}/step-${config.step}`);
        }*/
  }, [config]);

  useEffect(() => {
    if (!school?.district) return;

    const districtCode = school.district.toString();
    // In case district is outside of Portugal
    if (districtCode === '50') {
      setSchool({
        ...school,
        municipality: '5001',
      });
      setMunicipalities([]);
    } else {
      const munList = locData.filter((item) => {
        const code = item.code.toString();
        return (
          item.level === 2 &&
          code.startsWith(districtCode) &&
          code.length === districtCode.length + 2
        );
      });

      setMunicipalities(munList);
    }
  }, [school?.district]);

  const onChange = (e) => {
    if (e.target.name === 'district') {
      setSchool({
        ...school,
        [e.target.name]: e.target.value,
        municipality: '',
      });
    } else {
      setSchool({
        ...school,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setLogo = (value) => {
    setSchool({ ...school, logo: value });
  };

  const isValid = () => {
    return (
      school &&
      school.name &&
      school.journal &&
      school.subdirectory &&
      school.municipality &&
      school.district &&
      (school.logo?.file || school.logo?.url)
    );
  };

  const previousStep = async () => {
    const step = 1;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('school', defaultSchool));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.school = defaultSchool;
    cfg.step = 1;

    const proms = [];

    const updateCfg = updateConfigurationById(cid, cfg);
    proms.push(updateCfg);

    if (school?.logo?.id) {
      try {
        await deleteImage(school.logo.id);
      } catch (error) {
        console.error(error);
      }
    }

    try {
      await Promise.all(proms);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const nextStep = async () => {
    const step = 3;
    const cfg = { ...config };

    setLoading(true);

    let res;

    try {
      res = await validateSchool(
        school.name,
        school.journal,
        school.subdirectory,
        school.district,
        school.municipality,
        school.logo
      );
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
    }

    if (!res.valid) {
      setLoading(false);
      return toast(res.message, { className: 'toastStyleDanger', toastId: "res_message", autoClose: 2000 });
    }

    const schoolInfo = { ...school };

    if (school.logo.file) {
      const proms = [];

      if (school.logo.file?.size > 2 * ONE_MB) {
        setLoading(false);
        return toast(ToastError.UPLOAD_SIZE_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'upload_size_error',
          autoClose: 2000
        });
      }

      const uploadProm = uploadImage(school.logo.file);
      proms.push(uploadProm);

      if (school.logo.id) {
        try {
          await deleteImage(school.logo.id);
        } catch (error) {
          console.error(error);
        }
      }

      let result;
      try {
        result = await Promise.all(proms);
      } catch (error) {
        setLoading(false);
        return toast(ToastError.GENERAL_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'general_error',
          autoClose: 2000
        });
      }

      const logoRes = result[0];

      if (!logoRes || logoRes.length === 0) {
        setLoading(false);
        toast(ToastError.UPLOAD_ERROR, { className: 'toastStyleDanger', toastId: 'upload_error', autoClose: 2000 });
        return;
      }

      const logo = {
        id: logoRes[0].id,
        url: logoRes[0].url,
        file: '',
      };

      schoolInfo.logo = logo;
    }

    cfg.school = schoolInfo;
    cfg.step = step;

    dispatch(ConfigActions.updateKey('school', schoolInfo));
    dispatch(ConfigActions.updateKey('step', step));

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  if (status.error) {
    return <ConfigError />;
  }

  return (
    <div className="setupPage">
      <MetaTags title="Dados do Jornal &bull; Configuração | Projeto TRUE" />

      <SetupMenu step={2} />

      <div className={'setupBody' + (status.loading || loading ? ' isLoading' : '')}>
        {status.loading || loading ? (
          <div className="loadingContainer">
            <Loading />
          </div>
        ) : (
          <></>
        )}

        <div className="highlightContainer smallMargin">
          <div>
            <p className="setupTitle">Dados do Jornal</p>

            <p className="setupSubtitle">Configure os dados do jornal escolar</p>
          </div>
        </div>

        <Grid container spacing={3}>
          <Grid item container xs={7}>
            <Grid className="profileInputContainer leftSide" item container xs={12}>
              <TextInput
                className="custom-user-input"
                type="text"
                label="Agrupamento ou Escola"
                name="name"
                value={school?.name}
                placeholder="ex: Agrupamento de Escolas do Castêlo da Maia"
                setInputValue={onChange}
                required={true}
                maxLength={75}
              />
            </Grid>

            <Grid className="profileInputContainer leftSide" item container xs={12}>
              <TextInput
                className="custom-user-input"
                type="text"
                label="Nome do jornal"
                name="journal"
                value={school?.journal}
                placeholder="ex: Jornal do Castêlo da Maia"
                setInputValue={onChange}
                required={true}
                maxLength={50}
              />
            </Grid>

            <Grid className="profileInputContainer leftSide" item container xs={12}>
              <TextInput
                className="custom-user-input"
                type="text"
                label="URL do Jornal Escolar"
                name="subdirectory"
                value={school?.subdirectory}
                placeholder="escola-castelo-maia"
                setInputValue={onChange}
                multiline
                startAdornment={
                  <span className="prefix">{window.location.origin + '/'}</span>
                }
                helperText={
                  <>
                    O URL do jornal escolar só pode conter letras e travessões (-).
                    <br />
                    Exemplo de URL: https://true.publico.pt/
                    <span className="highlight">escola-castelo-maia</span>
                  </>
                }
                required={true}
              />
            </Grid>

            <Grid className="profileInputContainer leftSide" item container xs={12}>
              <TextInput
                className="custom-user-input"
                label="Distrito"
                select
                name="district"
                value={school?.district}
                setInputValue={onChange}
                required={true}
              >
                <MenuItem value="" disabled selected>
                  Selecione o distrito da escola
                </MenuItem>
                {districts.map((role) => (
                  <MenuItem key={role.code} value={role.code}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextInput>
            </Grid>

            <Grid className="profileInputContainer leftSide" item container xs={12}>
              {municipalities.length !== 0 ? (
                <TextInput
                  className="custom-user-input"
                  label="Concelho"
                  select
                  name="municipality"
                  value={school?.municipality}
                  setInputValue={onChange}
                  required={true}
                >
                  <MenuItem value="" disabled selected>
                    Selecione o concelho da escola
                  </MenuItem>
                  {municipalities.map((role) => (
                    <MenuItem key={role.code} value={role.code}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextInput>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <FileUpload
              id={school?.logo?.id}
              url={school?.logo?.url}
              file={school?.logo?.file}
              onChange={setLogo}
              firstConfig={true}
            />
          </Grid>
        </Grid>
        <div className="btnContainer">
          <div>
            <button className="secondaryBtnBlue" onClick={previousStep}>
              Voltar
            </button>
          </div>
          <div>
            {isValid() ? (
              <button className="mainBtn" onClick={nextStep}>
                Seguinte
              </button>
            ) : (
              <button className="mainBtn" disabled={true}>
                Seguinte
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
