import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArticleBody from '../../../components/frontend/articlebody/ArticleBody';
import LinkShare from '../../../components/frontend/socialmedia/LinkShare';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import PageNotFound from '../pagenotfound/PageNotFound';
import PublicoSectionItem from './PublicoSectionItem';
import { Grid } from '@mui/material';
import { getNewsById } from '../../../api/news';
import { NewsState, ToastError } from '../../../models/enum';
import { extractContent, getTitle } from '../../../utils/utils';

import LogoPublico from '../../../assets/logo/p-logo.png';
import './ArticlePage.css';

export default function ArticlePage({ school, idNews, previz }) {
  const mainColor = school?.palette?.color.main;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newsItem, setNewsItem] = useState(null);

  const journalName = school?.subdirectory;

  const { articleId } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    let newsId;
    if(idNews){
      newsId = idNews;
    } else{
      newsId = query.get('id');
    }
    if (newsId) {
      getNews(newsId);
    } else {
      setLoading(false);
      setError(true);
    }
  }, [articleId]);

  const getNews = async (newsId) => {
    let news;

    try {
      news = await getNewsById(newsId);
    } catch (error) {
      setLoading(false);
      setError(true);
      return toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: "general_error", autoClose: 2000 });
    }

    if (!previz && news.status !== NewsState.PUBLICADA.NAME) {
      setLoading(false);
      setError(true);
      return;
    }

    setNewsItem(news);
    setLoading(false);
  };

  if (error && !loading) {
    return (
      <div className="articlePage">
        <div className="articleContent articleWidth first">
          <PageNotFound
            link={`/${journalName}`}
            linkTxt="Aceder Homepage"
            isSmall={true}
          />
        </div>
      </div>
    );
  }

  const img = newsItem?.attachments.length > 0 ? newsItem?.attachments[0] : null;
  const title = newsItem?.title;
  const titleExtracted = extractContent(newsItem?.title);

  //------
  let relatedNewsPublico = newsItem?.relatedNews;
  if (relatedNewsPublico === null || relatedNewsPublico === undefined) {
    relatedNewsPublico = [];
  } else {
    relatedNewsPublico = relatedNewsPublico.sort((a, b) => {
      return new Date(b.ValueDate) - new Date(a.ValueDate);
    });
  }
  //-----

  const layout = 2;

  return (
    <div className="articlePage">
      {loading ? (
        <Loading />
      ) : (
        <>
          <MetaTags
            title={`${titleExtracted} | ${school?.journal} | Projeto TRUE`}
            ogURL={window.location.href}
            ogTitle={titleExtracted}
            ogImage={img?.url}
          />

          <div className="articleContent articleWidth first">
            <p className="articleAuthor">{newsItem?.author}</p>

            <p className="articlePublishDate">
              {moment(newsItem?.publishDate).format('DD/MM/YYYY')}
            </p>

            <h1 className="articleNewsTitle">{getTitle(title)}</h1>

            <LinkShare color={mainColor} />
          </div>

          <ArticleBody newsItem={newsItem} school={school} />

          {relatedNewsPublico === null || relatedNewsPublico.length === 0 ? (
            <></>
          ) : (
            <div className="slotSectionContainer">
              <div
                className="slotTitleContainer"
                style={{ borderBottom: `4px solid #D10019` }}
              >
                <span className="slotTitle">
                  <img
                    src={LogoPublico}
                    alt="Logo Público"
                    style={{ width: '30px', verticalAlign: 'baseline' }}
                  />{' '}
                  Notícias relacionadas
                </span>
              </div>
              <div className="slotSection homepageWidth">
                <Grid container spacing={4}>
                  {relatedNewsPublico.map((e, idx) => (
                    <Grid key={idx} item xs={12} sm={12} md={6}>
                      <PublicoSectionItem
                        news={e}
                        color={'#D10019'}
                        idx={idx}
                        layout={layout}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
