import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';

import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Header from '../../components/newsEditor/Header';

import Loading from '../../components/loading/Loading';
import MetaTags from '../../components/metatags/MetaTags';
import TextInput from '../../components/dashboard/textinput/TextInput';
import UserData from './UserData';
import { UserActions } from '../../redux/actions';
import { updateMyInfo, updatePassword } from '../../api/users';
import { ToastError, ToastSuccess } from '../../models/enum';

import locData from '../../assets/data/distritos-concelhos-freguesias-Portugal.json';
import TableLoading from '../../components/tableloading/TableLoading';

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tableLoading: false,
      schoolName: '',
      journalName: '',
      schoolMunicipality: '',
      schoolDistrict: '',
      subdirectory: '',
      firstName: '',
      lastName: '',
      userEmail: '',
      userType: '',
      oldPassword: '',
      userPassword: '',
      confirmPassword: '',
    };
  }

  async componentDidMount() {
    this.getUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.state.loading) {
      this.getUserInfo();
    }
  }

  getUserInfo() {
    const { school, user } = this.props;
    let loading = true;

    if (!school || !user) return;
    if (user && school) {
      loading = false;
    }

    const district = locData.find((item) => item.code === parseInt(school?.district));
    const municipality = locData.find(
      (item) => item.code === parseInt(school?.municipality),
    );

    this.setState({
      schoolName: school?.name,
      journalName: school?.journal,
      schoolMunicipality: municipality.name,
      schoolDistrict: district.name,
      subdirectory: school?.subdirectory,
      firstName: user?.name,
      lastName: user?.surname,
      userEmail: user?.email,
      userType: user?.role.name,
      loading: loading,
    });
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.name === 'userEmail' 
        ? e.target.value.trim() 
        : e.target.value 
      });
  };

  setPassword = async (e) => {
    e.preventDefault();

    const { userPassword, oldPassword, confirmPassword } = this.state;

    if (userPassword !== confirmPassword) {
      toast(ToastError.PASSWORD_NO_MATCH, {
        className: 'toastStyleDanger',
        toastId: 'password_no_match',
        autoClose: 2000,
      });
      return;
    }

    this.setState({ tableLoading: true });
    try {
      await updatePassword(oldPassword, userPassword);
    } catch (err) {
      this.setState({ tableLoading: false });
      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
      return;
    }

    this.setState({ tableLoading: false });
    toast(ToastSuccess.UPDATED_PASSWORD, {
      className: 'toastStyleSuccess',
      toastId: 'updated_password',
      autoClose: 2000,
    });
  };

  setChanges = async (e) => {
    e.preventDefault();

    const { firstName, lastName, userEmail } = this.state;

    this.setState({ tableLoading: true });
    try {
      await updateMyInfo(firstName, lastName, userEmail);
      const newUser = { ...this.props.user };
      newUser.name = firstName;
      newUser.surname = lastName;
      newUser.email = userEmail;
      newUser.username = userEmail;
      this.props.updateUser(newUser);
    } catch (err) {
      this.setState({ tableLoading: false });
      toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });

      return console.error(err);
    }

    this.setState({ tableLoading: false });
    toast(ToastSuccess.UPDATED_PROFILE, {
      className: 'toastStyleSuccess',
      toastId: 'updated_profile',
      autoClose: 2000,
    });
  };

  isValid = () => {
    const { firstName, lastName, userEmail } = this.state;
    return firstName && lastName && userEmail;
  };

  isValidPassword = () => {
    const { userPassword, oldPassword, confirmPassword } = this.state;
    return userPassword && oldPassword && confirmPassword;
  };

  render() {
    const {
      schoolName,
      journalName,
      schoolMunicipality,
      schoolDistrict,
      subdirectory,
      firstName,
      lastName,
      userEmail,
      userType,
      oldPassword,
      userPassword,
      confirmPassword,
      tableLoading
    } = this.state;

    const loadingClass = tableLoading ? 'bodyContainer profileContainer pageLoading' : 'bodyContainer profileContainer';

    return (
      <div>
        <MetaTags title="O Meu Perfil | Projeto TRUE" />

        <DashHeader title="O Meu Perfil" />
        <Header name={'help_profile'} />

        {this.state.loading ? (
          <Loading />
        ) : (
          <div className={loadingClass}>
            <div className="pageSectionContainer head">
              <p className="pageHighlight">Dados Pessoais</p>
            </div>

            <Grid className="myProfileContainer" container>
              <UserData
                schoolName={schoolName}
                journalName={journalName}
                schoolMunicipality={schoolMunicipality}
                schoolDistrict={schoolDistrict}
                subdirectory={subdirectory}
                firstName={firstName}
                lastName={lastName}
                userEmail={userEmail}
                userType={userType}
                changeHandler={this.changeHandler}
              />

              <Grid className="profileInputContainer" item xs={12} lg={10}>
                <div className="attributesWrapperProfile">
                  {this.isValid() ? (
                    <button className="mainBtn" onClick={this.setChanges}>
                      Guardar Alterações
                    </button>
                  ) : (
                    <button className="mainBtn" disabled={true}>
                      Guardar Alterações
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>

            <div className="pageSectionContainer">
              <p className="pageHighlight">Segurança</p>
            </div>

            <Grid className="myProfileContainer" container>
              <Grid className="profileInputContainer leftSide" item xs={4} lg={4}>
                <TextInput
                  className="custom-user-input"
                  type="password"
                  label="Palavra-passe atual"
                  name="oldPassword"
                  placeholder="Insira palavra-passe atual "
                  value={oldPassword}
                  setInputValue={this.changeHandler}
                />
              </Grid>

              <Grid className="profileInputContainer leftSide" item xs={4} lg={3}>
                <TextInput
                  className="custom-user-input"
                  type="password"
                  label="Nova Palavra-passe"
                  name="userPassword"
                  placeholder="Insira nova palavra-passe"
                  value={userPassword}
                  setInputValue={this.changeHandler}
                />
              </Grid>

              <Grid className="profileInputContainer" item xs={4} lg={3}>
                <TextInput
                  className="custom-user-input"
                  type="password"
                  label="Confirmar Palavra-passe"
                  name="confirmPassword"
                  placeholder="Confirme palavra-passe"
                  value={confirmPassword}
                  setInputValue={this.changeHandler}
                />
              </Grid>

              <Grid className="profileInputContainer" item xs={12} lg={10}>
                <div className="attributesWrapperProfile">
                  {this.isValidPassword() ? (
                    <button className="mainBtn" onClick={this.setPassword}>
                      Alterar Password
                    </button>
                  ) : (
                    <button className="mainBtn" disabled={true}>
                      Alterar Password
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>

            {tableLoading ? <TableLoading /> : <></>}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  school: state.school,
});

const mapActionsToProps = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(MyProfile);
