import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import TabCorrections from './TabCorrections';
import TabSearchNews from './TabSearchNews';
import TabRelatedNews from './TabRelatedNews';

function TabsContainer({ corrections, search, related }) {
  const [key, setKey] = useState('corrections');

  const boolToInt = (a, b, c) => 12 / (a + b + c);

  let colSize = boolToInt(corrections, search, related);

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        mountOnEnter
      >
        <Row>
          <Nav variant="tabs" className="gx-0">
            {corrections ? (
              <Col xs={colSize}>
                <Nav.Item>
                  <Nav.Link eventKey="corrections" className="titleTabs">
                    <img
                      className="iconCorrections mx-auto d-block"
                      alt="icon-correction"
                    />
                    <div className="text-center mb-0 tabsText">Correções</div>
                  </Nav.Link>
                </Nav.Item>
              </Col>
            ) : null}

            {search ? (
              <Col xs={colSize}>
                <Nav.Item>
                  <Nav.Link eventKey="search" className="titleTabs">
                    <img
                      className="iconSearch mx-auto d-block"
                      alt="icon-search"
                    />
                    <div className="text-center mb-0  tabsText">Pesquisar</div>
                  </Nav.Link>
                </Nav.Item>
              </Col>
            ) : null}

            {related ? (
              <Col xs={colSize}>
                <Nav.Item>
                  <Nav.Link eventKey="newsPublico" className="titleTabs">
                    <img
                      className="iconNewsPublico mx-auto d-block"
                      alt="icon-publico"
                    />
                    <div className="text-center mb-0 tabsText">Relacionadas</div>
                  </Nav.Link>
                </Nav.Item>
              </Col>
            ) : null}
          </Nav>
        </Row>

        <Row className="section-tab-padding">
          <Tab.Content className="px-0">
            {corrections ? (
              <Tab.Pane eventKey="corrections">
                <TabCorrections></TabCorrections>
              </Tab.Pane>
            ) : null}

            {search ? (
              <Tab.Pane eventKey="search">
                <TabSearchNews />
              </Tab.Pane>
            ) : null}

            {related ? (
              <Tab.Pane eventKey="newsPublico">
                <TabRelatedNews></TabRelatedNews>
              </Tab.Pane>
            ) : null}
          </Tab.Content>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default TabsContainer;
