import React from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import MascoteSearchError from '../../assets/newsEditor/images/mascoteSearchError.svg';

export default class ModalWarningBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      hdlClose: this.props.hdlClose,
    };
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.hdlClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            '*': {
              boxSizing: 'content-box !important',
            },
            '::after, ::before': {
              boxSizing: 'content-box !important',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-25%, -50%)',
              width: 450,
              bgcolor: 'background.paper',
              borderRadius: '6px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" component={'span'} variant={'body2'}>
              <Grid container>
                <Grid className="attributeContainer" item xs={12}>
                  <div
                    className="img-banner"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <img
                      style={{ width: '120px', height: '120px' }}
                      src={MascoteSearchError}
                      alt="Logo"
                    />
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                    }}
                  >
                    O seu jornal digital foi bloqueado. Para mais informações contacte o seu
                    coordenador ou a equipa do <i>Público na escola</i>.
                  </p>
                </Grid>
              </Grid>
            </Typography>
            <Typography id="modal-modal-description" component={'span'} variant={'body2'}>
              <div
                className="btnContainer"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className="attributeContainer1">
                  <button className="mainBtn" onClick={this.props.hdlClose}>
                    Voltar
                  </button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }
}
