import React from 'react';

export default function ModalSinonimo(props) {
  const results = [];

  if (props.sinList.length > 0) {
    props.sinList.forEach((synonym) => {
      results.push(
        <button
          type="button"
          key={synonym}
          className="cartas_palavras a_link_2"
          id="synonym_link"
          onClick={() => props.replaceWord(synonym, props.range)}
        >
          {synonym}
        </button>,
      );
    });
  } else {
    results.push(
      <div key="no_sin" className="cartas_palavras_empty">
        Sem sugestões.
      </div>,
    );
  }

  return (
    <div
      id="Div1_tooltip_editor"
      className="_close_Tooltip"
      style={{
        top: `${props.posY}px`,
        left: `${props.posX}px`,
      }}
    >
      <p id="p_tooltip" className="mb-0">
        Sugestões
      </p>{' '}
      <button
        onClick={() => props.removerModal()}
        id="fecharModalBody"
        className="btn-closing close-modal"
        type="button"
        aria-label="Close"
      ></button>
      {results}
    </div>
  );
}
