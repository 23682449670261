import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import _ from 'lodash';

import TableLoading from '../../components/tableloading/TableLoading';
import { extractContent } from '../../utils/utils';
import { NewsState, Roles } from '../../models/enum';

//Icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function NewsTable({
  items,
  loading,
  handleOpenPrevizModal,
  handleOpenDeleteModal,
}) {
  const StateFilters = Object.keys(NewsState).map((s) => NewsState[s].NAME);

  const user = useSelector((state) => state.user);
  const isAuthor = user?.role?.name === Roles.Author;
  const loadingClass = loading ? 'tableContainer pageLoading' : 'tableContainer';

  const editSelection = (news) => {
    if (
      user?.role?.name === Roles.Author &&
      (news?.status === 'Publicada' ||
        news?.status === 'Revista' ||
        news?.status === 'Para Revisão')
    ) {
      return;
    } else {
      window.location.href = `/dashboard/news/edit/${news?.id}`;
    }
  };

  return (
    <div className={loadingClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Título</TableCell>
            <TableCell>Autor(es)</TableCell>
            <TableCell>Data Publicação</TableCell>
            <TableCell>Secção</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Original</TableCell>
            <TableCell>Opções</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((news) => (
            <TableRow
              className={
                isAuthor &&
                (news?.status === 'Publicada' ||
                  news?.status === 'Revista' ||
                  news?.status === 'Para Revisão')
                  ? 'tableAttributesWrapper'
                  : 'tableAttributesWrapper fill'
              }
              role="button"
              key={news.id}
            >
              <TableCell
                className="attributeWidthEmail"
                onClick={() => editSelection(news)}
              >
                <span className="text-decoration-none text-black">
                  {extractContent(news.title) ? (
                    extractContent(news.title)
                  ) : (
                    <span className="newsNoTitle">Sem Título</span>
                  )}
                </span>
              </TableCell>
              <TableCell className="attributeWidth" onClick={() => editSelection(news)}>
                <span className="text-decoration-none">
                  <p className="attribute">{news.author ? news.author : '-'}</p>
                </span>
              </TableCell>
              <TableCell className="attributeWidth" onClick={() => editSelection(news)}>
                <span className="text-decoration-none">
                  <p className="attribute">
                    {news.publishDate
                      ? moment(news.publishDate).format('DD/MM/YYYY')
                      : '-'}
                  </p>
                </span>
              </TableCell>
              <TableCell className="attributeWidth" onClick={() => editSelection(news)}>
                <span className="text-decoration-none">
                  <p className="attribute">
                    {news.section?.sname ? news.section?.sname : '-'}
                  </p>
                </span>
              </TableCell>
              <TableCell className="attributeWidth" onClick={() => editSelection(news)}>
                <span className="text-decoration-none">
                  <div className="tableStateWrapper">
                    {Object.keys(NewsState).map((key) => (
                      <div
                        key={NewsState[key].KEY}
                        className={
                          news.status?.toLowerCase() === NewsState[key].NAME.toLowerCase()
                            ? 'active-buttons'
                            : 'inactive-buttons'
                        }
                      >
                        <img
                          src={NewsState[key].IMG}
                          alt={StateFilters[0]}
                          height="9px"
                        ></img>
                      </div>
                    ))}

                    <p className="attribute">{news.status}</p>
                  </div>
                </span>
              </TableCell>
              <TableCell className="attributeWidth" onClick={() => editSelection(news)}>
                <span className="text-decoration-none">
                  {_.isEmpty(news.plagiarized) ? (
                    <p className="attribute">Sim</p>
                  ) : (
                    <p className="attribute">Não</p>
                  )}
                </span>
              </TableCell>

              <TableCell className="attributeWidth optionsCell">
                <button
                  className="accessibleBtn mx-1"
                  type="button"
                  title="Pré-Visualizar"
                  onClick={() => handleOpenPrevizModal(news)}
                  style={{
                    display: 'inline-block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <VisibilityIcon sx={{ color: '#1f8598' }} />
                </button>
                {isAuthor &&
                (news?.status === 'Publicada' ||
                  news?.status === 'Revista' ||
                  news?.status === 'Para Revisão') ? (
                  <></>
                ) : (
                  <NavLink
                    className="mx-1"
                    to={`/dashboard/news/edit/${news.id}`}
                    title="Editar"
                    style={{
                      display: 'inline-block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <EditIcon sx={{ color: '#1f8598' }} />
                  </NavLink>
                )}
                {isAuthor &&
                (news?.status === 'Publicada' ||
                  news?.status === 'Para Revisão' ||
                  news?.status === 'Revista') ? (
                  <div></div>
                ) : (
                  <button
                    className="accessibleBtn mx-1"
                    type="button"
                    title="Apagar"
                    onClick={() => handleOpenDeleteModal(news)}
                    style={{
                      display: 'inline-block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <DeleteIcon sx={{ color: '#1f8598' }} />
                  </button>
                )}
              </TableCell>
            </TableRow>
          ))}

          {items.length === 0 ? (
            <TableRow>
              <TableCell colSpan="7">
                <div className="noResults">Sem resultados disponíveis...</div>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>

      {loading ? <TableLoading /> : <></>}
    </div>
  );
}
