import React from 'react';

import DashError from '../../../components/dashboard/dasherror/DashError';
import backgroundImg from '../../../assets/img/illustrations/configurationBkg.svg';
import { ReactComponent as TrueLogo } from '../../../assets/logo/logo-big.svg';

export default function ConfigError(props) {

  return (
    <div className="fullPageContainer">
      <div className="splashContainer">
        <div className="splashBackground" style={{ backgroundImage: `url("${backgroundImg}")` }} />

        <div className="elementsWrapper">
          <TrueLogo />

          <DashError />
        </div>
      </div>
    </div>
  );
}