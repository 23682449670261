import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReplaceWordActions } from '../../redux/actions';
import {
  Button,
  Col,
  Row,
  FormLabel,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import InfoIcon from '../../assets/img/icons/info.svg';

import _ from 'lodash';
import arrow from '../../assets/newsEditor/images/arrow.svg';
import { setGlobalState, useGlobalState } from '../../utils/MyContext';
import Mascote from './Mascote';
import mascoteBoa from '../../assets/newsEditor/images/mascoteBoa.svg';
import mascoteErro from '../../assets/newsEditor/images/mascoteErros.svg';
import { useLocation } from 'react-router-dom';

function TabCorrections() {
  const dispatch = useDispatch();
  const location = useLocation().pathname.split('/').slice(-1).toString();

  const errorsRedux = useSelector((state) => state.errors);

  const sugestoes = [];
  const errors = [...errorsRedux.bodyErrors, ...errorsRedux.titleErrors];
  // join body and title errors and remove duplicates
  const uniqueErrors = [...new Map(errors.map((v) => [v.word, v])).values()];
  const ignoredWords = useGlobalState('ignoredWords')[0];

  const [mascoteImg, setMascoteImg] = useState(mascoteBoa);
  const [mascoteText, setMascoteText] = useState('');

  const numErrors = uniqueErrors.filter(
    (item) => !ignoredWords.includes(item.word),
  );

  function removeSuggestion(word) {
    // let parsedWord = word.replace(/\.!?;:$/, "");
    setGlobalState('ignoredWords', (current) => [...current, String(word)]);
  }

  useEffect(() => {
    localStorage.setItem('ignoredWords', JSON.stringify(ignoredWords));
  }, [ignoredWords]);

  useEffect(() => {
    if (numErrors.length > 0) {
      setMascoteText(`Existem ${numErrors.length} erros no texto.`);
      setMascoteImg(mascoteErro);
    } else {
      setMascoteText('Boa! Sem erros de escrita.');
      setMascoteImg(mascoteBoa);
    }
  }, [uniqueErrors]);

  function corrigirPalavra(erro) {
    const newItem = {
      quill: '',
      error: erro.word,
      sugestion: erro.suggestion,
    };

    dispatch(ReplaceWordActions.updateReplaceWord(newItem));
  }

  // existem erros
  if (!_.isEmpty(uniqueErrors)) {
    uniqueErrors.forEach((erro) => {
      if (ignoredWords.includes(erro.word)) {
        return;
      }

      // erro com sugestão
      if (erro.suggestion != '') {
        sugestoes.push(
          <Row className="mt-2 mb-3 gx-0" key={erro.word}>
            <Col xs={5} className="btn btnErro button-text overflow-hidden">
              <span className="erroRasurado ">{erro.word}</span>
            </Col>

            <Col xs={1} className="my-auto text-center">
              <img src={arrow} alt={'arrow-right'} />
            </Col>

            <Col xs={5}>
              <Button
                onClick={() => corrigirPalavra(erro)}
                id={erro.suggestion}
                className="btnSubmitNews btnCorrecao overflow-hidden"
              >
                {erro.suggestion}
              </Button>
            </Col>

            <Col xs={1} className="my-auto text-center">
              {' '}
              <Button
                className="btn-closing"
                onClick={() => removeSuggestion(erro.word)}
              />
            </Col>
          </Row>,
        );
      }
      // erro sem sugestão
      else {
        sugestoes.push(
          <Row className="mt-2 mb-3 gx-0" key={erro.word}>
            <Col
              xs={5}
              className="btn btnErro text-center buttontext overflow-hidden"
            >
              <span className="erroRasurado">{erro.word}</span>
            </Col>
            <Col xs={1} className="my-auto text-center">
              <img src={arrow} alt={'arrow-right'} />
            </Col>
            <Col xs={5} className="">
              <FormLabel className="btnSubmitNews btnCorrecao btn_noSuggest mb-0">
                Sem sugestão
              </FormLabel>
            </Col>
            <Col xs={1} className="my-auto text-center">
              <Button
                className="btn-closing"
                onClick={() => removeSuggestion(erro.word)}
              />
            </Col>
          </Row>,
        );
      }
    });
  }

  return (
    <>
      <div className="font-title section-tab-title margin-title-section">
        Sugestões de correção
        <OverlayTrigger
          placement="bottom"
          overlay={
            location === 'editorial' ? (
              <Tooltip id="tooltip" className="tooltip">
                Aqui são identificados os erros ortográficos presentes no texto
                que estás a escrever e uma possível correção para eles.
              </Tooltip>
            ) : (
              <Tooltip id="tooltip" className="tooltip">
                Aqui são identificados os erros ortográficos da notícia que
                estás a escrever e uma possível correção. Para aceitar clica no
                botão com a sugestão. Para rejeitar clica no botão "x".
              </Tooltip>
            )
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2" />
        </OverlayTrigger>
      </div>

      <div className="classRelated">{sugestoes}</div>

      <Mascote img={mascoteImg} text={mascoteText}></Mascote>
    </>
  );
}

export default TabCorrections;
