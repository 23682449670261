import React from 'react';
import { Pagination } from '@mui/material';
import { ITEMS_PER_PAGE } from '../../../utils/globals';

export default function TablePagination({ count, itemsPerPage, page, handleChangePage }) {
  const itemsPage = itemsPerPage ? itemsPerPage : ITEMS_PER_PAGE;
  const itemsLength = Math.ceil(count / itemsPage);

  if (itemsLength === 0) { return <></>; } 

  return (
    <div className="paginationWrapper">
      <Pagination
        count={itemsLength}
        page={page}
        onChange={handleChangePage}
      />
    </div>
  ); 
}