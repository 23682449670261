import React from 'react';

import './dasherror.css';

export default function DashError(props) {
  const { title, description } = props;
    
  return (
    <div className="errorWrapper">
      <div className="errorTitle">
        {title ? title : "Oops!"}
      </div>
      <div className="errorDescription">
        {description ? description : "Ocorreu um erro, por favor tente mais tarde."}
      </div>
    </div>
  );
}