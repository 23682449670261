import React, { useState } from 'react';
import {
  Col,
  Row,
  Button,
  ProgressBar,
  InputGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import iconCalendar from '../../assets/newsEditor/images/icon_calendar.svg';
import iconCalendarToday from '../../assets/newsEditor/images/icon_calendar1.svg';
import iconCalendar7Days from '../../assets/newsEditor/images/icon_calendar7.svg';
import iconCalendar30Days from '../../assets/newsEditor/images/icon_calendar30.svg';
import ModalCalendar from './ModalCalendar';
import Mascote from './Mascote';
import iconPesquisaLittle from '../../assets/newsEditor/images/icon_search_little.svg';
import mascoteRelatedNews from '../../assets/newsEditor/images/mascoteRelatedNews.svg';
import mascoteLoading from '../../assets/newsEditor/images/mascoteSearchGif.gif';
import mascoteSearchError from '../../assets/newsEditor/images/mascoteSearchError.svg';
import mascoteEmpty from '../../assets/newsEditor/images/mascote1.svg';
import { getSearchNews } from '../../api/search';
import InfoIcon from '../../assets/img/icons/info.svg';

function TabSearchNews() {
  const [relatedNews, setRelatedNews] = useState([]);
  const [filteredRelatedNews, setFilteredRelatedNews] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [calendarIcon, setCalendarIcon] = useState(iconCalendar);
  const renderNews = [];
  const [mascoteImg, setMascoteImg] = useState(mascoteEmpty);
  const [mascoteText, setMascoteText] = useState('Começa a escrever');
  const [searchQuery, setSearchQuery] = useState('');

  const toggleModalCalendar = () => {
    if (calendar.length === 0) {
      setCalendar(
        <ModalCalendar
          filterNews={setFilterNews}
          closeModalCalendar={closeCalendar}
        ></ModalCalendar>,
      );
    } else {
      setCalendar([]);
    }
  };

  function closeCalendar() {
    setCalendar([]);
  }

  const setFilterNews = (num) => {
    const now = new Date();
    let len = relatedNews.length;

    if (num !== -1) {
      const filtered = relatedNews.filter((news) => {
        const date = new Date(news.ValueDate);
        const diff = Math.ceil((date - now) / 1000 / 60 / 60 / 24);
        return diff >= num;
      });

      setFilteredRelatedNews(filtered);
      len = filtered.length;
    } else {
      setFilteredRelatedNews(relatedNews);
      len = relatedNews.length;
    }

    if (len === 0) {
      setMascoteImg(mascoteSearchError);
    } else {
      setMascoteImg(mascoteRelatedNews);
    }

    let newsTerm = len === 1 ? 'notícia' : 'notícias';

    switch (num) {
      case -1:
        setCalendarIcon(iconCalendar);
        setMascoteText(`Encontrei ${len} ${newsTerm}.`);
        break;
      case 0:
        setCalendarIcon(iconCalendarToday);
        setMascoteText(`Encontrei ${len} ${newsTerm} para hoje.`);
        break;
      case -7:
        setCalendarIcon(iconCalendar7Days);
        setMascoteText(`Encontrei ${len} ${newsTerm} dos últimos 7 dias.`);
        break;
      case -30:
        setCalendarIcon(iconCalendar30Days);
        setMascoteText(`Encontrei ${len} ${newsTerm} dos últimos 30 dias.`);
        break;

      default:
        break;
    }

    closeCalendar();
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchRelatedNews = async () => {
    if (searchQuery !== '') {
      setMascoteText('Estou à procura.');
      setMascoteImg(mascoteLoading);

      let res;

      try {
        res = await getSearchNews(searchQuery);

        const filteredData = res;

        if (filteredData.length === 0) {
          setMascoteText('Não encontrei resultados.');
          setMascoteImg(mascoteSearchError);
        } else {
          setMascoteText(`Encontrei ${filteredData.length} notícias.`);
          setMascoteImg(mascoteRelatedNews);
        }

        setRelatedNews(filteredData);
        setFilteredRelatedNews(filteredData);
      } catch (err) {
        console.error(err);
        
        setMascoteText('Erro na pesquisa.');
        setMascoteImg(mascoteSearchError);
       /*  return toast(ToastError.GENERAL_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'general_error',
          autoClose: 2000,
        }); */
      }
    }
  };

  if (filteredRelatedNews !== []) {
    let i = 0;

    filteredRelatedNews.forEach((e) => {
      renderNews.push(
        <Row className="mb-4" key={i}>
          <p className="p-0 fontNews mb-1">
            <img
              src={e.SourceLogo}
              className="me-1 newsSearchIcon"
              alt="searc-icon"
            />
            {e.Source}
          </p>

          <a
            href={e.URL}
            target="_blank"
            className="p-0 titleNews mb-1 relatedNews giveMeEllipsis"
            rel="noreferrer"
          >
            {/* remove html tags from title */}
            {e.Title.replace(/(<([^>]+)>)/gi, '')}
          </a>
          <p className="p-0 fontNews mb-1">{e.Date}</p>

          {/*<ProgressBar className="px-0" now={e.Credibility * 100} />*/}
        </Row>,
      );

      i += 1;
    });
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      fetchRelatedNews();
    }
  };

  return (
    <>
      <Row className="font-title section-tab-title">
        <Col className="margin-title-section align-content-center">
          Pesquisa de notícias
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip" className="tooltip">
                Aqui podes pesquisar por notícias do Jornal Público. Para isso, escreve
                as palavras-chave na caixa de texto. Além disso, consegues
                filtrar pela data da sua publicação.
              </Tooltip>
            }
          >
            <img src={InfoIcon} alt="informação" className="mx-2" />
          </OverlayTrigger>
        </Col>
      </Row>

      <Row className="mt-2 me-2 gx-0">
        <Col xs={11}>
          <InputGroup className="">
            <FormControl
              className="mb-0 inputSearch"
              placeholder="Pesquisar"
              value={searchQuery}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <Button className="btnSearch no-input" onClick={fetchRelatedNews}>
              <img
                src={iconPesquisaLittle}
                className="ps-0 newsSearchIcon"
                alt="icon-search"
              />
            </Button>
          </InputGroup>
        </Col>

        <Col xs={1} className="gx-0 my-auto">
          <Button
            className="btnFilter"
            onClick={() => {
              toggleModalCalendar();
            }}
          >
            <img src={calendarIcon} alt="icon-calendar" />
          </Button>
        </Col>
      </Row>

      {calendar}
      {renderNews?.length !== 0 && (
        <Row className=" mt-4 small">Resultados:</Row>
      )}

      <Row className="divResults mt-2">{renderNews}</Row>

      <Mascote img={mascoteImg} text={mascoteText}></Mascote>
    </>
  );
}

export default TabSearchNews;
