import React from 'react';
import { NavLink } from 'react-router-dom';
import MetaTags from '../../../components/metatags/MetaTags';

export default function PageNotFound({ link, linkTxt, isSmall }) {
  const pageClass = `pageNotFound ${isSmall ? 'small' : 'big'}`;

  return (
    <div className={pageClass}>
      <MetaTags title={`Página não encontrada | Projeto TRUE`} />

      <h1 className="errorTitle">404</h1>

      <h2 className="errorSubtitle">Página não encontrada</h2>

      <div className="errorDescription">
        A página que estás à procura pode ter sido removida.
      </div>

      {link === undefined && linkTxt === undefined ? (
        <></>
      ) : (
        <NavLink className="mainBtn errorBtn" to={link}>
          {linkTxt}
        </NavLink>
      )}
    </div>
  );
}
