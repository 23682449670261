import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ModalConfirmState = ({ status, closeModal, submitForm }) => {
  let title = '';
  let textContent = '';
  const userRole = useSelector((state) => state.user.role.name);

  if (userRole === 'Autor') {
    switch (status) {
      case 'Em curso':
        title =
          'Tens a certeza que queres mudar o estado da notícia para “Em curso”?';
        textContent =
          'Ao fazeres esta alteração, a notícia deixará de estar disponível no jornal e poderás voltar a alterar a notícia';
        break;
      case 'Para Revisão':
        title = 'Tens a certeza que queres enviar esta notícia para revisão?';
        textContent =
          'Ao enviares para revisão, deixarás de conseguir alterar a notícia.';
        break;
      default:
        break;
    }
  } else {
    switch (status) {
      case 'Revista':
        title = 'Tem a certeza que já reviu esta notícia?';
        textContent = 'Posteriormente poderá alterar o estado da notícia';
        break;
      case 'Publicada':
        title =
          'Tem a certeza que já reviu a notícia e quer que esta seja publicada?';
        textContent = 'A notícia ficará disponivel de imediato no jornal';
        break;
      case 'Em curso':
        title =
          'Tem a certeza que quer mudar o estado da notícia para “Em curso”?';
        textContent =
          'Ao fazer esta alteração, a notícia deixará de estar disponível no jornal e o autor poderá voltar a alterar a notícia';
        break;
      case 'Para Revisão':
        title = 'Tem a certeza que quer enviar esta notícia para revisão?';
        textContent =
          'Ao enviar para revisão, esta notícia será revista antes de ser publicada.';
        break;
      default:
        break;
    }
  }

  const handleClick = () => {
    submitForm(false);
  };

  return (
    <Modal
      id="modal-confirm-state"
      size="md"
      aria-labelledby="contained-modl-title-vcenter"
      centered
      show
    >
      <Modal.Header>
        <Modal.Title className="mx-2">{title}</Modal.Title>
        <Button
          type="button"
          className="btn-closing btn btn-primary p-4"
          onClick={closeModal}
        ></Button>
      </Modal.Header>

      <Modal.Body>
        <p className="mx-2">{textContent}</p>
        <div className="modalConfirmState">
          <Button
            type="button"
            className="btnCancelNews px-5 mx-2"
            onClick={closeModal}
          >
            Não
          </Button>

          <Button
            form="my-form"
            className="btnSubmitNews px-5 mx-2"
            onClick={handleClick}
          >
            Sim
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
