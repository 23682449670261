import React, { Component } from 'react';

import { Grid } from '@mui/material';
import TextInput from '../../components/dashboard/textinput/TextInput';

class UserData extends Component {
  render() {
    const {
      schoolName,
      schoolMunicipality,
      schoolDistrict,
      firstName,
      lastName,
      userEmail,
      userType,
      changeHandler,
    } = this.props;

    return (
      <>
        <Grid className="profileInputContainer leftSide" item xs={4} lg={4}>
          <TextInput
            className="custom-user-input-disabled"
            type="text"
            label="Agrupamento ou escola"
            name="schoolName"
            value={schoolName}
            placeholder="ex: Escola Secundária do Castêlo da Maia"
            setInputValue={changeHandler}
            disabled={true}
          />
        </Grid>

        <Grid className="profileInputContainer leftSide" item xs={4} lg={3}>
          <TextInput
            className="custom-user-input-disabled"
            type="text"
            label="Concelho"
            name="schoolMunicipality"
            value={schoolMunicipality}
            placeholder="ex: Vila Nova de Gaia"
            setInputValue={changeHandler}
            disabled={true}
          />
        </Grid>

        <Grid className="profileInputContainer" item xs={4} lg={3}>
          <TextInput
            className="custom-user-input-disabled"
            type="text"
            label="Distrito"
            name="schoolDistrict"
            value={schoolDistrict}
            placeholder="ex: Castelo Branco"
            setInputValue={changeHandler}
            disabled={true}
          />
        </Grid>

        {/* <Grid className="profileInputContainer" item xs={6} lg={5}>
          <TextInput
            type="text"
            label="URL do Jornal Digital"
            name="subdirectory"
            value={subdirectory}
            startAdornment={
              <span className="prefix">{window.location.origin + '/'}</span>
            }
            placeholder="ex: https://jornaisescolares.pt/escola-castelo-maia"
            setInputValue={changeHandler}
            disabled={true}
          />
        </Grid> */}

        <Grid className="profileInputContainer leftSide" item xs={6} lg={5}>
          <TextInput
            className="custom-user-input"
            type="email"
            label="E-mail"
            name="userEmail"
            value={userEmail}
            placeholder="ex: joao.gomes@mail.com"
            setInputValue={changeHandler}
          />
        </Grid>

        <Grid className="profileInputContainer" item xs={6} lg={5}>
          <TextInput
            type="text"
            label="Tipo de utilizador"
            name="userType"
            value={userType}
            placeholder="Selecione o tipo de utilizador"
            disabled={true}
          />
        </Grid>

        <Grid className="profileInputContainer leftSide" item xs={6} lg={5}>
          <TextInput
            className="custom-user-input"
            type="text"
            label="Nome"
            name="firstName"
            value={firstName}
            placeholder="ex: João"
            setInputValue={changeHandler}
            maxLength={25}
          />
        </Grid>

        <Grid className="profileInputContainer" item xs={6} lg={5}>
          <TextInput
            className="custom-user-input"
            type="text"
            label="Apelido"
            name="lastName"
            value={lastName}
            placeholder="ex: Gomes"
            setInputValue={changeHandler}
            maxLength={25}
          />
        </Grid>

        {/* <Grid className="profileInputContainer leftSide" item xs={6} lg={5}>
          <TextInput
            className="custom-user-input-disabled"
            type="text"
            label="Nome do jornal"
            name="journalName"
            value={journalName}
            placeholder="ex: Jornal do Castêlo da Maia"
            setInputValue={changeHandler}
            disabled={true}
          />
        </Grid> */}
      </>
    );
  }
}

export default UserData;
