import React from 'react';
import { Grid } from '@mui/material';

import NewsPublicoItemInfo from './PublicoItemInfo';
import NewsPublicoItemPicture from './PublicoItemPicture';

export default function PublicoSectionItem({ news, color }) {
  if (!news) {
    return <></>;
  }

  const itemInfo = (
    <NewsPublicoItemInfo
      titleClass="slotItemTitle"
      news={news}
      color={color}
    />
  );

  const picture = <NewsPublicoItemPicture news={news} size="slot" />;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={7} sm={7}>
          {itemInfo}
        </Grid>

        <Grid item xs={5} sm={5}>
          {picture}
        </Grid>
      </Grid>
    </div>
  );
}
