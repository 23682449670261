import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import SuccessLogo from '../../assets/newsEditor/images/mascoteBoa.svg';
import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Loading from '../../components/loading/Loading';
import MetaTags from '../../components/metatags/MetaTags';
import SideMenuReset from '../../templates/sidemenu/SideMenuReset';
import TextInput from '../../components/dashboard/textinput/TextInput';
import {
  confirmUpdatePassword,
  getUserByPasswordToken,
  resetPasswordTokenToNull,
} from '../../api/users';
import { ToastError } from '../../models/enum';

function ChangePassword(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const { id } = props.match.params;
    let data;
    try {
      data = await getUserByPasswordToken(id);
    } catch (err) {
      console.error(err);
      setError(true);
      setLoading(false);
      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
      return;
    }

    setLoading(false);

    return data;
  };

  //pesquisa inteligente à media que escreve
  const changeHandler = (e) => {
    setUser((user) => {
      return {
        ...user,
        [e.target.name]: e.target.value,
      };
    });
  };

  const confirm = async (e) => {
    e.preventDefault();
    const { id } = props.match.params;
    const { userPassword, confirmPassword } = user;

    if (userPassword !== confirmPassword) {
      toast(ToastError.PASSWORD_NO_MATCH, {
        className: 'toastStyleDanger',
        toastId: 'password_no_match',
        autoClose: 2000,
      });
      return;
    }

    setLoading(true);

    try {
      await confirmUpdatePassword(id, userPassword);
    } catch (err) {
      setLoading(false);

      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });

      return;
    }

    await setTokenNull(null);
    setLoading(false);
    setOpenModal(true);
  };

  const setTokenNull = async (passwordToken) => {
    let userData = await getUserData();
    try {
      await resetPasswordTokenToNull(userData.id, passwordToken);
    } catch (err) {
      setLoading(false);

      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });

      return;
    }
  };

  return (
    <div className="backgroundPage">
      <MetaTags title="Repor palavra-passe &bull; Definições | Projeto TRUE" />

      <SideMenuReset pageTitle="Repor palavra-passe" />

      <main className="dashboardBody">
        <DashHeader
          title="Definições"
          subtitle="Repor palavra-passe"
          hideSignOut={true}
        />

        {error ? (
          <div className="errorWrapper">
            <div className="errorTitle">Oops!</div>
            <div className="errorDescription">
              Um erro ocorreu, por favor tente mais tarde.
            </div>

            <NavLink className="mainBtn errorBtn" to="/dashboard">
              Aceder Back-Office
            </NavLink>
          </div>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <div className="bodyContainer profileContainer">
                <div className="pageSectionContainer head">
                  <p className="pageHighlight">Dados Pessoais</p>
                </div>
                <Grid className="myProfileContainer" container>
                  <Grid className="profileInputContainer leftSide" item xs={6} lg={5}>
                    <TextInput
                      className="custom-user-input"
                      type="password"
                      label="Nova palavra-passe"
                      name="userPassword"
                      placeholder="Insira a sua nova palavra-passe"
                      value={user?.userPassword}
                      setInputValue={changeHandler}
                    />
                  </Grid>

                  <Grid className="profileInputContainer" item xs={6} lg={5}>
                    <TextInput
                      className="custom-user-input"
                      type="password"
                      label="Confirmar Palavra-passe"
                      name="confirmPassword"
                      placeholder="Confirme a palavra-passe"
                      value={user?.confirmPassword}
                      setInputValue={changeHandler}
                    />
                  </Grid>

                  <Grid className="profileInputContainer" item xs={12} lg={10}>
                    <div className="attributesWrapperProfile">
                      <button className="mainBtn" onClick={confirm}>
                        Confirmar
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
      </main>
      {openModal === true ? (
        <Modal
          open={openModal}
          //onClose={this.props.hdlClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            '*': {
              boxSizing: 'content-box !important',
            },
            '::after, ::before': {
              boxSizing: 'content-box !important',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 450,
              bgcolor: 'background.paper',
              borderRadius: '6px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" component={'span'} variant={'body2'}>
              <Grid container>
                <Grid className="attributeContainer" item xs={12}>
                  <div
                    className="img-banner"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <img
                      style={{ width: '120px', height: '120px', marginBottom: '10px' }}
                      src={SuccessLogo}
                      alt="Logo"
                    />
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                    }}
                  >
                    A sua palavra-passe foi modificada com sucesso!
                  </p>
                </Grid>
              </Grid>
            </Typography>
            <Typography id="modal-modal-description" component={'span'} variant={'body2'}>
              <div
                className="btnContainer"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className="attributeContainer1">
                  <NavLink className="mainBtn errorBtn" to="/dashboard">
                    Aceder ao Back-Office
                  </NavLink>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ChangePassword;
