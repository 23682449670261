import { Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import InfoIcon from '../../assets/img/icons/info.svg';
import DangerIcon from '../../assets/img/icons/alertTriangle.svg';
import { useEffect } from 'react';

function FormVideo() {
  const dispatch = useDispatch();

  let value = useSelector((state) => state?.textEditor.videoUrl);
  let videoValidate = useSelector((state) => state?.textEditorValidation.video);
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );
  const youtubeUrlRegex =
    /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

  useEffect(() => {
    if (youtubeUrlRegex.test(value)) {
      dispatch(TextEditorValidationActions.updateKey('video', true));
    } else if (value !== '' && value != undefined) {
      dispatch(TextEditorValidationActions.updateKey('video', false));
    }
  }, []);

  // Regex pattern for matching YouTube URLs

  const onChangeHandle = (val) => {
    dispatch(TextEditorActions.updateKey('videoUrl', val));
    console.log(youtubeUrlRegex.test(val));
    if (youtubeUrlRegex.test(val)) {
      //console.log('validation');
      dispatch(TextEditorValidationActions.updateKey('video', true));
      //console.log('video valid');
    } else {
      //console.log('video invalid');
      dispatch(TextEditorValidationActions.updateKey('video', false));
    }
    if (val === '') {
      dispatch(TextEditorValidationActions.updateKey('video', null));
    }
  };

  return (
    <Row id="inputVideo">
      <Form.Group
        as={Col}
        className="col-6 form-bottom-margin"
        controlId="inputVideo"
      >
        <Form.Label className="font-title label-bottom-margin">
          Adicionar vídeo (opcional)
        </Form.Label>

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              Podes incluir um vídeo do Youtube. Copia o URL e cola-o aqui. O
              vídeo irá aparecer na tua notícia a seguir às imagens.
            </Tooltip>
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
        </OverlayTrigger>

        <Form.Control
          className={'input-height'}
          type="text"
          defaultValue={value}
          placeholder="Url do video"
          onChange={(event) => onChangeHandle(event.target.value)}
        />
      </Form.Group>

      {videoValidate === false && finalVerif === false && (
        <div className="errorVerifContainer">
          <img src={DangerIcon} className="errorVerifIcon" />
          <p className="errorVerifMessage">
            Por favor, adiciona um link válido do Youtube.
          </p>
        </div>
      )}
    </Row>
  );
}

export default FormVideo;
