import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import TableLoading from '../../components/tableloading/TableLoading';
import Modal from '../../templates/modal/Modal';
import ModalUserForm from './ModalUserForm';
import { createUser } from '../../api/users';
import { ToastError, ToastSuccess } from '../../models/enum';

//Icons
import AddUserIcon from '../../assets/img/icons/addUser.svg';
import PersonIcon from '../../assets/img/sideMenuIcons/person.svg';

class ModalAddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      name: '',
      surname: '',
      email: '',
      role: '',
      loading: false,
      lastClickedElement: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  //Handle modal accessibility
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleClick(event) {
    this.setState({ lastClickedElement: event.target });
  }

  //Opens modal
  handleOpen() {
    this.setState({
      openModal: true,
    });
  }

  //Closes modal
  handleClose() {
    this.setState({
      openModal: false
    });
    this.state.lastClickedElement.focus();
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.name === 'email' 
      ? e.target.value.trim() 
      : e.target.value
    });
  };

  handleAddNewUser = async (e) => {
    e.preventDefault();
    const { email, name, surname, role } = this.state;
    this.setState({ loading: true });

    try {
      await createUser(email, name, surname, role, this.props.user.school);
    } catch (err) {
      this.setState({ loading: false });
      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
      return;
    }

    this.setState({ 
      loading: false, 
      openModal: false,
      name: '',
      surname: '',
      email: '',
      role: ''
    });

    toast(ToastSuccess.CREATED_USER, {
      className: 'toastStyleSuccess',
      toastId: 'created_user',
      autoClose: 2000
    });
    this.props.addUser();
  };

  isValid = () => {
    const { name, surname, email, role } = this.state;
    return name && surname && email && role;
  };

  render() {
    const { name, surname, email, role, loading } = this.state;

    const loadingClass = loading ? 'modalBodyContainer pageLoading' : 'modalBodyContainer';

    return (
      <div>
        <div className="btnWrapper" onClick={this.handleOpen}>
          <button className="mainBtn">
            <img src={AddUserIcon} alt="Adicionar utilizador" />
            <span className="modalBtnTxt">Adicionar</span>
          </button>
        </div>

        <Modal
          openModal={this.state.openModal}
          modalHeader
          modalIcon={<img src={PersonIcon} alt="Pessoa" height="23px" />}
          modalTitle="Adicionar Novo Utilizador"
        >
          <div className={loadingClass}>
            <ModalUserForm
              name={name}
              surname={surname}
              email={email}
              role={role}
              roles={this.props.roles}
              changeHandler={this.changeHandler}
            />

            <div className="btnContainer">
              <div className="attributeContainer">
                <button className="secondaryBtnBlue" onClick={this.handleClose}>
                  Cancelar
                </button>
              </div>
              <div className="attributeContainer">
                {this.isValid() ? (
                  <button className="mainBtn" onClick={this.handleAddNewUser}>
                    Adicionar
                  </button>
                ) : (
                  <button className="mainBtn" disabled={true}>
                    Adicionar
                  </button>
                )}
              </div>
            </div>

            {loading ? <TableLoading/> : <></>}
          </div>
          <div
            ref={(el) => {
              this.lastElement = el;
            }}
            tabIndex="-1"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ModalAddUser);
