import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import ConfirmData from './pages/profile/ConfirmData';
import ChangePassword from './pages/profile/ChangePassword';
import BackOfficeLayout from './templates/backofficelayout/BackOfficeLayout';
import ConfigLayout from './templates/configlayout/ConfigLayout';
import FrontendLayout from './templates/frontendlayout/FrontendLayout';
import ScrollToTop from './components/utilities/ScrollToTop';
import theme from './MuiTheme';
import store from './redux/store';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/general.css';
import './assets/css/tables.css';
import './App.css';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer />
          <Provider store={store}>
            <Switch>
              <Route path="/confirm-account/:id" exact component={ConfirmData} />

              <Route path="/change-password/:id" exact component={ChangePassword}/>

              <Route path="/configuration">
                <ConfigLayout />
              </Route>

              <Route path="/dashboard">
                <BackOfficeLayout />
              </Route>

              <Route path="/:journalName">
                <FrontendLayout />
              </Route>

              <Redirect to="/dashboard" />
            </Switch>
          </Provider>
          <ScrollToTop />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
