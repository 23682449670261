import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import ModalSectionForm from './ModalSectionForm';
import { createNewSection } from '../../api/sections';
import { ToastError, ToastSuccess } from '../../models/enum';

export default function ModalAddSection(props) {
  const user = useSelector((state) => state?.user);
  const [sectionName, setSectionName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddNewSection = async () => {
    if (!sectionName) {
      toast(ToastError.INVALID_SECTION_NAME, { className: 'toastStyleDanger', toastId: 'invalid_section_name', autoClose: 2000 });
      return;
    }

    if(props.numSections >= 18){
      toast(ToastError.EXCEED_SECTIONS, { className: 'toastStyleDanger', toastId: 'invalid_section_name', autoClose: 2000 });
      return;
    }

    const sections = props.sections.map(s => s.sname);
    if (sections.includes(sectionName)) {
      toast(ToastError.SECTION_NAME_EXISTS, { className: 'toastStyleDanger', toastId: 'section_name_exists', autoClose: 2000 });
      return;
    }

    setLoading(true);

    let res;
    try {
      res = await createNewSection(
        user?.school,
        sectionName
      );
    } catch (err) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: 'general_error', autoClose: 2000 });
    }

    const newSection = res;

    toast(ToastSuccess.CREATED_SECTION, { className: 'toastStyleSuccess', toastId: 'created_section', autoClose: 2000 });

    setLoading(false);
    setSectionName('');
    props.addSection(newSection);
  }

  return (
    <ModalSectionForm
      sectionName={sectionName}
      setSectionName={(value) => setSectionName(value)}
      loading={loading}
      openModal={props.openModal}
      modalTitle="Adicionar Nova Secção"
      handleClose={props.handleClose}
      handleSubmit={handleAddNewSection}
      submitBtnName="Adicionar"
    />
  );
}