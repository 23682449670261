import React, { Component } from "react";

//Components
import { HeadlightScheme } from "../../../models/enum";

//Icons
import { ReactComponent as EditIcon } from "../../../assets/img/icons/edit.svg";

import HeadlightLayout from "../../../components/frontend/headlightlayout/HeadlightLayout.js";

import Modal from "@mui/material/Modal";
// import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';

class HeadlightsSidebar extends Component {
  render() {
    const {
      layout,
      handleOpen,
      updateHeadlight,
      handlePreViz,
      handleClosePreViz,
      preViz,
      headlightInfo,
      schoolInfo,
    } = this.props;

    const open = true;

    return (
      <div className="rightSidebar">
        <div className="schemeContainer">
          <div className="schemeBox boxSquare">
            <p className="schemeTitle">ESQUEMA</p>

            {HeadlightScheme[layout].scheme}
          </div>

          <div className="secondaryContainer btnMargin">
            <button className="secondaryBtnBlue" onClick={handlePreViz}>
              Pré-visualizar
            </button>
          </div>

          <div className="secondaryContainer btnMargin">
            <button className="secondaryBtnBlue" onClick={handleOpen}>
              <EditIcon className="hpIcon" />
              Editar Layout
            </button>
          </div>
        </div>

        <div className="btnContainer">
          <button className="mainBtn" onClick={updateHeadlight}>
            Guardar Alterações
          </button>
        </div>

        {preViz === true ? (
          <Modal
            open={open}
            onClose={handleClosePreViz}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: "scroll" }}
            disableScrollLock={true}
          >
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5%",
                //marginBottom: "auto",
                width: "50%",
                transform: "translate(0%, 0%)",
                backgroundColor: "white",
                border: "15px solid #FFFFFF",
                borderRadius: "4px",
              }}
            >
              {/* <IconButton sx={{float: "right"}} onClick={handleClosePreViz}><CloseIcon/></IconButton> */}
              <HeadlightLayout
                headlight={headlightInfo()}
                school={schoolInfo()}
              />
            </div>
          </Modal>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default HeadlightsSidebar;
