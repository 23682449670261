import React, { useEffect, useState } from 'react';

import { ToastError, ToastSuccess } from '../../../models/enum';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import TrueCheckbox from '../../../components/dashboard/checkbox/Checkbox';
import DashHeader from '../../../components/dashboard/dashheader/DashHeader';
import Header from '../../../components/newsEditor/Header';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import FileUpload from '../../../components/dashboard/fileupload/FileUpload';
import TableLoading from '../../../components/tableloading/TableLoading';
import TextInput from '../../../components/dashboard/textinput/TextInput';
import PreVizIdentity from './PreVizIdentity';
import { deleteImage, uploadImage } from '../../../api/upload';
import { updateJournalSettings } from '../../../api/schools';
import { SchoolActions } from './../../../redux/actions';
import { ONE_MB } from '../../../utils/utils';

export default function Identity() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [isSetLogo, setIsSetLogo] = useState(false);
  const [elements, setElements] = useState({});
  const [error, setError] = useState(null);
  const [openViz, setOpenViz] = useState(false);

  const hdlOpen = () => setOpenViz(true);
  const hdlClose = () => setOpenViz(false);

  const school = useSelector((state) => state.school);
  const schoolLogo = school?.logo;
  const journalName = school?.journal;

  const logoState = school?.journalIdentity?.logo;
  const journalNameState = school?.journalIdentity?.journalName;
  const schoolNameState = school?.journalIdentity?.schoolName;

  const [data, setData] = useState({
    logo: null,
    journal: null,
  });

  useEffect(() => {
    setLoading(false);
    setData({ logo: schoolLogo, journal: journalName });
    setElements({
      1: { name: 'Logótipo', state: logoState },
      2: { name: 'Nome do Jornal', state: journalNameState },
      3: { name: 'Nome da Escola', state: schoolNameState },
    });
  }, [school]);

  const setLogo = (value) => {
    setData({ ...data, logo: value });
    setIsSetLogo(true);
  };

  const setJournalName = (e) => {
    setData({ ...data, journal: e.target.value });
  };

  const handleCheck = (e, key) => {
    setElements({
      ...elements,
      [key]: { name: elements[key].name, state: e.target.checked },
    });
  };

  const updateInfo = async () => {
    let logoURL = data?.logo;
    setTableLoading(true);

    if (isSetLogo) {
      if (data.logo.file?.size > 2 * ONE_MB) {
        setTableLoading(false);
        return toast(ToastError.UPLOAD_SIZE_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'upload_size_error',
          autoClose: 2000
        });
      }

      // delete old image before uploading new one.
      if (data?.logo?.id) { 
        try {
          await deleteImage(data?.logo?.id); 
        } catch (error) {
          console.error(error);
        }
      }
      
      const imageRes = await uploadImage(data?.logo?.file);
      logoURL = imageRes[0].id;
    }

    let logoValue = elements[1].state;
    let journalNameValue = elements[2].state;
    let schoolNameValue = elements[3].state;

    if (logoValue === false && journalNameValue === false && schoolNameValue === false) {
      setTableLoading(false);
      setError('* Selecione pelo menos um elemento');
    } else {
      setError(null);
      let res;
      try {
        res = await updateJournalSettings(
          school?._id,
          data?.journal,
          logoURL,
          logoValue,
          journalNameValue,
          schoolNameValue,
        );
      } catch (err) {
        setTableLoading(false);
        toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'general_error',
          autoClose: 2000,
        });
        return;
      }

      dispatch(SchoolActions.updateSchool(res));

      setTableLoading(false);
      toast(ToastSuccess.UPDATED_INFO, {
        className: 'toastStyleSuccess',
        toastId: 'updated_info',
        autoClose: 2000,
      });
      setIsSetLogo(false);
    }
  };

  const loadingClass = tableLoading ? 'bodyContainer profileContainer pageLoading' : 'bodyContainer profileContainer';

  return (
    <div>
      <MetaTags title="Paleta de cores &bull; Personalização do Jornal | Projeto TRUE" />
      <DashHeader title="Personalização do Jornal" subtitle="Identidade do jornal" />
      <Header name={'help_identity'} />

      {loading ? (
        <Loading />
      ) : (
        <div className={loadingClass}>
          <div className="pageSectionContainer head">
            <p className="pageHighlight">Editar dados do jornal</p>
            <Grid container spacing={3}>
              <Grid item container xs={6}>
                <Grid className="profileInputContainer leftSide" item container xs={12}>
                  <TextInput
                    className="custom-user-input-disabled"
                    type="text"
                    label="Agrupamento ou escola"
                    name="schoolName"
                    value={school?.name}
                    disabled={true}
                  />
                  <TextInput
                    className="custom-user-input"
                    type="text"
                    label="Nome do jornal"
                    name="journalName"
                    value={data?.journal}
                    setInputValue={setJournalName}
                    maxLength={50}
                  />
                </Grid>

                <div className="pageSectionContainer head">
                  <p
                    className="pageHighlight"
                    style={{ color: '#1f8598', fontSize: '16px', fontWeight: '500' }}
                  >
                    ELEMENTOS VISÍVEIS
                  </p>
                  {Object.keys(elements).map((key) => (
                    <div key={key}>
                      <TrueCheckbox
                        checked={elements[key].state ? true : false}
                        onChange={(e) => handleCheck(e, key)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                        name={elements[key].name}
                      />
                    </div>
                  ))}
                  {error && (
                    <p
                      style={{
                        color: 'red',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      }}
                    >
                      {error}
                    </p>
                  )}

                  <div className="attributesWrapperProfile">
                    <button
                      className="secondaryBtnBlue identityPreviz"
                      style={{ marginTop: '45px', marginRight: '35px' }}
                      onClick={hdlOpen}
                    >
                      Pré-visualizar
                    </button>
                    <button
                      className="mainBtn"
                      style={{ marginTop: '45px' }}
                      disabled={!data?.journal}
                      onClick={updateInfo}
                    >
                      Guardar Alterações
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid item container xs={1} />
              <Grid item xs={3}>
                <FileUpload
                  id={data?.logo?.id}
                  url={data?.logo?.url}
                  file={data?.logo?.file}
                  onChange={setLogo}
                />
              </Grid>
            </Grid>
          </div>

          {tableLoading ? <TableLoading /> : <></>}
        </div>
      )}

      {openViz === true ? (
        <PreVizIdentity
          hdlClose={hdlClose}
          school={school}
          color1={school?.palette?.color?.main}
          color2={school?.palette?.color?.secondary}
          logoVal={elements[1].state}
          journalNameVal={elements[2].state}
          schoolNameVal={elements[3].state}
          //prevLogo={data?.logo?.file === undefined ? data?.logo?.url : data?.logo?.file?.preview}
          prevLogoOrigin={data?.logo?.url}
          prevLogo={data?.logo?.file}
          prevName={data?.journal}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
