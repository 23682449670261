import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

import MetaTags from '../components/metatags/MetaTags';
import SecondaryFooter from '../templates/frontendfooter/SecondaryFooter';
import { login } from '../api/auth';
import { LoginError, ToastError, ToastSuccess } from '../models/enum';
import { sendConfirmationEmail, sendEmailPassword } from '../api/emails';
import { toast } from 'react-toastify';

import Link from '@mui/material/Link';

//STYLE
import PropTypes from 'prop-types';
import { withStyles, ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../MuiTheme';

//IMAGE
import logoOriginal from '../assets/logo/logo-big.svg';
import imageLogin from '../assets/img/illustrations/IlustracaoInicio.png';

//ICONS
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Style
const styles = (theme) => ({
  pageContainer: {
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
  margin: {
    margin: '15px !important',
    width: '300px',
  },
  button: {
    margin: '15px !important',
    width: '200px',
  },
  loginGridContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'center'
  },
  logo: {
    width: '250px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 10,
  },
  image: {
    height: '100vh',
    width: '50vw',
  },
  success: {
    color: 'green',
  },
  error: {
    color: 'red',
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      identifier: '', //user email
      password: '',
      sucessMessage: '',
      failureMessage: '',
      showPassword: false,
      showForgotPassword: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePasswordVisibility = this.handlePasswordVisibility.bind(this);
    this.showResetPassword = this.showResetPassword.bind(this);
    this.disableShowResetPassword = this.disableShowResetPassword.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.clickSendEmailPassword = this.clickSendEmailPassword.bind(this);
  }

  handleEmailChange(event) {
    this.setState({
      identifier: event.target.value.trim(),
      failureMessage: '',
      sucessMessage: '',
    });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value.trim() });
  }

  handlePasswordVisibility(event) {
    if (this.state.showPassword === false) {
      this.setState({ showPassword: true });
    } else if (this.state.showPassword === true) {
      this.setState({ showPassword: false });
    }
  }

  sendEmail(email) {
    try {
      sendConfirmationEmail(email);
    } catch (err) {
      this.setState({
        failureMessage: ToastError.GENERAL_ERROR,
      });
      return;
    }

    toast(ToastSuccess.EMAIL_SENT, {
      className: 'toastStyleSuccess',
      toastId: 'email_sent',
      autoClose: 2000,
    });
  }

  async handleClick(e) {
    e.preventDefault();

    this.setState({
      failureMessage: '',
      sucessMessage: 'a verificar credenciais ...',
    });

    try {
      const response = await login(this.state.identifier, this.state.password);
      localStorage.setItem('userToken', response.jwt);
      window.location.href = '/dashboard/news';
    } catch (error) {
      const msgcontainer = error.message ? error.message : null;
      const msgresult =
        msgcontainer && msgcontainer.length > 0 ? msgcontainer[0].messages : null;
      const msgId = msgresult && msgresult.length > 0 ? msgresult[0].id : null;

      let msg = LoginError[msgId] ? LoginError[msgId] : ToastError.GENERAL_ERROR;

      if (msgId === 'Auth.form.error.password.local') {
        msg = (
          <>
            O seu e-mail ainda não foi validado! <br />
            Clique{' '}
            <a href="#" onClick={() => this.sendEmail(this.state.identifier)}>
              aqui
            </a>{' '}
            para reenviar o e-mail de validação da sua conta.
          </>
        );
      }

      this.setState({
        sucessMessage: '',
        failureMessage: msg,
      });
      console.error(error);
    }
  }

  showResetPassword() {
    this.setState({ showForgotPassword: true, failureMessage: '', sucessMessage: '' });
  }

  disableShowResetPassword() {
    this.setState({ showForgotPassword: false, failureMessage: '', sucessMessage: '' });
  }

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  async clickSendEmailPassword(e) {
    e.preventDefault();
    let email = this.state.identifier;

    if (!this.isValidEmail(email)) {
      this.setState({
        failureMessage: 'E-mail inválido.',
        successMessage: '',
      });
    } else {
      try {
        await sendEmailPassword(email);
        this.setState({
          failureMessage: '',
          sucessMessage: 'E-mail de reposição de password enviado com sucesso!',
        });
      } catch (err) {
        this.setState({
          failureMessage: 'O e-mail não se encontra registado.',
          sucessMessage: '',
        });
      }
    }
  }

  render() {
    const { identifier, password, showPassword, showForgotPassword } = this.state;
    const { classes } = this.props;

    return (
      <div className="loginPage">
        <MetaTags />
        <form
          onSubmit={
            showForgotPassword === false ? this.handleClick : this.clickSendEmailPassword
          }
        >
          <Grid container className={classes.pageContainer}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Grid item md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <CardMedia
                  className={classes.image}
                  component="img"
                  alt="Splash screen TRUE"
                  image={imageLogin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.loginGridContainer}>
                  <Box className={classes.loginContainer}>
                    <CardMedia
                      className={classes.logo}
                      component="img"
                      alt="Logo TRUE"
                      image={logoOriginal}
                    />
                    {/* <form
               onSubmit={showForgotPassword === false ? this.handleClick : this.clickSendEmailPassword}
              > */}
                    {showForgotPassword === false ? (
                      <div>
                        {/* <form onSubmit={this.handleClick}> */}
                        <div>
                          <TextField
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Email"
                            value={identifier}
                            onChange={this.handleEmailChange}
                            color="secondary"
                            size="small"
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon fontSize="default" color="secondary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="outlined-password-input"
                            label="Password"
                            className={classes.margin}
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={this.handlePasswordChange}
                            autoComplete="current-password"
                            color="secondary"
                            size="small"
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon fontSize="default" color="secondary" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  onClick={this.handlePasswordVisibility}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon fontSize="default" color="secondary" />
                                  ) : (
                                    <VisibilityIcon fontSize="default" color="secondary" />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div>
                          <Link
                            //component="button"
                            variant="body2"
                            onClick={() => this.showResetPassword()}
                            style={{ marginBottom: '15px', cursor: 'pointer' }}
                          >
                            Esqueceu-se da sua password?
                          </Link>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          //onClick={this.handleClick}
                          >
                            <b>Entrar</b>
                          </Button>
                          <p className={classes.success}>{this.state.sucessMessage}</p>
                          <p className={classes.error}>{this.state.failureMessage}</p>
                        </div>
                        {/* </form> */}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <TextField
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Email"
                            value={identifier}
                            onChange={this.handleEmailChange}
                            color="secondary"
                            size="small"
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon fontSize="default" color="secondary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>

                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          //onClick={this.clickSendEmailPassword}
                          >
                            <b>Repor password</b>
                          </Button>
                          <p className={classes.success}>{this.state.sucessMessage}</p>
                          <p className={classes.error}>{this.state.failureMessage}</p>
                          <div>
                            <Link
                              //component="button"
                              variant="body2"
                              onClick={this.disableShowResetPassword}
                              style={{ cursor: 'pointer' }}
                            >
                              Voltar à página de Login
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </Box>

                  <div className="trueFooter">
                    <div className="footerContainer">
                      <SecondaryFooter />
                    </div>
                  </div>
                </Box>
              </Grid>
            </ThemeProvider>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Login));
