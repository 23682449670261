import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Radio } from '@mui/material';
import { toast } from 'react-toastify';

import DashHeader from '../../../components/dashboard/dashheader/DashHeader';
import HelpHeader from '../../../components/newsEditor/Header';

import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import SuccessModal from '../../../components/dashboard/successmodal/SuccessModal';
import Modal from '@mui/material/Modal';
import Header from '../../../templates/frontendheader/Header';
import Homepage from '../../frontend/homepage/Homepage';
import ModalPersonalization from './ModalPersonalization';
import TableLoading from '../../../components/tableloading/TableLoading';
import { Colors, ToastError, ToastSuccess } from '../../../models/enum';
import { updateSchoolPalette } from '../../../api/schools';
import { SchoolActions } from './../../../redux/actions';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaintIcon from '../../../assets/img/sideMenuIcons/brush.svg';
import './Personalization.css';

const DEFAULT_PAGE_OFFSET = 400;

export default function Personalization(props) {
  const school = useSelector((state) => state.school);
  const dispatch = useDispatch();
  const paletteKey = school?.palette?.key;

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedPalette, setSelectedPalette] = useState(paletteKey ? paletteKey : 1);
  const [successMessage, setSuccessMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  //pre viz
  const [openViz, setOpenViz] = useState(false);
  const [sections, setSections] = useState([]);
  const [height, setHeight] = useState(DEFAULT_PAGE_OFFSET);
  const [pageOffset, setPageOffset] = useState(DEFAULT_PAGE_OFFSET);
  const [headerCollapsed, setHeaderCollapsed] = useState(false);

  //Modal color personalization
  const [openModal, setOpenModal] = useState(false);
  const hdlOpenModal = () => setOpenModal(true);
  const hdlCloseModal = () => setOpenModal(false);

  const hdlOpen = () => setOpenViz(true);
  const hdlClose = () => setOpenViz(false);

  const schoolColorMain = school?.palette?.custom?.main;
  const schoolColorSecondary = school?.palette?.custom?.secondary;

  const [mainColor, setMainColor] = useState();
  const [secondaryColor, setSecondaryColor] = useState();

  useEffect(() => {
    if (!school?.palette?.key) {
      return;
    }
    setLoading(false);
    setSelectedPalette(school.palette.key);
    setSections(school.sections);
    setMainColor(schoolColorMain);
    setSecondaryColor(schoolColorSecondary);
  }, [school]);

  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedPalette(value);
  };

  const savePalette = async () => {
    const key = selectedPalette;

    let main;
    let secondary;
    let customMain;
    let customSecondary;

    //console.log(key);
    if (key === 4) {
      main = mainColor;
      secondary = secondaryColor;
      customMain = mainColor;
      customSecondary = secondaryColor;
    } else {
      main = Colors[key].main;
      secondary = Colors[key].secondary;
      customMain = mainColor;
      customSecondary = secondaryColor;
    }

    setTableLoading(true);

    let res;

    try {
      res = await updateSchoolPalette(
        school.id,
        key,
        main,
        secondary,
        customMain,
        customSecondary,
      );
    } catch (error) {
      setTableLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: 'general_error', autoClose: 2000 });
    }

    dispatch(SchoolActions.updateSchool(res));

    setTableLoading(false);
    setSuccessMessage(ToastSuccess.UPDATED_THEME);
    setModalOpen(true);
    // toast(ToastSuccess.UPDATED_THEME, { className: 'toastStyleSuccess' ,toastId: 'updated_theme', autoClose: 2000 });
  };

  const handleClose = () => {
    setSuccessMessage('');
    setModalOpen(false);
  };

  const getOffset = () => {
    if (height) {
      const offset = height * 0.6;
      return -offset;
    } else return -100;
  };

  const key = selectedPalette;
  let main;
  let secondary;

  if (key === 4) {
    main = mainColor;
    secondary = secondaryColor;
  } else {
    main = Colors[key].main;
    secondary = Colors[key].secondary;
  }

  const loadingClass = tableLoading ? 'homepageWrapper pageLoading' : 'homepageWrapper';

  return (
    <div>
      <MetaTags title="Paleta de cores &bull; Personalização do Jornal | Projeto TRUE" />
      <DashHeader title="Personalização do Jornal" subtitle="Paleta de cores" />
      <HelpHeader name={'help_palette'} />

      {loading ? (
        <Loading />
      ) : (
        <div className="bodyContainer">
          <div className="pageSectionContainer head">
            <p className="pageHighlight">Seleciona uma paleta de cores</p>
            <p className="highlightDescription">
              Escolhe as <b>cores principais</b> do jornal escolar
            </p>
          </div>

          <div className={loadingClass}>
            <Grid
              className="colorOptions"
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item container xs={9} lg={8} spacing={4}>
                {Object.keys(Colors).map((key) => (
                  <Grid key={key} className="colorOptionContainer" item container xs={6}>
                    <div>
                      <Radio
                        checked={selectedPalette === parseInt(key)}
                        onChange={handleChange}
                        value={key}
                        name="color-options"
                      />
                    </div>

                    {key === '4' ? (
                      <div>
                        <div className="colorContainer">
                          <div
                            className="paletteColor main"
                            style={{ backgroundColor: mainColor }}
                          >
                            <div className="paletteBottom">
                              <p className="paletteTitle">Primária</p>
                              <p>{mainColor}</p>
                            </div>
                          </div>

                          <div
                            className="paletteColor sec"
                            style={{ backgroundColor: secondaryColor }}
                          >
                            <div className="paletteBottom">
                              <p className="paletteTitle">Secundária</p>
                              <p>{secondaryColor}</p>
                            </div>
                          </div>
                        </div>

                        <div className="btnContainer">
                          <div className="paletteBtnContainer">
                            <button className="secondaryBtnBlue" onClick={hdlOpenModal}>
                              <div style={{ width: '22px', display: 'flex' }}>
                                <img
                                  src={PaintIcon}
                                  alt="Paint brush icon"
                                  style={{ width: '17px' }}
                                />
                              </div>
                              Personalizar Paleta
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="colorContainer">
                          <div
                            className="paletteColor main"
                            style={{ backgroundColor: Colors[key].main }}
                          >
                            <div className="paletteBottom">
                              <p className="paletteTitle">Primária</p>
                              <p>{Colors[key].main}</p>
                            </div>
                          </div>
                          <div
                            className="paletteColor sec"
                            style={{ backgroundColor: Colors[key].secondary }}
                          >
                            <div className="paletteBottom">
                              <p className="paletteTitle">Secundária</p>
                              <p>{Colors[key].secondary}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>

              <Grid className="paletteSidebar" item container xs={3} lg={4}>
                <div className="selectedPalette">
                  <p className="pageHighlight title">Paleta selecionada</p>

                  {selectedPalette === 4 ? (
                    <div>
                      <div className="color">
                        <div
                          className="colorBox"
                          style={{ backgroundColor: mainColor }}
                        />
                        {mainColor}
                      </div>

                      <div className="color">
                        <div
                          className="colorBox"
                          style={{
                            backgroundColor: secondaryColor,
                          }}
                        />
                        {secondaryColor}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="color">
                        <div
                          className="colorBox"
                          style={{ backgroundColor: Colors[selectedPalette].main }}
                        />
                        {Colors[selectedPalette].main}
                      </div>

                      <div className="color">
                        <div
                          className="colorBox"
                          style={{
                            backgroundColor: Colors[selectedPalette].secondary,
                          }}
                        />
                        {Colors[selectedPalette].secondary}
                      </div>
                    </div>
                  )}
                </div>

                <div className="paletteBtnContainer">
                  <button className="secondaryBtnBlue identityPreviz" onClick={hdlOpen}>
                    Pré-visualizar
                  </button>
                </div>

                <div className="btnContainer">
                  <div className="paletteBtnContainer">
                    <button className="mainBtn" onClick={() => savePalette()}>
                      Guardar Alterações
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>

            {tableLoading ? <TableLoading /> : <></>}
          </div>
        </div>
      )}

      {modalOpen ? (
        <SuccessModal
          open={modalOpen}
          handleClose={handleClose}
          message={successMessage}
          schoolLink={school?.subdirectory}
        />
      ) : (
        <></>
      )}

      {openViz === true ? (
        <Modal
          open={openViz}
          onClose={hdlClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: 'scroll' }}
          disableScrollLock={true}
        >
          <div
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '5%',
              width: '85%',
              transform: 'translate(0%, 0%)',
              backgroundColor: 'white',
              border: '15px solid #FFFFFF',
              borderRadius: '4px',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <IconButton sx={{ zIndex: '1', marginLeft: 'auto' }} onClick={hdlClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Header
              sections={sections}
              school={school}
              headerCollapsed={headerCollapsed}
              color1={main}
              color2={secondary}
              height={height}
              setHeight={setHeight}
            />
            <main
              className="frontendBody"
              style={{
                marginTop: headerCollapsed ? pageOffset : getOffset(),
                zIndex: headerCollapsed ? 0 : 10,
              }}
            >
              <Homepage
                school={school}
                slot={school?.slots}
                color1={main}
                color2={secondary}
              />
            </main>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {openModal === true ? (
        <ModalPersonalization
          hdlCloseModal={hdlCloseModal}
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          setMainColor={setMainColor}
          setSecondaryColor={setSecondaryColor}
          schoolId={school?.id}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
