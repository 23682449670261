import React, { Component } from 'react';
import { Input, FormControl, Select } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import TextLabel from './TextLabel';

import { ReactComponent as ArrowDown } from '../../../assets/img/icons/arrowDown.svg';

const useStyles = createStyles({
  formInput: {
    marginTop: 18,
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    background: '#EFEFEF',
    fontSize: 16,
    color: '#004552',
    lineHeight: 1.2,
    borderRadius: 4,
    '-webkit-transition': 'border 500ms ease-out',
    '-moz-transition': 'border 500ms ease-out',
    '-o-transition': 'border 500ms ease-out',
    transition: 'border 500ms ease-out',
    '&:not(.select-input)': {
      padding: 15,
    },
    '&.Mui-error': {
      border: '1px solid red',
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '@media (max-width: 1250px)': {
      fontSize: 15,
    },
  },
  inputTxt: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&::placeholder': {
      color: '#767676',
      opacity: 1,
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
    '&:-webkit-autofill:hover': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
  },
  adornment: {
    '& .prefix': {
      fontSize: 14,
      whiteSpace: 'nowrap',
      color: '#000000',
      fontWeight: 500,
    },
  },
  selectInput: {
    padding: 15,
    '&:focus': {
      background: 'unset',
    },
  },
  selectIcon: {
    width: 15,
    height: 12,
    marginRight: 15,
  },
  focused: {
    boxShadow: '2px 2px #ccc !important',
    '-moz-box-shadow': '2px 2px #ccc !important',
    '-webkit-box-shadow': '2px 2px #ccc !important',
  },
});

class TextInput extends Component {
  render() {
    const {
      classes,
      type,
      name,
      placeholder,
      value,
      setInputValue,
      startAdornment,
      disabled,
      required,
      label,
      select,
      children,
      error,
      helperText,
      maxLength,
      ...props
    } = this.props;

    let selectClasses = 'select-input';
    if (!value) selectClasses += ' class-font-colour-gray';

    const setInputFunction = (value) => {
      if (setInputValue) {
        setInputValue(value);
      }
    };

    const requiredValue = required ? true : false;

    const InputElement = (
      <Input
        classes={{
          root: classes.inputContainer,
          input: classes.inputTxt,
          focused: classes.focused,
          adornedStart: classes.adornment,
        }}
        type={type}
        name={name}
        value={value ? value : ''}
        placeholder={placeholder}
        disableUnderline
        fullWidth
        disabled={disabled}
        required={requiredValue}
        onChange={(e) => setInputFunction(e)}
        startAdornment={startAdornment}
        inputProps={{ maxLength: maxLength ? maxLength : null }}
        {...props}
      />
    );

    return (
      <FormControl className={classes.formInput} error={error} fullWidth>
        <TextLabel label={label} helperText={helperText} required={requiredValue}>
          {select ? (
            <Select
              classes={{ select: classes.selectInput, icon: classes.selectIcon }}
              className={selectClasses}
              value={value ? value : ''}
              input={InputElement}
              IconComponent={ArrowDown}
              displayEmpty
            >
              {children}
            </Select>
          ) : (
            InputElement
          )}
        </TextLabel>
      </FormControl>
    );
  }
}

export default withStyles(useStyles)(TextInput);
