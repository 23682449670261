import React, { Component } from 'react';

//CSS
import './modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount(){
    this.modalRef.current.focus();
  }
  render() {
    return (
      <div className={(this.props.openModal ? 'open' : 'close')} ref={this.modalRef} tabIndex={0}>
        <div className={`backgroundsWrapper${this.props.centered ? " centered" : ""}`}>
          <div className="windowBackground" />

          <div className="modalBackground">
            {this.props.modalHeader ? <div className="modalHeaderContainer">
              <div className="modalHeader">
                {this.props.modalIcon}
                <p className="modalTitle">
                  {this.props.modalTitle}
                </p>
              </div>
            </div> : <></>}

            {this.props.children}
          </div>
        </div>
      </div>
    );

  }
}

export default Modal;