import React from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { connect } from 'react-redux';

import MascoteSearchError from '../../assets/newsEditor/images/mascoteSearchError.svg';

import { UserActions } from '../../redux/actions';

class ModalSetCoordinator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usr: this.props.usr,
      user: this.props.user,
      open: this.props.open,
    };
  }

  render() {
    const { usr } = this.state;
    const nameCurrentUser = usr.name + ' ' + usr.surname;

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.hdlClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            '*': {
              boxSizing: 'content-box !important',
            },
            '::after, ::before': {
              boxSizing: 'content-box !important',
            }
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-25%, -50%)',
              width: 450,
              bgcolor: 'background.paper',
              borderRadius: '6px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              //variant="h6"
              //component="h2"
              component={'span'}
              variant={'body2'}
            >
              <Grid container>
                <Grid className="attributeContainer" item xs={12}>
                  <div className="img-banner" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      //className="img"
                      style={{ width: '120px', height: '120px' }}
                      src={MascoteSearchError}
                      alt="Logo"
                    />
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                    }}
                  >
                    Têm a certeza de que pretende deixar o cargo de coordenador e atribuir
                    o seu cargo a <b>{nameCurrentUser}</b>?
                  </p>
                </Grid>
              </Grid>
            </Typography>
            <Typography
              id="modal-modal-description"
              //sx={{ mt: 2 }}
              component={'span'}
              variant={'body2'}
            >
              <div className="btnContainer" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="attributeContainer1">
                  <button className="secondaryBtnBlue" onClick={this.props.hdlClose}>
                    Cancelar
                  </button>
                </div>
                <div className="attributeContainer1">
                  <button
                    className="mainBtn"
                    onClick={this.props.assignCoordination}
                    disabled={false}
                  >
                    Atribuir cargo
                  </button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(ModalSetCoordinator);