import { useState } from "react";
import React from "react";

import Modal from "@mui/material/Modal";

import { Fade } from "@mui/material";

import S1 from "../../../assets/img/seccoes/seccao1.png";
import S2 from "../../../assets/img/seccoes/seccao2.png";
import S3 from "../../../assets/img/seccoes/seccao3.png";
import S4 from "../../../assets/img/seccoes/seccao4.png";

export default function PreViz(props) {
  const [open, setOpen] = useState(true);

  let numImages = { 1: S1, 2: S2, 3: S3, 4: S4 };

  let sectionID = props.sectionValue;

  return (
    <div>
      <Modal
        open={open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
        disableScrollLock={true}
      >
        <Fade in={open} timeout={500}>
          <img
            src={numImages[sectionID]}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "60%",
              transform: "translate(0%, 0%)",
            }}
          />
        </Fade>
      </Modal>
    </div>
  );
}
