export const ACTION_TYPES = {
  USER: {
    UPDATE_USER: 'UPDATE_USER',
  },
  SCHOOL: {
    UPDATE_SCHOOL: 'UPDATE_SCHOOL',
  },
  MENU: {
    UPDATE_MENU: 'UPDATE_MENU',
  },
  CONFIGURATION: {
    UPDATE_KEY: 'UPDATE_KEY',
    UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
    CLEAN_CONFIGURATION: 'CLEAN_CONFIGURATION',
  },
  STATUS_CONFIG: {
    UPDATE_LOADING: 'UPDATE_LOADING',
    UPDATE_ERROR: 'UPDATE_ERROR',
  },
  TEXTEDITOR: {
    UPDATE_TEXTEDITOR: 'UPDATE_TEXTEDITOR',
    UPDATE_KEY_TEXTEDITOR: 'UPDATE_KEY_TEXTEDITOR',
    CLEAN_TEXTEDITOR: 'CLEAN_TEXTEDITOR',
  },
  TEXTEDITOR_VALIDATION: {
    UPDATE_TEXTEDITOR_VALIDATION: 'UPDATE_TEXTEDITOR_VALIDATION',
    UPDATE_KEY_TEXTEDITOR_VALIDATION: 'UPDATE_KEY_TEXTEDITOR_VALIDATION',
    CLEAN_TEXTEDITOR_VALIDATION: 'CLEAN_TEXTEDITOR_VALIDATION',
  },
  ERRORS: {
    UPDATE_KEY: 'UPDATE_KEY',
  },
  REPLACEWORD: {
    UPDATE_REPLACEWORD: 'UPDATE_REPLACEWORD',
  },
};

export class UserActions {
  static updateUser = (user) => ({
    type: ACTION_TYPES.USER.UPDATE_USER,
    payload: user,
  });
}

export class SchoolActions {
  static updateSchool = (school) => ({
    type: ACTION_TYPES.SCHOOL.UPDATE_SCHOOL,
    payload: school,
  });
}

export class MenuActions {
  static updateMenu = (value) => ({
    type: ACTION_TYPES.MENU.UPDATE_MENU,
    payload: value,
  });
}

export class ConfigActions {
  static updateKey = (key, value) => ({
    type: ACTION_TYPES.CONFIGURATION.UPDATE_KEY,
    payload: { key: key, value: value },
  });

  static updateConfiguration = (configuration) => ({
    type: ACTION_TYPES.CONFIGURATION.UPDATE_CONFIGURATION,
    payload: configuration,
  });

  static cleanConfiguration = () => ({
    type: ACTION_TYPES.CONFIGURATION.CLEAN_CONFIGURATION,
    payload: null,
  });
}

export class ConfigStatusActions {
  static updateError = (error) => ({
    type: ACTION_TYPES.STATUS_CONFIG.UPDATE_ERROR,
    payload: error,
  });

  static updateLoading = (loading) => ({
    type: ACTION_TYPES.STATUS_CONFIG.UPDATE_LOADING,
    payload: loading,
  });
}

export class TextEditorActions {
  static updateTextEditor = (value) => ({
    type: ACTION_TYPES.TEXTEDITOR.UPDATE_TEXTEDITOR,
    payload: value,
  });

  static updateKey = (key, value) => ({
    type: ACTION_TYPES.TEXTEDITOR.UPDATE_KEY_TEXTEDITOR,
    payload: { key: key, value: value },
  });

  static cleanTextEditor = () => ({
    type: ACTION_TYPES.TEXTEDITOR.CLEAN_TEXTEDITOR,
    payload: null,
  });
}

export class TextEditorValidationActions {
  static updateTextEditorValidation = (value) => ({
    type: ACTION_TYPES.TEXTEDITOR_VALIDATION.UPDATE_TEXTEDITOR_VALIDATION,
    payload: value,
  });

  static updateKey = (key, value) => ({
    type: ACTION_TYPES.TEXTEDITOR_VALIDATION.UPDATE_KEY_TEXTEDITOR_VALIDATION,
    payload: { key: key, value: value },
  });

  static cleanTextEditorValidation = () => ({
    type: ACTION_TYPES.TEXTEDITOR_VALIDATION.CLEAN_TEXTEDITOR_VALIDATION,
    payload: null,
  });
}

export class ErrorsActions {
  static updateKey = (key, value) => ({
    type: ACTION_TYPES.ERRORS.UPDATE_KEY,
    payload: { key: key, value: value },
  });
}

export class ReplaceWordActions {
  static updateReplaceWord = (value) => ({
    type: ACTION_TYPES.REPLACEWORD.UPDATE_REPLACEWORD,
    payload: value,
  });
}
