import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ErrorsActions,
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import ModalSinonimo from './ModalSinonimo';
import { setGlobalState, useGlobalState } from '../../utils/MyContext';
import 'react-quill/dist/quill.snow.css';
import { checkErrors, relatedWords } from '../../api/correction';
import { TextEditorType } from '../../models/enumsNewsEditor';
import CustomLink from '../utilities/CustomLink';
import store from '../../redux/store';

function TextEditor({ type, height, className, quillModules, id }) {
  const quill = useRef(null);
  const ignoredWords = useGlobalState('ignoredWords')[0];
  const synonymModal = useGlobalState('synonymModal')[0];
  const [timer, setTimer] = useState(null);
  let posX;
  let posY;

  const formats = ['bold', 'font', 'italic', 'link', 'color'];

  const dispatch = useDispatch();

  let bodyRedux = useSelector((state) => state?.textEditor.bodyObject);
  let titleRedux = useSelector((state) => state?.textEditor.titleObject);

  let activeQuillRedux = useSelector((state) => state?.textEditor.activeQuill);
  let postionRedux = useSelector((state) => state?.textEditor.position);

  let replaceWordRedux = useSelector((state) => state?.replaceWord);

  let titleVerif = useSelector((state) => state?.textEditorValidation.title);
  let bodyVerif = useSelector((state) => state?.textEditorValidation.body);

  useEffect(() => {
    // Disable spellcheck as component is mounted
    quill.current.editor.root.setAttribute('spellcheck', 'false');

    // set inial value for edit
    if (type === TextEditorType.Body) {
      quill.current.getEditor().setContents(bodyRedux);
      const text = getText();
      // console.log('texto body', text);
      if (text !== ' ') {
        dispatch(TextEditorValidationActions.updateKey('body', true));
      }

      dispatch(TextEditorActions.updateKey('bodyRaw', text));
    } else if (type === TextEditorType.Title) {
      quill.current.getEditor().setContents(titleRedux);
      const text = getText();
      console.log('texto titulo', text);
      if (text !== ' ') {
        dispatch(TextEditorValidationActions.updateKey('title', true));
      }
      dispatch(TextEditorActions.updateKey('titleRaw', text));
    }
  }, []);

  // fetch erros quando palavra a ignorar é adicionada
  useEffect(() => {
    if (quill) {
      fetchDetetaErros();
    }
  }, [ignoredWords]);

  const getText = () => {
    const delta = quill.current.getEditor().getContents();
    let newString = '';

    delta.ops.forEach((e) => {
      newString += e.insert;
    });

    // remove break lines and new lines
    newString = newString.replace(/(\r\n|\n|\r)/gm, ' ');

    return newString;
  };

  // Funçao para identificar posição clicada
  const showCoords = (evt) => {
    posX = evt.pageX;
    posY = evt.pageY;
  };

  // 700ms timer after last keyup to fetch errors
  const onKeyUp = (e) => {
    updateForm();

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fetchDetetaErros();
    }, 3000);

    if (type === TextEditorType.Body) {
      dispatch(TextEditorActions.updateKey('activeQuill', TextEditorType.Body));
    } else if (type === TextEditorType.Title) {
      dispatch(
        TextEditorActions.updateKey('activeQuill', TextEditorType.Title),
      );
    }

    const getSelection = quill.current.getEditor().getSelection();
    let index = getSelection != null ? getSelection.index : 0;

    dispatch(TextEditorActions.updateKey('position', index));

    setTimer(newTimer);
  };

  // call back send text to form parent component
  const updateForm = () => {
    const html = quill.current.unprivilegedEditor.getHTML();

    // remove red formatting
    let parsedHTML = html.replace(
      '<span style="color: red;">',
      '<span style="color: black;">',
    );

    const delta = quill.current.getEditor().getContents();
    const textRaw = getText();

    if (type === TextEditorType.Body) {
      dispatch(TextEditorActions.updateKey('bodyObject', delta));
      dispatch(TextEditorActions.updateKey('body', parsedHTML));
      dispatch(TextEditorActions.updateKey('bodyRaw', textRaw));

      bodyVerification(textRaw);
    } else if (type === TextEditorType.Title) {
      dispatch(TextEditorActions.updateKey('titleObject', delta));
      dispatch(TextEditorActions.updateKey('title', parsedHTML));
      dispatch(TextEditorActions.updateKey('titleRaw', textRaw));

      titleVerification(textRaw);
    }
  };

  const titleVerification = (text) => {
    const status = store.getState().textEditor.status;

    if (text.length > 1 && titleVerif != true && text.length <= 86) {
      dispatch(TextEditorValidationActions.updateKey('title', true));
    } else if ((text.length <= 1 || text.length > 86) && status != 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('title', false));
    } else if (
      (text.length <= 1 || text.length > 86) &&
      status === 'Em curso'
    ) {
      dispatch(TextEditorValidationActions.updateKey('title', null));
    }
  };

  const bodyVerification = (text) => {
    const status = store.getState().textEditor.status;

    if (bodyRedux != null) {
      if (text.length > 1 && bodyVerif != true) {
        dispatch(TextEditorValidationActions.updateKey('body', true));
      } else if (text.length <= 1 && status != 'Em curso') {
        dispatch(TextEditorValidationActions.updateKey('body', false));
      } else if (text.length <= 1 && status === 'Em curso') {
        dispatch(TextEditorValidationActions.updateKey('body', null));
      }
    }
  };

  // selection change fetch synonyms (disabled)
  const selectionChange = () => {
    const range = quill.current.getEditor().getSelection();
    if (range != null) {
      const text = quill.current.getEditor().getText(range.index, range.length);
      if (_.isEmpty(text) || text === ' ') {
      } else {
        FetchSinonimos(text, range);
      }
    }
  };

  const fetchDetetaErros = async () => {
    const text = getText();

    if (text === '') return;

    let res;
    const formData = new FormData();
    formData.append('text', text);

    try {
      res = await checkErrors(formData);

      const filteredIncorrect = res.results.filter((x) => x.correct === false);

      if (type === TextEditorType.Body) {
        dispatch(ErrorsActions.updateKey('bodyErrors', filteredIncorrect));
      } else if (type === TextEditorType.Title) {
        dispatch(ErrorsActions.updateKey('titleErrors', filteredIncorrect));
      }

      formatText(res.results);
    } catch (err) {
      /*  toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      }); */
    }
  };

  const formatText = (text) => {
    text.forEach((e) => {
      if (e.correct === true || ignoredWords.includes(e.word)) {
        quill.current
          .getEditor()
          .formatText(e.startRange, e.endRange, 'color', 'black');
      } else {
        quill.current
          .getEditor()
          .formatText(e.startRange, e.endRange, 'color', 'red');
        quill.current
          .getEditor()
          .formatText(e.endRange, e.endRange + 1, 'color', 'black');
      }
    });

    updateForm();
  };

  const FetchSinonimos = async (word, range) => {
    if (word === '') return;

    let res;

    const formData = new FormData();
    formData.append('sinonimos', word);

    try {
      res = await relatedWords(formData);

      let modal = (
        <ModalSinonimo
          word={word}
          sinList={res.palavras_enviados}
          posX={posX}
          posY={posY + 15}
          removerModal={removerModal}
          replaceWord={replaceWord}
          range={range}
        />
      );

      setGlobalState('synonymModal', modal);
    } catch (err) {
      console.error(err);
      // toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger' ,toastId: "general_error", autoClose: 2000 });
    }
  };

  const removerModal = () => {
    setGlobalState('synonymModal', []);
  };

  // Verifica-se se existem palavras a corrigir no corpo da notícia
  useEffect(() => {
    corrigirPalavra(replaceWordRedux.sugestion, replaceWordRedux.error);
  }, [replaceWordRedux]);

  const corrigirPalavra = (sugestao, palavra) => {
    const oldContent = quill.current.getEditor().getContents();

    oldContent.ops.forEach((e) => {
      const color = e.attributes !== undefined ? e.attributes.color : '';

      if (e.insert.includes(palavra) && color === 'red') {
        e.insert = e.insert.replace(palavra, sugestao);
      }
    });

    quill.current.getEditor().setContents(oldContent);

    // get active quill
    if (activeQuillRedux === type) {
      setSelection();
    }

    fetchDetetaErros();
  };

  // Para o cursor do texto voltar à posição final
  const setSelection = () => {
    quill.current.getEditor().setSelection(postionRedux);
  };

  const replaceWord = (synonym, range) => {
    // remove word
    quill.current.getEditor().deleteText(range.index, range.length);
    // insert synonym
    quill.current.getEditor().insertText(range.index, synonym);
    removerModal();
    updateForm();
  };

  <CustomLink />;

  return (
    <div onMouseDown={showCoords}>
      <ReactQuill
        style={{ height }}
        theme="snow"
        ref={quill}
        modules={quillModules}
        id={id}
        onKeyUp={onKeyUp}
        onChangeSelection={selectionChange}
        class={className}
        formats={formats}
      />

      {synonymModal}
    </div>
  );
}

export default TextEditor;
