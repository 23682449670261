import React from 'react';

import MainFooter from './MainFooter';
import SecondaryFooter from './SecondaryFooter';
// import FooterBkg from './FooterBkg';

import './Footer.css';

export default function Footer() {
  return (
    <div className="trueFooter">
      <div className="footerContainer">
        <MainFooter />
        <SecondaryFooter />
      </div>
    </div>
  );
}
