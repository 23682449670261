import React from 'react';

import { ReactComponent as Publico } from '../../assets/img/footer/ppublico.svg';
import { ReactComponent as Mog } from '../../assets/img/footer/pmog.svg';
import { ReactComponent as UAveiro } from '../../assets/img/footer/puaveiro.svg';

import { ReactComponent as P2020 } from '../../assets/img/footer/fportugal2020.svg';
import { ReactComponent as L2020 } from '../../assets/img/footer/flisboa2020.svg';
import { ReactComponent as C2020 } from '../../assets/img/footer/fcompete2020.svg';

import './SecondaryFooter.css';

export default function SecondaryFooter({ color }) {
  return (
    <div className="secondaryFooter">
      <div className="projectList">
        <div className="footerTxt">Projeto de:</div>

        <div className="footerImg">
          <a href="https://www.publico.pt/" target="_blank" rel="noreferrer">
            <Publico />
          </a>
          <a href="https://www.mog-technologies.com/" target="_blank" rel="noreferrer">
            <Mog />
          </a>
          <a href="https://www.ua.pt/" target="_blank" rel="noreferrer">
            <UAveiro />
          </a>
        </div>
      </div>

      <div className="projectList">
        <div className="footerTxt">Financiado por:</div>

        <div className="footerImg">
          <a href="https://portugal2020.pt/" target="_blank" rel="noreferrer">
            <P2020 />
          </a>
          <a href="https://lisboa.portugal2020.pt/" target="_blank" rel="noreferrer">
            <L2020 />
          </a>
          <a href="https://www.compete2020.gov.pt/" target="_blank" rel="noreferrer">
            <C2020 />
          </a>
        </div>
      </div>
    </div>
  );
}
