import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { convertStringToUrl, extractContent, getTitle } from '../../../utils/utils';

const useStyles = makeStyles({
  newsHover: {
    transition: 'color 0.3s ease',
    '&:hover': {
      opacity: 0.8,
      textShadow: '1px 1px rgba(255,255,255,0.8)',
      color: ({ color }) => {
        return color;
      },
    },
  },
});

const removeHtmlTagsExceptItalic = (str) => {
  const regex = /<(?!\/?em)[^>]*>|style="[^"]*"/gi;
  return str.replace(regex, '');
}

export default function NewsItemInfo({
  journalName,
  news,
  color,
  titleClass,
  isSection,
}) {
  const classes = useStyles({ color });

  const titleExtracted = extractContent(news?.title);

  const processedTitle = removeHtmlTagsExceptItalic(news?.title);

  const titleClassName = `${titleClass} ${classes.newsHover}`;
  const subtitleClassName = `newsItemSubtitle ${isSection ? 'section' : 'homepage'}`;



  return (
    <>
      <NavLink
        className={titleClassName}
        to={`/${journalName}/news/${convertStringToUrl(titleExtracted)}?id=${news?._id}`}
      >
        {/* {titleExtracted} */}
        {getTitle(processedTitle)}
      </NavLink>

      <p className={subtitleClassName}>{news?.author}</p>
    </>
  );
}
