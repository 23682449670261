import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditorActions } from '../../redux/actions';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Header from '../../components/newsEditor/Header';
import Loading from '../../components/loading/Loading';
import MetaTags from '../../components/metatags/MetaTags';
import ModalDeleteNews from './ModalDeleteNews';
import ModalOnboarding from '../../components/newsEditor/ModalOnboarding';
import ModalWarningBlock from './ModalWarningBlock';
import NewsHeader from './NewsHeader';
import NewsTable from './NewsTable';
import Pagination from '../../components/dashboard/pagination/Pagination';
import PreVizNews from './PreVizNews';
import usePrevious from '../../components/utilities/UsePrevious';
import { Steps, StepsAuthor } from '../../models/enumHelpModal';
import { getSchoolNewsCount, getSchoolNews, deleteNews } from '../../api/news';
import { updateOnboarding } from '../../api/users';
import { NewsFilters, ToastError } from '../../models/enum';
import { getNewCheckedValues, getNewsParams } from '../../utils/utils';
import './News.css';

export default function NewsManagement(props) {
  const user = useSelector((state) => state.user);
  const school = useSelector((state) => state.school);
  const dispatch = useDispatch();
  const lastElement = useRef(null);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPrevizModal, setOpenPrevizModal] = useState(false);
  const [openWarningBlock, setOpenWarningBlock] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const previousPage = usePrevious(page);

  const [filtersState, setFiltersState] = useState({
    stateValues: false,
    sectionValues: false,
    dateValues: false
  });
  const [checkboxValues, setCheckboxValues] = useState({
    stateValues: [],
    sectionValues: [],
    dateValues: []
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setFiltersState({
        stateValues: false,
        sectionValues: false,
        dateValues: false
      });
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    dispatch(TextEditorActions.updateKey('relatedNews', []));

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!school) { return; }

    // execute function asynchronously
    (async () => {
      await getNews();
      setFirstLoad(false);
    })();
  }, [school]);

  useEffect(() => {
    if (!user) { return; }
    updateOnboardingAPI();
  }, [user]);

  useEffect(() => {
    if (!user || !school) return;
    if (previousPage === page) { setPage(1); }
    applyFilters(page, checkboxValues, debouncedSearchValue);
  }, [page, checkboxValues, debouncedSearchValue]);

  const getNews = async () => {
    if (!school) return;

    const { filter, sort, start, limit } = getNewsParams(page, school, user);

    const proms = [
      getSchoolNews(sort, '', filter, start, limit),
      getSchoolNewsCount('', filter)
    ];

    let res;
    try {
      res = await Promise.all(proms);
    } catch (err) {
      console.error('[news] Get news error:', err);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
    }

    setItems(res[0]);
    setCount(res[1]);
  }

  const applyFilters = async (page, checkboxValues, searchValue) => {
    const { sort, filter, search, start, limit } = getNewsParams(page, school, user, searchValue, checkboxValues, school?.sections);

    const proms = [
      getSchoolNews(sort, search, filter, start, limit),
      getSchoolNewsCount(search, filter)
    ];

    // do not show loading animation and table loading animation at the same time 
    if (!firstLoad) { setTableLoading(true); }

    let res;
    try {
      res = await Promise.all(proms);
    } catch (err) {
      console.error('[news] Get news error:', err);
      setTableLoading(false);

      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    setItems(res[0]);
    setCount(res[1]);
    setTableLoading(false);
  }

  const toggleFilter = (type) => {
    setFiltersState({
      [type]: !filtersState[type]
    });
  }

  const cleanFilter = (type) => {
    setFiltersState({ [type]: false });

    setCheckboxValues(val => {
      return { ...val, [type]: [] }
    });
  }

  const changeHandler = async (e) => {
    const searchQuery = e.target.value;
    setSearchValue(searchQuery);
  };

  const changeHandlerCheckbox = (e, type) => {
    const checkedValue = e.target.value;
    const newValue = getNewCheckedValues(checkedValue, checkboxValues[type]);

    setCheckboxValues(val => {
      return { ...val, [type]: newValue }
    });
  };

  const submitDate = (startDate, endDate) => {
    setCheckboxValues(val => {
      return { ...val, [NewsFilters.DATE]: [startDate, endDate] }
    });

    setFiltersState({
      [NewsFilters.DATE]: false
    });
  };

  const handleChangePage = async (event, page) => {
    setPage(page);
  }

  const updateOnboardingAPI = async () => {
    if (!user) return;
    if (user.onboarding === false) {
      let onboarding = true;
      try {
        await updateOnboarding(onboarding);
      } catch (err) {
        /* toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
          className: 'toastStyleDanger',
          toastId: 'general_error',
          autoClose: 2000,
        }); */
        console.log(err)
        return;
      }
    }
  };

  // Open delete modal
  const handleOpenDeleteModal = (news) => {
    setOpenDeleteModal(true);
    setCurrentNews(news);
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }

  // Open News PreViz modal
  const handleOpenPrevizModal = (news) => {
    setOpenPrevizModal(true);
    setCurrentNews(news);
  }

  const handleClosePrevizModal = () => {
    setOpenPrevizModal(false);
  }

  const handleCloseWarningModal = () => {
    setOpenWarningBlock(false);
  }

  const deleteNewsFunc = async () => {
    setTableLoading(true);

    try {
      await deleteNews(currentNews.id);
    } catch (error) {
      setTableLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    handleCloseDeleteModal();
    await getNews();
    setTableLoading(false);
  };

  const showOnboarding = () => {
    if (user?.role.name === 'Administrador') {
      return <ModalOnboarding name={'onboarding'} steps={Steps.MODAL_ONBOARDING} />;
    } else if (user?.role.name === 'Autor') {
      return <ModalOnboarding name={'onboarding'} steps={StepsAuthor.MODAL_ONBOARDING} />;
    } else if (user?.role.name === 'Editor') {
      return <ModalOnboarding name={'onboarding'} steps={Steps.MODAL_ONBOARDING} />; // alterar
    }
  };


  return (
    <div>
      <MetaTags title="Gestão de Notícias | Projeto TRUE" />
      <DashHeader title="Gestão de Notícias" />
      <Header name={'help_news'} />

      {!user?.onboarding && !firstLoad ? (
        showOnboarding()
      ) : (
        <></>
      )}

      {firstLoad ? <Loading /> : <div className="bodyContainer">
        <NewsHeader
          filtersState={filtersState}
          checkboxValues={checkboxValues}
          searchValue={searchValue}
          toggleFilter={toggleFilter}
          changeHandler={changeHandler}
          changeHandlerCheckbox={changeHandlerCheckbox}
          cleanFilter={cleanFilter}
          submitDate={submitDate}
        />

        <NewsTable
          items={items}
          loading={tableLoading}
          handleOpenPrevizModal={handleOpenPrevizModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />

        <div className="resultsWrapper">
          <p className="results">
            <b>{count} resultados disponíveis</b>
          </p>
        </div>

        <Pagination
          count={count}
          page={page}
          handleChangePage={handleChangePage}
        />
      </div>}

      <div ref={lastElement} tabIndex="-1" />

      {school?.blockJournal === true ? (
        <ModalWarningBlock
          open={openWarningBlock}
          hdlClose={handleCloseWarningModal}
        />
      ) : (
        <></>
      )}

      {openDeleteModal ? (
        <ModalDeleteNews
          news={currentNews}
          open={openDeleteModal}
          hdlClose={handleCloseDeleteModal}
          deleteNewsFunc={deleteNewsFunc}
        />
      ) : (
        <></>
      )}

      {openPrevizModal ? (
        <PreVizNews
          news={currentNews}
          open={openPrevizModal}
          hdlClose={handleClosePrevizModal}
        />
      ) : (
        <> </>
      )}
    </div>
  );
}