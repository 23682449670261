import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '../../../templates/modal/Modal';
import Pagination from '../../../components/dashboard/pagination/Pagination';
import TableLoading from '../../../components/tableloading/TableLoading';
import HomepageIcon from '../../../assets/img/sideMenuIcons/homepage.svg';
import TextLabel from '../../../components/dashboard/textinput/TextLabel';
import ItemsTable from '../ItemsTable';
import { SlotOptions, ToastError } from '../../../models/enum';
import { getGalleryNews } from '../../../api/news';
import { getNewsParams } from '../../../utils/utils';

import { ReactComponent as PrevArrow } from '../../../assets/img/icons/previousArrow.svg';

export default function ModalSelectSlot(props) {
  const school = useSelector((state) => state.school);
  const user = useSelector((state) => state.user);

  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);
  const [step, setStep] = useState(props.step);
  const [news, setNews] = useState([]);
  const [count, setCount] = useState(0);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!school) return;
    getPaginatedSlots(school, page);
  }, [school, page]);

  const getPaginatedSlots = async (school, page) => {
    let sectionsList = school.sections;
    sectionsList = sectionsList?.filter(n => !props.selectedSlots.some(sN => sN?.section?.id === n.id));

    setTableLoading(true);

    const slots = props.selectedSlots.filter(s => s?.gallery?.id).map(s => s.gallery.id);
    const data = { slots: slots };
    const { sort, start, limit } = getNewsParams(page, school, user);

    let res;
    try {
      res = await getGalleryNews(school.id, data, sort, start, limit);
    } catch (err) {
      console.error('[slots] Get slots error:', err)
      setTableLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
    }

    let { gallery, count } = res;

    if (selectedItem?.section) {
      sectionsList = [selectedItem.section, ...sectionsList]
    } else if (selectedItem?.gallery && page === 1) {
      gallery = [selectedItem?.gallery, ...gallery];
    }

    setTableLoading(false);
    setNews(gallery);
    setSections(sectionsList);
    setCount(count);
  }

  const selectItem = (item, type) => {
    let selectedItem = {
      section: null,
      gallery: null
    };

    switch (type) {
      case SlotOptions.Section:
        selectedItem.section = item;
        break;
      case SlotOptions.Gallery:
        selectedItem.gallery = item;
        break;
      default:
        console.log('Selected invalid type:', type);
    }

    setSelectedItem(selectedItem);
  }

  const selectOption = (option) => {
    setSelectedOption(option);
  }

  const goBack = () => {
    setStep(1);
    setSelectedItem(null);
    setSelectedOption('');
  }

  const nextStep = () => {
    const { position, handleSelectItem } = props;

    if (step === 1) {
      setStep(2);
    } else {
      handleSelectItem(position, selectedItem);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const renderItemsTable = () => {
    const item = selectedItem?.section ? selectedItem.section : selectedItem?.gallery;
    const label = selectedOption === SlotOptions.Section ? 'Selecione uma secção' : 'Selecione uma notícia fotogaleria (mais de uma imagem)';
    const items = selectedOption === SlotOptions.Section ? sections : news;
    const placeholder = selectedOption === SlotOptions.Section ? 'Não há secções ativas.' : 'Não há notícias fotogaleria publicadas.';
    const isNews = selectedOption === SlotOptions.Section ? false : true;
    const loadingClass = tableLoading ? 'tableBodyContainer pageLoading' : 'tableBodyContainer';

    return (<>
      <div className="goBackContainer">
        <button className="secondaryBtnBlue goBack" onClick={() => goBack()}>
          <PrevArrow /> Voltar atrás
        </button>
      </div>

      <TextLabel label={label} />

      <div className={loadingClass}>
        <ItemsTable
          items={items}
          selectedItem={item}
          selectItem={(item) => selectItem(item, selectedOption)}
          isNews={isNews}
          placeholderTxt={placeholder}
        />

        {tableLoading ? <TableLoading /> : <></>}
      </div>

      {selectedOption === SlotOptions.Section ? <></> :
        <div className="paginationWrapper">
          <Pagination
            count={count}
            page={page}
            handleChangePage={handleChangePage}
          />
        </div>}
    </>);
  }

  const isValid = () => {
    if ((step === 1 && selectedOption) || (step === 2 && selectedItem)) return true;

    return false;
  };

  const { openModal, position, handleClose } = props;
  
  return (
    <Modal
      openModal={openModal}
      modalHeader
      modalIcon={<img src={HomepageIcon} alt="Homepage icon" />}
      modalTitle={`Selecionar Caixa - Posição ${position}`}
    >
      <div className="modalBodyContainer">
        {step === 1 ? <div className="modalSelectHeader">
          <TextLabel label="Selecione o tipo de conteúdo" />
          <p className="highlightDescription">Aqui pode adicionar uma secção ou uma galeria de imagens à homepage do seu jornal digital.</p>

          <div className="btnOptions">
            <div className="attributeContainer">
              <button
                className={(selectedOption === SlotOptions.Section ? "mainBtn" : "whiteBtn")}
                onClick={() => selectOption(SlotOptions.Section)}
              >
                Adicionar Secção
              </button>
            </div>

            <div className="attributeContainer">
              <button
                className={(selectedOption === SlotOptions.Gallery ? "mainBtn" : "whiteBtn")}
                onClick={() => selectOption(SlotOptions.Gallery)}
              >
                Adicionar Galeria
              </button>
            </div>
          </div>
        </div>
          : <div className="modalSelectHeader">
            {renderItemsTable()}
          </div>
        }

      </div>

      <div className="btnContainer tableButtons">
        <div className="attributeContainer">
          <button className="secondaryBtnBlue" onClick={() => handleClose()}>
            Cancelar
          </button>
        </div>
        <div className="attributeContainer">
          {isValid() ?
            <button className="mainBtn" onClick={() => nextStep()}>
              {step === 1 ? "Continuar" : "Adicionar"}
            </button> :
            <button className="mainBtn" disabled={true}>
              {step === 1 ? "Continuar" : "Adicionar"}
            </button>
          }
        </div>
      </div>
    </Modal>
  );
}