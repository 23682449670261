import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ToastError } from '../../../models/enum';

import GallerySection from '../../../components/frontend/gallerysection/GallerySection';
import HeadlightLayout from '../../../components/frontend/headlightlayout/HeadlightLayout';
import Loading from '../../../components/loading/Loading';
import SlotSection from '../../../components/frontend/slotsection/SlotSection';

import SlotPublicoSection from '../../../components/frontend/slotsection/SlotPublicoSection';

import MetaTags from '../../../components/metatags/MetaTags';
import { getHomepageNews } from '../../../api/news';

export default function Homepage({ school, slot, color1, color2 }) {
  const [isMobile, setIsMobile] = useState(false);
  const [schoolHeadlight, setSchoolHeadlight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastNews, setLastNews] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 || (window.innerWidth >= 900 && window.innerWidth <= 1023)) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let mainColor;
  let secondaryColor;

  if (color1 === undefined && color2 === undefined) {
    mainColor = school?.palette?.color.main;
    secondaryColor = school?.palette?.color.secondary;
  } else {
    mainColor = color1;
    secondaryColor = color2;
  }

  useEffect(() => {
    const headlight = school?.headlight;
    let slots;
    if (slot === undefined || slot === null) {
      slots = school?.slots;
    } else {
      slots = slot;
    }
    const sections = slots?.filter((s) => s.section).map((s) => s.section?.id);
    const gallery = slots?.filter((s) => s.gallery).map((s) => s.gallery?.id);

    getHomepageNews(school?.id, headlight?.id, sections.toString(), gallery.toString())
      .then((result) => {
        setSchoolHeadlight(result.headlight);
        setLastNews(result.last);
        setSections(result.sections);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
      });
  }, [school]);

  const getSlotContent = (slot, idx) => {
    let elem = <></>;
    const color = idx % 2 === 0 ? secondaryColor : mainColor;

    if (slot?.section) {
      const sectionName = slot.section.sname;
      const slotSection = sections.find((s) => s.section === sectionName);

      elem = (
        <SlotSection
          title={sectionName}
          journalName={school?.subdirectory}
          newsList={slotSection?.news}
          color={color}
        />
      );
    } else if (slot?.gallery) {
      elem = (
        <GallerySection
          newsItem={slot?.gallery}
          journalName={school?.subdirectory}
          color={mainColor}
        />
      );
    }

    return elem;
  };

  return (
    <div className="hpContainer">
      <MetaTags title={`${school?.journal} | Projeto TRUE`} />

      <div className="homepageHeader homepageWidth">
        {loading ? (
          <Loading />
        ) : (
          <HeadlightLayout school={school} headlight={schoolHeadlight} isMobile={isMobile} />
        )}
      </div>

      <SlotSection
        title="Últimas Notícias"
        journalName={school?.subdirectory}
        newsList={lastNews}
        color={mainColor}
      />

      {slot === undefined || slot === null
        ? school?.slots.map((s, idx) => <div key={s?._id}>{getSlotContent(s, idx)}</div>)
        : slot.map((sl, idx) => <div key={sl?._id}>{getSlotContent(sl, idx)}</div>)}
      {/* {school?.slots.map((s, idx) => <div key={s?._id}>{getSlotContent(s, idx)}</div>)} */}

      <SlotPublicoSection/>
    </div>
  );
}
