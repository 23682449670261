import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfigError from '../error/Error';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import SetupMenu from '../../../templates/setupmenu/SetupMenu';
import { ConfigActions } from '../../../redux/actions';
import {
  finalizeConfiguration,
  updateConfigurationById,
} from '../../../api/configuration';
import { ToastError } from '../../../models/enum';

import { ReactComponent as SlotsOne } from '../../../assets/img/homepage/slots-1.svg';
import { ReactComponent as SlotsTwo } from '../../../assets/img/homepage/slots-2.svg';
import { ReactComponent as SlotsThree } from '../../../assets/img/homepage/slots-3.svg';
import { ReactComponent as SlotsFour } from '../../../assets/img/homepage/slots-4.svg';

import PreViz from './PreViz';

import locData from '../../../assets/data/distritos-concelhos-freguesias-Portugal.json';

const SlotsOptions = [
  {
    img: SlotsOne,
    option: 1,
  },
  {
    img: SlotsTwo,
    option: 2,
  },
  {
    img: SlotsThree,
    option: 3,
  },
  {
    img: SlotsFour,
    option: 4,
  },
];

export default function Sections(props) {
  const [sectionTemplate, setSectionTemplate] = useState([]);
  const [loading, setLoading] = useState(false);

  const cid = props.match.params.cid;
  const config = useSelector((state) => state.config);
  const status = useSelector((state) => state.statusConfig);

  const [openViz, setOpenViz] = useState(false);
  const [sectionValue, setSectionValue] = useState([]);
  const handleOpen = (e) => {
    setOpenViz(true);
    setSectionValue(e.target.id);
  };

  const handleClose = () => setOpenViz(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setSectionTemplate(config.sectionTemplate);
  }, [config]);

  const previousStep = async () => {
    const step = 4;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('sectionTemplate', -1));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.headlightTemplate = -1;
    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const finalize = async () => {
    const cfg = { ...config };

    setLoading(true);
    dispatch(ConfigActions.cleanConfiguration());

    const district = locData.find((item) => item.code === cfg.school.district);
    const municipality = locData.find((item) => item.code === cfg.school.municipality);
    if (district) {
      cfg.school.district = district.name;
    }
    if (municipality) {
      cfg.school.municipality = municipality.name;
    }

    let res;

    try {
      res = await finalizeConfiguration(cid, sectionTemplate);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger" ,toastId: "general_error", autoClose: 2000 });
    }
    setLoading(false);

    if (!res.valid) {
      return toast(res.message, { className: "toastStyleDanger", toastId: "res_message", autoClose: 2000 });
    }

    history.push(`/configuration/${cid}/finish`);
  };

  const isValid = () => {
    return sectionTemplate > 0;
  };

  if (status.error) {
    return <ConfigError />;
  }

  return (
    <div className="setupPage">
      <MetaTags title="Template Secções &bull; Configuração | Projeto TRUE" />

      <SetupMenu step={5} />

      <div className={'setupBody' + (status.loading || loading ? ' isLoading' : '')}>
        {status.loading || loading ? (
          <div className="loadingContainer">
            <Loading />
          </div>
        ) : (
          <></>
        )}

        <div className="highlightContainer">
          <div>
            <p className="setupTitle">Template Secções</p>

            <p className="setupSubtitle">
              Selecione o layout para as secções do jornal escolar, clicando na imagem
              correspondente. Pode escolher outro layout mais tarde, se assim o pretender.
            </p>
          </div>
        </div>

        <Grid container spacing={4}>
          {SlotsOptions.map((H) => (
            <Grid key={H.option} item xs={6}>
              <div
                className={`headlightItem${
                  sectionTemplate === H.option ? ' selected' : ''
                }`}
              >
                <H.img onClick={() => setSectionTemplate(H.option)} />

                <div className="headlightBtnContainer">
                  <button
                    id={H.option}
                    className="secondaryBtn"
                    onClick={(e) => handleOpen(e)}
                  >
                    Pré-visualizar
                  </button>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>

        <div className="btnContainer">
          <div>
            <button className="secondaryBtnBlue" onClick={previousStep}>
              Voltar
            </button>
          </div>
          <div>
            {isValid() ? (
              <button className="mainBtn" onClick={finalize}>
                Finalizar
              </button>
            ) : (
              <button className="mainBtn" disabled={true}>
                Finalizar
              </button>
            )}
          </div>
        </div>
      </div>
      {openViz === true ? (
        <PreViz handleClose={handleClose} sectionValue={sectionValue} />
      ) : (
        <></>
      )}
    </div>
  );
}
