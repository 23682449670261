import React from 'react';

import { ReactComponent as TrueLogo } from '../../../assets/logo/logo-big.svg';
import backgroundImg from '../../../assets/img/illustrations/configurationBkg.svg';

import './unsupported.css';

export default function Unsupported() {
  return (
    <div className="fullPageContainer">
      <div className="splashContainer">
        <div className="splashBackground" style={{ backgroundImage: `url("${backgroundImg}")` }} />

        <div className="elementsWrapper">
          <TrueLogo className="trueLogo" />

          <div className="unsupportedContent">
            <span className="unsupportedTxt">
                            Resolução de ecrã não suportada
            </span>

            <span className="unsupportedDescription">
                            Pedimos desculpa, o Back-Office do projeto TRUE não é compatível com este dispositivo. <br className="divisor" />
                            Experimente aceder a esta página no seu computador.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}