import React from 'react';
import { useState } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { HexColorPicker } from 'react-colorful';

import OutlinedInput from '@mui/material/OutlinedInput';

import './ModalPersonalization.css';

export default function ModalPersonalization(props) {
  const [open, setOpen] = useState(true);

  const [colorMain, setColorMain] = useState(props.mainColor);

  const [colorSecondary, setColorSecondary] = useState(props.secondaryColor);

  const [selectSndColor, setSelectSndColor] = useState(false);

  const firstColor = () => setSelectSndColor(false);
  const secColor = () => setSelectSndColor(true);

  const handleChangeM = (e) => {
    const value = e.target.value;

    if (!value.match(/^#/)) {
      e.target.value = '#' + value;
    } 
    
    setColorMain(e.target.value);
  };

  const handleChangeS = (e) => {
    const value = e.target.value;
    if (!value.match(/^#/)) {
      e.target.value = '#' + value;
    }
    
    setColorSecondary(e.target.value);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 4,
  };

  const saveColorPallete = () => {
    props.setMainColor(colorMain.toUpperCase());
    props.setSecondaryColor(colorSecondary.toUpperCase());
    props.hdlCloseModal();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={props.hdlCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '*': {
            boxSizing: 'content-box !important',
          },
          '::after, ::before': {
            boxSizing: 'content-box !important',
          },
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" component={'span'} variant={'body2'}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Grid item xs={4.5} style={{ paddingLeft: '7%' }}>
                {selectSndColor === true ? (
                  <HexColorPicker color={colorSecondary} onChange={setColorSecondary} />
                ) : (
                  <HexColorPicker color={colorMain} onChange={setColorMain} />
                )}
              </Grid>
              <Grid item xs>
                <p className="paletteTitle1">Primária</p>
                <button
                  className={
                    selectSndColor === false
                      ? 'colorContainer1 colorContainerBorder'
                      : 'colorContainer1'
                  }
                  style={{
                    backgroundColor: colorMain,
                    marginBottom: '20px',
                    position: 'relative',
                    left: '20%',
                  }}
                  onClick={firstColor}
                ></button>

                <OutlinedInput
                  className="outlinedInputStyle"
                  onChange={(e) => handleChangeM(e)}
                  value={colorMain.toUpperCase()}
                  inputProps={{ maxLength: 7, style: { textAlign: 'center' } }}
                  disabled={selectSndColor === false ? false : true}
                ></OutlinedInput>
              </Grid>
              <Grid item xs>
                <p className="paletteTitle1">Secundária</p>
                <button
                  className={
                    selectSndColor === true
                      ? 'colorContainer1 colorContainerBorder'
                      : 'colorContainer1'
                  }
                  style={{
                    backgroundColor: colorSecondary,
                    marginBottom: '20px',
                    position: 'relative',
                    left: '20%',
                  }}
                  onClick={secColor}
                ></button>
                <OutlinedInput
                  className="outlinedInputStyle"
                  onChange={(e) => handleChangeS(e)}
                  value={colorSecondary.toUpperCase()}
                  inputProps={{ maxLength: 7, style: { textAlign: 'center' }  }}
                  disabled={selectSndColor === true ? false : true}
                ></OutlinedInput>
              </Grid>
            </Grid>
            <p className="textWarning">
              Para maior contraste aconselha-se que a cor primária seja a mais escura.
            </p>
          </Typography>

          <Typography id="modal-modal-description" component={'span'} variant={'body2'}>
            <div
              className="btnContainer"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className="attributeContainer1">
                <button className="secondaryBtnBlue" onClick={props.hdlCloseModal}>
                  Cancelar
                </button>
              </div>
              <div className="attributeContainer1">
                <button className="mainBtn" onClick={saveColorPallete} disabled={false}>
                  Guardar
                </button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
