import React from 'react';
import PreviewSlide from './PreviewSlide';
import { useSelector } from 'react-redux';
import DangerIcon from '../../../assets/img/icons/alertTriangle.svg';

function Preview(props) {
  const { fileWithMeta, handleRemove, updateCaption, cropFile } = props;
  const captionVerif = useSelector(
    (state) => state?.textEditorValidation.caption,
  );

  const finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );
  const removeUploaded = () => {
    handleRemove(fileWithMeta);
  };

  const editImageSrc = (url) => {
    cropFile(url, fileWithMeta);
  };

  const writeCaptionImg = (value) => {
    updateCaption(value, fileWithMeta);
  };

  return (
    <div>
      <PreviewSlide
        previewUrl={fileWithMeta?.meta.previewUrl}
        removeUploaded={removeUploaded}
        editImageSrc={editImageSrc}
        desc={fileWithMeta?.meta.caption}
        id={fileWithMeta?.meta.id}
        writeCaptionImg={writeCaptionImg}
      />

      {/* {!fileWithMeta?.meta.cropped && finalVerif === false && (
        <div className="errorVerifContainer">
          <img src={DangerIcon} className="errorVerifIcon" alt="danger-icon" />
          <p className="errorVerifMessage">Por favor, recorte a imagem.</p>
        </div>
      )} */}
    </div>
  );
}

export default Preview;
