import React from 'react';
import { ReactComponent as FooterLogo } from '../../assets/img/footer/footer-logo.svg';

import './MainFooter.css';

export default function MainFooter() {
  return (
    <div className="mainFooter">
      <FooterLogo />

      <div className="projectDescription">
        <div className="descriptionTitle">TRUE Project</div>

        <div className="descriptionSubtitle">
          Estudantes como criadores de jornais escolares online.
        </div>
      </div>

      <div className="footerBtn">
        <a href="#" className="secondaryBtnWhite" target="_blank" rel="noreferrer">
          TRUE Project
        </a>
      </div>
    </div>
  );
}
