import React from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import TextLabel from '../textinput/TextLabel';
import { apiInfo } from '../../../api/api';
import { ToastError } from '../../../models/enum';
import { ReactComponent as Upload } from '../../../assets/img/icons/upload.svg';
import './fileupload.css';

export default function FileUpload(props) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (files) => {
      const fileInput = files?.length > 0 ? files[0] : null;
      const isValid = fileInput?.type.startsWith('image/');
      if (files?.length === 0 || !isValid) {
        toast(ToastError.INVALID_SELECTION, { className: 'toastStyleDanger', toastId: "invalid_selection", autoClose: 2000 });
        return;
      }

      fileInput.preview = URL.createObjectURL(fileInput);

      let result = {
        id: '',
        file: fileInput,
        url: '',
      };

      if (props.id) {
        result.id = props.id;
      }

      if (props.url) {
        result.url = props.url;
      }

      props.onChange(result);
    },
  });

  let thumbUrl = '';

  if (props.file) {
    thumbUrl = props.file.preview;
  } else if (props.url) {
    thumbUrl = apiInfo.base + props.url;
  }

  const helperText = (
    <>
      O tamanho da imagem não pode exceder 2MB.
      <br />
      Por favor, escolha uma imagem em formato horizontal.
      <br />
      {props.firstConfig ? 'Pode alterar este logótipo posteriormente.' : <></>}
    </>
  );

  return (
    <section>
      <TextLabel label="Upload do logótipo" helperText={helperText}>
        <div {...getRootProps({ className: 'fileSquareContainer' })}>
          <div className="fileDropzone">
            <input {...getInputProps()} />

            {thumbUrl ? (
              <div className="thumbContainer">
                <img src={thumbUrl} alt="Preview" />
              </div>
            ) : (
              <div className="uploadDefault">
                <div className="uploadIconContainer">
                  <Upload />
                </div>
                <p className="uploadDescription">
                  Arraste para aqui a imagem ou{' '}
                  <span className="txtHighlight">localize no teu computador</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </TextLabel>
    </section>
  );
}
