import React, { useState } from 'react';
import { toast } from 'react-toastify';

import ModalSectionForm from './ModalSectionForm';
import { updateSection } from '../../api/sections';
import { ToastError, ToastSuccess } from '../../models/enum';

export default function ModalEditSection(props) {
  const [sectionName, setSectionName] = useState(props.section?.sname);
  const [loading, setLoading] = useState(false);

  const handleEditSection = async () => {
    if (!sectionName) {
      toast(ToastError.INVALID_SECTION_NAME, { className: 'toastStyleDanger', toastId: 'invalid_section_name', autoClose: 2000 });
      return;
    }

    const sections = props.sections.map(s => s.sname);
    if (sections.includes(sectionName)) {
      toast(ToastError.SECTION_NAME_EXISTS, { className: 'toastStyleDanger', toastId: 'section_name_exists', autoClose: 2000 });
      return;
    }

    setLoading(true);

    let res;
    const data = { sname: sectionName };
    try {
      res = await updateSection(
        props.section?.id,
        data
      );
    } catch (err) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: 'general_error', autoClose: 2000 });
    }

    const updatedSection = res;

    toast(ToastSuccess.UPDATED_SECTION, { className: 'toastStyleSuccess', toastId: 'updated_section', autoClose: 2000 });
    
    setLoading(false);
    props.editSection(updatedSection.id, updatedSection.sname);
  }

  return (
    <ModalSectionForm
      sectionName={sectionName}
      setSectionName={(value) => setSectionName(value)}
      loading={loading}
      openModal={props.openModal}
      modalTitle="Editar Secção"
      handleClose={props.handleClose}
      handleSubmit={handleEditSection}
      submitBtnName="Guardar"
    />
  );
}