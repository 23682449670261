import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getSchoolById = (schoolId) => {
  return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`);
};

export const getSchoolByUrl = (url) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.SCHOOLS}?subdirectory=${url}`,
  );
};

export const updateSchoolSections = (schoolId, sections) => {
  const data = {
    sections: sections,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`,
    data,
    true,
  );
};

export const updateSchoolSectionsTemplate = (schoolId, sectionTemplate) => {
  const data = {
    sectionTemplate: sectionTemplate,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`,
    data,
    true,
  );
};

export const updateSchoolSlots = (schoolId, slots) => {
  const data = {
    slots: slots,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`,
    data,
    true,
  );
};

export const updateJournalSettings = (
  schoolId,
  journalName,
  journalLogo,
  logoState,
  journalNameState,
  schoolNameState,
) => {
  const data = {
    journal: journalName,
    logo: journalLogo,
    journalIdentity: {
      journalName: journalNameState,
      logo: logoState,
      schoolName: schoolNameState,
    },
  };
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`,
    data,
    true,
  );
};

export const updateSchoolPalette = (
  schoolId,
  key,
  mainColor,
  secondaryColor,
  customMainColor,
  customSecondaryColor,
) => {
  const palette = {
    key: key,
    color: {
      main: mainColor,
      secondary: secondaryColor,
    },
    custom: {
      main: customMainColor,
      secondary: customSecondaryColor,
    },
  };

  const data = {
    palette: palette,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.SCHOOLS}/${schoolId}`,
    data,
    true,
  );
};
