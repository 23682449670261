import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Dialog, Grid } from '@mui/material';

import TableLoading from '../../components/tableloading/TableLoading';
import { updateSchoolSections } from '../../api/schools';
import { ToastError, ToastSuccess } from '../../models/enum';

import ArrowDown from '../../assets/img/icons/arrowDown.svg';
import ArrowUp from '../../assets/img/icons/arrowUp.svg';
import { SchoolActions } from '../../redux/actions';

export default function ModalSortSections(props) {
  const dispatch = useDispatch();
  const school = useSelector((state) => state.school);
  const [selectedSections, setSelectedSections] = useState(props.sections);
  const [loading, setLoading] = useState(false);

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: 800,
    p: 4
  };

  // Returns array with numbers corresponding to each section's position
  const getSectionsRows = (noRows) => {
    let SectionsRows = [];
    if (noRows && noRows > -1) { SectionsRows = Array.from({ length: noRows }, (_, i) => i + 1); }

    return SectionsRows;
  }

  // Increase section item position
  const increasePosition = (position) => {
    // Swap the elements at index1 and index2
    let index1 = position;
    let index2 = position + 1;

    if (selectedSections[position] && index2 <= 4) {
      setSelectedSections((prevState) => {
        const sections = [...prevState];
        [sections[index1], sections[index2]] = [
          sections[index2],
          sections[index1],
        ];

        return sections;
      });
    }
  };

  // Decrease slot item position
  const decreasePosition = (position) => {
    // Swap the elements at index1 and index2
    let index1 = position;
    let index2 = position - 1;

    if (selectedSections[position] && index2 >= 0) {
      setSelectedSections((prevState) => {
        const sections = [...prevState];
        [sections[index1], sections[index2]] = [
          sections[index2],
          sections[index1],
        ];

        return sections;
      });
    }
  };

  const saveSections = async () => {
    setLoading(true);

    try {
      await updateSchoolSections(school.id, selectedSections);
    } catch (err) {
      setLoading(false);
      toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: 'general_error', autoClose: 2000 });
      return;
    }

    let newSchool = { ...school };
    newSchool = { ...newSchool, sections: selectedSections };
    dispatch(SchoolActions.updateSchool(newSchool));

    toast(ToastSuccess.UPDATED_SECTIONS, { className: 'toastStyleSuccess', toastId: 'updated_sections', autoClose: 2000 });
    setLoading(false);
    props.handleClose();
  };

  // Number of rows corresponds with sections array length
  const noRows = selectedSections.length;
  const SectionsRows = getSectionsRows(noRows);
  const loadingClass = loading ? "sectionsRowsContainer pageLoading" : "sectionsRowsContainer";

  return (
    <Dialog
      open={props.openModal}
      onClose={props.handleClose}
      maxWidth="lg"
    >
      <Box sx={boxStyle}>
        <div className="modalSectionsWrapper">
          <p className="pageHighlight">Ordernar Secções</p>
          <p className="highlightDescription">Escolha a ordem das secções na barra de navegação</p>
        </div>

        <div className={loadingClass}>
          {SectionsRows.length === 0 ? <Grid item xs={12} className="highlightDescription noResults">
            Não existem secções ativas.
          </Grid> : <></>}

          {SectionsRows.map((value) =>
            <div key={value} className="rowWrapper">
              <div className="blueSquare">
                <p className="squareNumber">
                  {value}
                </p>
              </div>

              <div className="greyRectangle sections noPointer">
                <p className="selectedItemTitle sections">
                  {selectedSections[value - 1]?.sname}
                </p>
              </div>

              <div className="arrowWrapper">
                {value < noRows ?
                  <img
                    src={ArrowDown}
                    alt="Seta para baixo"
                    role="button"
                    tabIndex="0"
                    onClick={() => increasePosition(value - 1)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        increasePosition(value - 1)
                      }
                    }} /> : <></>}
                {value > 1 ?
                  <img
                    className={value === noRows ? "marginArrow" : ""}
                    src={ArrowUp}
                    alt="Seta para cima"
                    role="button"
                    tabIndex="0"
                    onClick={() => decreasePosition(value - 1)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        decreasePosition(value - 1)
                      }
                    }} /> : <></>}
              </div>
            </div>
          )}

          {loading ? <TableLoading /> : <div></div>}
        </div>

        <div className="centeredButtons">
          <div className="attributeContainer1">
            <button className="secondaryBtnBlue" onClick={props.handleClose}>
              Cancelar
            </button>
          </div>
          <div className="attributeContainer1">
            <button className="mainBtn" onClick={() => saveSections()}>
              Guardar
            </button>
          </div>
        </div>
      </Box>
    </Dialog>
  );
}
