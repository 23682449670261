function HelpBtn() {
  return (
    <div>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1352_8936)">
          <rect
            x="8"
            y="2"
            width="36"
            height="36"
            rx="18"
            fill="white"
            shapeRendering="crispEdges"
          />
          <path
            d="M42.75 20C42.75 29.2508 35.2508 36.75 26 36.75C16.7492 36.75 9.25 29.2508 9.25 20C9.25 10.7492 16.7492 3.25 26 3.25C35.2508 3.25 42.75 10.7492 42.75 20Z"
            stroke="#1F8598"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.7622 14.6C21.1854 13.397 22.0207 12.3826 23.1201 11.7364C24.2196 11.0903 25.5122 10.8541 26.7691 11.0697C28.026 11.2853 29.1661 11.9387 29.9873 12.9144C30.8086 13.89 31.2581 15.1247 31.2562 16.4C31.2562 20 25.8562 21.8 25.8562 21.8"
            stroke="#1F8598"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26 29H26.0175"
            stroke="#1F8598"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1352_8936"
            x="0"
            y="0"
            width="52"
            height="52"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0677596 0 0 0 0 0.29071 0 0 0 0 0.33224 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1352_8936"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1352_8936"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default HelpBtn;
