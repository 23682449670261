import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';

import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Loading from '../../components/loading/Loading';
import MetaTags from '../../components/metatags/MetaTags';
import SideMenuReset from '../../templates/sidemenu/SideMenuReset';
import TextInput from '../../components/dashboard/textinput/TextInput';
import UserData from './UserData';
import { confirmAccount, getUserByToken } from '../../api/users';
import { ToastError } from '../../models/enum';

import locData from '../../assets/data/distritos-concelhos-freguesias-Portugal.json';

function ConfirmData(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const { id } = props.match.params;

    let data;
    try {
      data = await getUserByToken(id);
    } catch (err) {
      console.error(err);
      setError(true);
      setLoading(false);
      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000
      });
      return;
    }

    const district = locData.find((item) => item.code === parseInt(data.school.district));
    const municipality = locData.find(
      (item) => item.code === parseInt(data.school.municipality),
    );

    setUser({
      schoolName: data.school?.name,
      journalName: data.school?.journal,
      subdirectory: data.school?.subdirectory,
      schoolMunicipality: municipality?.name,
      schoolDistrict: district?.name,
      firstName: data.name,
      lastName: data.surname,
      userEmail: data.email,
      userType: data.role?.name,
    });
    setLoading(false);
  };

  const changeHandler = (e) => {
    setUser((user) => {
      return {
        ...user,
        [e.target.name]: e.target.name === 'userEmail' 
          ? e.target.value.trim() 
          : e.target.value
      };
    });
  };

  const confirm = async (e) => {
    e.preventDefault();
    const { id } = props.match.params;
    const { firstName, lastName, userEmail, userPassword, confirmPassword } = user;

    if (userPassword !== confirmPassword) {
      toast(ToastError.PASSWORD_NO_MATCH, {
        className: 'toastStyleDanger',
        toastId: "password_no_match",
        autoClose: 2000
      });
      return;
    }

    setLoading(true);

    try {
      await confirmAccount(id, firstName, lastName, userEmail, userPassword);
    } catch (err) {
      setLoading(false);

      toast(err.message ? err.message : ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000
      });

      return;
    }

    setLoading(false);
    history.push('/');
  };

  return (
    <div className="backgroundPage">
      <MetaTags title="Confirmar Dados &bull; Definições | Projeto TRUE" />

      <SideMenuReset pageTitle="Confirmar Dados" />

      <main className="dashboardBody">
        <DashHeader title="Definições" subtitle="Confirmar Dados" hideSignOut={true} />

        {error ? (
          <div className="errorWrapper">
            <div className="errorTitle">Oops!</div>
            <div className="errorDescription">
              Um erro ocorreu, por favor tente mais tarde.
            </div>

            <NavLink className="mainBtn errorBtn" to="/dashboard">
              Aceder Back-Office
            </NavLink>
          </div>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <div className="bodyContainer profileContainer">
                <div className="pageSectionContainer head">
                  <p className="pageHighlight">Dados Pessoais</p>
                </div>

                <Grid className="myProfileContainer" container>
                  <UserData
                    schoolName={user?.schoolName}
                    journalName={user?.journalName}
                    schoolMunicipality={user?.schoolMunicipality}
                    schoolDistrict={user?.schoolDistrict}
                    subdirectory={user?.subdirectory}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    userEmail={user?.userEmail}
                    userType={user?.userType}
                    changeHandler={changeHandler}
                  />

                  <Grid className="profileInputContainer leftSide" item xs={6} lg={5}>
                    <TextInput
                      className="custom-user-input"
                      type="password"
                      label="Palavra-passe"
                      name="userPassword"
                      placeholder="Insira a sua palavra-passe"
                      value={user?.userPassword}
                      setInputValue={changeHandler}
                    />
                  </Grid>

                  <Grid className="profileInputContainer" item xs={6} lg={5}>
                    <TextInput
                      className="custom-user-input"
                      type="password"
                      label="Confirmar Palavra-passe"
                      name="confirmPassword"
                      placeholder="Confirme palavra-passe"
                      value={user?.confirmPassword}
                      setInputValue={changeHandler}
                    />
                  </Grid>

                  <Grid className="profileInputContainer" item xs={12} lg={10}>
                    <div className="attributesWrapperProfile">
                      <button className="mainBtn" onClick={confirm}>
                        Confirmar
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}

export default ConfirmData;
