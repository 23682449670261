import React from 'react'

import InputDefault from './Input'
import SubmitButtonDefault from './SubmitButton'
import {
  formatBytes,
  formatDuration,
  accepts,
  resolveValue,
  mergeStyles,
  defaultClassNames,
  getFilesFromEvent
} from './utils';

class Dropzone extends React.Component {
  // Change: remove this.files from constructor...
  // ...files array is provided using props
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      dragged: [],
    }
    this.mounted = true;
    this.dropzone = React.createRef()
  }

  componentDidMount() {
    if (this.props.initialFiles) this.handleFiles(this.props.initialFiles)
  }

  componentDidUpdate(prevProps) {
    const { initialFiles } = this.props
    if (prevProps.initialFiles !== initialFiles && initialFiles) { 
      this.props.setFiles([]);
      this.handleFiles(initialFiles);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  // Change: use getFilesFromEvent function to deal with drag&drop events
  handleDragEnter = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const dragged = (await getFilesFromEvent(e))
    this.setState({ active: true, dragged })
  }

  // Change: use getFilesFromEvent function to deal with drag&drop events
  handleDragOver = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    clearTimeout(this.dragTimeoutId)
    const dragged = await getFilesFromEvent(e)
    this.setState({ active: true, dragged })
  }

  handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // prevents repeated toggling of `active` state when file is dragged over children of uploader
    // see: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    this.dragTimeoutId = window.setTimeout(() => this.setState({ active: false, dragged: [] }), 150)
  }

  // Change: use getFilesFromEvent function to deal with drag&drop events
  handleDrop = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ active: false, dragged: [] })
    const files = (await getFilesFromEvent(e))
    this.handleFiles(files)
  }

  handleDropDisabled = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ active: false, dragged: [] })
  }

  // Change: this no longer applies:
  // "Returning a meta object from this callback lets you merge new values into the file's meta."
  handleChangeStatus = (fileWithMeta, customStatus) => {
    if (!this.props.onChangeStatus) return
    const status = customStatus || fileWithMeta.meta.status;
    this.props.onChangeStatus(fileWithMeta, status, this.props.files)
  }

  handleSubmit = (files) => {
    if (this.props.onSubmit) this.props.onSubmit(files, [...this.props.files])
  }

  // Change: handleFiles supports arrays with the following structure:
  // { file: File, extra: { caption, cropped }}
  handleFiles = (files) => {
    files.forEach((f, i) => this.handleFile(f.file, `${new Date().getTime()}-${i}`, f.extra))
    const { current } = this.dropzone
    if (current) setTimeout(() => current.scroll({ top: current.scrollHeight, behavior: 'smooth' }), 150)
  }

  // Change: remove support for automatic uploads
  // Change: remove support for cancel, restart, remove functions
  // Change: remove support for xhr
  handleFile = async (file, id, extra) => {
    const { name, size, type, lastModified } = file
    const { minSizeBytes, maxSizeBytes, maxFiles, accept } = this.props

    const uploadedDate = new Date().toISOString()
    const lastModifiedDate = lastModified && new Date(lastModified).toISOString()
    let fileWithMeta = {
      file,
      meta: { name, size, type, lastModifiedDate, uploadedDate, percent: 0, id },
    }

    if (extra) {
      fileWithMeta.meta.caption = extra.caption;
      fileWithMeta.meta.cropped = extra.cropped;
    }

    // firefox versions prior to 53 return a bogus mime type for file drag events,
    // so files with that mime type are always accepted
    if (file.type !== 'application/x-moz-file' && !accepts(file, accept)) {
      fileWithMeta.meta.status = 'rejected_file_type'
      this.handleChangeStatus(fileWithMeta)
      return
    }
    if (this.props.files.length >= maxFiles) {
      fileWithMeta.meta.status = 'rejected_max_files'
      this.handleChangeStatus(fileWithMeta)
      return
    }

    fileWithMeta.meta.status = 'preparing'

    if (size < minSizeBytes || size > maxSizeBytes) {
      fileWithMeta.meta.status = 'error_file_size'

      const filesArray = [...this.props.files, fileWithMeta]
      if (this.mounted) {
        this.props.setFiles(filesArray)
        this.handleChangeStatus(fileWithMeta)
      }
    } else {
        fileWithMeta = await this.generatePreview(fileWithMeta)
        fileWithMeta.meta.status = 'ready'

        if (this.mounted) {
          const filesArray = [...this.props.files, fileWithMeta]
          this.props.setFiles(filesArray)
          this.handleChangeStatus(fileWithMeta)
        }
    }

  }

  generatePreview = async (fileWithMeta) => {
    const {
      meta: { type },
      file,
    } = fileWithMeta
    const isImage = type.startsWith('image/')
    const isAudio = type.startsWith('audio/')
    const isVideo = type.startsWith('video/')
    if (!isImage && !isAudio && !isVideo) return

    const objectUrl = URL.createObjectURL(file)

    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise(resolve => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve
          else fileObj.onloadedmetadata = resolve
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000)
        }),
      ])
    }

    try {
      if (isImage) {
        const img = new Image()
        img.src = objectUrl
        fileWithMeta.meta.previewUrl = objectUrl
        await fileCallbackToPromise(img)
        fileWithMeta.meta.width = img.width
        fileWithMeta.meta.height = img.height
      }

      if (isAudio) {
        const audio = new Audio()
        audio.src = objectUrl
        await fileCallbackToPromise(audio)
        fileWithMeta.meta.duration = audio.duration
      }

      if (isVideo) {
        const video = document.createElement('video')
        video.src = objectUrl
        await fileCallbackToPromise(video)
        fileWithMeta.meta.duration = video.duration
        fileWithMeta.meta.videoWidth = video.videoWidth
        fileWithMeta.meta.videoHeight = video.videoHeight
      }
      if (!isImage) URL.revokeObjectURL(objectUrl)
    } catch (e) {
      URL.revokeObjectURL(objectUrl)
    }

    // Change: return fileWithMeta
    return fileWithMeta;
  }

  render() {
    // remove support for autoUpload, getUploadParams, canCancel, canRemove, canRestart
    const {
      accept,
      multiple,
      maxFiles,
      minSizeBytes,
      maxSizeBytes,
      onSubmit,
      disabled,
      inputContent,
      inputWithFilesContent,
      submitButtonDisabled,
      submitButtonContent,
      classNames,
      styles,
      addClassNames,
      InputComponent,
      PreviewComponent,
      SubmitButtonComponent,
      LayoutComponent,
    } = this.props

    const { active, dragged } = this.state

    const reject = dragged.some(file => file.type !== 'application/x-moz-file' && !accepts(file, accept))
    const extra = { active, reject, dragged, accept, multiple, minSizeBytes, maxSizeBytes, maxFiles }
    const files = [...this.props.files]
    const dropzoneDisabled = resolveValue(disabled, files, extra)

    const {
      classNames: {
        dropzone: dropzoneClassName,
        dropzoneActive: dropzoneActiveClassName,
        dropzoneReject: dropzoneRejectClassName,
        dropzoneDisabled: dropzoneDisabledClassName,
        input: inputClassName,
        inputLabel: inputLabelClassName,
        inputLabelWithFiles: inputLabelWithFilesClassName,
        preview: previewClassName,
        previewImage: previewImageClassName,
        submitButtonContainer: submitButtonContainerClassName,
        submitButton: submitButtonClassName,
      },
      styles: {
        dropzone: dropzoneStyle,
        dropzoneActive: dropzoneActiveStyle,
        dropzoneReject: dropzoneRejectStyle,
        dropzoneDisabled: dropzoneDisabledStyle,
        input: inputStyle,
        inputLabel: inputLabelStyle,
        inputLabelWithFiles: inputLabelWithFilesStyle,
        preview: previewStyle,
        previewImage: previewImageStyle,
        submitButtonContainer: submitButtonContainerStyle,
        submitButton: submitButtonStyle,
      },
    } = mergeStyles(classNames, styles, addClassNames, files, extra)

    const Input = InputComponent || InputDefault
    const Preview = PreviewComponent
    const SubmitButton = SubmitButtonComponent || SubmitButtonDefault
    const Layout = LayoutComponent

    let previews = null
    if (PreviewComponent !== null) {
      previews = files.map(f => {
        return (
          //@ts-ignore
          <Preview
            className={previewClassName}
            imageClassName={previewImageClassName}
            style={previewStyle}
            imageStyle={previewImageStyle}
            key={f.meta.id}
            fileWithMeta={f}
            meta={{ ...f.meta }}
            files={files}
            extra={extra}
          />
        )
      })
    }

    const input =
      InputComponent !== null ? (
        //@ts-ignore
        <Input
          className={inputClassName}
          labelClassName={inputLabelClassName}
          labelWithFilesClassName={inputLabelWithFilesClassName}
          style={inputStyle}
          labelStyle={inputLabelStyle}
          labelWithFilesStyle={inputLabelWithFilesStyle}
          accept={accept}
          multiple={multiple}
          disabled={dropzoneDisabled}
          content={resolveValue(inputContent, files, extra)}
          withFilesContent={resolveValue(inputWithFilesContent, files, extra)}
          onFiles={this.handleFiles} // see: https://stackoverflow.com/questions/39484895
          files={files}
          extra={extra}
        />
      ) : null

    const submitButton =
      onSubmit && SubmitButtonComponent !== null ? (
        //@ts-ignore
        <SubmitButton
          className={submitButtonContainerClassName}
          buttonClassName={submitButtonClassName}
          style={submitButtonContainerStyle}
          buttonStyle={submitButtonStyle}
          disabled={resolveValue(submitButtonDisabled, files, extra)}
          content={resolveValue(submitButtonContent, files, extra)}
          onSubmit={this.handleSubmit}
          files={files}
          extra={extra}
        />
      ) : null

    let className = dropzoneClassName
    let style = dropzoneStyle

    if (dropzoneDisabled) {
      className = `${className} ${dropzoneDisabledClassName}`
      style = { ...(style || {}), ...(dropzoneDisabledStyle || {}) }
    } else if (reject) {
      className = `${className} ${dropzoneRejectClassName}`
      style = { ...(style || {}), ...(dropzoneRejectStyle || {}) }
    } else if (active) {
      className = `${className} ${dropzoneActiveClassName}`
      style = { ...(style || {}), ...(dropzoneActiveStyle || {}) }
    }

    return (
      //@ts-ignore
      <Layout
        input={input}
        previews={previews}
        submitButton={submitButton}
        dropzoneProps={{
          ref: this.dropzone,
          className,
          style: style,
          onDragEnter: this.handleDragEnter,
          onDragOver: this.handleDragOver,
          onDragLeave: this.handleDragLeave,
          onDrop: dropzoneDisabled ? this.handleDropDisabled : this.handleDrop,
        }}
        files={files}
        extra={
          {
            ...extra,
            onFiles: this.handleFiles
          }
        }
      />
    )
  }
}

export default Dropzone
export {
  InputDefault as Input,
  SubmitButtonDefault as SubmitButton,
  formatBytes,
  formatDuration,
  accepts,
  defaultClassNames
}