import React from 'react';

import TrueLogo from '../../assets/logo/logo.png';
import SettingsIcon from '../../assets/img/sideMenuIcons/settings.svg';
import './SideMenu.css';

export default function SideMenuReset(props) {

  return (
    <div className="sideMenu" >
      <div className="sideMenuWrapepr">
        <div className="logoContainer">
          <img src={TrueLogo} alt="Logótipo" height="80px" />
        </div>

        <div className="linksContainer">
          <div className="menuOption active">
            <div className="linkWrapper menuTitle">
              <div className="linkIcon">
                <img src={SettingsIcon} alt="Definições" />
              </div>
              <span className="menuTxt">
                Definições
              </span>
            </div>
          </div>

          <div className="subMenuOption subMenuTxt active" to="/dashboard/profile">
            <span className="subMenuTitle">
              {props.pageTitle}
            </span>
          </div>
        </div>
      </div>
    </div >
  );
}