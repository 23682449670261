import React from 'react';
import { Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';

import InfoIcon from '../../assets/img/icons/info.svg';
import TrueCheckbox from '../../components/dashboard/checkbox/Checkbox';

export default function EditorialVisibility({ visibility, setVisibility }) {
  return (
    <Row>
      <Form.Group
        as={Col}
        className="col-12 primary input-bottom-margin"
        controlId="formInputImages"
      >
        <Form.Label className="font-title label-bottom-margin">
            Visibilidade da ficha técnica
        </Form.Label>

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip">
                Podes mostrar ou esconder a página "Sobre Nós" na barra de navegação do jornal
            </Tooltip>
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
        </OverlayTrigger>
      </Form.Group>

      <Form.Group
        as={Col}
        className="col-12 primary input-bottom-margin"
        controlId="formInputImages"
      >
        <TrueCheckbox
          checked={visibility}
          onChange={(e) => setVisibility(e.target.checked)}
          name="Mostrar/esconder ficha técnica"
          sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
        />
      </Form.Group>

    </Row>
  );
}
