import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AboutUs from '../../pages/frontend/aboutus/AboutUs';
import Footer from '../frontendfooter/Footer';
import Loading from '../../components/loading/Loading';
import Header from '../frontendheader/Header';
import Homepage from '../../pages/frontend/homepage/Homepage';
// import SecondaryHeader from '../frontendheader/SecondaryHeader';
import SectionPage from '../../pages/frontend/sectionpage/SectionPage';
import ArticlePage from '../../pages/frontend/articlepage/ArticlePage';
import PageNotFound from '../../pages/frontend/pagenotfound/PageNotFound';
import { getSchoolByUrl } from '../../api/schools';
// import { Colors, ToastError } from '../../models/enum';
import { ToastError } from '../../models/enum';

import './FrontendLayout.css';

const DESKTOP_PAGE_OFFSET = 400;
const MOBILE_PAGE_OFFSET = 325;

export default function FrontendLayout() {
  const [height, setHeight] = useState(DESKTOP_PAGE_OFFSET);
  const [headerHeight, setHeaderHeight] = useState(MOBILE_PAGE_OFFSET);
  const [pageOffset, setPageOffset] = useState(DESKTOP_PAGE_OFFSET);
  const [headerCollapsed, setHeaderCollapsed] = useState(false);
  const [sections, setSections] = useState([]);
  const [school, setSchool] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    handleResize();
    window.addEventListener('scroll', scrollFunction);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
     window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!school) {
      return;
    }
    setSections(school.sections);
  }, [school]);

  useEffect(() => {
    const { pathname } = location;
    const schoolSubdirectory = pathname?.split('/')[1];

    getSchoolInfo(schoolSubdirectory);
  }, [location]);

  const getSchoolInfo = async (schoolSubdirectory) => {
    try {
      const res = await getSchoolByUrl(schoolSubdirectory);
      setSchool(res.length > 0 ? res[0] : null);
      setLoading(false);
    } catch (err) {
      toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
      setLoading(false);
      return;
    }
  };

  const scrollFunction = () => {
    if (document.body.scrollTop > 200 || document.documentElement?.scrollTop > 200) {
      const offset = window.pageYOffset + ref.current?.getBoundingClientRect().top;
      setPageOffset(offset > DESKTOP_PAGE_OFFSET ? offset : DESKTOP_PAGE_OFFSET);
      setHeaderCollapsed(true);
    } else {
      setHeaderCollapsed(false);
    }
  };

  const getMobileOffset = () => {
    if (headerHeight) {
      if (headerHeight < 150) {
        return 275;
      } else return MOBILE_PAGE_OFFSET;
    } else return MOBILE_PAGE_OFFSET;
  }

  const getDesktopOffset = () => {
    if (height) {
      const offset = height * 0.6;
      return -offset;
    } else return -100;
  };

  if (loading) {
    return <Loading />;
  }

  if (!loading && !school) {
    return (
      <div className="frontendLayout">
        {/* <SecondaryHeader mainColor={Colors[1].main} /> */}
        <PageNotFound link={`/dashboard`} linkTxt="Aceder Back-Office" />

        <Footer />
      </div>
    );
  }

  if (school.blockJournal === true) {
    return (
      <div className="frontendLayout">
        <PageNotFound />
        <Footer />
      </div>
    );
  }

  return (
    <div className="frontendLayout">
      <Header
        school={school}
        sections={sections}
        headerCollapsed={headerCollapsed}
        height={height}
        setHeight={setHeight}
        setHeaderHeight={setHeaderHeight}
        isMobile={isMobile}
        loading={loading}
      />

      <main
        ref={ref}
        className="frontendBody"
        style={{
          marginTop: headerCollapsed ? pageOffset : (isMobile === true ? getMobileOffset() : getDesktopOffset()),
          zIndex: headerCollapsed ? 0 : isMobile === true ? 0 : 10,
        }}
      >
        <Switch>
          <Route exact path="/:journalName">
            <Homepage school={school} />
          </Route>

          <Route exact path="/:journalName/sobre-nos">
            <AboutUs school={school} />
          </Route>

          <Route exact path="/:journalName/news/:articleId">
            <ArticlePage school={school} />
          </Route>

          <Route exact path="/:journalName/:sectionId">
            <SectionPage school={school} sections={sections} />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
}
