import qs from 'qs';
import { ITEMS_PER_PAGE, USER_STATUS } from './globals';
import { isValidElement } from 'react';
import { Parser } from 'html-to-react';

import { NewsFilters, Roles } from '../models/enum';

import green from '../assets/img/state/green.svg';
import red from '../assets/img/state/red.svg';
import yellow from '../assets/img/state/yellow.svg';

export const ONE_MB = 1024*1024;

export const checkIfObject = (objVar) => {
  if (
    typeof objVar === 'object' &&
    !Array.isArray(objVar) &&
    objVar !== null
  ) return true
  else return false;
}

export const compareArrayProps = (firstArray, secondArray) => {
  const sameLength = firstArray.length === secondArray.length;
  if (!sameLength) return false;

  let sameProps = true;
  for (let i = 0; i < firstArray.length; i++) {
    const propsOne = firstArray[i]?.props;
    const propsTwo = secondArray[i]?.props;

    const firstProps = checkIfObject(propsOne) ? JSON.stringify(propsOne) : propsOne;
    const secondProps = checkIfObject(propsTwo) ? JSON.stringify(secondArray[i].props) : propsTwo;

    if (firstProps !== secondProps) {
      sameProps = false;
      break;
    }
  }

  return sameProps;
}

export const getNewCheckedValues = (checkedValue, checkedValues) => {
  const newValues = checkedValues?.includes(checkedValue)
    ? checkedValues?.filter((value) => value !== checkedValue)
    : [...(checkedValues ?? []), checkedValue];

  return newValues;
};

export const getTitle = (title) => {
  const htmlToReactParser = new Parser();
  const newsTitle = `<div>${title ? title : ''}</div>`;
  const newsTitleHtml = htmlToReactParser.parse(newsTitle);
  return isValidElement(newsTitleHtml) ? newsTitleHtml : title;
};

export const extractContent = (s) => {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const getCurrentData = (data, page, itemsPage) => {
  const itemsPerPage = itemsPage ? itemsPage : ITEMS_PER_PAGE;
  const begin = (page - 1) * itemsPerPage;
  const end = begin + itemsPerPage;
  return data.slice(begin, end);
}

export const convertStringToUrl = (str) => {
  if (!str) { return ''; }
  return str
    .toLowerCase()
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w-]+/g, '');
}

export const getStatusInfo = (confirmed, blocked) => {
  let color = green;
  let statusName = USER_STATUS.in_validation;

  if (blocked) {
    color = red;
    statusName = USER_STATUS.inactive;
  } else if (confirmed) {
    color = green;
    statusName = USER_STATUS.active;
  } else {
    color = yellow;
    statusName = USER_STATUS.in_validation;
  }

  return {
    color: color,
    status: statusName
  };
}

export const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find(k => obj[k] === value);
}

export const deleteElementsFromArray = (arr, elemsToDelete) => {
  const filteredArr = arr.filter(n => !elemsToDelete.find(sN => sN?.id === n.id));
  return filteredArr;
}

export const hex2rgb = (hex) => {
  const validHEXInput = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!validHEXInput) { return false; }
  const output = {
    r: parseInt(validHEXInput[1], 16),
    g: parseInt(validHEXInput[2], 16),
    b: parseInt(validHEXInput[3], 16),
  };
  return output;
}

export const getFrontendNewsParams = (page, itemsPerPage) => {
  const start = (page - 1) * itemsPerPage;
  const limit = itemsPerPage;
  
  const res = { start, limit };
  return res;
};

export const getNewsParams = (page, school, user, searchQuery, filterValues, sections) => {
  const isAuthor = user?.role?.name === Roles.Author;

  const filter = getNewsFilterParams(school, user, filterValues, sections);
  const sort = isAuthor ? '_sort=updatedAt:DESC' : '_sort=updatedAt:DESC,publishDate:DESC';
  const search = searchQuery ? `_q=${searchQuery}` : '';
  const start = (page - 1) * ITEMS_PER_PAGE;
  const limit = ITEMS_PER_PAGE;

  const res = { filter, sort, search, start, limit };
  return res;
}

export const getNewsFilterParams = (school, user, filterValues, sections) => {
  const isAuthor = user?.role?.name === Roles.Author;

  // AND filter: filter ALL news by school id
  let andFilters = [{ school: school.id }];

  // If user is an author, filter by user id as well
  if (isAuthor) {
    andFilters.push({
      user: user.id
    });
  }

  if (filterValues) {
    const stateValues = filterValues[NewsFilters.STATE];
    const sectionValues = filterValues[NewsFilters.SECTIONS];
    const dateValues = filterValues[NewsFilters.DATE];

    if (stateValues?.length > 0) {
      andFilters.push({
        status_in: stateValues
      });
    }

    if (sectionValues?.length > 0) {
      const sectionsIds = sections
        .filter(s => sectionValues?.includes(s.sname))
        .map(s => s.id);

      andFilters.push({
        section_in: sectionsIds
      });
    }

    if (dateValues?.length > 0) {
      const startDate = dateValues[0].toISOString();
      const endDate = dateValues[1].toISOString();

      andFilters.push({ publishDate_gte: startDate });
      andFilters.push({ publishDate_lte: endDate });
    }
  };

  const filters = {
    _where: andFilters
  };

  const query = qs.stringify(filters);
  return query;
}