import React, { useEffect, useState } from 'react';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { setGlobalState } from '../../utils/MyContext';
import Header from './Header';
import FormNews from './FormNews';
import TabsContainer from './TabsContainer';
import DashHeader from '../dashboard/dashheader/DashHeader';
import Loading from '../loading/Loading';
import MetaTags from '../metatags/MetaTags';
import { getNewsById } from '../../api/news';

function EditNews() {
  const school = useSelector((state) => state.school);
  const menu = useSelector((state) => state.menu);

  const dispatch = useDispatch();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [plagerism, setPlagerism] = useState([]);

  // close synonym modal when the user clicks outside the component
  const handleClick = (e) => {
    if (
      e.target.id === 'synonym_link' ||
      e.target.id === 'Div1_tooltip_editor'
    ) {
      return;
    }

    setGlobalState('synonymModal', []);
  };

  useEffect(() => {
    if (!school) return;

    const fetchData = async () => {
      const res = await getNewsById(params.newsID);
      setPlagerism(res.plagiarized);

      dispatch(TextEditorValidationActions.cleanTextEditorValidation());

      dispatch(TextEditorActions.updateKey('relatedNews', []));
      dispatch(TextEditorActions.updateTextEditor(res));
      dispatch(TextEditorActions.updateKey('initialAttachments', res.attachments));
      dispatch(TextEditorActions.updateKey('initialStatus', res.status));

      setLoading(false);
    };

    setSections(school.sections);
    fetchData();
  }, [school]);

  return (
    <div>
      <MetaTags title="Editar Notícia &bull; Gestão de Notícias | Projeto TRUE" />

      <DashHeader
        title="Gestão de Notícias"
        subtitle="Editar Notícia"
        menu={menu}
      />

      {loading ? (
        <Loading />
      ) : (
        <Container fluid onClick={(e) => handleClick(e)}>
          <Header name="Adicionar Nova Notícia" />

          <Row className="padding-row">
            <Col xs={8} className="padding-form">
              <FormNews sections={sections} plagerism={plagerism} />
            </Col>

            <Col
              xs={4}
              className={
                menu === 'collapsed'
                  ? 'section-tab tabsDefault'
                  : 'section-tab tabsMenuOpen'
              }
            >
              <TabsContainer corrections={true} search={true} related={true} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default EditNews;