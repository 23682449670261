import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import HelpBtn from './icons/HelpBtn';
import ModalHelp from './ModalHelp';
import ModalOnboarding from './ModalOnboarding';
import { Steps, StepsAuthor } from '../../models/enumHelpModal';
import { useSelector } from 'react-redux';

function Header({ name }) {
  const [modalHelp, setModalHelp] = useState(false);
  const role = useSelector((state) => state.user)?.role.name;

  let steps;

  const handleHelp = () => {
    if (modalHelp === false) {
      setModalHelp(true);
      //console.log('open');
    } else {
      setModalHelp(false);
      //console.log('close');
    }
  };

  const openHelp = () => {
    switch (name) {
      case 'help_users':
        if (role === 'Administrador') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={3}
              />
            );
          }
        }
        break;
      case 'help_news':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={4}
              />
            );
          }
        } else {
          steps = StepsAuthor.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={3}
              />
            );
          }
        }
        break;
      case 'help_sections':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={8}
              />
            );
          }
        }
        break;
      case 'help_headlight':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={10}
              />
            );
          }
        }
        break;
      case 'help_slots':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={11}
              />
            );
          }
        }
        break;
      case 'help_palette':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={12}
              />
            );
          }
        }
        break;
      case 'help_editorial':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={9}
              />
            );
          }
        }
        break;
      case 'help_report':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={14}
              />
            );
          }
        } else {
          steps = StepsAuthor.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={7}
              />
            );
          }
        }
        break;
      case 'help_profile':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={15}
              />
            );
          }
        } else {
          steps = StepsAuthor.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={8}
              />
            );
          }
        }
        break;
      case 'help_identity':
        if (role === 'Administrador' || role === 'Editor') {
          steps = Steps.MODAL_ONBOARDING;
          if (modalHelp === true) {
            return (
              <ModalOnboarding
                close={handleHelp}
                steps={steps}
                indexProps={13}
              />
            );
          }
        }
        break;

      default:
        steps = Steps.MODAL_HELP_DEFAULT;
        if (modalHelp === true) {
          return <ModalHelp close={handleHelp} steps={steps} />;
        }
        break;
    }
  };

  return (
    <>
      <Row className="justify-content-end position-fixed helpPosition">
        <Col xs={1} className="px-1">
          <Button
            id="openHelp"
            className="btnHelp float-end py-0 "
            onClick={handleHelp}
          >
            <HelpBtn />
          </Button>
        </Col>
      </Row>

      {openHelp()}
    </>
  );
}

export default Header;
