import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';

import Header from '../../../templates/frontendheader/Header';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const DEFAULT_PAGE_OFFSET = 400;

export default function PreVizIdentity(props) {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(DEFAULT_PAGE_OFFSET);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!props.school) { return; }
    setSections(props.school.sections);
  }, [props.school]);

  return (
    <Modal
      open={open}
      onClose={props.hdlClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflow: 'scroll' }}
      disableScrollLock={true}
    >
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '5%',
          width: '85%',
          transform: 'translate(0%, 0%)',
          backgroundColor: 'white',
          border: '15px solid #FFFFFF',
          borderRadius: '4px',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <IconButton sx={{ zIndex: '1', marginLeft: 'auto' }} onClick={props.hdlClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Header
          sections={sections}
          school={props.school}
          color1={props.color1}
          color2={props.color2}
          height={height}
          setHeight={setHeight}
          logoVal={props.logoVal}
          journalNameVal={props.journalNameVal}
          schoolNameVal={props.schoolNameVal}
          prevLogo={props.prevLogo}
          prevLogoOrigin={props.prevLogoOrigin}
          prevName={props.prevName}
        />
        <div style={{ backgroundColor: 'white', height: '40px' }} />
      </div>
    </Modal>
  );
}
