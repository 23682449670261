import React from 'react';

import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg';
import './setupmenu.css';

const SetupOptions = {
  1: 'Perfil de Utilizador',
  2: 'Dados do Jornal',
  3: 'Equipa do Jornal',
  4: 'Template Manchete',
  5: 'Template Secções'
};

export default function SetupMenu(props) {
  return (
    <div className="setupWrapper">
      {Object.keys(SetupOptions).map((option) => 
        <div className="setupOptWrapper" key={option}>
          <div className={`setupOption${props.step >= parseInt(option) ? " selected": ""}`}>
            <div className="setupNumber">
              {props.step > parseInt(option) ? <CheckIcon /> : option}
            </div>

            <div className="setupDescription">
              {SetupOptions[option]}
            </div>
          </div>

          {option < 5 ? <div className="setupSeparator" /> : <></>}
        </div>)}
    </div>
  );
}