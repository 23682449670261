import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const saveEditorial = (editorialId, data) => {
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.EDITORIALS}/${editorialId}`,
    data,
    true
  );
};
