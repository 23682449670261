import React, { useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import PrivateRoute from '../../utils/privateRoute';
import Unsupported from '../../components/dashboard/unsupported/Unsupported';
import useResize from '../../components/utilities/Resize';
import SideMenu from '../sidemenu/SideMenu';
import Users from '../../pages/users/Users';
import Sections from '../../pages/sections/Sections';
import News from '../../pages/news/News';
import Profile from '../../pages/profile/Profile';
import Headlights from '../../pages/homepage/headlights/Headlights';
import Editorial from '../../pages/editorial/Editorial';
import Personalization from '../../pages/personalization/colorPalette/Personalization';
import Identity from '../../pages/personalization/journalIdentity/Identity';
import Slots from '../../pages/homepage/slots/Slots';
import EditNews from '../../components/newsEditor/EditNews';
import CreateNews from '../../components/newsEditor/CreateNews';
import Report from '../../pages/report/Report';

export default function DashLayout() {
  const [isUnsupported, setIsUnsupported] = useState(false);
  const { width } = useResize();

  useEffect(() => {
    if (width < 991) {
      setIsUnsupported(true);
    } else {
      setIsUnsupported(false);
    }
  }, [width]);

  if (isUnsupported) {
    return <Unsupported />;
  }

  return (
    <div className="backgroundPage">
      <SideMenu />
      <main className="dashboardBody">
        <Switch>
          <PrivateRoute path="/dashboard/users" exact component={Users} />
          <PrivateRoute path="/dashboard/sections" exact component={Sections} />
          <PrivateRoute path="/dashboard/news" exact component={News} />
          <PrivateRoute path="/dashboard/news/create" exact component={CreateNews} />
          <PrivateRoute path="/dashboard/news/edit/:newsID" exact component={EditNews} />
          <PrivateRoute path="/dashboard/profile" exact component={Profile} />
          <PrivateRoute path="/dashboard/headlight" exact component={Headlights} />
          <PrivateRoute path="/dashboard/editorial" exact component={Editorial} />
          <PrivateRoute
            path="/dashboard/personalization/color"
            exact
            component={Personalization}
          />
          <PrivateRoute
            path="/dashboard/personalization/identity"
            exact
            component={Identity}
          />
          <PrivateRoute path="/dashboard/slots" exact component={Slots} />

          <PrivateRoute path="/dashboard/report" exact component={Report} />

          <Redirect to="/dashboard/news" />
        </Switch>
      </main>
    </div>
  );
}
