import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfigError from '../error/Error';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import SetupMenu from '../../../templates/setupmenu/SetupMenu';
import { ConfigActions } from '../../../redux/actions';
import { updateConfigurationById } from '../../../api/configuration';
import { HeadlightOptions, ToastError } from '../../../models/enum';

import PreViz from './PreViz';

import './headlight.css';

export default function Headlight(props) {
  const [headlightTemplate, setHeadlightTemplate] = useState([]);
  const [loading, setLoading] = useState(false);

  const cid = props.match.params.cid;
  const config = useSelector((state) => state.config);
  const status = useSelector((state) => state.statusConfig);

  const [openViz, setOpenViz] = useState(false);
  const [mancheteValue, setMancheteValue] = useState([]);
  const handleOpen = (e) => {
    setOpenViz(true);
    setMancheteValue(e.target.id);
  };

  const handleClose = () => setOpenViz(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setHeadlightTemplate(config.headlightTemplate);
  }, [config]);

  const previousStep = async () => {
    const step = 3;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('headlightTemplate', -1));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.headlightTemplate = -1;
    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger", toastId: "general_error", autoClose: 2000 });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const nextStep = async () => {
    const step = 5;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('headlightTemplate', headlightTemplate));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.headlightTemplate = headlightTemplate;
    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, { className: "toastStyleDanger" , toastId: "general_error", autoClose: 2000 });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const isValid = () => {
    return headlightTemplate > 0;
  };

  if (status.error) {
    return <ConfigError />;
  }

  return (
    <div className="setupPage">
      <MetaTags title="Template Manchete &bull; Configuração | Projeto TRUE" />

      <SetupMenu step={4} />

      <div className={'setupBody' + (status.loading || loading ? ' isLoading' : '')}>
        {status.loading || loading ? (
          <div className="loadingContainer">
            <Loading />
          </div>
        ) : (
          <></>
        )}

        <div className="highlightContainer smallMargin">
          <div>
            <p className="setupTitle">Template Manchete</p>

            <p className="setupSubtitle">
              Selecione o layout para a manchete do jornal escolar, clicando na imagem
              correspondente. Pode escolher outro layout mais tarde, se assim o pretender.
            </p>
          </div>
        </div>

        <Grid container spacing={4}>
          {HeadlightOptions.map((H) => (
            <Grid key={H.option} item xs={6}>
              <div
                className={`headlightItem${
                  headlightTemplate === H.option ? ' selected' : ''
                }`}
              >
                <H.img onClick={() => setHeadlightTemplate(H.option)} />

                <div className="headlightBtnContainer">
                  <button
                    id={H.option}
                    className="secondaryBtn"
                    onClick={(e) => handleOpen(e)}
                  >
                    Pré-visualizar
                  </button>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="btnContainer">
          <div>
            <button className="secondaryBtnBlue" onClick={previousStep}>
              Voltar
            </button>
          </div>
          <div>
            {isValid() ? (
              <button className="mainBtn" onClick={nextStep}>
                Seguinte
              </button>
            ) : (
              <button className="mainBtn" disabled={true}>
                Seguinte
              </button>
            )}
          </div>
        </div>
      </div>
      {openViz === true ? (
        <PreViz handleClose={handleClose} mancheteValue={mancheteValue} />
      ) : (
        <></>
      )}
    </div>
  );
}
