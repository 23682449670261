import React, { useState } from 'react';

import Modal from '../../../templates/modal/Modal';
import ModalContactForm from './ModalContactForm';
import TableLoading from '../../../components/tableloading/TableLoading';

import { ReactComponent as AddUserIcon } from '../../../assets/img/icons/addUser.svg';
import { ReactComponent as EditUserIcon } from '../../../assets/img/icons/edit.svg';

export default function ModalContact(props) {
  const {
    open, loading, user, userRoles, edit,
    setOpen, onChange, updateContacts
  } = props;
  const [oldUser] = useState(user);

  const isValid = () => {
    return user && user.name && user.surname && user.email && user.role;
  };

  const mainBtnTxt = edit ? 'Atualizar' : 'Adicionar';
  const loadingClass = loading ? 'modalBodyContainer pageLoading' : 'modalBodyContainer';

  return (
    <Modal
      openModal={open}
      modalHeader
      modalIcon={edit ? <EditUserIcon /> : <AddUserIcon />}
      modalTitle={edit ? 'Editar membro da equipa' : 'Adicionar membro da equipa'}
      centered={true}
    >
      <div className={loadingClass}>
        <ModalContactForm
          user={user}
          onChange={onChange}
          userRoles={userRoles}
        />

        <div className="btnContainer">
          <div className="attributeContainer">
            <button className="secondaryBtnBlue" onClick={() => setOpen(false)}>Cancelar</button>
          </div>
          <div className="attributeContainer">
            {isValid() ?
              <button className="mainBtn" onClick={() => updateContacts(oldUser, edit)}>{mainBtnTxt}</button> :
              <button className="mainBtn" disabled={true}>{mainBtnTxt}</button>
            }
          </div>
        </div>

        {loading ? <TableLoading /> : <></>}
      </div>
    </Modal>
  );
}