import { combineReducers } from 'redux';
import { ACTION_TYPES } from './actions';

export const defaultUser = {
  name: '',
  surname: '',
  email: '',
  role: '',
  password: '',
  isCoordinator: false,
};

export const defaultSchool = {
  grouping: '',
  school: '',
  journal: '',
  subdirectory: '',
  district: '',
  municipality: '',
  logo: '',
};

const config = {
  step: 1,
  user: defaultUser,
  school: defaultSchool,
  contacts: [],
  headlightTemplate: -1,
  sectionTemplate: -1,
};

const status = {
  loading: true,
  error: false,
};

const userReducer = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER.UPDATE_USER:
      return { ...action.payload };
    default:
      return state;
  }
};

const schoolReducer = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.SCHOOL.UPDATE_SCHOOL:
      return { ...action.payload };
    default:
      return state;
  }
};

const menuReducer = (state = localStorage.getItem('menu_state'), action) => {
  switch (action.type) {
    case ACTION_TYPES.MENU.UPDATE_MENU:
      return action.payload;
    default:
      return state;
  }
};

const configReducer = (state = config, action) => {
  switch (action.type) {
    case ACTION_TYPES.CONFIGURATION.UPDATE_KEY: {
      return { ...state, [action.payload.key]: action.payload.value };
    }

    case ACTION_TYPES.CONFIGURATION.UPDATE_CONFIGURATION:
      return { ...action.payload };

    case ACTION_TYPES.CONFIGURATION.CLEAN_CONFIGURATION:
      return { ...config };

    default:
      return state;
  }
};

const configStatusReducer = (state = status, action) => {
  switch (action.type) {
    case ACTION_TYPES.STATUS_CONFIG.UPDATE_ERROR: {
      return { ...state, error: action.payload };
    }

    case ACTION_TYPES.STATUS_CONFIG.UPDATE_LOADING: {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
};

const textEditor = {
  plagiarized: [],
  loading: true,
  swiperRef: null,
  saveRef: null,
  tableLoading: false,
  author: null,
  status: 'Em curso',
  section: null,
  body: null,
  bodyObject: null,
  bodyRaw: null,
  title: null,
  titleObject: null,
  activeQuill: '',
  position: null,
  attachments: [],
  initialAttachments: [],
  relatedNews: [],
  initialStatus: 'Em curso',
  files: [],
};

const textEditorReducer = (state = textEditor, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEXTEDITOR.UPDATE_TEXTEDITOR:
      return action.payload;

    case ACTION_TYPES.TEXTEDITOR.UPDATE_KEY_TEXTEDITOR: {
      return { ...state, [action.payload.key]: action.payload.value };
    }

    case ACTION_TYPES.TEXTEDITOR.CLEAN_TEXTEDITOR: {
      return { ...textEditor };
    }
    default:
      return state;
  }
};

const textEditorValidation = {
  author: null,
  section: null,
  body: null,
  title: null,
  attachments: null,
  caption: [],
  cropped: null,
  video: null,
  finalValidation: null,
};

const textEditorValidationReducer = (state = textEditorValidation, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEXTEDITOR_VALIDATION.UPDATE_TEXTEDITOR_VALIDATION:
      return action.payload;

    case ACTION_TYPES.TEXTEDITOR_VALIDATION.UPDATE_KEY_TEXTEDITOR_VALIDATION: {
      return { ...state, [action.payload.key]: action.payload.value };
    }

    case ACTION_TYPES.TEXTEDITOR_VALIDATION.CLEAN_TEXTEDITOR_VALIDATION: {
      return { ...textEditorValidation };
    }

    default:
      return state;
  }
};

const errors = {
  bodyErrors: [],
  titleErrors: [],
};

const errorsReducer = (state = errors, action) => {
  switch (action.type) {
    case ACTION_TYPES.ERRORS.UPDATE_KEY:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

const replaceWord = {
  quill: '',
  error: '',
  sugestion: '',
};

const replaceWordReducer = (state = replaceWord, action) => {
  switch (action.type) {
    case ACTION_TYPES.REPLACEWORD.UPDATE_REPLACEWORD:
      return action.payload;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  user: userReducer,
  school: schoolReducer,
  config: configReducer,
  statusConfig: configStatusReducer,
  textEditor: textEditorReducer,
  errors: errorsReducer,
  menu: menuReducer,
  replaceWord: replaceWordReducer,
  textEditorValidation: textEditorValidationReducer,
});
