import React from 'react';
import { Grid, MenuItem } from '@mui/material';

import TextInput from '../../components/dashboard/textinput/TextInput';
import { USER_STATUS } from '../../utils/globals';

export default function ModalUserForm(props) {
  const { name, surname, email, role, status, roles, changeHandler } = props;

  return (
    <Grid container>
      <Grid className="attributeContainer" item xs={6}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="Nome"
          name="name"
          required={true}
          placeholder="ex: João"
          value={name}
          setInputValue={changeHandler}
          maxLength={25}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={6}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="Apelido"
          name="surname"
          required={true}
          placeholder="ex: Gomes"
          value={surname}
          setInputValue={changeHandler}
          maxLength={25}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={12}>
        <TextInput
          className="custom-user-input"
          type="text"
          label="E-mail"
          name="email"
          required={true}
          placeholder="ex: joao.gomes@mail.com"
          value={email}
          setInputValue={changeHandler}
        />
      </Grid>

      <Grid className="attributeContainer" item xs={12}>
        <TextInput
          className="custom-user-input"
          label="Tipo de Utilizador"
          select
          name="role"
          required={true}
          value={role}
          setInputValue={changeHandler}
        >
          <MenuItem value="" disabled selected>
            Selecione o tipo de utilizador
          </MenuItem>
          {roles.map((role) => (
            <MenuItem key={role._id} value={role._id}>
              {role.name}
            </MenuItem>
          ))}
        </TextInput>
      </Grid>

      {status ? (
        <Grid className="attributeContainer" item xs={12}>
          <TextInput
            className="custom-user-input"
            label="Estado"
            select
            name="status"
            value={status}
            setInputValue={changeHandler}
          >
            <MenuItem value="" disabled selected>
              Selecione o estado do utilizador
            </MenuItem>

            <MenuItem value={USER_STATUS.active}>Ativo</MenuItem>

            <MenuItem value={USER_STATUS.inactive}>Inativo</MenuItem>

            {/* <MenuItem value={USER_STATUS.in_validation}>
                            Em validação
                        </MenuItem> */}
          </TextInput>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
