import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfigError from '../error/Error';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import SetupMenu from '../../../templates/setupmenu/SetupMenu';
import TextInput from '../../../components/dashboard/textinput/TextInput';
import { ConfigActions } from '../../../redux/actions';
import { defaultUser } from '../../../redux/reducer';
import { updateConfigurationById, validateUser } from '../../../api/configuration';
import { ToastError } from '../../../models/enum';

export default function UserProfile(props) {
  const [user, setUser] = useState(defaultUser);
  const [loading, setLoading] = useState(false);

  const cid = props.match.params.cid;
  const config = useSelector((state) => state.config);
  const status = useSelector((state) => state.statusConfig);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setUser(config.user);
  }, [config]);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    return user && user.name && user.surname && user.email && user.password;
  };

  const previousStep = async () => {
    const step = 1;
    const cfg = { ...config };

    setLoading(true);

    cfg.user.name = defaultUser.name;
    cfg.user.surname = defaultUser.surname;
    cfg.user.password = defaultUser.password;

    dispatch(ConfigActions.updateKey('user', cfg.user));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000
      });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/init`);
  };

  const nextStep = async () => {
    const step = 2;
    const cfg = { ...config };

    setLoading(true);

    //user.isCoordinator = true;
    let res;

    try {
      res = await validateUser(user.name, user.surname, user.email, null, false);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }

    if (!res.valid) {
      setLoading(false);
      return toast(res.message, { className: 'toastStyleDanger', toastId: "res_message", autoClose: 2000 });
    }

    dispatch(ConfigActions.updateKey('user', user));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.user = user;
    cfg.step = step;
    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  if (status.error) {
    return <ConfigError />;
  }

  return (
    <div className="setupPage">
      <MetaTags title="Perfil de Utilizador &bull; Configuração | Projeto TRUE" />

      <SetupMenu step={1} />

      <div className={'setupBody' + (status.loading || loading ? ' isLoading' : '')}>
        {status.loading || loading ? (
          <div className="loadingContainer">
            <Loading />
          </div>
        ) : (
          <></>
        )}

        <div className="highlightContainer smallMargin">
          <div>
            <p className="setupTitle">Perfil de Utilizador</p>

            <p className="setupSubtitle">Adicione os dados do seu perfil</p>
          </div>
        </div>

        <Grid container>
          <Grid className="profileInputContainer leftSide" item xs={6}>
            <TextInput
              className="custom-user-input"
              type="text"
              label="Nome"
              name="name"
              value={user?.name}
              placeholder="ex: João"
              setInputValue={onChange}
              required={true}
              maxLength={25}
            />
          </Grid>

          <Grid className="profileInputContainer" item xs={6}>
            <TextInput
              className="custom-user-input"
              type="text"
              label="Apelido"
              name="surname"
              value={user?.surname}
              placeholder="ex: Gomes"
              setInputValue={onChange}
              required={true}
              maxLength={25}
            />
          </Grid>

          <Grid className="profileInputContainer leftSide" item xs={6}>
            <TextInput
              className="custom-user-input-disabled"
              type="text"
              label="E-mail"
              name="email"
              value={user?.email}
              placeholder="ex: joao.gomes@mail.com"
              setInputValue={onChange}
              required={true}
              disabled={true}
            />
          </Grid>

          <Grid className="profileInputContainer" item xs={6}>
            <TextInput
              className="custom-user-input"
              type="password"
              label="Password"
              name="password"
              value={user?.password}
              placeholder="Insira a password"
              setInputValue={onChange}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="btnContainer">
          <div>
            <button className="secondaryBtnBlue" onClick={previousStep}>
              Voltar
            </button>
          </div>
          <div>
            {isValid() ? (
              <button className="mainBtn" onClick={nextStep}>
                Seguinte
              </button>
            ) : (
              <button className="mainBtn" disabled={true}>
                Seguinte
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
