import Quill from "quill";

const Link = Quill.import("formats/link");

class CustomLink extends Link {
  static sanitize(url) {
    let value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if (value.startsWith(this.PROTOCOL_WHITELIST[i])) return value;

      return `http://${value}`;
    }
    return value;
  }
}

Quill.register('formats/link', CustomLink, true);

export default CustomLink;