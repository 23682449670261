import { fetchRequest, HTTPMethod, Resource } from './api';
import { CORRECTOR_PATH, PROTOCOL, IP, PORT } from '../utils/globals';

import { searchApiInfo} from './api';

const urlEntities = `${PROTOCOL}://${IP}:${PORT}${CORRECTOR_PATH}/extract-entities`;

export const getSearchNews = (input) =>
  fetchRequest(
    HTTPMethod.GET,
    `${searchApiInfo.base}/${Resource.SEARCHPUBLICO}?input=${input}&sort=dateDESC`,
    null,
    true,
  );

export const getPublicoRelatedNews = (input) =>
  fetchRequest(
    HTTPMethod.GET,
    `${searchApiInfo.base}/${Resource.SEARCHPUBLICO}?input=${input}&sort=dateDESC`,
    null,
    true,
  );

export const getEntities = (data) =>
  fetchRequest(HTTPMethod.POST, urlEntities, data, true, true, true);
