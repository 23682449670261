import React from 'react';

import Link from '@mui/material/Link';

import DefaultImage from '../../../assets/img/homepage/no-image.jpg';

export default function NewsPublicoItemPicture({ news, layout, size }) {
    
  const img = news?.Image;

  const getThumbnail = (img, size, layout) => {
    if (!img) {
      return DefaultImage;
    }

    let thumbnail;
    if (size === 'big') {
      thumbnail = img.formats?.large?.url;
    } else if (size === 'slot') {
      thumbnail = img.formats?.small?.url;
    } else {
      thumbnail = img.formats?.medium?.url;
    }

    if (thumbnail && layout !== 2) {
      return `${thumbnail}`;
    } else return `${img}`;
  };

  return (
    <Link className="newsItemImgLink" href={news?.OriginalURL} underline="none">
      <picture className="newsImgContainer">
        <img
          className="newsItemImg"
          src={getThumbnail(img, size, layout)}
          alt="Imagem manchete"
        />
      </picture>
    </Link>
  );
}
