import React from 'react';

import Layout1 from './Layout1';
import Layout2 from './Layout2';
import Layout3 from './Layout3';
import Layout4 from './Layout4';

import './HeadlightLayout.css';

export default function HeadlightLayout({ headlight, school, isMobile }) {
  const layout = school?.headlight.layout;

  const getLayout = () => {
    const { news1, news2, news3, news4, news5 } = headlight;

    const emptyHeadlight = !news1 && !news2 && !news3 && !news4 && !news5;
    if (emptyHeadlight) {
      return <div className="emptySlot emptyStateFill small">
        Ainda não há notícias nesta manchete.
      </div>;
    }

    let elem = <div></div>;
    const props = {
      headlight: headlight,
      layout: layout,
      school: school,
      isMobile: isMobile
    };

    switch (layout) {
    case 1:
      elem = <Layout1 {...props} />;
      break;
    case 2:
      elem = <Layout2 {...props} />;
      break;
    case 3:
      elem = <Layout3 {...props} />;
      break;
    case 4:
      elem = <Layout4 {...props} />;
      break;
    default:
      console.error('[homepage] Headlight layout not found.');
    }

    return elem;
  }

  return (
    <>{getLayout()}</>
  );
}