import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // Main color (Sidebar, Buttons, Tables)
      main: '#1F8598',
      contrastText: '#fff',
    },
    secondary: {
      // Secondary color (Text, Icons)
      main: '#0C404A',
      contrastText: '#fff',
    },
    light: {
      // Background color
      main: '#F6F6F6',
    },
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
  overrides: {
    MuiTableRow: {
      root: {
        //for the body
        height: '100%',
      },
      head: {
        //for the head
        height: '100%',
      },
    },
    MuiSelect: {},
  },
});

export default theme;
