import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import logoOriginal from '../../assets/logo/logo-big.svg';

export default class MetaTags extends Component {
  render() {
    let title = 'Projeto TRUE';
    let description = 'O projecto TRUE tem como objetivo geral a criação de uma plataforma que permita às novas gerações ' +
            'escrever, em Português, textos noticiosos, com base numa análise da credibilidade de fontes de informação e das respetivas ' +
            'notícias, assim como na identificação e contextualização de temáticas-chave, conduzindo à revitalização dos jornais escolares.';

    if (this.props.title) { title = this.props.title; }
    if (this.props.description) { description = this.props.description; }
    const { ogURL, ogTitle, ogImage } = this.props;

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="article" />
        <meta property="og:url" content={ogURL ? ogURL : window.location.href} />
        <meta property="og:title" content={ogTitle ? ogTitle : 'Projeto TRUE'} />
        <meta property="og:image" content={ogImage ? ogImage : logoOriginal} />
      </Helmet>
    );
  }
}