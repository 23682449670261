import TextEditor from './TextEditor';
import { TextEditorType } from '../../models/enumsNewsEditor';
import InfoIcon from '../../assets/img/icons/info.svg';
import DangerIcon from '../../assets/img/icons/alertTriangle.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function FormTitle({}) {
  const modules = {
    toolbar: [['italic']],
  };

  let titleVerif = useSelector((state) => state?.textEditorValidation).title;
  let titleCharCount = useSelector((state) => state?.textEditor).titleRaw;
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation,
  ).finalValidation;

  return (
    <div className="form-bottom-margin">
      <label className="font-title label-bottom-margin">
        Título da notícia
      </label>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip" className="tooltip">
            Escreve uma frase breve e esclarecedora que prenda a atenção do
            leitor. Esta frase tem um limite de 85 caracteres
          </Tooltip>
        }
      >
        <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
      </OverlayTrigger>

      <TextEditor
        quillModules={modules}
        id="text-editor-title"
        height="auto"
        type={TextEditorType.Title}
        className="bg-black"
      />

      {titleCharCount ? (
        titleCharCount.length - 1 <= 85 ? (
        <div className="text-muted m-2">{titleCharCount?.length - 1}/85</div>
      ) : (
        <div className="text-danger m-2">
          <b>{titleCharCount?.length - 1}</b>/85
        </div>
      )
      ) : (
        undefined
      )}

      {(titleVerif === false ||
        (titleVerif === null && finalVerif === false)) && (
        <div className="errorVerifContainer mt-2">
          <img src={DangerIcon} className="errorVerifIcon" alt='danger icon'/>
          <p className="errorVerifMessage">
            {titleCharCount?.length > 86 ? 
              'Por favor, dá um título mais curto à tua notícia'
             : 
              'Por favor, dá um título à tua notícia.'
            }
          </p>
        </div>
      )}
    </div>
  );
}

export default FormTitle;
