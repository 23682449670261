import { useEffect, useRef, useState } from 'react';

export default function LinkShare({ color }) {
  const timerRef = useRef(null);
  const [btnCopied, setBtnCopied] = useState(false);

  const copyLinkClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard?.writeText(window.location.href);
    if (!btnCopied) {
      setBtnCopied(true);
      timerRef.current = setTimeout(() => setBtnCopied(false), 8000);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <div className="articleNewsShare">
      <span>Partilhar</span>
      <button
        onClick={copyLinkClipboard}
        style={{ color: color, backgroundColor: `${color}19` }}
      >
        {btnCopied ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline xmlns="http://www.w3.org/2000/svg" points="20 6 9 17 4 12" />
            </svg>
            <span>Copiado!</span>
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
            </svg>
            <span>Copiar URL</span>
          </>
        )}
      </button>
    </div>
  );
}
