import React, { useEffect, useState } from 'react';

import ArticleBody from '../../../components/frontend/articlebody/ArticleBody';
import LinkShare from '../../../components/frontend/socialmedia/LinkShare';
import MetaTags from '../../../components/metatags/MetaTags';
import Loading from '../../../components/loading/Loading';
import PageNotFound from '../pagenotfound/PageNotFound';

export default function AboutUs({ school }) {
  const mainColor = school?.palette?.color.main;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editorial, setEditorial] = useState(null);

  const journalName = school?.subdirectory;

  useEffect(() => {
    if (!school || !loading) { return; }
    if (!school.editorial) { setError(true); }

    setEditorial(school.editorial);
    setLoading(false);
  }, [school])

  if (error && !loading) {
    return (
      <div className="articlePage">
        <div className="articleContent articleWidth first">
          <PageNotFound link={`/${journalName}`} linkTxt="Aceder Homepage" isSmall={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="articlePage">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="articleContent articleWidth first editorial">                        
            <h1 className="articleNewsTitle">
                            Sobre Nós
            </h1>

            <LinkShare color={mainColor} /> 
          </div>

          <MetaTags title={`Sobre Nós | ${school?.journal} | Projeto TRUE`} />

          <ArticleBody newsItem={editorial} school={school} />
        </>
      )}
    </div>
  );
}
