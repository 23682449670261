import imageCompression from 'browser-image-compression';
import { PROTOCOL, IP, PORT, PATH } from './globals';
import { deleteImage } from '../api/upload';
import { ONE_MB } from './utils';

export const dataUrlToFile = (dataurl) => {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });

  return new File([blob], 'img.jpg', {
    type: mime,
    lastModified: new Date(),
  });
};

export const toDataURL = async (path) => {
  const url = `${PROTOCOL}://${IP}:${PORT}${PATH}${path}`;

  const response = await fetch(url, {
    method: 'GET',
  });

  const data = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async () => {
      try {
        // Resolve the promise with the response value
        resolve(reader.result);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const uploadAttachments = (files) => {
  const fetchImages = async (formdata) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: formdata,
    };

    const response = await fetch(
      `${PROTOCOL}://${IP}:${PORT}${PATH}/upload`,
      requestOptions,
    );

    const data = await response.json();

    return data[0];
  };

  return Promise.all(
    files.map(async (file) => {
      // fetch api mog-technologies
      const formdata = new FormData();
      // if file size is higher than 1mb, we create new image
      if (file?.file?.size > ONE_MB) {
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file.file, options);
          formdata.append('files', compressedFile, 'img.jpg');
          console.log({ compressedFile });
        } catch (error) {
          console.log(error);
        }
      } else {
        formdata.append('files', file.file);
      }

      if (file?.meta?.caption !== undefined) {
        const fileInfo = { 
          caption: file.meta.caption 
        };

        formdata.append('fileInfo', JSON.stringify(fileInfo));
      }

      return fetchImages(formdata);
    }),
  );
};

export const deleteAttachments = (files) => {
  const fetchDeleteImages = async (id) => {
    try {
      await deleteImage(id);
      return true;
    } catch (error) {
      return false;
    }
  };

  return Promise.all(files.map(async (id) => fetchDeleteImages(id)));
};