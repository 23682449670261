import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import mascoteGuardadoSucesso from '../../assets/newsEditor/images/mascoteGuardarSucesso.svg';
import mascoteErro from '../../assets/newsEditor/images/mascoteErros.svg';
import { ModalMessage } from '../../models/enumsNewsEditor';
import { useSelector } from 'react-redux';

const ModalSaveResult = ({ sucess, update, closeModal }) => {
  const history = useHistory();
  let newsStatus = useSelector((state) => state?.textEditor.status);
  const userRole = useSelector((state) => state?.user.role.name);

  const continueEdit = () => {
    closeModal();
  };

  const goToAllNews = () => {
    history.push('/dashboard/news');
  };

  let Btn1Text = 'Continuar a editar';
  let Btn1Func = closeModal;
  let Btn2Text = 'Voltar à lista';
  let Btn2Func = goToAllNews;
  let icon = '';
  let headerText = '';
  let bodyText = '';

  if (sucess) {
    icon = mascoteGuardadoSucesso;

    if (update) {
      headerText = ModalMessage.UPDATE_SUCCESS_HEADER;
      bodyText = ModalMessage.UPDATE_SUCCESS_BODY;
    } else {
      headerText = ModalMessage.CREATE_SUCCESS_HEADER;
      bodyText = ModalMessage.CREATE_SUCCESS_BODY;
      Btn1Func = continueEdit;
    }
  } else {
    icon = mascoteErro;

    headerText = ModalMessage.CREATE_ERROR_HEADER;
    bodyText = ModalMessage.CREATE_ERROR_BODY;
  }

  return (
    <Modal
      id="modal-news-result"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show
    >
      <Modal.Header>
        <Modal.Title>{headerText}</Modal.Title>
        {(newsStatus === 'Em curso' || userRole !== 'Autor') && (
          <Button
            type="button"
            className="btn-closing btn btn-primary"
            onClick={Btn1Func}
          ></Button>
        )}
      </Modal.Header>

      <Modal.Body style={{ width: '650px' }}>
        <img
          src={icon}
          className="imageInsideModal mx-auto d-block my-3"
          alt=""
        />
        <p className="text-center">{bodyText}</p>
      </Modal.Body>

      <Modal.Footer>
        {(newsStatus === 'Em curso' || userRole != 'Autor') && (
          <Button className="btnCancelNews px-5 mx-2" onClick={Btn1Func}>
            {Btn1Text}
          </Button>
        )}
        <Button className="btnSubmitNews px-5 mx-2" onClick={Btn2Func}>
          {Btn2Text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSaveResult;
