import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

export default function TrueCheckbox(props) {
  return (
    <>
      <FormControlLabel
        className="checkboxTxt"
        control={
          <Checkbox
            checked={props.checked}
            onChange={props.onChange}
            {...props}
          />
        }
        label={props.name}
      />
    </>
  );
}