import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ConfigError from '../error/Error';
import MetaTags from '../../../components/metatags/MetaTags';
import Loading from '../../../components/loading/Loading';

import { ReactComponent as Success } from '../../../assets/img/illustrations/success.svg';
import './finish.css';

export default function Finish(props) {
  const status = useSelector((state) => state.statusConfig);

  // TO DO: Make this work!!!
  /*const closeWindow = async () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }*/

  if (status.error) { return <ConfigError />; }

  return (
    <div className="fullPageContainer">
      <MetaTags title="Finalização &bull; Configuração | Projeto TRUE" />
            
      {status.loading ? <Loading /> : <div className="finishContainer">
        <Success />

        <h1 className="successTitle">
                    Sucesso!
        </h1>

        <div className="successDescription">
          <div>
                        A configuração foi concluída com sucesso.
          </div>
          <div>
                        Já pode começar a utilizar o back-office que preparámos para si.
          </div>
        </div>

        <div className="finishOptions">
          {/*<div>
                        <button className="whiteBtn" onClick={closeWindow}>
                            Fechar a janela
                        </button>
                    </div>*/}
          <div>
            <NavLink className="mainBtn" to="/dashboard">Back-Office</NavLink>
          </div>
        </div>
      </div>}
    </div>
  );
}