import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';

import TrueCheckbox from '../../components/dashboard/checkbox/Checkbox';
import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Loading from '../../components/loading/Loading';
import TableLoading from '../../components/tableloading/TableLoading';
import MetaTags from '../../components/metatags/MetaTags';
import Search from '../../components/dashboard/search/Search';
import ModalAddSection from './ModalAddSection';
import ModalEditSection from './ModalEditSection';
import ModalSortSections from './ModalSortSections';
// import Pagination from '../../components/dashboard/pagination/Pagination';
import { updateSchoolSections, updateSchoolSectionsTemplate } from '../../api/schools';
import { getSectionsBySchool } from '../../api/sections';
import { ToastError, ToastSuccess } from '../../models/enum';
//import { getCurrentData } from '../../utils/utils';
import { SchoolActions } from '../../redux/actions';
import Header from '../../components/newsEditor/Header';

//Icons
import AddSectionIcon from '../../assets/img/icons/addSection.svg';
import EditIcon from '@mui/icons-material/Edit';
import './sections.css';

import ModalEditSectionTemplate from './ModalEditSectionTemplate';

class SectionsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstLoad: true,
      loading: false,
      openAddModal: false,
      openEditModal: false,
      openSortModal: false,
      openEditTemplateModal: false,
      searchValue: '',
      sections: [],
      sectionsSlice: [],
      //page: 1,
      //itemsPerPage: 15,
      //Handle modal accessibility
      lastClickedElement: null,
      hoveredSection: -1,
      currentSection: null,
    };

    this.sectionUpdateMessage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateSectionTemplate = this.updateSectionTemplate.bind(this);
  }

  componentDidMount() {
    this.getSections();

    //Handle modal accessibility
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleClick = (event) => {
    this.setState({ lastClickedElement: event.target });
  };

  componentDidUpdate(prevProps) {
    if (this.props.school !== prevProps.school) {
      if (this.state.firstLoad) {
        this.getSections();
      }
    }
  }

  async getSections() {
    const { school } = this.props;
    if (!school) return;

    let res;
    try {
      res = await getSectionsBySchool(school.id);
    } catch (err) {
      this.setState({ firstLoad: false });
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    this.setState({
      sections: res,
      sectionsSlice: res,
      firstLoad: false
    });
  }

  // MODAL - Handle open
  handleOpen = (openModalState, section) => {
    let data = {
      [openModalState]: true,
      searchValue: '',
      sectionsSlice: this.state.sections,
    };

    if (section) {
      data = { ...data, currentSection: section };
    }

    this.setState(data);
  };

  // MODAL - Handle close
  handleClose(openModalState) {
    let data = {};

    if (openModalState) {
      data = { [openModalState]: false };
    } else {
      data = {
        openAddModal: false,
        openEditModal: false,
        openSortModal: false,
        openEditTemplateModal: false,
      };
    }

    this.setState(data);

    // Allows any user to use the escape key to dismiss modal
    if (this.state.lastClickedElement !== null) {
      this.state.lastClickedElement.focus();
    }
  }

  // MODAL - Validate and submit new section
  addSection = async (newSection) => {
    this.setState((prevState) => {
      const sections = [...prevState.sections, newSection];
      return {
        ...prevState,
        openAddModal: false,
        sections: sections,
        sectionsSlice: sections,
      };
    });
  };

  editSection = async (id, name) => {
    this.setState((prevState) => {
      let listSections = [...prevState.sections];
      let section = listSections.find((s) => s.id === id);
      section.sname = name;

      return {
        ...prevState,
        openEditModal: false,
        sections: listSections,
        sectionsSlice: listSections,
      };
    });
  };

  // SEARCH - Pesquisa inteligente à medida que escreve
  changeHandler = (e) => {
    this.setState({
      searchValue: e.target.value,
    });

    const sections = this.state.sections;
    const valueLower = e.target.value.toLowerCase();
    const arraySearches = sections.filter((x) =>
      x.sname.toLowerCase().includes(valueLower),
    );

    this.setState({
      sectionsSlice: arraySearches,
    });
  };

  // Mostrar mensagem após atualização do valor da checkbox
  sectionUpdateMessage = (sections) => {
    const activeSections = sections.length;
    let toastMsg = '';
    if (activeSections === 1) {
      toastMsg = ToastSuccess.UPDATED_SECTION_MIN;
    } else if (activeSections < 5) {
      //toastMsg = ToastSuccess.UPDATED_SECTION;
      return;
    } else if (activeSections >= 5) {
      toastMsg = ToastSuccess.UPDATED_SECTION_MAX;
    }

    toast(toastMsg, {
      className: 'toastStyleDanger',
      toastId: 'general_error',
      autoClose: 2000,
    });
  };

  // Add or remove section from array of sections associated with school
  changeHandlerCheckbox = async (e, section) => {
    const checked = e.target.checked;
    this.setState({ loading: true });

    let school = { ...this.props.school };
    if (checked) {
      school.sections = [...school.sections, section];
    } else {
      const sectionIdx = school.sections.findIndex((s) => s.id === section.id);
      school.sections.splice(sectionIdx, 1);
    }

    try {
      await updateSchoolSections(school.id, school.sections);
    } catch (err) {
      this.setState({ loading: false });
      toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
      return;
    }

    this.props.updateSchool(school);
    this.sectionUpdateMessage(school.sections);
    this.setState({ loading: false });
  };

  checkIfInactive = (selectedSections, sectionId) => {
    const active = selectedSections?.includes(sectionId);
    const activeSections = selectedSections?.length;

    const maxNoSections = activeSections >= 5 && !active;
    const minNoSections = activeSections === 1 && active;

    return maxNoSections || minNoSections;
  };

  updateSectionTemplate = async (sectionTemplate) => {
    this.setState({ loading: true });
    this.handleClose('openEditTemplateModal');

    let school = { ...this.props.school };
    let res;
    try {
      res = await updateSchoolSectionsTemplate(school.id, sectionTemplate);
    } catch (err) {
      this.setState({ loading: false });
      toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000
      });
      return;
    }

    toast(ToastSuccess.UPDATED_SECTION_TEMPLATE, {
      className: 'toastStyleSuccess',
      toastId: 'updated_headlight',
      autoClose: 2000
    });
    this.setState({ loading: false });
    this.props.updateSchool(res);
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  }

  render() {
    const { firstLoad, searchValue, loading } = this.state;

    // array with ids of selected sections
    const selectedSections = this.props.school?.sections.map((s) => s.id);
    const loadingClass = loading ? "bodyContainer pageLoading" : "bodyContainer";

    return (
      <div>
        <MetaTags title="Gestão de Secções | Projeto TRUE" />
        <DashHeader title="Gestão de Secções" />
        <Header name={'help_sections'} />

        {firstLoad ? (
          <Loading />
        ) : (
          <div className={loadingClass}>
            <div className="optionsContainer sections">
              <Search
                imgAlt="Pesquisar secções"
                placeholder="Pesquisar por secção"
                value={searchValue}
                onChange={this.changeHandler}
              />
            </div>

            <Grid container spacing={3}>
              <Grid item container xs={9} className="sectionsContentWrapper">
                <Grid item xs={12}>
                  <div className="sectionsWrapper">
                    <p className="pageHighlight">Selecione as secções do jornal</p>
                    <p className="highlightDescription">
                      Escolha no máximo <b>cinco</b>
                    </p>
                  </div>
                </Grid>

                {this.state.sectionsSlice.length === 0 ? (
                  <Grid item xs={12} className="highlightDescription noResults">
                    Ainda não existem secções!
                  </Grid>
                ) : (
                  this.state.sectionsSlice.map((s) => (
                    <Grid
                      key={s.id}
                      item
                      xs={4}
                      className="sectionContainer"
                      onMouseEnter={() => {
                        this.setState({ hoveredSection: s.id });
                      }}
                      onMouseLeave={() => {
                        this.setState({ hoveredSection: null });
                      }}
                    >
                      <div className="checkboxFlex">
                        <TrueCheckbox
                          checked={selectedSections?.includes(s.id)}
                          onChange={(e) => this.changeHandlerCheckbox(e, s)}
                          disabled={
                            this.checkIfInactive(selectedSections, s.id) ? true : false
                          }
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                          name={s.sname}
                        />
                      </div>

                      {this.state.hoveredSection === s.id ? (
                        <EditIcon
                          className="enableCursor"
                          sx={{ color: '#1f8598' }}
                          onClick={() => this.handleOpen('openEditModal', s)}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ))
                )}
              </Grid>

              <Grid item container xs={3} className="paletteSidebar">
                <div className="schemeContainer">
                  <button
                    className="mainBtn"
                    onClick={() => this.handleOpen('openAddModal')}
                  >
                    <img src={AddSectionIcon} alt="Adicionar secção" />
                    <span className="modalBtnTxt">Adicionar Secção</span>
                  </button>

                  <div className="btnContainer">
                    <div className="secondaryContainer btnMargin">
                      <button
                        className="secondaryBtnBlue"
                        onClick={() => this.handleOpen('openEditTemplateModal')}
                      >
                        <EditIcon className="hpIcon" />
                        Editar Layout
                      </button>
                    </div>
                  </div>

                  <div className="secondaryContainer btnMargin">
                    <button
                      className="secondaryBtnBlue"
                      onClick={() => this.handleOpen('openSortModal')}
                    >
                      Ordenar Secções
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>

            {loading ? <TableLoading /> : <></>}
          </div>
        )}

        <ModalAddSection
          openModal={this.state.openAddModal}
          handleClose={() => this.handleClose('openAddModal')}
          addSection={this.addSection.bind(this)}
          sections={this.state.sections}
          numSections={this.state.sections.length}
        />

        {this.state.openEditModal ? (
          <ModalEditSection
            openModal={this.state.openEditModal}
            handleClose={() => this.handleClose('openEditModal')}
            editSection={this.editSection.bind(this)}
            section={this.state.currentSection}
            sections={this.state.sections}
          />
        ) : (
          <></>
        )}

        {this.state.openSortModal ? (
          <ModalSortSections
            openModal={this.state.openSortModal}
            handleClose={() => this.handleClose('openSortModal')}
            setLoading={(value) => this.setLoading(value)}
            sections={this.props.school.sections}
          />
        ) : (
          <></>
        )}

        {this.state.openEditTemplateModal ? (
          <ModalEditSectionTemplate
            openModal={this.state.openEditTemplateModal}
            handleClose={() => this.handleClose('openEditTemplateModal')}
            sections={this.props.school.sectionTemplate}
            updateSectionTemplate={this.updateSectionTemplate}
          />
        ) : (
          <></>
        )}

        <div
          ref={(el) => {
            this.lastElement = el;
          }}
          tabIndex="-1"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  school: state.school,
});

const mapActionsToProps = {
  updateSchool: SchoolActions.updateSchool,
};

export default connect(mapStateToProps, mapActionsToProps)(SectionsManagement);
