import React from 'react';
import { Grid } from '@mui/material';
import HeadlightItem from './HeadlightItem';

export default function Layout4({ headlight, layout, school, isMobile }) {
  const props = {
    layout: layout,
    school: school
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <HeadlightItem news={headlight?.news1} size="big" isMobile={isMobile} {...props} />
      </Grid>

      <Grid item container spacing={3} xs={12} sm={12} md={6}>
        <Grid item xs={12} sm={12} md={12}>
          <HeadlightItem news={headlight?.news2} size="small"  {...props}  />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <HeadlightItem news={headlight?.news3} size="small" {...props} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <HeadlightItem news={headlight?.news4} size="small" {...props} />
        </Grid>
      </Grid>

    </Grid>
  );
}