import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const sendConfirmationEmail = (email) => {
  const data = {
    email: email
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.EMAILS}`,
    data,
    true
  );
}

export const sendEmailPassword = (email) => {
  const data = {
    email: email
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.EMAILPASSWORD}`,
    data,
    true
  );
}