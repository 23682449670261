import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const uploadImage = (imgData) => {
  const formData = new FormData();
  formData.append('files', imgData);

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.UPLOAD}`,
    formData,
    false,
    true
  );
}

export const deleteImage = (imgId) => {
  return fetchRequest(
    HTTPMethod.DELETE,
    `${apiInfo.base}/${Resource.UPLOAD}/files/${imgId}`
  );
}