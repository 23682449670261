import React, { useState, useEffect } from 'react';
import { Form, Container, Col, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Header from '../../components/newsEditor/Header';
import MetaTags from '../../components/metatags/MetaTags';
import TableLoading from '../../components/tableloading/TableLoading';
import TabsContainer from '../../components/newsEditor/TabsContainer';
import Loading from '../../components/loading/Loading';
import FormBody from '../../components/newsEditor/FormBody';
import FormEditorialVisibility from './EditorialVisibility';
import ImageUpload from '../../components/newsEditor/ImageUpload';
import store from '../../redux/store';
import { TextEditorActions, TextEditorValidationActions } from '../../redux/actions';
import { ToastError, ToastSuccess } from '../../models/enumsNewsEditor';
import { deleteAttachments, uploadAttachments } from '../../utils/func';
import { saveEditorial } from '../../api/editorial';
import { SchoolActions } from '../../redux/actions';

import './Editorial.css';

function Editorial() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [editorial, setEditorial] = useState(null);
  const [visibility, setVisibility] = useState(false);
  // const [imageVerif, setImageVerif] = useState(false);
  // const [bodyVerif, setBodyVerif] = useState('');
  // const [allCropped, setAllCropped] = useState(false);
  // const [croppedVerif, setCroppedVerif] = useState(false);

  const school = useSelector((state) => state?.school);
  const menu = useSelector((state) => state.menu);

  useEffect(() => {
    if (!school || !loading) {
      return;
    }

    const fetchData = async () => {
      dispatch(TextEditorValidationActions.cleanTextEditorValidation());

      dispatch(TextEditorActions.updateKey('relatedNews', []));
      dispatch(TextEditorActions.updateTextEditor(school?.editorial));
      dispatch(TextEditorActions.updateKey('initialAttachments', school?.editorial.attachments));

      setLoading(false);
    };

    if (!school.editorial) {
      return toast(ToastError.CREATE_ERROR_EDITORIAL, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }
    fetchData();
    setEditorial(school.editorial);
    setVisibility(school.editorial?.visibility);
  }, [school]);

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    
    setTableLoading(true);
    const reduxState = store.getState();

    const initialAttachments = reduxState?.textEditor.initialAttachments;
    const attachments = reduxState?.textEditor.attachments;

    const attachmentsRes = await uploadAttachments(attachments);

    // update initial attachments after update
    dispatch(TextEditorActions.updateKey('initialAttachments', attachmentsRes));

    const attachmentsIds = attachmentsRes.map(attachment => attachment.id);
    const data = {
      body: reduxState?.textEditor.body,
      bodyObject: reduxState?.textEditor.bodyObject,
      attachments: attachmentsIds,
      visibility: visibility,
    };

    let editorialRes;
    try {
      editorialRes = await saveEditorial(editorial.id, data);
    } catch (err) {
      console.error(err);
      setTableLoading(false);
      return toast(ToastError.CREATE_ERROR_EDITORIAL, {
        className: 'toastStyleDanger',
        toastId: 'create_error_editorial',
        autoClose: 2000,
      });
    }

    try {
      const initialAttachmentsIds = initialAttachments.map((el) => el.id);
      await deleteAttachments(initialAttachmentsIds);
    } catch (err) {
      console.log(err);
    }

    setTableLoading(false);
    toast(ToastSuccess.UPDATE_SUCCESS_EDITORIAL, {
      className: 'toastStyleSuccess',
      toastId: 'update_success_editorial',
      autoClose: 2000,
    });

    let newSchool = { ...school };
    newSchool.editorial = editorialRes;
    dispatch(SchoolActions.updateSchool(newSchool));
  };

  const loadingClass = tableLoading ? 'newsForm pageLoading' : 'newsForm';

  return (
    <div className="editorial-page">
      <MetaTags title="Ficha Técnica | Projeto TRUE" />

      <DashHeader title="Ficha Técnica" menu={menu} />
      <Header name={'help_editorial'} />

      {loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <Row className="padding-row">
            <Col xs={8} className="padding-form">
              <Form className={loadingClass} onSubmit={handleSubmitForm}>
                <FormBody editorial={true} />

                <div className="form-spacing" />

                <ImageUpload />

                <FormEditorialVisibility
                  visibility={visibility}
                  setVisibility={setVisibility}
                />

                <Row className="btn-container">
                  <Col className="text-end">
                    <Button
                      variant="primary"
                      className="btnSubmitNews ms-4 px-5"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>

                {tableLoading ? <TableLoading />: <></>}
              </Form>
            </Col>

            <Col
              xs={4}
              className={
                menu === 'collapsed'
                  ? 'section-tab tabsDefault'
                  : 'section-tab tabsMenuOpen'
              }
            >
              <TabsContainer corrections={true} search={false} related={false} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Editorial;
