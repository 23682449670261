import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TextEditor from './TextEditor';
import { TextEditorType } from '../../models/enumsNewsEditor';
import InfoIcon from '../../assets/img/icons/info.svg';
import DangerIcon from '../../assets/img/icons/alertTriangle.svg';
import { useSelector } from 'react-redux';

function FormBody({ editorial }) {
  const modules = {
    toolbar: [['bold', 'italic', 'link']],
  };

  let bodyVerif = useSelector((state) => state?.textEditorValidation).body;
  let finalVerif = useSelector((state) => state?.textEditorValidation).finalValidation;


  return (
    <div>
      <div className="row form-bottom-margin">
        <Form.Group controlId="formGridBody">
          <Form.Label className="font-title label-bottom-margin">
            {editorial ? 'Corpo da ficha técnica' : 'Corpo da notícia'}
          </Form.Label>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip" className="tooltip">
                {editorial
                  ? 'Escreve o conteúdo da ficha técnica do jornal. Podes referir qual é o propósito do jornal e falar sobre os responsáveis do mesmo.'
                  : 'Escreve o conteúdo da tua notícia. Normalmente no 1º parágrafo responde-se às perguntas: quem, o quê, onde e quando.  Nos parágrafos seguintes esclarece-se o “como” e o “porquê” dos acontecimentos.'}
              </Tooltip>
            }
          >
            <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
          </OverlayTrigger>

          <TextEditor
            quillModules={modules}
            id="text-editor-body"
            type={TextEditorType.Body}
            minHeight={200}
            className="verif-danger"
          />
        </Form.Group>
      </div>
      {(bodyVerif===false || (bodyVerif===null && finalVerif===false)) && (
        <div className="errorVerifContainer">
          <img src={DangerIcon} className="errorVerifIcon" />
          <p className="errorVerifMessage">
            Por favor, escreve o texto para a tua notícia.
          </p>
        </div>
      )}
    </div>
  );
}
export default FormBody;
