import { Col, Form, OverlayTrigger, Tooltip, Row } from 'react-bootstrap';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import { Roles } from '../../models/enum';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InfoIcon from '../../assets/img/icons/info.svg';

function FormNewsState() {
  const dispatch = useDispatch();
  let value = useSelector((state) => state?.textEditor.status);
  const user = useSelector((state) => state?.user);

  const onChangeHandle = (val) => {
    dispatch(TextEditorActions.updateKey('status', val));

    if (value !== 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('finalValidation', null));
    }
  };

  return (
    <Row>
      <Form.Group
        as={Col}
        controlId="formGridState"
        className="form-bottom-margin col-6"
      >
        <Form.Label className="font-title label-bottom-margin">
          Estado
        </Form.Label>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              Seleciona “Em curso” quando ainda estás a escrever a tua notícia.
              Quando terminares muda o estado “Para revisão” para que o editor
              possa rever a tua notícia.
            </Tooltip>
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
        </OverlayTrigger>

        <Form.Select
          required
          aria-label="SelectStateForm"
          onChange={(event) => onChangeHandle(event.target.value)}
          value={value}
        >
          <option className="optionSelect" value="Em curso">
            Em curso
          </option>

          <option className="optionSelect" value="Para Revisão">
            Para revisão
          </option>

          {user?.role?.name === Roles.Admin ||
          user?.role?.name === Roles.Editor ? (
            <>
              <option className="optionSelect" value="Revista">
                Revista
              </option>

              <option className="optionSelect" value="Publicada">
                Publicada
              </option>
            </>
          ) : (
            <></>
          )}
        </Form.Select>
      </Form.Group>
    </Row>
  );
}

// export default FormNewsState;
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(FormNewsState));
