import React from 'react';
import { Grid } from '@mui/material';

import NewsItemInfo from '../newsitem/NewsItemInfo';
import NewsItemPicture from '../newsitem/NewsItemPicture';

export default function HeadlightItem({ news, layout, size, school, isMobile }) {
  const mainColor = school?.palette?.color.main;
  const journalName = school?.subdirectory;

  if (!news) { return <></>; }

  const picture = <NewsItemPicture
    journalName={journalName}
    news={news}
    layout={layout}
    size={size}
    isMobile={isMobile}
  />;

  const itemInfo = <NewsItemInfo
    titleClass={`headlightTitle l${layout} ${size}`}
    journalName={journalName}
    news={news}
    color={mainColor}
  />;

  if ((layout === 3 && size === 'big' && isMobile === false) ||
        (layout === 4 && size === 'small')) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={layout === 3 ? 5 : 7} sm={layout === 3 ? 5 : 7} md={layout === 3 ? 5 : 7}>
          {itemInfo}
        </Grid>

        <Grid item xs={layout === 3 ? 7 : 5} sm={layout === 3 ? 7 : 5} md={layout === 3 ? 7 : 5}>
          {picture}
        </Grid>
      </Grid>
    );
  }

  return (
    <div className="frontendNewsItem">
      {picture}

      {itemInfo}
    </div>
  );
}