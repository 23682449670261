import React from 'react';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ArticlePage from '../frontend/articlepage/ArticlePage';

export default class PreVizNews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: this.props.news,
      open: this.props.open,
    };
  }

  render() {
    const { news } = this.state;
    const school = news?.school;
    //const newsTitle = extractContent(news.title);

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.hdlClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflow: 'scroll',
          }}
          disableScrollLock={true}
        >
          <div
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '5%',
              width: '85%',
              transform: 'translate(0%, 0%)',
              backgroundColor: 'white',
              border: '15px solid #FFFFFF',
              borderRadius: '4px',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <IconButton
                sx={{ zIndex: '1', marginLeft: 'auto' }}
                onClick={this.props.hdlClose}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <ArticlePage school={school} idNews={news?.id} previz={true} />
            <div style={{ backgroundColor: 'white', height: '40px' }} />
          </div>
        </Modal>
      </div>
    );
  }
}
