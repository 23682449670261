function Mascote({ img, text }) {
  return (
    <div className="px-0">
      <img className="mascote d-block mx-auto" src={img} alt="Mascote" />
      <div className="mascoteText m-0 text-center pt-2">{text}</div>
    </div>
  );
}

export default Mascote;
