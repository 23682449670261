import React from 'react';
import { Grid } from '@mui/material';

import Modal from '../../templates/modal/Modal';
import TextInput from '../../components/dashboard/textinput/TextInput';
import TableLoading from '../../components/tableloading/TableLoading';

//Icons
import SectionIcon from '../../assets/img/sideMenuIcons/sections.svg';

export default function ModalSectionForm(props) {
  const { loading, sectionName } = props;

  const isValid = () => {
    return sectionName ? true : false;
  };

  const loadingClass = loading ? 'modalBodyContainer pageLoading' : 'modalBodyContainer';

  return (
    <Modal
      openModal={props.openModal}
      modalHeader
      modalIcon={<img src={SectionIcon} alt="Secção" />}
      modalTitle={props.modalTitle}
    >
      <div className={loadingClass}>
        <Grid container>
          <Grid className="attributeContainer" item xs={12}>
            <TextInput
              className="custom-user-input"
              type="text"
              label="Nome da secção"
              name="sname"
              required={true}
              value={sectionName}
              placeholder="ex: Política"
              setInputValue={(e) => props.setSectionName(e.target.value)}
              maxLength="20"
            />
          </Grid>
        </Grid>

        <div className="btnContainer">
          <div className="attributeContainer">
            <button className="secondaryBtnBlue" onClick={props.handleClose}>Cancelar</button>
          </div>

          <div className="attributeContainer">
            {isValid() ?
              <button className="mainBtn" onClick={props.handleSubmit}>{props.submitBtnName}</button> :
              <button className="mainBtn" disabled={true}>{props.submitBtnName}</button>
            }
          </div>
        </div>

        {loading ? <TableLoading /> : <></>}
      </div>
    </Modal>
  );
}