import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//Icons
import black from '../../../assets/img/state/black.svg';
import signOut from '../../../assets/img/sideMenuIcons/signOut.svg';
import './dashheader.css';
import cloudCheck from '../../../assets/img/header/CloudCheck.svg';

const DashHeader = (props) => {
  const logout = () => {
    localStorage.removeItem('userToken');
    window.location.reload();
  };

  const lastSaved = useSelector((state) => state.textEditor?.lastSaved);
  const [showSaved, setShowSaved] = useState(false);

  useEffect(() => {
    setShowSaved(false);

    if (lastSaved instanceof Date && !isNaN(lastSaved.getTime())) {
      setShowSaved(true);

      const timer = setTimeout(() => {
        setShowSaved(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [lastSaved]);

  const subSectionClass = props.subtitle ? 'pageSectionName' : 'pageTitleName';

  const subtitle = () => {
    if (
      props.subtitle === 'Criar Notícia' ||
      props.subtitle === 'Editar Notícia' ||
      props.title === 'Ficha Técnica'
    ) {
      if (props.menu === 'collapsed') {
        return 'dashHeaderContainer dashHeaderClosed';
      } else {
        return 'dashHeaderContainer dashHeaderOpen';
      }
    } else {
      return 'dashHeaderContainer';
    }
  };

  return (
    <div className={subtitle()}>
      <div className="dashHeader">
        <div className="titleContainer">
          <p className={subSectionClass}>{props.title}</p>

          {props.subtitle ? (
            <p className="pageSubTitleContainer">
              <img src={black} alt="Ponto preto" className="blackDot" />
              <span className="pageSubSectionName">{props.subtitle}</span>
            </p>
          ) : (
            <></>
          )}

          {showSaved &&
            (props.subtitle === 'Criar Notícia' ||
              props.subtitle === 'Editar Notícia') && (
              <p className={'pageSectionName'}>
                <img src={cloudCheck} alt="Ponto preto" className="cloudCheck" />
                <span className="savedText">{'Guardado'}</span>
              </p>
            )}
        </div>

        {props.hideSignOut ? (
          <></>
        ) : (
          <button type="button" className="signOutContainer" onClick={() => logout()}>
            <img src={signOut} alt="Sign Out" />
            <p className="signOut">Terminar Sessão</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default DashHeader;
