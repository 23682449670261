import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import FormTitle from './FormTitle';
import FormBody from './FormBody';
import ImageUpload from './ImageUpload';
import FormRelatedNews from './FormRelatedNews';
import FormAuthor from './FormAuthor';
import FormNewsSection from './FormNewsSection';
import FormNewsState from './FormNewsState';
import FormVideo from './FormVideo';
import TableLoading from '../tableloading/TableLoading';
import ModalSaveResult from './ModalSaveResult';
import store from '../../redux/store';
import { createNews, updateNews } from '../../api/news';
import { checkPlagirism } from '../../api/correction';
import { deleteAttachments, uploadAttachments } from '../../utils/func';
import { ModalConfirmState } from './ModalConfirmState';
import {
  SchoolActions,
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';

import 'bootstrap/dist/css/bootstrap.min.css';

function FormNews({ sections, plagerism }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);
  const newsID = useSelector((state) => state?.textEditor.id);
  const captionVerif = useSelector(
    (state) => state?.textEditorValidation.caption,
  );
  let validateCaptions;

  const textEditor = useSelector((state) => state?.textEditor);
  const saveRef = useRef(null);
  const [modalResult, setModalResult] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  // useEffect(() => {
  //   if (newsID && plagerism?.length > 0) {
  //     // console.log('show plag');
  //     setModalResult(
  //       <ModalPlagiarism
  //         plagerismResult={plagerism}
  //         closeModal={closeModal}
  //       ></ModalPlagiarism>,
  //     );
  //   }
  // }, []);

  // autosave every minute
  useEffect(() => {
    const interval = setInterval(() => {
      submitForm(true);
    }, 60000);

    return () => clearInterval(interval);
  }, [newsID]);

  useEffect(() => {
    setTableLoading(textEditor?.tableLoading);
  }, [textEditor]);

  useEffect(() => {
    dispatch(TextEditorActions.updateKey('saveRef', saveRef));
  }, [saveRef]);

  const checkFormValidation = (textEditorValidation) => {
    if (
      textEditorValidation.title &&
      textEditorValidation.section &&
      textEditorValidation.author &&
      textEditorValidation.body &&
      textEditorValidation.attachments &&
      validateCaptions &&
      (textEditorValidation.video || textEditorValidation?.video === null)
    ) {
      return true;
    } else {
      dispatch(TextEditorValidationActions.updateKey('finalValidation', false));
      return false;
    }
  };

  const handleSubmitForm = async () => {
    const reduxState = store.getState();
    const status = reduxState.textEditor.status;
    const initialStatus = reduxState.textEditor.initialStatus;

    reduxState?.textEditorValidation.caption.forEach((caption) => {
      if (caption.caption !== true) {
        validateCaptions = false;
      }
    });

    if (validateCaptions !== false) {
      validateCaptions = true;
    }

    if (initialStatus !== status) {
      setModalResult(
        <ModalConfirmState
          status={status}
          closeModal={closeModal}
          submitForm={submitForm}
        ></ModalConfirmState>,
      );
    } else {
      submitForm(false);
    }
  };

  const submitForm = async (autoSave) => {
    const reduxState = store.getState();
    let isValid = false;

    if (
      (!autoSave &&
        reduxState.textEditor.status !== 'Em curso' &&
        reduxState.user.role.name === 'Autor') ||
      (!autoSave &&
        reduxState.user.role.name !== 'Autor' &&
        (reduxState.textEditor.status === 'Revista' ||
          reduxState.textEditor.status === 'Publicada'))
    ) {
      dispatch(TextEditorActions.updateKey('forceCrop', true));
    }

    if (!autoSave) {
      dispatch(TextEditorActions.updateKey('tableLoading', true));
    }

    if (modalResult != []) {
      setModalResult([]);
    }

    if (
      (autoSave &&
        (reduxState.textEditor.status === 'Em curso' ||
          reduxState.textEditor.status === 'Para Revisão')) ||
      reduxState.textEditor.status === 'Em curso'
    ) {
      isValid = true;
    } else {
      isValid = checkFormValidation(reduxState?.textEditorValidation);
    }

    if (isValid) {
      const initialAttachments = reduxState?.textEditor.initialAttachments;
      const attachments = reduxState?.textEditor.attachments;

      const res = await uploadAttachments(attachments);

      // update initial attachments after update
      dispatch(TextEditorActions.updateKey('initialAttachments', res));

      const attachmentsIds = res.map((attachment) => attachment.id);

      let submit = {
        title: reduxState?.textEditor.title,
        titleObject: reduxState?.textEditor.titleObject,
        body: reduxState?.textEditor.body,
        bodyObject: reduxState?.textEditor.bodyObject,
        bodyRaw: reduxState?.textEditor.bodyRaw,
        author: reduxState?.textEditor.author,
        section: reduxState?.textEditor.section,
        videoUrl: reduxState?.textEditor.videoUrl,
        school: reduxState?.school.id,
        relatedNews: reduxState?.textEditor.relatedNews,
        attachments: attachmentsIds,
      };

      // only save status if its a manualsave
      if (!autoSave || reduxState?.textEditor.status === 'Em curso') {
        submit.status = reduxState?.textEditor.status;
      }

      if (newsID) {
        await handleUpdateNews(submit, autoSave);
      } else {
        // Add user id on news creation only, otherwise user will always be updated
        submit = { ...submit, user: user?.id };
        await handleCreateNews(submit, autoSave);
      }

      if (initialAttachments.length > 0) {
        try {
          const initialAttachmentsIds = initialAttachments.map((el) => el.id);
          await deleteAttachments(initialAttachmentsIds);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      if (modalResult != []) {
        setModalResult([]);
      }
      const swiperRef = reduxState.textEditor?.swiperRef;

      const captionIndex = captionVerif.findIndex(
        (caption) => caption.caption === false,
      );

      setTimeout(() => {
        const element = document.querySelector('.errorVerifContainer');
        const elementPosition = element?.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 300;

        if (swiperRef && captionIndex != -1) {
          swiperRef.slideTo(captionIndex);
        }
        if (element) {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 100);
    }

    dispatch(TextEditorActions.updateKey('forceCrop', false));
    if (!autoSave) {
      dispatch(TextEditorActions.updateKey('tableLoading', false));
    }
  };

  const handleCreateNews = async (data, autoSave) => {
    let res;

    try {
      res = await createNews(data);

      dispatch(TextEditorActions.updateKey('id', res.id));

      if (!autoSave) {
        setModalResult(
          <ModalSaveResult
            sucess={true}
            update={false}
            newsID={res.id}
            closeModal={closeModal}
          />,
        );

        fetchCheckPlagirism(res.id, data.bodyRaw);
      }
    } catch (err) {
      setModalResult(
        <ModalSaveResult
          sucess={false}
          update={false}
          closeModal={closeModal}
        />,
      );
    }

    const date = new Date();
    dispatch(TextEditorActions.updateKey('lastSaved', date));
  };

  const handleUpdateNews = async (data, autoSave) => {
    let res;

    try {
      res = await updateNews(data, newsID);

      if (!autoSave) {
        setModalResult(
          <ModalSaveResult
            sucess={true}
            update={true}
            closeModal={closeModal}
          />,
        );

        if (res?.school) {
          dispatch(SchoolActions.updateSchool(res?.school));
        }

        fetchCheckPlagirism(res?.news?.id, data.bodyRaw);
      }
    } catch (err) {
      console.error('[news] Update news error:', err);
      setModalResult(
        <ModalSaveResult
          sucess={false}
          update={true}
          closeModal={closeModal}
        />,
      );
    }

    const date = new Date();
    dispatch(TextEditorActions.updateKey('lastSaved', date));
    dispatch(TextEditorActions.updateKey('forceCrop', false));
  };

  const closeModal = () => {
    setModalResult([]);
  };

  const fetchCheckPlagirism = async (newsID, text) => {
    const formData = new FormData();
    formData.append('newsID', newsID);
    formData.append('text', text);
    formData.append('token', localStorage.getItem('userToken'));

    try {
      await checkPlagirism(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const loadingClass = tableLoading ? 'newsForm pageLoading' : 'newsForm';

  return (
    <Form id="my-form" className={loadingClass}>
      <FormTitle />

      <Row id="section_State">
        <FormNewsSection sections={sections}></FormNewsSection>

        <FormAuthor />
      </Row>

      <FormBody />

      <FormRelatedNews />

      <ImageUpload />

      <FormVideo />

      <FormNewsState />

      <Row id="btn_set">
        <Col className="text-end">
          <Link
            variant="secondary"
            className="btnCancelNews px-5"
            to="dashboard/news"
          >
            Cancelar
          </Link>

          <Button
            variant="primary"
            className="btnSubmitNews ms-4 px-5"
            onClick={handleSubmitForm}
            ref={saveRef}
          >
            Guardar
          </Button>
        </Col>
      </Row>

      {modalResult}

      {tableLoading ? <TableLoading /> : <></>}
    </Form>
  );
}

export default FormNews;
