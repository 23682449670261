import React from 'react';
import { Grid } from '@mui/material';

import NewsItemInfo from '../newsitem/NewsItemInfo';
import NewsItemPicture from '../newsitem/NewsItemPicture';

export default function SlotItem({ news, journalName, color }) {
  if (!news) { return <></>; }

  return (
    <Grid container spacing={2}>
      <Grid item xs={7} sm={7}>
        <NewsItemInfo
          titleClass="slotItemTitle"
          journalName={journalName}
          news={news}
          color={color}
        />
      </Grid>

      <Grid item xs={5} sm={5}>
        <NewsItemPicture
          journalName={journalName}
          news={news}
          size='slot'
        />
      </Grid>
    </Grid>
  )
}