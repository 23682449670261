import React from 'react';
import TrueCheckbox from '../../components/dashboard/checkbox/Checkbox';

export default function Filter(props) {
  return (
    <div className="setOptionsWrapper">
      <div className="setOptions">
        {props.filterOptions.map(option => (
          <div className="checkboxContainer" key={option}>
            <TrueCheckbox
              value={option}
              checked={props.selectedValues?.includes(option)}
              onChange={e => props.changeHandlerCheckbox(e, props.filterType)}
              name={option}
              size="small"
            />
          </div>
        ))}

        {props.filterOptions.length === 0 ?
          <div className="noResults">
            Sem opções disponíveis
          </div>
          : <></>}

        <div className="filterButtons">
          <button
            className="cleanFilterButton"
            onClick={props.cleanFilter}
          >
            Limpar
          </button>
        </div>
      </div>
    </div>
  );
}