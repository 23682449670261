import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../redux/actions';
import InfoIcon from '../../assets/img/icons/info.svg';
import DangerIcon from '../../assets/img/icons/alertTriangle.svg';
import store from '../../redux/store';

function FormNewsSection({ sections = [] }) {
  const dispatch = useDispatch();
  let value = useSelector((state) => state?.textEditor.section);
  let sectionVerif = useSelector(
    (state) => state?.textEditorValidation.section,
  );
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );

  const options = [];

  useEffect(() => {
    if (value) {
      dispatch(TextEditorValidationActions.updateKey('section', true));
    }
  }, []);

  const onChangeHandle = (val) => {
    if (val !== '') {
      dispatch(TextEditorActions.updateKey('section', val));
    } else {
      dispatch(TextEditorActions.updateKey('section', null));
    }

    const status = store.getState().textEditor.status;

    if (val !== '' && !sectionVerif) {
      dispatch(TextEditorValidationActions.updateKey('section', true));
    } else if (val === '' && status !== 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('section', false));
    } else if (val === '' && status === 'Em curso') {
      dispatch(TextEditorValidationActions.updateKey('section', null));
    }
  };

  if (sections !== []) {
    options.push(
      <option key="0" value="" className="defaultOption">
        Escolher secção
      </option>,
    );

    sections.forEach((e) => {
      options.push(
        <option key={e.id} id={e.id} value={e.id}>
          {e.sname}
        </option>,
      );
    });
  }

  return (
    <Form.Group as={Col} controlId="formGridSection">
      <Form.Label className="font-title label-bottom-margin">Secção</Form.Label>

      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip" className="tooltip">
            Escolhe o tema que se enquadra melhor com a tua notícia.
          </Tooltip>
        }
      >
        <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
      </OverlayTrigger>

      <Form.Select
        aria-label="SelectSectionForm"
        onChange={(event) => onChangeHandle(event.target.value)}
        value={value?.id}
      >
        {options}
      </Form.Select>

      {(sectionVerif === false ||
        (sectionVerif === null && finalVerif === false)) && (
        <div className="errorVerifContainer mt-2">
          <img src={DangerIcon} className="errorVerifIcon" />
          <p className="errorVerifMessage">
            Por favor, escolhe uma secção para a tua notícia.
          </p>
        </div>
      )}
    </Form.Group>
  );
}

export default FormNewsSection;
