export const TextEditorType = {
  Title: 'Title',
  Body: 'Body',
};

export const ToastError = {
  GENERAL_ERROR: 'Ocorreu um erro. Tente novamente mais tarde.',
  CREATE_NEWS: 'Ocorreu um erro a criar a notícia',
  UPDATE_NEWS: 'Ocorreu um erro a editar a notícia',
  CREATE_ERROR_EDITORIAL: 'Ocorreu um erro a guardar a ficha técnica'
};

export const ToastSuccess = {
  GENERAL_SUCCESS: 'Operação realizada com sucesso.',
  CREATE_NEWS: 'Notícia criada com sucesso',
  UPDATE_NEWS: 'Notícia editada com sucesso',
  UPDATE_SUCCESS_EDITORIAL: 'Ficha técnica guardada com sucesso'
};

export const ModalMessage = {
  CREATE_SUCCESS_HEADER: 'Notícia criada com sucesso',
  CREATE_SUCCESS_BODY: 'Podes continuar a editar ou voltar à lista de notícias.',
  UPDATE_SUCCESS_HEADER: 'Notícia editada com sucesso',
  UPDATE_SUCCESS_BODY: 'Podes continuar a editar ou voltar à lista de notícias.',
  CREATE_ERROR_HEADER: 'Ocorreu um erro a guardar a notícia',
  CREATE_ERROR_BODY:
    'Por favor verifica se todos os campos estão preenchidos corretamente.',
  PLAGERISM_HEADER: 'Aviso de deteção de plágio',
  PLAGERISM_BODY: 'Detetei contéudo nas seguintes fontes:',
};

export const ModalCalendarMsg = {
  ALL: 'Todas',
  LAST_24_HOURS: 'Últimas 24 horas',
  LAST_7_DAYS: 'Últimos 7 dias',
  LAST_30_DAYS: 'Últimos 30 dias',
};
