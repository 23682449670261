import React, { Component } from 'react';
import { toast } from 'react-toastify';

import TableLoading from '../../components/tableloading/TableLoading';
import Modal from '../../templates/modal/Modal';
import ModalUserForm from './ModalUserForm';
import { updateUserInfo } from '../../api/users';
import { getStatusInfo } from '../../utils/utils';
import { USER_STATUS } from '../../utils/globals';
import { LoginError, ToastError, ToastSuccess } from '../../models/enum';

//Icons
import PersonIcon from '../../assets/img/sideMenuIcons/person.svg';

export default class ModalEditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.usr.name,
      surname: this.props.usr.surname,
      email: this.props.usr.email,
      role: this.props.usr.role?._id,
      status: this.getStatusName(this.props.usr.confirmed, this.props.usr.blocked),
      loading: false,
      lastClickedElement: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  //Handle modal accessibility
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(event) {
    this.setState({ lastClickedElement: event.target });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  //Closes modal
  handleClose() {
    this.props.handleModalClose(false);
    this.state.lastClickedElement.focus();
  }

  changeHandler = (e) => {
    this.setState({ 
      [e.target.name]: e.target.name === 'email'
        ? e.target.value.trim()
        : e.target.value 
    });
  };

  //guarda alterações do utilizador em causa
  handleEditUser = async (userId) => {
    const { email, name, surname, role, status } = this.state;
    this.setState({ loading: true });

    let confirmed = this.props.usr.confirmed;
    let blocked = this.props.usr.blocked;

    if (status === USER_STATUS.active) {
      confirmed = true;
      blocked = false;
    } else if (status === USER_STATUS.inactive) {
      blocked = true;
    } else if (status === USER_STATUS.in_validation) {
      confirmed = false;
      blocked = false;
    }

    try {
      await updateUserInfo(userId, email, name, surname, role, confirmed, blocked);
    } catch (err) {
      console.error(err);
      const msgcontainer = err.message ? err.message : null;
      const msgresult =
         msgcontainer && msgcontainer.length > 0
            ? msgcontainer[0].messages
            : null;
      const msgId =
         msgresult && msgresult.length > 0 ? msgresult[0].id : null;

      const errorResponse = LoginError[msgId]
         ? LoginError[msgId]
         : ToastError.GENERAL_ERROR;
      
      this.setState({ loading: false });
      
      toast(errorResponse, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
      return;
    }

    toast(ToastSuccess.UPDATED_USER, {
      className: 'toastStyleSuccess',
      toastId: 'updated_user',
      autoClose: 2000,
    });

    this.setState({ loading: false });

    this.props.handleModalClose(false);
    this.props.editUser();
  };

  getStatusName = (confirmed, blocked) => {
    const { status } = getStatusInfo(confirmed, blocked);
    return status;
  }

  isValid = () => {
    const { name, surname, email, role } = this.state;
    return name && surname && email && role;
  };

  render() {
    const { name, surname, email, role, status, loading } = this.state;

    const loadingClass = loading ? 'modalBodyContainer pageLoading' : 'modalBodyContainer';

    return (
      <Modal
        openModal={this.props.isOpen}
        modalHeader
        modalIcon={<img src={PersonIcon} alt="Pessoa" height="23px" />}
        modalTitle="Editar Utilizador"
      >
        <div className={loadingClass}>
          {status === 'Em validação' ? (
            <ModalUserForm
              name={name}
              surname={surname}
              email={email}
              role={role}
              roles={this.props.roles}
              changeHandler={this.changeHandler}
            />
          ) : (
            <ModalUserForm
              name={name}
              surname={surname}
              email={email}
              role={role}
              status={status}
              roles={this.props.roles}
              changeHandler={this.changeHandler}
            />
          )}

          <div className="btnContainer">
            <div className="attributeContainer">
              <button className="secondaryBtn" onClick={this.handleClose}>Cancelar</button>
            </div>
            <div className="attributeContainer">
              {this.isValid() ?
                <button className="mainBtn" onClick={this.handleEditUser.bind(this, this.props.usr.id)}>Guardar</button> :
                <button className="mainBtn" disabled={true}>Guardar</button>
              }
            </div>
          </div>

          {loading ? <TableLoading/> : <></>}
        </div>
        <div ref={(el) => { this.lastElement = el; }} tabIndex="-1" />
      </Modal>
    );

  }
}
