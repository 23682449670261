import React, { useEffect, useState } from 'react';
import useResize from '../../components/utilities/Resize';

export default function HeaderImg(props) {
  const [height, setHeight] = useState(292);
  const { width } = useResize();

  useEffect(() => {
    if (width > 1920) {
      setHeight(270);
    } else if (width < 1920 && width >= 1500) {
      setHeight(292);
    } else if (width < 1500 && width >= 1366) {
      setHeight(320);
    } else if (width < 1366 && width >= 1280) {
      setHeight(350);
    } else if (width < 1280 && width >= 1024) {
      setHeight(375);
    } else if (width < 1024) {
      setHeight(100)
    }
  }, [width]);

  return (
    <div>
      {height === 100 ? (
        <svg
          id="headerImg"
          width="1440"
          height={height}
          viewBox={`0 0 1440 ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1440" height={height} fill={props.mainColor} />
        </svg>
      ) : (
        <svg
          id="headerImg"
          width="1440"
          height={height}
          viewBox={`0 0 1440 ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1440" height={height} fill={props.mainColor} />

          <g clipPath="url(#clip0_456_166274)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M578.739 461.86C569.434 545.36 513.597 637.992 411.45 639.454C310.03 640.905 254.962 513.405 157.68 467.885C68.4192 426.118 -51.8215 461.951 -114.725 393.245C-181.012 320.843 -174.567 220.278 -129.068 154.686C-87.3782 94.5853 11.1986 87.2451 100.304 83.2554C168.466 80.2034 236.337 103.963 301.979 135.385C363.402 164.788 413.29 204.171 454.533 252.822C511.491 320.011 586.829 389.253 578.739 461.86Z"
              fill={props.secondaryColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1237.24 421.296C1196.47 396.739 1162.01 346.07 1185.44 293.149C1208.7 240.605 1287.36 242.447 1333.82 203.153C1376.45 167.099 1386.47 96.729 1436.73 80.6187C1489.69 63.6417 1539.92 90.7672 1562.91 129.714C1583.97 165.399 1564.41 217.874 1545.37 264.681C1530.8 300.485 1502.51 329.793 1470.79 356.14C1441.12 380.793 1409.04 397.147 1374.23 406.856C1326.17 420.265 1272.69 442.649 1237.24 421.296Z"
              fill={props.secondaryColor}
            />
          </g>

          <defs>
            <clipPath id="clip0_456_166274">
              <rect width="1440" height="400" fill="white" transform="translate(0 72)" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
}
