import React, { Component } from 'react';

import Modal from '../../templates/modal/Modal';

import SectionIcon from '../../assets/img/sideMenuIcons/sections.svg';

import TextLabel from '../../components/dashboard/textinput/TextLabel';

import { Grid } from '@mui/material';

import { ReactComponent as SlotsOne } from '../../assets/img/homepage/slots-1.svg';
import { ReactComponent as SlotsTwo } from '../../assets/img/homepage/slots-2.svg';
import { ReactComponent as SlotsThree } from '../../assets/img/homepage/slots-3.svg';
import { ReactComponent as SlotsFour } from '../../assets/img/homepage/slots-4.svg';

import PreVizSections from './PreVizSections';

import './sections.css';

const SlotsOptions = [
  {
    img: SlotsOne,
    option: 1,
  },
  {
    img: SlotsTwo,
    option: 2,
  },
  {
    img: SlotsThree,
    option: 3,
  },
  {
    img: SlotsFour,
    option: 4,
  },
];

class ModalEditSectionTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: this.props.sections,
      openViz: false,
      sectionValue: [],
    };
    this.selectSection.bind(this);
    // this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen(e) {
    this.setState({
      openViz: true,
      sectionValue: e.target.id,
    });
  }

  handleClose() {
    this.setState({
      openViz: false,
    });
  }

  selectSection(sections) {
    this.setState({
      sections: sections,
    });
  }

  isValid = () => {
    const { sections } = this.state;
    return sections;
  };

  render() {
    return (
      <div>
        <Modal
          openModal={this.props.openModal}
          modalHeader
          modalIcon={<img src={SectionIcon} alt="Secção" />}
          modalTitle={`Selecionar Template Secções`}
        >
          <div className="modalBodyContainer">
            <div className="modalSelectHeader">
              <TextLabel label="Template Secções" />
              <p className="highlightDescription">
                Selecione o layout para as secções do jornal escolar, clicando na imagem
                correspondente.
              </p>
            </div>

            <div className="tableBodyContainer" style={{marginTop: '40px'}} >
              <Grid container spacing={4}>
                {SlotsOptions.map((H) => (
                  <Grid key={H.option} item xs={6}>
                    <div
                      className={`slotItem${
                        this.state.sections === H.option ? ' selected' : ''
                      }`}
                    >
                      <H.img
                        role="button"
                        tabIndex={0}
                        onClick={() => this.selectSection(H.option)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            this.selectSection(H.option);
                          }
                        }}
                      />

                      <div className="headlightBtnContainer">
                        <button
                          id={H.option}
                          className="secondaryBtn"
                          onClick={(e) => this.handleOpen(e)}
                        >
                          Pré-visualizar
                        </button>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          <div className="btnContainer tableButtons">
            <div className="attributeContainer">
              <button
                className="secondaryBtnBlue"
                onClick={() => this.props.handleClose()}
              >
                Cancelar
              </button>
            </div>
            <div className="attributeContainer">
              {this.isValid() ? (
                <button
                  className="mainBtn"
                  onClick={() => this.props.updateSectionTemplate(this.state.sections)}
                >
                  Guardar
                </button>
              ) : (
                <button className="mainBtn" disabled={true}>
                  Guardar
                </button>
              )}
            </div>
          </div>
        </Modal>

        {this.state.openViz === true ? (
          <PreVizSections
            handleClose={this.handleClose}
            sectionValue={this.state.sectionValue}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ModalEditSectionTemplate;
