import estadoNoticia from '../assets/newsEditor/images/help_images/estado.png';
import estilosTexto from '../assets/newsEditor/images/help_images/estilos_texto.png';
import hiperligacao from '../assets/newsEditor/images/help_images/hiperligacao.png';
import palavrasSugeridas from '../assets/newsEditor/images/help_images/palavras_sugeridas.png';
import sugestaoCorrecao from '../assets/newsEditor/images/help_images/sugestao_correcao.png';
import pesquisar from '../assets/newsEditor/images/help_images/pesquisar.png';
import relacionadas from '../assets/newsEditor/images/help_images/relacionadas.png';
import noticiasRelacionadasAdicionadas from '../assets/newsEditor/images/help_images/noticias_relacionadas_adicionadas.png';
import escolherImagens from '../assets/newsEditor/images/help_images/escolher_imagens.png';
import editarImagens from '../assets/newsEditor/images/help_images/editar_imagens.png';
import video from '../assets/newsEditor/images/help_images/video.png';

//onboarding inicial admin
import initialOnboardingDOT from '../assets/newsEditor/images/help_images/initialOnboardingDOT.svg';
import ajudaContextAdmin from '../assets/newsEditor/images/help_images/1_ADM_EDI_ONB.gif';
import colapsarMenuAdmin from '../assets/newsEditor/images/help_images/2_ADM_EDI_ONB.gif';
import gerirUtilizadoresAdmin from '../assets/newsEditor/images/help_images/3_ADM_ONB.gif';
import gerirNoticiasAdmin from '../assets/newsEditor/images/help_images/4_ADM_EDI_ONB.gif';
import reverNoticiasAdmin from '../assets/newsEditor/images/help_images/5_ADM_EDI_ONB.gif';
import publicarNoticiasAdmin from '../assets/newsEditor/images/help_images/6_ADM_EDI_ONB.gif';
import acederJornalAdmin from '../assets/newsEditor/images/help_images/7_ADM_EDI_ONB.gif';
import seccoesAdmin from '../assets/newsEditor/images/help_images/8_ADM_EDI_ONB.gif';
import fichaTecnicaAdmin from '../assets/newsEditor/images/help_images/9_ADM_EDI_ONB.gif';
import mancheteAdmin from '../assets/newsEditor/images/help_images/10_ADM_EDI_ONB.gif';
import caixasAdmin from '../assets/newsEditor/images/help_images/11_ADM_EDI_ONB.gif';
import coresAdmin from '../assets/newsEditor/images/help_images/12_ADM_EDI_ONB.gif';
import identidadeAdmin from '../assets/newsEditor/images/help_images/13_ADM_EDI_ONB.gif';
import reportarAdmin from '../assets/newsEditor/images/help_images/14_ADM_EDI_ONB.gif';
import perfilAdmin from '../assets/newsEditor/images/help_images/15_ADM_EDI_ONB.gif';

//onboarding inicial autor
import ajudaContextAutor from '../assets/newsEditor/images/help_images/1_AUT_ONB.gif';
import colapsarMenuAutor from '../assets/newsEditor/images/help_images/2_AUT_ONB.gif';
import criarNoticiasAutor from '../assets/newsEditor/images/help_images/4_AUT_ONB.gif';
import reverNoticiasAutor from '../assets/newsEditor/images/help_images/5_AUT_ONB.gif';
import acederJornalAutor from '../assets/newsEditor/images/help_images/6_AUT_ONB.gif';

let progressOnboardingAdmin = 6.25;
let progressOnboardingAuthor = 11.111;

let progressNewsAdmin = 0;
let progressUsers = 0;
let progressSections = 0;
let progressHeadlight = 0;

export const Steps = {
  MODAL_HELP_DEFAULT: [
    {
      index: 0,
      img: null,
      text: null,
      header: 'Ajuda — Guia de Utilização',
      progress: 8.33333333333,
    },
    {
      index: 1,
      img: estadoNoticia,
      text: 'Ao selecionares “Em curso” significa que ainda estás a desenvolver a notícia. Quando terminares seleciona “Para revisão”, assim o editor do jornal irá rever a notícia. Após revisão o editor poderá mudar o estado para publicada.',
      header: 'Ajuda — Estado da notícia',
      progress: 16.6666666667,
    },
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Ajuda — Estilos de texto',
      progress: 25,
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Ajuda — Hiperligação',
      progress: 33.3333333333,
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Ajuda — Palavras Sugeridas',
      progress: 41.6666666667,
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Ajuda — Sugestão de Correção',
      progress: 50,
    },
    {
      index: 6,
      img: pesquisar,
      text: 'Neste separador podes pesquisar por notícias credíveis. Para isso, escreve as palavras-chave na caixa de texto. Além disso, consegues filtrar pela data da publicação das notícias.',
      header: 'Ajuda — Pesquisar Notícias',
      progress: 58.3333333333,
    },
    {
      index: 7,
      img: relacionadas,
      text: 'No separador de "P relacionadas" encontras notícias do jornal Público com conteúdo relacionado com o que estás a escrever. Podes adicionar algumas delas para que fiquem associadas à tua notícia.',
      header: 'Ajuda — Notícias relacionadas do jornal Público',
      progress: 66.6666666666,
    },
    {
      index: 8,
      img: noticiasRelacionadasAdicionadas,
      text: 'As notícias do jornal Público que selecionaste anteriormente ficam associadas à tua notícia. Podes apagar clicando no “x”. Clica no texto para teres acesso à notícia indicada. ',
      header: 'Ajuda — Notícias relacionadas inseridas',
      progress: 75,
    },
    {
      index: 9,
      img: escolherImagens,
      text: 'Podes arrastar e soltar uma ou mais imagens na área destacada. Caso prefiras também podes clicar em “carregar imagens” e depois selecionas as imagens pretendidas.',
      header: 'Ajuda — Escolher imagens',
      progress: 83.3333333333,
    },
    {
      index: 10,
      img: editarImagens,
      text: 'Após a seleção de imagens podes recortar cada uma no formato correto. É possível associar legendas às imagens. Também é possível apagar uma imagem que não queiras.',
      header: 'Ajuda — Editar imagens',
      progress: 91.6666666666,
    },
    {
      index: 11,
      img: video,
      text: 'É possível incorporar um vídeo que pode estar numa plataforma como o Youtube, por exemplo. Copia o url dessa plataforma e cola-o no campo indicado.',
      header: 'Ajuda — Incorporar vídeo',
      progress: 100,
    },
  ],
  MODAL_ONBOARDING: [
    {
      index: 0,
      img: initialOnboardingDOT,
      text: 'Apresentamos-lhe algumas dicas e funcionalidades da plataforma TRUE, bastante úteis para a gestão do seu jornal escolar. Este é o DOT, a nossa mascote. Está pronto(a) para começar?',
      header: 'Seja bem-vindo(a) à plataforma TRUE',
      progress: progressOnboardingAdmin,
    },
    {
      index: 1,
      img: ajudaContextAdmin,
      text: 'Estamos sempre disponíveis para ajudar! Se surgir alguma dúvida, encontrará uma opção de ajuda disponível em cada página da plataforma TRUE, bastando para isso clicar no botão localizado no canto inferior direito.',
      header: 'Ajuda contextual em cada página',
      progress: (progressOnboardingAdmin += 6.666),
    },
    {
      index: 2,
      img: colapsarMenuAdmin,
      text: 'Para simplificar a sua experiência de utilização, disponibilizamos, do lado esquerdo do ecrã, um menu que pode ser facilmente escondido. ',
      header: 'Colapsar menu',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 3,
      img: gerirUtilizadoresAdmin,
      text: 'Apenas os administradores da plataforma possuem a capacidade de gerir os utilizadores que fazem parte da mesma, tendo a possibilidade de inserir, editar e remover utilizadores, conforme necessário.',
      header: 'Gerir Utilizadores',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 4,
      img: gerirNoticiasAdmin,
      text: 'Nesta área da plataforma poderá gerir as notícias do seu jornal escolar, tendo a opção de acompanhar as notícias escritas pelos vários autores. Pode editar, apagar ou visualizar cada notícia.',
      header: 'Gerir notícias',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 5,
      img: reverNoticiasAdmin,
      text: `Após o envio “para revisão” da notícia pelo autor, não será possível a este editá-la novamente, a menos que um ADMINISTRADOR ou EDITOR a coloque novamente no estado “em curso”. 
Para ajudá-lo durante a edição da notícia, do lado direito do ecrã, utilizando os separadores disponíveis, é possível:
corrigir erros ortográficos, 
pesquisar notícias relacionadas sobre um determinado tópico
visualizar e adicionar as notícias relacionadas disponíveis no jornal Público.`,
      header: 'Rever notícias',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 6,
      img: publicarNoticiasAdmin,
      text: `Poderá optar por: 
      pedir mais alterações ao AUTOR, mudando o estado da notícia para “em curso”; 
      indicar que a notícia foi “revista”, ou seja, está pronta para ser publicada; 
      ou, ainda, mudar o estado para “publicada”, tornando a notícia visível no jornal escolar.`,
      header: 'Rever e Publicar notícias',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 7,
      img: acederJornalAdmin,
      text: `Pode aceder, a partir de qualquer parte da plataforma ao jornal escolar clicando no botão “ver jornal”, no final do menu lateral. Este é o rosto visível do seu jornal. `,
      header: 'Aceder ao jornal',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 8,
      img: seccoesAdmin,
      text: 'Cada notícia será organizada por secção. Pode editar, ordenar e adicionar mais secções, podendo selecionar, no máximo, cinco para ficarem visíveis no seu jornal. Além disso, é possível modificar a disposição como as notícias aparecem no jornal escolar clicando em “Editar Layout”.',
      header: 'Gerir as secções do jornal',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 9,
      img: fichaTecnicaAdmin,
      text: 'Há uma secção “Sobre nós” presente no jornal que não aparece na gestão de secções. Esta só pode ser editada clicando em “ficha técnica” no menu. Aqui pode-se dar a conhecer o jornal explicando como surgiu, quem colabora e ilustrar com umas imagens. Os autores também têm acesso a esta área e podem editá-la. ',
      header: 'Dar a conhecer os membros da equipa e a história do jornal',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 10,
      img: mancheteAdmin,
      text: 'Pode escolher a composição da manchete do seu jornal e quais as notícias publicadas que vão aparecer em destaque. Além disso, é possível pré-visualizar o resultado antes de efetuar a alteração diretamente no jornal.',
      header: 'Organizar a manchete',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 11,
      img: caixasAdmin,
      text: 'Logo abaixo da manchete, encontra-se a secção "Últimas notícias", que não pode ser modificada. No entanto, as outras secções podem ser personalizadas de acordo com a sua preferência. Pode optar por uma galeria de imagens de uma única notícia ou várias notícias de uma secção específica.',
      header: 'Organizar as caixas',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 12,
      img: coresAdmin,
      text: 'O jornal apresenta quatro combinações de cores pré-definidas, mas é possível personalizar uma delas com a paleta de cores para representa melhor a identidade do seu jornal. Para fazer isso, clique em "Personalizar Paleta".',
      header: 'Personalizar as cores do jornal',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 13,
      img: identidadeAdmin,
      text: 'Na plataforma TRUE, é possível escolher como deseja que o cabeçalho do jornal apareça. Antes de realizar a alteração diretamente no jornal, pode simular as diversas opções clicando em "Pré-visualizar".',
      header: 'Escolher a identidade do jornal',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 14,
      img: reportarAdmin,
      text: 'Aqui pode reportar um problema que encontre na plataforma ou fazer sugestões para melhorias. Preencha o formulário e, se necessário, inclua uma imagem para complementar essa informação. Estamos sempre dispostos a ajudar e a tornar a plataforma ainda melhor para si!',
      header: 'Reportar problemas e sugestões',
      progress: (progressOnboardingAdmin += 6.25),
    },
    {
      index: 15,
      img: perfilAdmin,
      text: 'Aqui é possível editar os seus dados pessoais e alterar a sua palavra-passe.',
      header: 'Editar o perfil',
      progress: (progressOnboardingAdmin += 6.25),
    },
  ],
  MODAL_HELP_NEWS_ADMIN: [
    {
      index: 0,
      img: estadoNoticia,
      text: 'Nesta área da plataforma poderá gerir as notícias do seu jornal escolar, tendo a opção de acompanhar as notícias escritas pelos vários autores. Pode editar, apagar ou visualizar cada notícia.',
      header: 'Gerir Notícias',
      progress: progressNewsAdmin,
    },
    {
      index: 1,
      img: estadoNoticia,
      text: 'Ao selecionares “Em curso” significa que ainda estás a desenvolver a notícia. Quando terminares seleciona “Para revisão”, assim o editor do jornal irá rever a notícia. Após revisão o editor poderá mudar o estado para publicada.',
      header: 'Estados da Notícia',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Visualizar',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Editar',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Apagar Notícias',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Pesquisar Notícias',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 6,
      img: pesquisar,
      text: 'Neste separador podes pesquisar por notícias credíveis. Para isso, escreve as palavras-chave na caixa de texto. Além disso, consegues filtrar pela data da publicação das notícias.',
      header: 'Filtrar Notícias',
      progress: (progressNewsAdmin += 14.286),
    },
    {
      index: 7,
      img: relacionadas,
      text: 'No separador de "P relacionadas" encontras notícias do jornal Público com conteúdo relacionado com o que estás a escrever. Podes adicionar algumas delas para que fiquem associadas à tua notícia.',
      header: 'Criar Nova Notícia',
      progress: (progressNewsAdmin += 14.286),
    },
  ],
  MODAL_HELP_USERS: [
    {
      index: 0,
      img: estadoNoticia,
      text: 'Apenas os administradores da plataforma possuem a capacidade de gerir os utilizadores que fazem parte da mesma, tendo a possibilidade de inserir, editar e remover utilizadores, conforme necessário.',
      header: 'Gerir Utilizadores',
      progress: progressUsers,
    },
    {
      index: 1,
      img: estadoNoticia,
      text: 'Ao selecionares “Em curso” significa que ainda estás a desenvolver a notícia. Quando terminares seleciona “Para revisão”, assim o editor do jornal irá rever a notícia. Após revisão o editor poderá mudar o estado para publicada.',
      header: 'Adicionar Utilizador',
      progress: (progressUsers += 20),
    },
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Coordenador',
      progress: (progressUsers += 20),
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Editar Utilizador',
      progress: (progressUsers += 20),
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Remover Utilizador',
      progress: (progressUsers += 20),
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Filtrar',
      progress: (progressUsers += 20),
    },
  ],
  MODAL_HELP_SECTIONS: [
    {
      index: 0,
      img: estadoNoticia,
      text: 'Cada notícia será organizada por secção. Pode editar, ordenar e adicionar mais secções, podendo selecionar, no máximo, cinco para ficarem visíveis no seu jornal. Além disso, é possível modificar a disposição como as notícias aparecem no jornal escolar clicando em “Editar Layout”.',
      header: 'Gerir as secções do jornal',
      progress: progressSections,
    },
    {
      index: 1,
      img: estadoNoticia,
      text: 'Ao selecionares “Em curso” significa que ainda estás a desenvolver a notícia. Quando terminares seleciona “Para revisão”, assim o editor do jornal irá rever a notícia. Após revisão o editor poderá mudar o estado para publicada.',
      header: 'Adicionar Secção',
      progress: (progressSections += 16.6667),
    },
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Editar Secção',
      progress: (progressSections += 16.6667),
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Selecionar Secção',
      progress: (progressSections += 16.6667),
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Pesquisar Secção',
      progress: (progressSections += 16.6667),
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Ordenar Secções',
      progress: (progressSections += 16.6667),
    },
    {
      index: 6,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Editar Layout',
      progress: (progressSections += 16.6667),
    },
  ],
  MODAL_HELP_HEADLIGHT: [
    {
      index: 0,
      img: estadoNoticia,
      text: 'Pode escolher a composição da manchete do seu jornal e quais as notícias publicadas que vão aparecer em destaque. Além disso, é possível pré-visualizar o resultado antes de efetuar a alteração diretamente no jornal.',
      header: 'Organizar a Manchete',
      progress: progressHeadlight,
    },
    {
      index: 1,
      img: estadoNoticia,
      text: 'Ao selecionares “Em curso” significa que ainda estás a desenvolver a notícia. Quando terminares seleciona “Para revisão”, assim o editor do jornal irá rever a notícia. Após revisão o editor poderá mudar o estado para publicada.',
      header: 'Editar Notícia',
      progress: (progressHeadlight += 16.6667),
    },
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Pré-visualizar',
      progress: (progressHeadlight += 16.6667),
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Editar Layout',
      progress: (progressHeadlight += 16.6667),
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Adicionar Notícias',
      progress: (progressHeadlight += 16.6667),
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Ordenar Notícias',
      progress: (progressHeadlight += 16.6667),
    },
    {
      index: 6,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Guardar Alterações',
      progress: (progressHeadlight += 16.6667),
    },
  ],
};

export const StepsAuthor = {
  MODAL_HELP_NEWS: [
    {
      index: 0,
      img: estadoNoticia,
      text: 'Nesta área da plataforma, poderás gerir as tuas notícias. Poderás editar e apagar notícias que estão “em curso”, ou seja, que não foram enviadas “para revisão” para o editor.',
      header: 'Ajuda — Noticias',
      progress: 8.33333333333,
    },
    Steps.MODAL_HELP_NEWS_ADMIN[1],
    {
      index: 2,
      img: estilosTexto,
      text: 'Ao escreveres a tua notícia pode ser interessante destacares alguma palavra, colocando-a a negrito. Podes clicar em N (negrito). O mesmo se passa para citações ou estrangeirismos, em que podes ter que usar o itálico.',
      header: 'Ajuda — Estilos de texto',
      progress: 25,
    },
    {
      index: 3,
      img: hiperligacao,
      text: 'Pode ser útil haver hiperligações na notícia, para tal, seleciona a parte do texto que desejas que tenha essa interação e cola o endereço de destino.',
      header: 'Ajuda — Hiperligação',
      progress: 33.3333333333,
    },
    {
      index: 4,
      img: palavrasSugeridas,
      text: 'Caso não te estejas a lembrar de uma palavra melhor, podes selecioná-la e ver algumas sugestões. Ao clicar no botão a palavra é substituída.',
      header: 'Ajuda — Palavras Sugeridas',
      progress: 41.6666666667,
    },
    {
      index: 5,
      img: sugestaoCorrecao,
      text: 'Aqui são identificados os erros ortográficos da notícia que estás a escrever e uma possível correção. Para aceitar clica no botão com a sugestão. Para rejeitar clica no botão "x".',
      header: 'Ajuda — Sugestão de Correção',
      progress: 50,
    },
    {
      index: 6,
      img: pesquisar,
      text: 'Neste separador podes pesquisar por notícias credíveis. Para isso, escreve as palavras-chave na caixa de texto. Além disso, consegues filtrar pela data da publicação das notícias.',
      header: 'Ajuda — Pesquisar Notícias',
      progress: 58.3333333333,
    },
    {
      index: 7,
      img: relacionadas,
      text: 'No separador de "P relacionadas" encontras notícias do jornal Público com conteúdo relacionado com o que estás a escrever. Podes adicionar algumas delas para que fiquem associadas à tua notícia.',
      header: 'Ajuda — Notícias relacionadas do jornal Público',
      progress: 66.6666666666,
    },
    {
      index: 8,
      img: noticiasRelacionadasAdicionadas,
      text: 'As notícias do jornal Público que selecionaste anteriormente ficam associadas à tua notícia. Podes apagar clicando no “x”. Clica no texto para teres acesso à notícia indicada. ',
      header: 'Ajuda — Notícias relacionadas inseridas',
      progress: 75,
    },
    {
      index: 9,
      img: escolherImagens,
      text: 'Podes arrastar e soltar uma ou mais imagens na área destacada. Caso prefiras também podes clicar em “carregar imagens” e depois selecionas as imagens pretendidas.',
      header: 'Ajuda — Escolher imagens',
      progress: 83.3333333333,
    },
    {
      index: 10,
      img: editarImagens,
      text: 'Após a seleção de imagens podes recortar cada uma no formato correto. É possível associar legendas às imagens. Também é possível apagar uma imagem que não queiras.',
      header: 'Ajuda — Editar imagens',
      progress: 91.6666666666,
    },
    {
      index: 11,
      img: video,
      text: 'É possível incorporar um vídeo que pode estar numa plataforma como o Youtube, por exemplo. Copia o url dessa plataforma e cola-o no campo indicado.',
      header: 'Ajuda — Incorporar vídeo',
      progress: 100,
    },
  ],
  MODAL_ONBOARDING: [
    {
      index: 0,
      img: initialOnboardingDOT,
      text: 'Este é o DOT, a nossa mascote, que quer dar-te a conhecer a plataforma TRUE. Estás pronto?',
      header: 'Sê bem-vindo(a) à plataforma TRUE',
      progress: progressOnboardingAuthor,
    },
    {
      index: 1,
      img: ajudaContextAutor,
      text: 'Sempre que precisares de ajuda adicional, podes "chamar" o DOT clicando no botão no canto inferior direito. Ele fornecerá informações importantes sobre a página em que te encontras.',
      header: 'Ajuda em cada página',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 2,
      img: colapsarMenuAutor,
      text: 'Do lado esquerdo, tens o menu que pode ser escondido. ',
      header: 'Ajustar largura menu',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 3,
      img: gerirNoticiasAdmin,
      text: 'Nesta área da plataforma, poderás gerir as tuas notícias. Poderás editar e apagar notícias que estão “em curso”, ou seja, que não foram enviadas “para revisão”. ',
      header: 'Gerir notícias',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 4,
      img: criarNoticiasAutor,
      text: `Aqui podes criar a tua notícia. Do lado direito, existem separadores que te ajudam:
      a corrigir erros ortográficos
      pesquisar notícias sobre um determinado tópico
      ver e adicionar as notícias relacionadas disponíveis do jornal Público, a tua notícia.`,
      header: 'Criar notícia',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 5,
      img: reverNoticiasAutor,
      text: `No final, envia a notícia para revisão e depois do editor do jornal rever ela estará pronta para ser publicada no jornal. Para enviar para revisão deves colocar o estado da notícia em “para revisão” e clicar em “Guardar” A qualquer momento podes ver o jornal clicando no botão “ver jornal” que está no fundo do menu ao lado esquerdo.`,
      header: 'Enviar notícia para revisão',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 6,
      img: acederJornalAutor,
      text: 'Podes aceder ao teu jornal clicando no botão “ver jornal” presente final do menu lateral. As tuas notícias publicadas e as de outros autores vão estar aqui presentes. ',
      header: 'Aceder ao jornal',
      progress: (progressOnboardingAuthor += 11.111),
    },
    /* {
      index: 7,
      img: fichaTecnicaAdmin,
      text: 'Os leitores podem saber mais sobre o jornal clicando em “Sobre Nós”. Podes ver e editar essa informação clicando na ficha técnica no lado esquerdo do menu. Aqui, podes dar a conhecer o jornal da tua escola escrevendo: como surgiu, quem colabora e se quiseres, podes complementar essa informação com imagens. ',
      header: 'Sobre Nós',
      progress: (progressOnboardingAuthor += 10),
    }, */
    {
      index: 7,
      img: reportarAdmin,
      text: 'Encontraste um problema na plataforma? ou tens uma sugestão? Podes reportar por aqui, descrevendo o sucedido e se desejares, acrescentando uma imagem exemplificativa. Estamos aqui para te ajudar!',
      header: 'Reportar problemas e sugestões',
      progress: (progressOnboardingAuthor += 11.111),
    },
    {
      index: 8,
      img: perfilAdmin,
      text: 'Aqui é possível editares os teus dados pessoais e mudares a tua palavra-passe.',
      header: 'Editar o perfil',
      progress: (progressOnboardingAuthor += 11.111),
    },
  ],
};
