import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../dropzone/Dropzone';
import { Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Layout from './Layout';
import Preview from './Preview';
import { dataUrlToFile, toDataURL } from '../../../utils/func';
import {
  TextEditorActions,
  TextEditorValidationActions,
} from '../../../redux/actions';

import InfoIcon from '../../../assets/img/icons/info.svg';
import DangerIcon from '../../../assets/img/icons/alertTriangle.svg';
import AddImageBtn from '../icons/AddImageBtn';
import '../../../assets/newsEditor/css/App.css';
import '../dropzone/styles.css';
import { useLocation } from 'react-router-dom';

function ImageUpload({}) {
  const dispatch = useDispatch();
  const location = useLocation().pathname.split('/').slice(-1).toString();
  // initial files provided by the API
  const [initialFiles, setInitialFiles] = useState([]);
  // contains updated files information at all times
  const [files, setFiles] = useState([]);

  const initialAttachments = useSelector(
    (state) => state?.textEditor.initialAttachments,
  );
  const reduxStatus = useSelector((state) => state?.textEditor.status);

  const attachmentsVerif = useSelector(
    (state) => state?.textEditorValidation.attachments,
  );

  const captionVerif = useSelector(
    (state) => state?.textEditorValidation.caption,
  );

  const finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );

  useEffect(() => {
    // cancel promise if component is unmounted while promises are still ongoing
    // see: https://juliangaramendy.dev/blog/use-promise-subscription
    let isSubscribed = true;
    if (!initialAttachments) return;

    (async () => {
      // get the images from api and convert to dataURL as promises
      const initialFilesPromises = initialAttachments.map(async (el) =>
        toDataURL(el.url),
      );

      const initialFilesDataURL = await Promise.all(initialFilesPromises);
      const files = initialFilesDataURL.map((el) => dataUrlToFile(el));

      let initFiles = [];
      // iterate array of files to retrieve captions from API
      for (let i = 0; i < initialAttachments.length; i++) {
        const file = files[i];
        const img = initialAttachments[i];
        const filemetadata = {
          id: img?.id,
          caption: img?.caption,
          cropped: true,
        };

        const fileObj = {
          file: file,
          extra: filemetadata,
        };

        initFiles.push(fileObj);
      }

      // cancel promises if page closes in the meantime
      if (isSubscribed) {
        setInitialFiles(initFiles);

        if (initFiles.length > 0 && attachmentsVerif === null) {
          dispatch(TextEditorValidationActions.updateKey('attachments', true));
        }
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, []);

  // send files to FormNews
  useEffect(() => {
    dispatch(TextEditorActions.updateKey('attachments', files));

    if (files.length > 0) {
      dispatch(TextEditorValidationActions.updateKey('attachments', true));
    }
  }, [files]);

  // remove file from array of files
  const handleRemove = (fileWithMeta) => {
    const index = files.findIndex((f) => f.meta.id === fileWithMeta.meta.id);
    if (index !== -1) {
      const filesArray = [...files];
      let file = filesArray[index];
      URL.revokeObjectURL(file.meta.previewUrl || '');
      file.meta.status = 'removed';

      // update array files
      filesArray.splice(index, 1);
      setFiles(filesArray);

      let removedImageCaption = captionVerif.filter(
        (caption) => caption.id !== file.meta.id,
      );

      dispatch(
        TextEditorValidationActions.updateKey('caption', removedImageCaption),
      );

      // set validation to false if news is NOT in "Em curso" and there are no images left
      if (filesArray?.length === 0 && reduxStatus !== 'Em curso') {
        dispatch(TextEditorValidationActions.updateKey('attachments', false));
        // ignore validation if news is in "Em curso"
      } else if (filesArray?.length === 0 && reduxStatus === 'Em curso') {
        dispatch(TextEditorValidationActions.updateKey('attachments', null));
      }
    }
  };

  // update caption in array of files
  const updateCaption = (caption, fileWithMeta) => {
    setFiles((prevFiles) => {
      const index = prevFiles.findIndex(
        (f) => f.meta.id === fileWithMeta.meta.id,
      );
      if (index !== -1) {
        prevFiles[index].meta.caption = caption;
      }
      return prevFiles;
    });
  };

  // crop file: update file information in array of files
  const cropFile = (url, fileWithMeta) => {
    const filesCopy = [...files];
    let idx = filesCopy.findIndex((f) => f.meta.id === fileWithMeta.meta.id);
    if (idx !== -1) {
      let file = filesCopy[idx];

      const imageFile = dataUrlToFile(url);
      file.meta.previewUrl = url;
      file.meta.cropped = true;
      file.file = imageFile;
    }

    setFiles(filesCopy);
  };

  const CustomInput = () => {
    return (
      <div className="text-center">
        <div className="">Carregar Imagens</div>
        <div className="imageFormats">Apenas PNG, JPG, SVG</div>
      </div>
    );
  };

  const verificationUpload = () => {
    if (
      attachmentsVerif === false ||
      (attachmentsVerif === null && finalVerif === false)
    ) {
      return (
        <div className="errorVerifContainer">
          <img src={DangerIcon} className="errorVerifIcon" alt="danger-icon" />
          <p className="errorVerifMessage">Por favor, adiciona uma imagem.</p>
        </div>
      );
    }
  };

  /* const croppedAlert = () => {
    if (croppedVerif === false) {
      return (
        <div className="errorVerifContainer">
          <img src={DangerIcon} className="errorVerifIcon" alt="danger-icon" />
          <p className="errorVerifMessage">
            Por favor, recorta todas as imagens que inseriste.
          </p>
        </div>
      );
    }
  }; */

  return (
    <Row id="inputNewsAuthor">
      <Form.Group
        as={Col}
        className="col-12 primary input-bottom-margin"
        controlId="formInputImages"
      >
        {location === 'editorial' ? (
          <Form.Label className="font-title label-bottom-margin">
            Escolher imagens (opcional)
          </Form.Label>
        ) : (
          <Form.Label className="font-title label-bottom-margin">
            Escolher imagens
          </Form.Label>
        )}

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              Arrasta uma ou mais imagens para a área destacada. Caso prefiras
              clica em “Carregar Imagens” e seleciona as pretendidas. Podes
              cortar as imagens diretamente, e adicionar também uma legenda com
              um limite de 125 caracteres.
            </Tooltip>
          }
        >
          <img src={InfoIcon} alt="informação" className="mx-2 mb-1" />
        </OverlayTrigger>

        <Dropzone
          PreviewComponent={(props) => (
            <Preview
              {...props}
              handleRemove={handleRemove}
              updateCaption={updateCaption}
              cropFile={cropFile}
            />
          )}
          autoUpload={false}
          LayoutComponent={Layout}
          accept="image/*"
          initialFiles={initialFiles}
          files={files}
          setFiles={setFiles}
          inputContent={<CustomInput key="texto_imagem" />}
          inputWithFilesContent={<AddImageBtn key="adicionar imagem" />}
          styles={{ dropzone: { minHeight: 200, paddingBottom: 10 } }}
        />
      </Form.Group>

      {verificationUpload()}

      {/* {croppedAlert()} */}
    </Row>
  );
}

export default ImageUpload;
