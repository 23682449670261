import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import HeaderImg from './HeaderImg';
import { apiInfo } from '../../api/api';
import { convertStringToUrl } from '../../utils/utils';
import { useState } from 'react';

import { slide as Menu } from 'react-burger-menu';

import './Hamburguer.css';

const useStyles = makeStyles({
  sectionItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 16px',
    color: '#FCFCFC',
    border: '2px solid transparent',
    borderRadius: 8,
    textDecoration: 'none',
    '&.active': {
      color: '#FCFCFC',
      backgroundColor: 'rgba(26, 30, 34, 0.4)',
    },
    '&:hover': {
      color: '#FCFCFC',
      backgroundColor: 'rgba(26, 30, 34, 0.4)',
      opacity: 0.8,
      border: ({ secondaryColor }) => {
        return `2px solid ${secondaryColor}`;
      },
    },
  },
});

export default function MainHeader(props) {
  const { school, mainColor, secondaryColor, sections, headerCollapsed, loading, setHeight, setHeaderHeight } =
    props;

  const classes = useStyles({ secondaryColor });
  const { logo, journalName, schoolName } = school?.journalIdentity;
  const [isMobile, setIsMobile] = useState(false);
  const headerRef = useRef(null);

  const schoolLogoClass =
    logo && !journalName && !schoolName ? 'schoolLogo big' : 'schoolLogo small';

  useEffect(() => {
    if (!headerCollapsed && !loading && setHeaderHeight) {
      setTimeout(() => {
        setHeaderHeight(headerRef?.current.clientHeight);
      }, 300);
    }
  }, [headerCollapsed, loading]);

  useEffect(() => {
    const handleResize = () => {
      const imgHeight = getElemHeight('headerImg');
      if (imgHeight && imgHeight !== 0) {
        setHeight(imgHeight);
      }

      /*const headerHeight = getElemHeight('headerInfo');
      if (headerHeight && headerHeight !== 0) {
        setHeaderHeight(headerHeight);
      }*/

      if (window.innerWidth >= 1024) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    handleResize();
    setHeight(getElemHeight('headerImg'));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setHeight]);

  const getElemHeight = (elemId) => {
    const img = document.getElementById(elemId);
    const dimensions = img?.getBoundingClientRect();
    const height = dimensions?.height;
    return height;
  };

  const styleMenu = {
    bmMenuWrap: { background: `${mainColor}` },
    bmMenu: { background: `${mainColor}` },
    bmMorphShape: { fill: `${mainColor}` },
    bmBurgerBarsHover: { background: `${secondaryColor}` },
  };

  const schoolHeaderClass = `schoolHeaderSubtitle ${logo && schoolName ? ' additionalSpace' : ''}`;
  const transitionMobile = headerCollapsed ? 'max-height 0.2s ease-out' : 'max-height 0.2s ease-in'

  return (
    <div className="mainHeader">
      <div
        id="headerInfo"
        className="mainHeaderInfo"
        style={{
          maxHeight: headerCollapsed ? 0 : (isMobile ? '400px' : '100%'),
          overflow: 'hidden',
          transition: isMobile ? transitionMobile : 'unset'
        }}
        ref={headerRef}
      >
        {props.logoVal === undefined &&
          props.journalNameVal === undefined &&
          props.schoolNameVal === undefined ? (
          <div style={{ textAlign: 'center' }}>
            <NavLink to={`/${school?.subdirectory}`}>
              {logo === true ? (
                <img
                  className={schoolLogoClass}
                  src={`${apiInfo.base}${school?.logo?.url}`}
                  alt="School logo example"
                />
              ) : (
                <></>
              )}
            </NavLink>

            <a
              href={`/${school?.subdirectory}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {journalName === true ? (
                <h2 className="schoolHeaderTitle">{school?.journal}</h2>
              ) : (
                <></>
              )}
            </a>

            <a
              href={`/${school?.subdirectory}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {schoolName === true ? (
                <h4 className={schoolHeaderClass}>{school?.name}</h4>
              ) : (
                <></>
              )}
            </a>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <NavLink to={`/${school?.subdirectory}`}>
              {props.logoVal === true ? (
                <img
                  className={schoolLogoClass}
                  src={props.prevLogo === undefined ? `${apiInfo.base}${props.prevLogoOrigin}` : props.prevLogo.preview}
                  alt="School logo example"
                />
              ) : (
                <></>
              )}
            </NavLink>

            <a
              href={`/${school?.subdirectory}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {props.journalNameVal === true ? (
                <h2 className="schoolHeaderTitle">{props.prevName}</h2>
              ) : (
                <></>
              )}
            </a>

            <a
              href={`/${school?.subdirectory}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {props.schoolNameVal === true ? (
                <h4 className={schoolHeaderClass}>{school?.name}</h4>
              ) : (
                <></>
              )}
            </a>
          </div>
        )}
      </div>

      <div
        className="mainHeaderContent"
        style={{
          height: headerCollapsed
            ? (isMobile ? '72px' : '100px')
            : (isMobile ? '0px' : props.height),
        }}
      >
        {isMobile ? (
          <div className="headerContentSections">
            <Menu styles={styleMenu}>
              {sections.map((section) => (
                <NavLink
                  className={classes.sectionItem}
                  key={section.id}
                  to={`/${school?.subdirectory}/${convertStringToUrl(section?.sname)}`}
                >
                  {section.sname}
                </NavLink>
              ))}

              {school?.editorial?.visibility ? (
                <NavLink
                  className={classes.sectionItem}
                  to={`/${school?.subdirectory}/sobre-nos`}
                >
                  Sobre Nós
                </NavLink>
              ) : (
                <></>
              )}
            </Menu>
          </div>
        ) : (
          <div className="headerContentSections">
            {sections.map((section) => (
              <NavLink
                className={classes.sectionItem}
                key={section.id}
                to={`/${school?.subdirectory}/${convertStringToUrl(section?.sname)}`}
              >
                {section.sname}
              </NavLink>
            ))}

            {school?.editorial?.visibility ? (
              <NavLink
                className={classes.sectionItem}
                to={`/${school?.subdirectory}/sobre-nos`}
              >
                Sobre Nós
              </NavLink>
            ) : (
              <></>
            )}
          </div>
        )}

        {!isMobile && !headerCollapsed ? (
          <HeaderImg
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            headerCollapsed={headerCollapsed}
          />
        ) : (
          <></>
        )}

        <div
          className="headerBkg"
          style={{
            backgroundColor: mainColor,
            height: headerCollapsed
              ? (isMobile ? '100px' : '72px')
              : (isMobile ? '100px' : props.height),
            visibility: headerCollapsed ? 'visible' : (isMobile ? 'visible' : 'hidden'),
          }}
        />
      </div>
    </div>
  );
}
