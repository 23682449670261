import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { makeStyles } from '@mui/styles';

import NewsItemInfo from '../../components/frontend/newsitem/NewsItemInfo';
import { apiInfo } from '../../api/api';
import { extractContent, hex2rgb } from '../../utils/utils';

import './Gallery.css';

const useStyles = makeStyles({
  gallery: {
    '& .image-gallery-left-nav': {
      background: ({ colorRGB }) => {
        return `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.1)`;
      },
    },
    '& .image-gallery-right-nav': {
      background: ({ colorRGB }) => {
        return `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.1)`;
      },
    },
    '& .image-gallery-right-nav .image-gallery-svg': {
      color: ({ color }) => {
        return color;
      },
    },
    '& .image-gallery-left-nav .image-gallery-svg': {
      color: ({ color }) => {
        return color;
      },
    },
    '& .image-gallery-bullets .image-gallery-bullet': {
      background: ({ color }) => {
        return `${color} !important`;
      },
    },
    '& .image-gallery-icon .image-gallery-svg': {
      color: ({ color }) => {
        return color;
      },
    },
  },
});

export default function Gallery({ news, isHomepage, journalName, color }) {
  const [isMobile, setIsMobile] = useState(false);
  const [media, setMedia] = useState([]);
  const colorRGB = hex2rgb(color);
  const classes = useStyles({ color, colorRGB });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  useEffect(() => {
    let mediaArray = [];
    let imgArray = news.attachments.map((img) => {
      return {
        original: apiInfo.base + img.url,
        description: img.caption,
      };
    });

    if (imgArray) {
      mediaArray.push(...imgArray);
    }

    if (news.videoUrl && !isHomepage) {
      //embed youtube video
      let videoURL = news.videoUrl;
      if (!/^https?:\/\//i.test(videoURL)) {
        videoURL = 'https://' + videoURL;
      }

      try {
        const url = new URL(videoURL);
        const urlParams = new URLSearchParams(url.search);
        let videoID;
        if (url.search === '') {
          let regex = /\/([^\/]+)$/;
          let match = videoURL.match(regex);
          if (match) {
            videoID = match[1];
          }
        } else {
          videoID = urlParams.get('v');
        }

        let embedURL = `https://youtube.com/embed/${videoID}`;
        let thumbnailImg = `http://img.youtube.com/vi/${videoID}/0.jpg`;

        mediaArray.push({
          original: thumbnailImg,
          embedUrl: embedURL,
          title: '',
          renderItem: _renderVideo,
        });
      } catch (err) {
        console.error('[gallery] Video error:', err);
      }
    }

    setMedia(mediaArray);
  }, [news.attachments, news.videoUrl]);

  const _renderVideo = (item) => {
    return (
      <div className="video-wrapper-container">
        <div className="video-wrapper">
          <iframe
            width="560"
            height="315"
            title={extractContent(item.title)}
            src={item.embedUrl}
            allowFullScreen
          />
          {item.description && (
            <span
              className="image-gallery-description"
              style={{ right: '0', left: 'initial' }}
            >
              {item.description}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="galleryContainer">
      <ImageGallery
        items={media}
        lazyLoad={false}
        infinite={true}
        showBullets={true}
        showFullscreenButton={isHomepage ? false : true}
        showPlayButton={false}
        showThumbnails={false}
        showIndex={false}
        showNav={true}
        isRTL={false}
        slideDuration={450}
        slideInterval={6000}
        slideOnThumbnailOver={false}
        useWindowKeyDown={true}
        additionalClass={classes.gallery}
        autoPlay={false}
      />

      {isHomepage ? (
        <div className="sliderContent">
          <NewsItemInfo
            titleClass={
              isMobile === true ? 'headlightTitle small l5' : 'headlightTitle big l2'
            }
            journalName={journalName}
            news={news}
            color={color}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
