import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import Loading from '../../../components/loading/Loading';
import SectionItem from './SectionItem';
import PageNotFound from '../pagenotfound/PageNotFound';
import MetaTags from '../../../components/metatags/MetaTags';
import { getSectionNews } from '../../../api/news';
import { ToastError } from '../../../models/enum';
import { convertStringToUrl } from '../../../utils/utils';

import './SectionPage.css';

export default function SectionPage({ school, sections }) {
  const { sectionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [news, setNews] = useState([]);
  const [noNewsShown, setNoNewsShown] = useState(10);

  useEffect(() => {
    const section = sections.find((s) => convertStringToUrl(s.sname) === sectionId);
    if (section && currentSection?.id !== section.id) {
      setLoading(true);
      setCurrentSection(section);
      setNews([]);
      getNewsBySection(section.id);
      setNoNewsShown(school?.sectionTemplate === 3 ? 12 : 10);
    }

    if (!section && sections.length > 0) {
      setLoading(false);
      setError(true);
    }
  }, [sections]);

  const getNewsBySection = async (sectionId) => {
    let news;

    try {
      news = await getSectionNews(sectionId, school?.id);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }

    setLoading(false);
    setNews(news);
  };

  if (error && !loading) {
    return (
      <div className="articlePage">
        <div className="articleContent articleWidth first">
          <PageNotFound
            link={`/${school?.subdirectory}`}
            linkTxt="Aceder Homepage"
            isSmall={true}
          />
        </div>
      </div>
    );
  }

  const layout = school?.sectionTemplate;

  const showMoreNews = () => {
    if (school?.sectionTemplate === 3) {
      if (noNewsShown + 12 <= news.length) {
        setNoNewsShown(noNewsShown + 12);
      } else {
        setNoNewsShown(news.length);
      }
    } else {
      if (noNewsShown + 10 <= news.length) {
        setNoNewsShown(noNewsShown + 10);
      } else {
        setNoNewsShown(news.length);
      }
    }
  };

  console.log(school?.sectionTemplate);

  return (
    <div className="sectionHeader sectionWidth">
      {currentSection ? (
        <MetaTags
          title={`${currentSection?.sname} | ${school?.journal} | Projeto TRUE`}
        />
      ) : (
        <></>
      )}

      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid container spacing={layout === 1 || layout === 2 ? 5 : 3}>
            {news && news.length > 0 ? (
              news
                .slice(0, noNewsShown)
                .map((n, idx) => (
                  <SectionItem
                    key={n.id}
                    news={n}
                    idx={idx}
                    journalName={school?.subdirectory}
                    color={school?.palette?.color.main}
                    layout={school?.sectionTemplate}
                  />
                ))
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <div className="emptySlot emptyStateFill">
                  Ainda não há notícias nesta secção.
                </div>
              </Grid>
            )}
          </Grid>

          {news.length < noNewsShown || news.length === noNewsShown ? (
            <></>
          ) : (
            <Grid item xs={12} sm={12}>
              <div
                className="sectionsPagination"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <button
                  className="buttonSeeMore makeStyles-sectionItem-2 active"
                  onClick={showMoreNews}
                  style={{
                    backgroundColor: `${school?.palette?.color?.main}`,
                  }}
                >
                  Ver mais notícias
                </button>
              </div>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
}
