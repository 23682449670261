import React, { Component } from 'react';

import Modal from '../../../templates/modal/Modal';

import HomepageIcon from '../../../assets/img/sideMenuIcons/homepage.svg';
import { HeadlightOptions } from '../../../models/enum';
import TextLabel from '../../../components/dashboard/textinput/TextLabel';
import { Grid } from '@mui/material';

class ModalEditHeadlight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: this.props.layout
    };
    this.selectLayout.bind(this);
  }

  selectLayout(layout) {
    this.setState({
      layout: layout
    });
  }

  isValid = () => {
    const { layout } = this.state;
    return layout;
  };

  render() {
    return (
      <Modal
        openModal={this.props.openModal}
        modalHeader
        modalIcon={<img src={HomepageIcon} alt="Homepage icon" />}
        modalTitle={`Selecionar Template Manchete`}
      >
        <div className="modalBodyContainer">
          <div className="modalSelectHeader">
            <TextLabel label="Template Manchete" />

            <div className="tableBodyContainer">
              <Grid container spacing={4}>
                {HeadlightOptions.map(H =>
                  <Grid key={H.option} item xs={6}>
                    <div 
                      className={`headlightItem${this.state.layout === H.option ? ' selected' : ''}`}
                    >
                      <H.img
                        role='button' 
                        tabIndex={0}  
                        onClick={() => this.selectLayout(H.option)}
                        onKeyDown={(event) =>{
                          if (event.key === "Enter") {
                            this.selectLayout(H.option)
                          }
                        }} 
                      />

                      {/*<div className="headlightBtnContainer">
                                                <button className="secondaryBtn">Pré-visualizar</button>
                                            </div>*/}
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>

          </div>
        </div>

        <div className="btnContainer tableButtons">
          <div className="attributeContainer">
            <button className="secondaryBtnBlue" onClick={() => this.props.handleClose()}>Cancelar</button>
          </div>
          <div className="attributeContainer">
            {this.isValid() ?
              <button className="mainBtn" onClick={() => this.props.updateLayout(this.state.layout)}>Guardar</button> :
              <button className="mainBtn" disabled={true}>Guardar</button>
            }

          </div>
        </div>
      </Modal >
    );
  }
}

export default ModalEditHeadlight;