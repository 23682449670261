import React from 'react';

import Green from '../assets/img/state/green.svg';
import Orange from '../assets/img/state/orange.svg';
import Red from '../assets/img/state/red.svg';
import Yellow from '../assets/img/state/yellow.svg';
import { ReactComponent as HeadlightOne } from '../assets/img/homepage/headlight-1.svg';
import { ReactComponent as HeadlightTwo } from '../assets/img/homepage/headlight-2.svg';
import { ReactComponent as HeadlightThree } from '../assets/img/homepage/headlight-3.svg';
import { ReactComponent as HeadlightFour } from '../assets/img/homepage/headlight-4.svg';

export const LoginError = {
  'Auth.form.error.email.provide': 'Por favor, forneça o seu e-mail',
  'Auth.form.error.invalid': 'E-mail ou palavra-passe inválidos',
  'Auth.form.error.username.taken': 'E-mail já existe',
};

export const ToastError = {
  GENERAL_ERROR: 'Ocorreu um erro. Tente novamente mais tarde.',
  INVALID_SELECTION: 'Selecione um ficheiro válido!',
  UPLOAD_ERROR: 'Erro ao fazer upload da imagem!',
  UPLOAD_SIZE_ERROR: 'Imagem não deve ter mais de 2MB!',
  EMAIL_EXISTS: 'E-mail já existe',
  NO_SCHOOL: 'Nenhuma escola associada ao utilizador',
  PASSWORD_NO_MATCH: 'Palavras-passe não coincidem!',
  INVALID_SECTION_NAME: 'Nome de secção inválido!',
  EXCEED_SECTIONS: 'Atingiu o limite de 18 secções permitidas!',
  SECTION_NAME_EXISTS: 'Nome de secção já existe!',
  NO_PERMISSIONS: 'Não tem permissões para aceder a esta página!',
  NO_HEADLIGHT: 'Escola não tem manchete associada',
  NEWS_NOT_CONTINUOUS: 'Selecione uma notícia na posição ',
  SLOTS_NOT_CONTINUOUS: 'Selecione uma caixa na posição ',
};

export const ToastSuccess = {
  EMAIL_SENT: 'E-mail enviado com sucesso',
  UPDATED_HEADLIGHT: 'Manchete do jornal atualizada com sucesso!',
  UPDATED_SLOTS: 'Caixas atualizadas com sucesso!',
  UPDATED_SECTIONS: 'Secções atualizadas com sucesso!',
  UPDATED_PASSWORD: 'Palavra-passe atualizada!',
  UPDATED_PROFILE: 'Perfil atualizado!',
  CREATED_SECTION: 'Secção criada com sucesso!',
  UPDATED_SECTION: 'Secção atualizada com sucesso!',
  CREATED_REPORT: 'Problema reportado!',
  UPDATED_SECTION_STATUS: 'Secção atualizada!',
  UPDATED_SECTION_MIN: 'Não pode desativar mais secções.',
  UPDATED_SECTION_MAX: 'Não pode ativar mais secções.',
  CREATED_USER:
    'O utilizador criado recebeu um e-mail de confirmação na sua caixa de correio eletrónico',
  UPDATED_USER: 'Utilizador atualizado!',
  UPDATED_LAYOUT: 'Template da manchete atualizado com sucesso!',
  UPDATED_SECTION_TEMPLATE: 'Template das secções atualizado com sucesso!',
  UPDATED_THEME: 'Paleta de cores do jornal atualizada!',
  UPDATED_INFO: 'Informação atualizada!',
};

export const HeadlightScheme = {
  1: {
    scheme: <HeadlightOne />,
    rows: 5,
  },
  2: {
    scheme: <HeadlightTwo />,
    rows: 1,
  },
  3: {
    scheme: <HeadlightThree />,
    rows: 4,
  },
  4: {
    scheme: <HeadlightFour />,
    rows: 4,
  },
};

export const HeadlightOptions = [
  {
    img: HeadlightOne,
    option: 1,
  },
  {
    img: HeadlightTwo,
    option: 2,
  },
  {
    img: HeadlightThree,
    option: 3,
  },
  {
    img: HeadlightFour,
    option: 4,
  },
];

export const Roles = {
  Author: 'Autor',
  Editor: 'Editor',
  Admin: 'Administrador',
};

export const SlotOptions = {
  Gallery: 'gallery',
  Section: 'section',
  News: 'news',
};

export const Colors = {
  1: {
    main: '#2F23C1',
    secondary: '#DF5854',
  },
  2: {
    main: '#7D54F6',
    secondary: '#74FACE',
  },
  3: {
    main: '#4B327A',
    secondary: '#AE98D6',
  },
  // 4: {
  //   main: "#008354",
  //   secondary: "#99CA61",
  // },
  4: {
    main: '',
    secondary: '',
  },
};

export const NewsState = {
  EM_CURSO: {
    KEY: 0,
    IMG: Red,
    NAME: 'Em curso',
  },
  PARA_REVISAO: {
    KEY: 1,
    IMG: Orange,
    NAME: 'Para Revisão',
  },
  REVISTA: {
    KEY: 2,
    IMG: Yellow,
    NAME: 'Revista',
  },
  PUBLICADA: {
    KEY: 3,
    IMG: Green,
    NAME: 'Publicada',
  },
};

export const NewsFilters = {
  STATE: 'stateValues',
  SECTIONS: 'sectionValues',
  DATE: 'dateValues',
};
