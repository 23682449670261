import React from 'react';

export default function Video(props) {
  // const url = new URL(props.videoUrl);
  // const urlParams = new URLSearchParams(url.search);
  // const videoID = urlParams.get('v');

  const videoID = props.videoUrl.match(/(?:youtu\.be\/|youtube.com\/(?:live\/|embed\/|v\/|watch\?v=))([\w-]{11})/)?.[1];

  return (
    <div className="video-wrapper">
      <iframe
        width="560"
        height="315"
        title={props.title}
        src={`https://youtube.com/embed/${videoID}`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}
