import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashError from '../../../components/dashboard/dasherror/DashError';
import MetaTags from '../../../components/metatags/MetaTags';
import Loading from '../../../components/loading/Loading';

import backgroundImg from '../../../assets/img/illustrations/configurationBkg.svg';
import { ReactComponent as TrueLogo } from '../../../assets/logo/logo-big.svg';
import './init.css';

export default function ConfigInit(props) {
  const cid = props.match.params.cid;

  const status = useSelector((state) => state.statusConfig);

  return (
    <div className="fullPageContainer">
      <MetaTags title="Início &bull; Configuração | Projeto TRUE" />

      <div className="splashContainer">
        <div className="splashBackground" style={{ backgroundImage: `url("${backgroundImg}")` }} />

        {status.loading ? <Loading /> : <div className="elementsWrapper">
          <TrueLogo />

          {status.error ?
            <DashError />
            : <div className="buttonWrapper">
              <NavLink className="mainBtn" to={`/configuration/${cid}/step-1`}>
                                Iniciar Configuração
              </NavLink>
            </div>}
        </div>}
      </div>
    </div>
  );
}