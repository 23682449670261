import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ConfigError from '../error/Error';
import Loading from '../../../components/loading/Loading';
import MetaTags from '../../../components/metatags/MetaTags';
import ModalContact from './ModalContact';
import SetupMenu from '../../../templates/setupmenu/SetupMenu';
import { ConfigActions } from '../../../redux/actions';
import { getUserRoles } from '../../../api/permissions';
import { updateConfigurationById, validateUser } from '../../../api/configuration';
import { defaultUser } from '../../../redux/reducer';
import { ToastError } from '../../../models/enum';

import { ReactComponent as AddUserIcon } from '../../../assets/img/icons/addUser.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import './contacts.css';

export default function Contacts(props) {
  const [open, setOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [user, setUser] = useState(defaultUser);
  const [userIndex, setUserIndex] = useState(-1);
  const [modalEdit, setModalEdit] = useState(false);

  const cid = props.match.params.cid;
  const config = useSelector((state) => state.config);
  const status = useSelector((state) => state.statusConfig);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserRoles()
      .then((result) => {
        let rolesList = result.roles;
        rolesList = rolesList.filter((rol) => rol.name !== 'Public');
        setUserRoles(rolesList);
      })
      .catch((err) => {
        console.error(err);
        toast(ToastError.GENERAL_ERROR, { className: 'toastStyleDanger', toastId: "general_error", autoClose: 2000 });
      });
  }, []);

  useEffect(() => {
    setContacts(config.contacts);
  }, [config]);

  const previousStep = async () => {
    const step = 2;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('contacts', []));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.contacts = [];
    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }
    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const nextStep = async () => {
    const step = 4;
    const cfg = { ...config };

    setLoading(true);

    dispatch(ConfigActions.updateKey('contacts', contacts));
    dispatch(ConfigActions.updateKey('step', step));

    cfg.contacts = contacts;
    cfg.step = step;

    try {
      await updateConfigurationById(cid, cfg);
    } catch (error) {
      setLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }

    setLoading(false);

    history.push(`/configuration/${cid}/step-${step}`);
  };

  const getInitials = (name, surname) => {
    return name.charAt(0) + surname.charAt(0);
  };

  const onChange = (e) => {
    setUser({ 
      ...user, 
      [e.target.name]: e.target.name === 'email'
        ? e.target.value.trim()
        : e.target.value
    });
  };

  const updateContacts = async (olduser, editingmode) => {
    let existingEmails = contacts ? contacts.map((c) => c.email) : [];
    existingEmails.push(config?.user?.email);
    
    // if in editing mode, ignore current user
    if (editingmode) {
      const index = existingEmails.findIndex(email => email === olduser.email);
      if (index !== -1) { existingEmails.splice(index, 1); }
    }

    if (existingEmails.includes(user.email)) {
      return toast(ToastError.EMAIL_EXISTS, { className: 'toastStyleDanger', toastId: "email_exists", autoClose: 2000 });
    }

    let res;

    setTableLoading(true);
    try {
      res = await validateUser(user.name, user.surname, user.email, user.role, true);
    } catch (error) {
      setTableLoading(false);
      return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: "general_error",
        autoClose: 2000,
      });
    }
    setTableLoading(false);

    if (!res.valid) {
      return toast(res.message, { className: 'toastStyleDanger', toastId: "res_message", autoClose: 2000 });
    }

    setOpen(false);

    if (modalEdit) {
      const updatedContacts = [...contacts];
      updatedContacts[userIndex] = user;
      setContacts(updatedContacts);
    } else {
      setContacts((contacts) => (contacts ? [...contacts, user] : [user]));
    }

    setUser(defaultUser);
  };

  const openAddModal = () => {
    setUser(defaultUser);
    setModalEdit(false);
    setOpen(true);
  };

  const openEditModal = (user, index) => {
    setUser(user);
    setUserIndex(index);
    setModalEdit(true);
    setOpen(true);
  };

  const deleteContact = (idx) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(idx, 1);
    setContacts(updatedContacts);
  };

  if (status.error) {
    return <ConfigError />;
  }

  return (
    <div className="setupPage">
      <MetaTags title="Equipa do Jornal &bull; Configuração | Projeto TRUE" />

      <SetupMenu step={3} />

      <div className={'setupBody' + (status.loading || loading ? ' isLoading' : '')}>
        {status.loading || loading ? (
          <div className="loadingContainer">
            <Loading />
          </div>
        ) : (
          <></>
        )}

        <div className="highlightContainer">
          <p className="setupTitle">Equipa do Jornal</p>

          <button className="mainBtn" onClick={openAddModal}>
            <AddUserIcon />
            <span className="modalBtnTxt">Adicionar</span>
          </button>
        </div>

        <Grid container>
          {contacts?.map((c, index) => (
            <Grid className="contactWrapper" key={index} item xs={12}>
              <div className="contactLeft">
                <div className="contactBadge">{getInitials(c.name, c.surname)}</div>

                <div className="contactInfo">
                  <span className="contactName">{c.name + ' ' + c.surname}</span>
                  <span className="contactEmail">{c.email}</span>
                </div>
              </div>

              <div className="contactRight">
                <EditIcon
                  className="contactIcon"
                  onClick={() => openEditModal(c, index)}
                />
                <DeleteIcon
                  className="contactIcon"
                  onClick={() => deleteContact(index)}
                />

                <div className="contactRoleBadge">
                  {userRoles.find((r) => r.id === c.role)?.name}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>

        {!contacts || contacts?.length === 0 ? (
          <div className="noResults">
            <div>
              É possível adicionar mais membros da equipa do jornal aqui
              (opcional). Pode fazer isto mais tarde.
            </div>
            <div className="txtHighlight" onClick={() => setOpen(true)}>
              Adicione um membro da equipa aqui!
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="btnContainer">
          <div>
            <button className="secondaryBtnBlue" onClick={previousStep}>
              Voltar
            </button>
          </div>
          <div>
            <button className="mainBtn" onClick={nextStep}>
              Seguinte
            </button>
          </div>
        </div>

        <ModalContact
          open={open}
          loading={tableLoading}
          user={user}
          userRoles={userRoles}
          edit={modalEdit}
          setOpen={setOpen}
          onChange={onChange}
          updateContacts={updateContacts}
        />
      </div>
    </div>
  );
}
