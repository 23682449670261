import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { ModalCalendarMsg } from '../../models/enumsNewsEditor';

function ModalCalendar(props) {
  return (
    <div id="divFilterCalendar" className="position-search position-related">
      <p id="p_modalCalendar">
        Filtrar por:
        <Button
          id="fecharModal"
          onClick={() => {
            props.closeModalCalendar();
          }}
          className="btn-closing pt-0"
          type="button"
          aria-label="Close"
        ></Button>
      </p>
      <Col xs={12} id="col2-all" className="opcoesCalendario mb-2">
        <Button
          id="all"
          onClick={() => {
            props.filterNews(-1);
          }}
        >
          {ModalCalendarMsg.ALL}
        </Button>
      </Col>
      <Col xs={12} id="col2-today" className="opcoesCalendario mb-2">
        <Button
          id="today"
          onClick={() => {
            props.filterNews(0);
          }}
        >
          {ModalCalendarMsg.LAST_24_HOURS}
        </Button>
      </Col>
      <Col xs={12} id="col2-last7Days" className="opcoesCalendario mb-2">
        <Button
          id="last7Days"
          onClick={() => {
            props.filterNews(-7);
          }}
        >
          {ModalCalendarMsg.LAST_7_DAYS}
        </Button>
      </Col>
      <Col xs={12} id="col2-last30Days" className="opcoesCalendario mb-2">
        <Button
          id="last30Days"
          onClick={() => {
            props.filterNews(-30);
          }}
        >
          {ModalCalendarMsg.LAST_30_DAYS}
        </Button>
      </Col>
    </div>
  );
}

export default ModalCalendar;
