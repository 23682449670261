import React from 'react';
import { Grid } from '@mui/material';
import SlotItem from './SlotItem';

import './SlotSection.css';

export default function SlotSection({ title, newsList, journalName, color }) {
  return (
    <div className="slotSectionContainer">
      <div className="slotTitleContainer" style={{ borderBottom: `4px solid ${color}` }}>
        <span className="slotTitle">
          {title}
        </span>
      </div>

      <div className="slotSection homepageWidth">
        <Grid container spacing={3}>
          {newsList && newsList.length > 0 ?
            newsList?.map(n => <Grid key={n?._id} item xs={12} sm={12} md={6}>
              <SlotItem journalName={journalName} news={n} color={color} />
            </Grid>) : 
            <Grid item xs={12} sm={12} md={12}>
              <div className="emptySlot">
                Ainda não há notícias nesta secção.
              </div>
            </Grid>
          }
        </Grid>
      </div>
    </div>
  )
}