import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getHeadlightById = (headlightId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.HEADLIGHTS}/${headlightId}`,
    null,
    true
  );
}

export const updateSchoolHeadlight = (headlightId, headlightData) => {
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.HEADLIGHTS}/${headlightId}`,
    headlightData,
    true
  );
}