import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';

import DashHeader from '../../components/dashboard/dashheader/DashHeader';
import Header from '../../components/newsEditor/Header';

import MetaTags from '../../components/metatags/MetaTags';
import TableLoading from '../../components/tableloading/TableLoading';
import TextInput from '../../components/dashboard/textinput/TextInput';
import TextLabel from '../../components/dashboard/textinput/TextLabel';
import { createReport } from '../../api/report';
import { ToastError, ToastSuccess } from '../../models/enum';

import './report.css';
import { ONE_MB } from '../../utils/utils';

function ReportManagement() {
  const school = useSelector((state) => state.school);
  const user = useSelector((state) => state?.user);
  const [tableLoading, setTableLoading] = useState(false);
  const [assunto, setAssunto] = useState('');
  const [tipo, setTipo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [file, setFile] = useState(null);
  const dropzoneEl = useRef(null);
  const [removeBtn, setRemoveBtn] = useState(false);

  const handleChangeStatus = (fileWithMeta, status) => {
    // console.log({ status, fileWithMeta });
    if (status === 'ready') {
      setRemoveBtn(true);
      setFile(fileWithMeta);
    } else if (status === 'getting_upload_params') {
      setFile(fileWithMeta);
      setRemoveBtn(true);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setRemoveBtn(false);
    dropzoneEl.current.files = [];
  };

  const getUploadParams = () => null;

  const submit = async (event) => {
    event.preventDefault();

    if (file?.file?.size > 2 * ONE_MB) {
      return toast(ToastError.UPLOAD_SIZE_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'upload_size_error',
        autoClose: 2000,
      });
    }

    setTableLoading(true);
    try {
      const data = {
        subject: assunto,
        issueType: tipo,
        description: descricao,
        school: school?.id,
        user: user?.id,
        img: file,
      };

      const response = await createReport(data);

      if (response.id) {
        dropzoneEl.current.files = [];
        setAssunto('');
        setTipo('');
        setDescricao('');
        setFile(null);
        toast(ToastSuccess.CREATED_REPORT, {
          className: 'toastStyleSuccess',
          toastId: 'created_report',
          autoClose: 2000,
        });
      }

      setTableLoading(false);
    } catch (err) {
      setTableLoading(false);
      toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      });
    }
  };

  const loadingClass = tableLoading ? 'bodyContainer pageLoading' : 'bodyContainer';

  return (
    <div>
      <MetaTags title="Reportar Problema | Projeto TRUE" />

      <DashHeader title="Reportar Problema" />
      <Header name={'help_report'} />

      <div className={loadingClass}>
        <Form className="reportContainer" onSubmit={submit}>
          <Grid item xs={12}>
            <TextInput
              className="custom-user-input"
              type="text"
              label="Assunto"
              name="assunto"
              value={assunto}
              placeholder="ex: Falha a guardar notícia"
              setInputValue={(e) => setAssunto(e.target.value)}
              disabled={false}
              multiline={true}
              minRows={1}
              required={true}
            />
          </Grid>
          <Grid className="" item xs={12}>
            <TextInput
              className="custom-user-input"
              label="Tipo de Problema"
              select
              name="role"
              value={tipo}
              setInputValue={(e) => setTipo(e.target.value)}
              required={true}
            >
              <MenuItem value="" disabled selected>
                Selecione o tipo de problema
              </MenuItem>
              <MenuItem key={1} value={1}>
                {'Problema'}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {'Sugestão'}
              </MenuItem>
            </TextInput>
          </Grid>
          <Grid className="text-area-custom" item xs={12}>
            <TextInput
              className="custom-user-input"
              type="text"
              label="Descrição"
              name="descricao"
              value={descricao}
              placeholder="ex: Ao guardar a notícia que estava a escrever tive o feedback de que esta tinha sido guardada. No entanto ao voltar à mesma, as alterações que tinha feito não ficaram registadas."
              setInputValue={(e) => setDescricao(e.target.value)}
              disabled={false}
              multiline={true}
              minRows={3}
              required={true}
            />
          </Grid>
          <Grid className="TextInput-formInput-1 mt-3" item xs={12}>
            <TextLabel label={'Imagem'}></TextLabel>
            <Dropzone
              getUploadParams={getUploadParams}
              PreviewComponent={({ meta }) => {
                const { name, previewUrl } = meta;
                return (
                  <div className="py-4 imgPreviewContainer">
                    {removeBtn ? (
                      <button
                        type="button"
                        className="removeBtn"
                        onClick={handleRemoveFile}
                      >
                        <svg
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99723 8.06919C9.12052 8.19247 9.18978 8.35968 9.18978 8.53403C9.18978 8.70838 9.12052 8.87559 8.99723 8.99887C8.87395 9.12216 8.70674 9.19142 8.53239 9.19142C8.35804 9.19142 8.19083 9.12216 8.06754 8.99887L4.59544 5.52567L1.12223 8.99778C0.998948 9.12106 0.831739 9.19032 0.657388 9.19032C0.483038 9.19032 0.315829 9.12106 0.192545 8.99778C0.0692604 8.8745 1.83708e-09 8.70729 0 8.53294C-1.83708e-09 8.35859 0.0692604 8.19138 0.192545 8.06809L3.66575 4.59598L0.193638 1.12278C0.070354 0.999494 0.00109377 0.832285 0.00109378 0.657935C0.00109378 0.483585 0.070354 0.316376 0.193638 0.193091C0.316922 0.0698072 0.484132 0.000546785 0.658482 0.000546783C0.832832 0.000546781 1.00004 0.0698072 1.12333 0.193091L4.59544 3.66629L8.06864 0.192544C8.19192 0.0692602 8.35913 -2.90468e-09 8.53348 0C8.70783 2.90468e-09 8.87504 0.0692602 8.99833 0.192544C9.12161 0.315829 9.19087 0.483038 9.19087 0.657388C9.19087 0.831738 9.12161 0.998948 8.99833 1.12223L5.52512 4.59598L8.99723 8.06919Z"
                            fill="#1F8598"
                          />
                        </svg>
                      </button>
                    ) : (
                      ''
                    )}
                    <img
                      src={previewUrl}
                      alt="Report Problem"
                      className="imgPreviewReport"
                    />
                    <div className="text-end">{name}</div>
                  </div>
                );
              }}
              ref={dropzoneEl}
              maxFiles={1}
              autoUpload={false}
              multiple={false}
              accept="image/*"
              onChangeStatus={handleChangeStatus}
              inputContent="Escolher imagem ilustrativa"
              styles={{ dropzone: { minHeight: 200, paddingBottom: 10 } }}
            />
          </Grid>

          <div className="disclaimerTxtContainer">
            <b>NOTA</b>: A plataforma TRUE não está em desenvolvimento ativo desde junho
            de 2023 (ver{' '}
            <a
              href="https://www.mog-technologies.com/pt/financed-projects/true-project"
              target="_blank"
            >
              ficha técnica
            </a>{' '}
            do projeto). No entanto, é importante salientar que a plataforma permanece e
            continuará completamente funcional. Todos os relatórios de problemas e
            sugestões são de extrema importância para futuras iterações do projeto e
            problemas no website serão corrigidos quando for possível.
          </div>

          <div className="btnContainer d-flex justify-content-end">
            <button
              className="mainBtn"
              type="submit"
              disabled={!assunto || !tipo || !descricao}
            >
              Finalizar
            </button>
          </div>
        </Form>

        {tableLoading ? <TableLoading /> : <></>}
      </div>
    </div>
  );
}

export default ReportManagement;
