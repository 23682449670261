import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import DangerIcon from '../../../assets/img/icons/alertTriangle.svg';

import 'cropperjs/dist/cropper.css';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditorValidationActions } from '../../../redux/actions';

function PreviewSlide({
  previewUrl,
  removeUploaded,
  editImageSrc,
  writeCaptionImg,
  desc,
  id,
}) {
  const dispatch = useDispatch();
  const cropperRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [editMode, setEditMode] = useState(true);
  const captionVerif = useSelector(
    (state) => state?.textEditorValidation.caption,
  );
  let finalVerif = useSelector(
    (state) => state?.textEditorValidation.finalValidation,
  );
  let matchingCaption = captionVerif.find((image) => image.id === id);
  const saveRef = useSelector((state) => state?.textEditor.saveRef);

  const forceCrop = useSelector((state) => state?.textEditor.forceCrop);

  useEffect(() => {
    if (desc) {
      setCharCount(desc.length);
    }

    if (desc?.length === 0 || !desc) {
      if (!matchingCaption) {
        dispatch(
          TextEditorValidationActions.updateKey('caption', [
            ...captionVerif,
            { id: id, caption: false },
          ]),
        );
      } else {
        let newArrayCaption = captionVerif.map((item) =>
          item.id == id ? { id: id, caption: false } : item,
        );
        dispatch(
          TextEditorValidationActions.updateKey('caption', newArrayCaption),
        );
      }
    } else {
      if (!matchingCaption) {
        dispatch(
          TextEditorValidationActions.updateKey('caption', [
            ...captionVerif,
            { id: id, caption: true },
          ]),
        );
      } else if (matchingCaption.caption != true) {
        let newArrayCaption = captionVerif.map((item) =>
          item.id == id ? { id: id, caption: true } : item,
        );
        dispatch(
          TextEditorValidationActions.updateKey('caption', newArrayCaption),
        );
      }
    }
  }, [desc]);

  useEffect(() => {
    if (forceCrop) {
      submitEdition();
    }
  }, [forceCrop]);

  const submitEdition = () => {
    const imageElement = cropperRef?.current;
    const cropperCanvas = imageElement?.cropper.getCroppedCanvas();

    if (cropperCanvas) {
      editImageSrc(cropperCanvas.toDataURL('image/jpeg'));
      setEditMode(!editMode);
    }
  };

  const captionVerification = (e) => {
    if (e.target.value.length === 0) {
      if (!matchingCaption) {
        dispatch(
          TextEditorValidationActions.updateKey('caption', [
            ...captionVerif,
            { id: id, caption: false },
          ]),
        );
      } else {
        let newArrayCaption = captionVerif.map((item) =>
          item.id == id ? { id: id, caption: false } : item,
        );
        dispatch(
          TextEditorValidationActions.updateKey('caption', newArrayCaption),
        );
      }
    } else {
      if (!matchingCaption) {
        dispatch(
          TextEditorValidationActions.updateKey('caption', [
            ...captionVerif,
            { id: id, caption: true },
          ]),
        );
      } else if (matchingCaption.caption != true) {
        let newArrayCaption = captionVerif.map((item) =>
          item.id == id ? { id: id, caption: true } : item,
        );
        dispatch(
          TextEditorValidationActions.updateKey('caption', newArrayCaption),
        );
      }
    }
  };

  const writeCaption = (e) => {
    writeCaptionImg(e.target.value);
    captionVerification(e);
  };

  const charCounter = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <div className="d-flex flex-column mt-3">
        {editMode ? (
          <div className="img-container">
            <Cropper
              style={{ height: 'calc(500px*9/16)', width: 500 }}
              aspectRatio={16 / 9}
              viewMode={1}
              autoCropArea={1}
              minCropBoxHeight={200}
              minCropBoxWidth={200}
              src={previewUrl}
              center
              dragMode="move"
              ref={cropperRef}
              zoomOnWheel={false}
            />
            <button
              type="button"
              onClick={removeUploaded}
              className="top-right-button"
            >
              <svg
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.99723 8.06919C9.12052 8.19247 9.18978 8.35968 9.18978 8.53403C9.18978 8.70838 9.12052 8.87559 8.99723 8.99887C8.87395 9.12216 8.70674 9.19142 8.53239 9.19142C8.35804 9.19142 8.19083 9.12216 8.06754 8.99887L4.59544 5.52567L1.12223 8.99778C0.998948 9.12106 0.831739 9.19032 0.657388 9.19032C0.483038 9.19032 0.315829 9.12106 0.192545 8.99778C0.0692604 8.8745 1.83708e-09 8.70729 0 8.53294C-1.83708e-09 8.35859 0.0692604 8.19138 0.192545 8.06809L3.66575 4.59598L0.193638 1.12278C0.070354 0.999494 0.00109377 0.832285 0.00109378 0.657935C0.00109378 0.483585 0.070354 0.316376 0.193638 0.193091C0.316922 0.0698072 0.484132 0.000546785 0.658482 0.000546783C0.832832 0.000546781 1.00004 0.0698072 1.12333 0.193091L4.59544 3.66629L8.06864 0.192544C8.19192 0.0692602 8.35913 -2.90468e-09 8.53348 0C8.70783 2.90468e-09 8.87504 0.0692602 8.99833 0.192544C9.12161 0.315829 9.19087 0.483038 9.19087 0.657388C9.19087 0.831738 9.12161 0.998948 8.99833 1.12223L5.52512 4.59598L8.99723 8.06919Z"
                  fill="#1F8598"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={submitEdition}
              className="bottom-left-button"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1818 8.90909V3.81818H5.09091V2.54546H10.1818C10.5318 2.54546 10.8315 2.67018 11.081 2.91964C11.3305 3.16909 11.455 3.46861 11.4545 3.81818V8.90909H10.1818ZM10.8182 14C10.6379 14 10.4866 13.9389 10.3645 13.8167C10.2423 13.6945 10.1814 13.5435 10.1818 13.3636V11.4545H3.81818C3.46818 11.4545 3.16846 11.3298 2.919 11.0804C2.66955 10.8309 2.54503 10.5314 2.54546 10.1818V3.81818H0.636366C0.456063 3.81818 0.30482 3.75709 0.182638 3.63491C0.0604567 3.51273 -0.00042204 3.3617 2.20195e-06 3.18182C2.20195e-06 3.00152 0.0610931 2.85027 0.183275 2.72809C0.305457 2.60591 0.456487 2.54503 0.636366 2.54546H2.54546V0.636366C2.54546 0.456063 2.60655 0.30482 2.72873 0.182638C2.85091 0.0604567 3.00194 -0.00042204 3.18182 2.20195e-06C3.36212 2.20195e-06 3.51336 0.0610931 3.63555 0.183275C3.75773 0.305457 3.81861 0.456487 3.81818 0.636366V10.1818H13.3636C13.5439 10.1818 13.6952 10.2429 13.8174 10.3651C13.9395 10.4873 14.0004 10.6383 14 10.8182C14 10.9985 13.9389 11.1497 13.8167 11.2719C13.6945 11.3941 13.5435 11.455 13.3636 11.4545H11.4545V13.3636C11.4545 13.5439 11.3935 13.6952 11.2713 13.8174C11.1491 13.9395 10.9981 14.0004 10.8182 14Z"
                  fill="#1F8598"
                />
              </svg>
              <span className="m-1">Recortar</span>
            </button>
          </div>
        ) : null}
        <div className="mt-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={1}
              defaultValue={desc}
              placeholder="Adiciona uma descrição e uma fonte à imagem."
              onBlur={writeCaption}
              onChange={charCounter}
              maxLength={125}
            />
            {charCount <= 125 ? (
              <div className="text-muted my-2">{charCount}/125 caracteres</div>
            ) : undefined}
          </Form.Group>
        </div>
        {!matchingCaption?.caption && finalVerif === false && (
          <div className="errorVerifContainer mt-0">
            <img src={DangerIcon} className="errorVerifIcon" />
            <p className="errorVerifMessage">
              Por favor, insira uma legenda para a imagem.
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewSlide;
