import React, { Component } from 'react';

//Components
import { HeadlightScheme } from '../../../models/enum';

//Icons
import ArrowDown from '../../../assets/img/icons/arrowDown.svg';
import ArrowUp from '../../../assets/img/icons/arrowUp.svg';
import EditOutlinedIcon from '../../../assets/img/icons/editItem.svg';
import { extractContent } from '../../../utils/utils';

class HeadlightsNews extends Component {

  getNewsRows = (noRows) => {
    let NewsRows = [];
    if (noRows && noRows > -1) { NewsRows = Array.from({ length: noRows }, (_, i) => i + 1); }

    return NewsRows;
  }

  getTitle = (n) => {
    return n.title ? extractContent(n.title) : n.title;
  }

  render() {
    const { 
      layout, selectedNews, 
      handleOpen, increasePosition, decreasePosition 
    } = this.props;
        
    const noRows = HeadlightScheme[layout]?.rows;
    const NewsRows = this.getNewsRows(noRows);

    return (
      <div className="newsRowsContainer">
        {NewsRows.map((value) =>
          <div key={value} className="rowWrapper">
            <div className="blueSquare">
              <p className="squareNumber">
                {value}
              </p>
            </div>

            <button type='button' className="greyRectangle" onClick={() => handleOpen(value, selectedNews[value - 1])}>
              <div className="selectItem">
                {selectedNews && selectedNews[value - 1] ?
                  <>
                    <p className="selectedItemTitle homepage">{this.getTitle(selectedNews[value - 1])}</p>
                    <img
                      className="editIcon"
                      src={EditOutlinedIcon}
                      alt="Edit news"
                      height="22px"
                    />
                  </>
                  : <p className="chooseItemModal">Selecionar Notícia</p>
                }
              </div>
            </button>

            <div className="arrowWrapper">
              {value < noRows ? 
                <img 
                  src={ArrowDown} 
                  alt="Arrow Down" 
                  role="button" 
                  tabIndex="0"
                  onClick={() => increasePosition(value - 1)} 
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      increasePosition(value-1)
                    }
                  }} /> : <></>}
              {value > 1 ? 
                <img 
                  className={value === noRows ? "marginArrow" : ""} 
                  src={ArrowUp} 
                  alt="Arrow Up" 
                  role="button" 
                  tabIndex="0"
                  onClick={() => decreasePosition(value - 1)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      decreasePosition(value-1)
                    }
                  }}/> : <></>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HeadlightsNews;