import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditorActions } from '../../redux/actions';
import {
  Col,
  Row,
  Image,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import ModalCalendar from './ModalCalendar';
import Mascote from './Mascote';
import iconPlus from '../../assets/newsEditor/images/icon_plus.svg';
import iconCalendar from '../../assets/newsEditor/images/icon_calendar.svg';
import iconCalendarToday from '../../assets/newsEditor/images/icon_calendar1.svg';
import iconCalendar7Days from '../../assets/newsEditor/images/icon_calendar7.svg';
import iconCalendar30Days from '../../assets/newsEditor/images/icon_calendar30.svg';
import iconCheck from '../../assets/newsEditor/images/icon_check.svg';
import mascoteRelatedNews from '../../assets/newsEditor/images/mascoteRelatedNews.svg';
import mascoteLoading from '../../assets/newsEditor/images/mascoteSearchGif.gif';
import mascoteSearchError from '../../assets/newsEditor/images/mascoteSearchError.svg';
import mascoteEmpty from '../../assets/newsEditor/images/mascote1.svg';
import InfoIcon from '../../assets/img/icons/info.svg';
import ArrowUpdate from '../../assets/img/icons/ArrowClockwise.svg';

import { getPublicoRelatedNews, getEntities } from '../../api/search';
import { ToastError } from '../../models/enumsNewsEditor';

function TabRelatedNews() {
  const dispatch = useDispatch();

  const [relatedNews, setRelatedNews] = useState([]);
  const addedNews = useSelector((state) => state.textEditor.relatedNews);
  const [filteredRelatedNews, setFilteredRelatedNews] = useState([]);

  const [calendar, setCalendar] = useState([]);
  const [calendarIcon, setCalendarIcon] = useState(iconCalendar);

  let [renderNews, setRenderNews] = useState([]);
  let icon;

  const [mascoteImg, setMascoteImg] = useState(mascoteEmpty);
  const [mascoteText, setMascoteText] = useState('Começa a escrever');

  const bodyRaw = useSelector((state) => state.textEditor.bodyRaw);
  const titleRaw = useSelector((state) => state.textEditor.titleRaw);
  const [reload, setReload] = useState(false);

  const [timer, setTimer] = useState(null);

  const [blacklistedWords, setBlacklistedWords] = useState([]);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const text = titleRaw + '. ' + bodyRaw;
    fetchEntities(text);
  }, []);

  useEffect(() => {
    let filteredEntities = entities.filter(
      (entity) => !blacklistedWords.includes(entity),
    );

    if (filteredEntities.length > 0) {
      fetchRelatedNews(filteredEntities);
    } else {
      if (filteredEntities.length === 0 && blacklistedWords.length !== 0) {
        setMascoteText(
          'Seleciona pelo menos um conceito chave para filtrar resultados',
        );
        setRelatedNews([]);
        setFilteredRelatedNews([]);
      } else {
        setMascoteText('Escreve mais e vou tentar procurar de novo.');
        setRelatedNews([]);
        setFilteredRelatedNews([]);
      }
      setMascoteImg(mascoteSearchError);
    }
  }, [blacklistedWords, entities]);

  // remove or add related news
  function addRelatedNews(inputNews) {
    if (
      addedNews?.includes(inputNews) ||
      addedNews?.filter((e) => e.OriginalURL === inputNews.OriginalURL).length >
        0
    ) {
      const newItem = addedNews.filter(
        (data) => data.OriginalURL !== inputNews.OriginalURL,
      );

      dispatch(TextEditorActions.updateKey('relatedNews', newItem));
    } else {
      const newItem = [...addedNews, inputNews];

      dispatch(TextEditorActions.updateKey('relatedNews', newItem));
    }
  }

  function toggleModalCalendar() {
    if (calendar.length === 0) {
      setCalendar(
        <ModalCalendar
          filterNews={setFilterNews}
          closeModalCalendar={closeCalendar}
        ></ModalCalendar>,
      );
    } else {
      setCalendar([]);
    }
  }

  function closeCalendar() {
    setCalendar([]);
  }

  function setFilterNews(num) {
    const now = new Date();
    let len = filteredRelatedNews.length;

    if (num !== -1) {
      const filtered = relatedNews.filter((news) => {
        const date = new Date(news.ValueDate);
        const diff = Math.ceil((date - now) / 1000 / 60 / 60 / 24);
        return diff >= num;
      });

      setFilteredRelatedNews(filtered);
      len = filtered.length;
    } else {
      // remove filter
      len = relatedNews.length;
      setFilteredRelatedNews(relatedNews);
    }

    if (len === 0) {
      setMascoteImg(mascoteSearchError);
    } else {
      setMascoteImg(mascoteRelatedNews);
    }

    console.log(len, 'len');
    if (len > 1) {
      switch (num) {
        case -1:
          setCalendarIcon(iconCalendar);
          setMascoteText(`Encontrei ${len} notícias.`);
          break;
        case 0:
          setCalendarIcon(iconCalendarToday);
          setMascoteText(`Encontrei ${len} notícias para hoje.`);
          break;
        case -7:
          setCalendarIcon(iconCalendar7Days);
          setMascoteText(`Encontrei ${len} notícias dos últimos 7 dias.`);
          break;
        case -30:
          setCalendarIcon(iconCalendar30Days);
          setMascoteText(`Encontrei ${len} notícias dos últimos 30 dias.`);
          break;

        default:
          break;
      }
    } else if (len === 1) {
      switch (num) {
        case -1:
          setCalendarIcon(iconCalendar);
          setMascoteText(`Encontrei ${len} notícia.`);
          break;
        case 0:
          setCalendarIcon(iconCalendarToday);
          setMascoteText(`Encontrei ${len} notícia para hoje.`);
          break;
        case -7:
          setCalendarIcon(iconCalendar7Days);
          setMascoteText(`Encontrei ${len} notícia dos últimos 7 dias.`);
          break;
        case -30:
          setCalendarIcon(iconCalendar30Days);
          setMascoteText(`Encontrei ${len} notícia dos últimos 30 dias.`);
          break;

        default:
          break;
      }
    }

    closeCalendar();
  }

  const fetchEntities = async (text) => {
    let res;

    const formData = new FormData();
    formData.append('text', text);

    try {
      res = await getEntities(formData);
      let entities = [...new Set(res.results)]; //prevents duplicate entities
      setEntities(entities);
    } catch (err) {
      console.error(err);
      /* return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      }); */
    }
  };

  async function fetchRelatedNews(input) {
    setMascoteText('Estou à procura.');
    setMascoteImg(mascoteLoading);

    let res;

    try {
      res = await getPublicoRelatedNews(input);

      if (res.length === 0) {
        setMascoteText('Não encontrei resultados.');
        setMascoteImg(mascoteSearchError);
      } else if (res.length === 1) {
        setMascoteText(`Encontrei ${res.length} notícia.`);
        setMascoteImg(mascoteRelatedNews);
      } else {
        setMascoteText(`Encontrei ${res.length} notícias.`);
        setMascoteImg(mascoteRelatedNews);
      }

      setRelatedNews(res);
      setFilteredRelatedNews(res);
    } catch (err) {
      console.error(err);

      setMascoteText('Erro na pesquisa.');
      setMascoteImg(mascoteSearchError);
      /*  return toast(ToastError.GENERAL_ERROR, {
        className: 'toastStyleDanger',
        toastId: 'general_error',
        autoClose: 2000,
      }); */
    }
  }

  // after news text changes, trigger new search 30s after
  useEffect(() => {
    clearTimeout(timer);

    const text = titleRaw + '. ' + bodyRaw;

    const newTimer = setTimeout(() => {
      fetchEntities(text);
    }, 30000);

    setTimer(newTimer);

    return () => {
      clearTimeout(newTimer);
    };
  }, [bodyRaw, titleRaw]);

  //Handle pills click
  const handlePillClick = (el) => {
    const isWordBlacklisted = blacklistedWords?.includes(el);
    if (isWordBlacklisted) {
      // REMOVE WORD FROM BLACKLISTED ARRAY
      const newBlacklistedWords = blacklistedWords.filter(
        (word) => word !== el,
      );
      setBlacklistedWords(newBlacklistedWords);
    } else {
      // ADD WORD TO BLACKLISTED ARRAY
      setBlacklistedWords([...blacklistedWords, el]);
    }
  };

  // update search results
  useEffect(() => {
    let rows = [];

    filteredRelatedNews.forEach(function (val, i) {
      if (
        addedNews?.filter((e) => e.OriginalURL === val.OriginalURL).length > 0
      ) {
        icon = iconCheck;
      } else {
        icon = iconPlus;
      }

      rows.push(
        <Row className="mb-3 gx-0" key={i}>
          <Col xs={2} className="overflow-hidden pad-image">
            <a href={val.URL}>
              <Image src={val.Image} className="minImageNews" />
            </a>
          </Col>
          <Col xs={9} className="px-3">
            <a
              href={val.URL}
              target="_blank"
              rel="noreferrer"
              className="titleNews mb-1 relatedNews giveMeEllipsis pe-3"
            >
              {/* remove html tags from title */}
              {val.Title.replace(/(<([^>]+)>)/gi, '')}
            </a>
            <p className="fontNews mb-1">{val.Date}</p>
          </Col>
          <Col xs={1} className="my-auto pe-0 d-flex justify-content-end">
            <Button
              className="btnAddNews"
              onClick={() => {
                addRelatedNews(val);
              }}
            >
              <Image src={icon} />
            </Button>
          </Col>
        </Row>,
      );
    });

    setRenderNews(rows);
  }, [filteredRelatedNews, addedNews]);

  const refreshResults = () => {
    console.log('refresh related');
    const text = titleRaw + '. ' + bodyRaw;
    setReload(true);
    fetchEntities(text);
  };

  return (
    <>
      <Row className="font-title color-title">
        <Col xs={10} className="my-auto">
          Notícias relacionadas do Público
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip" className="tooltip">
                Aqui encontras notícias do jornal Público com conteúdo
                relacionado com o que estás a escrever. Podes adicionar algumas
                delas para que fiquem associadas à tua notícia.
              </Tooltip>
            }
          >
            <img src={InfoIcon} alt="informação" className="mx-2" />
          </OverlayTrigger>
        </Col>
        <Col xs={2} className="my-auto justify-content-end d-flex">
          <Button
            className="btnFilter"
            onClick={() => {
              toggleModalCalendar();
            }}
          >
            <img src={calendarIcon} alt="calendar-icon" />
          </Button>
        </Col>
      </Row>

      {calendar}

      {/* Related News Pills */}
      <div className="pillsMain row">
        <Col xs={10} className="my-auto">
          <p className="pillsHeaderText">
            Conceitos chave identificados ({entities.length})
          </p>
        </Col>
        <Col xs={2} className="my-auto justify-content-end d-flex">
          {entities.length === 0 && (
            <img
              onClick={refreshResults}
              alt="atualizar notícias relacionadas"
              src={ArrowUpdate}
              className={
                reload
                  ? 'enableCursor reloadIconAnimation align-self-end'
                  : 'enableCursor align-self-end'
              }
              onAnimationEnd={() => setReload(false)}
            />
          )}
        </Col>
        {entities.length !== 0 && (
          <div className="pillsContainer pillsErrors row">
            <div className="d-flex col-11 flex-wrap">
              {entities.map((el, idx) => (
                <div
                  className={
                    blacklistedWords.includes(el)
                      ? 'pillsDivActive'
                      : 'pillsDiv'
                  }
                  key={idx}
                  onClick={() => {
                    handlePillClick(el);
                  }}
                >
                  <p
                    className={
                      blacklistedWords.includes(el)
                        ? 'pillsActiveContent'
                        : 'pillsContent'
                    }
                    title={el}
                  >
                    {el}
                  </p>
                </div>
              ))}
            </div>

            {entities.length ? (
              <div className="col-1 p-0  px-1 d-flex reloadIcon justify-content-end">
                <img
                  onClick={refreshResults}
                  alt="atualizar notícias relacionadas"
                  src={ArrowUpdate}
                  className={
                    reload
                      ? 'enableCursor reloadIconAnimation align-self-end'
                      : 'enableCursor align-self-end'
                  }
                  onAnimationEnd={() => setReload(false)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      <Row className="classErrors my-4">{renderNews}</Row>

      <Mascote img={mascoteImg} text={mascoteText}></Mascote>
    </>
  );
}

export default TabRelatedNews;
