import React, { Component } from 'react';
import { FormLabel, FormHelperText } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

const useStyles = createStyles({
  label: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    color: '#08798E !important',
    textTransform: 'uppercase',
    lineHeight: '19px !important',
    marginBottom: '10px !important',
    '&.Mui-focused': {
      color: '#08798E !important',
    },
    '&.Mui-error': {
      color: '#08798E',
    },
    '@media (max-width: 1250px)': {
      fontSize: 15
    }
  },
  asterisk: {
    fontSize: 13,
  },
  helperText: {
    color: '#000000',
    fontSize: 13,
    fontWeight: 300,
    marginTop: 8,
    marginRight: 0,
    marginLeft: 0,
    lineHeight: '130%',
    '& .highlight': {
      fontWeight: 400
    }
  }
});

class TextLabel extends Component {
  render() {
    const { 
      classes, children,
      label, required, helperText
    } = this.props;

    return (
      <>
        <FormLabel
          classes={{ root: classes.label }}
          component="legend"
        >
          {label} <span className={classes.asterisk}>{required ? '*' : ''}</span>
        </FormLabel>
                
        {children}

        {helperText && (
          <FormHelperText className={classes.helperText}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }
}

export default withStyles(useStyles)(TextLabel);
