import { apiInfo, searchApiInfo, fetchRequest, HTTPMethod, Resource } from './api';
import { NewsState } from '../models/enum';

export const getNewsById = (newsId) => {
  return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.NEWS}/${newsId}`);
};

export const deleteNews = (newsId) => {
  return fetchRequest(
    HTTPMethod.DELETE,
    `${apiInfo.base}/${Resource.NEWS}/${newsId}`,
    null,
    true,
  );
};

export const deleteUpload = (id) => {
  return fetchRequest(
    HTTPMethod.DELETE,
    `${apiInfo.base}/${Resource.UPLOAD_FILES}/${id}`,
    null,
    true,
    null,
    true,
  );
};

export const getSchoolNewsCount = (search, filter) => {
  const params = search !== '' ? `${search}&${filter}` : `${filter}`;

  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.NEWS}/count?${params}`,
    null,
    true,
  );
};

export const getSchoolNews = (sort, search, filter, start, limit) => {
  const searchParams = search !== '' ? `${search}&${sort}` : `${sort}`;

  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.NEWS}?${searchParams}&_start=${start}&_limit=${limit}&${filter}`,
    null,
    true,
  );
};

export const getPublishedNews = (schoolId, data, search, start, limit) => {
  const filter = `school=${schoolId}&status=${NewsState.PUBLICADA.NAME}`;
  const params = search !== '' ? `_q=${search}&${filter}` : filter;
  const sort = 'publishDate:DESC,published_at:DESC';

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.NEWS}/published?${params}&_sort=${sort}&_start=${start}&_limit=${limit}`,
    data,
    true,
  );
};

export const getGalleryNews = (schoolId, data, sort, start, limit) => {
  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.NEWS}/gallery?school=${schoolId}&status=${NewsState.PUBLICADA.NAME}&${sort}&_start=${start}&_limit=${limit}`,
    data,
    true,
  );
};

export const getSectionNews = (sectionId, schoolId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.NEWS}?section=${sectionId}&status=${NewsState.PUBLICADA.NAME}&school=${schoolId}&_sort=publishDate:DESC,published_at:DESC&_limit=-1`
  );
}

export const getSectionNewsCount = (sectionId, schoolId) => {
  const filter = `section=${sectionId}&status=${NewsState.PUBLICADA.NAME}&school=${schoolId}`;

  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.NEWS}/count?${filter}`,
  );
};

export const getHomepageNews = (schoolId, headlightId, sections, gallery) => {
  let params = '';

  if (sections) {
    params += `&sections=${sections}`;
  }
  if (gallery) {
    params += `&gallery=${gallery}`;
  }

  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.NEWS}/homepage?school=${schoolId}&headlight=${headlightId}${params}`,
  );
};

export const getPublicoLastNews = () =>{
  return fetchRequest(
    HTTPMethod.GET,
    `${searchApiInfo.base}/${Resource.SEARCHLASTNEWSPUBLICO}`,
    null,
  );

}
export const getPublicoPopularNews = () =>{
  return fetchRequest(
    HTTPMethod.GET,
    `${searchApiInfo.base}/${Resource.SEARCHPOPULARNEWSPUBLICO}`,
    null,
  );
}

export const createNews = (data) =>
  fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.NEWS}`, data, true);

export const updateNews = (data, newsId) =>
  fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.NEWS}/${newsId}`, data, true);
