import React from 'react';
import { Grid } from '@mui/material';

import NewsItemInfo from '../../../components/frontend/newsitem/NewsItemInfo';
import NewsItemPicture from '../../../components/frontend/newsitem/NewsItemPicture';

export default function SectionItem({ news, idx, layout, journalName, color }) {
  if (!news) {
    return <></>;
  }

  const itemInfo = (
    <NewsItemInfo
      titleClass={`slotItemTitle l${layout}`}
      journalName={journalName}
      news={news}
      color={color}
      isSection={true}
    />
  );

  const picture = <NewsItemPicture journalName={journalName} news={news} />;

  const getHorizontalItem = (layout, index, first, second) => {
    if (layout === 1) {
      return first;
    } else {
      if (index % 2 === 0) {
        return first;
      } else {
        return second;
      }
    }
  };

  if (layout === 1 || layout === 2) {
    const pictureGridItem = (
      <Grid item xs={5} sm={5} md={5}>
        {picture}
      </Grid>
    );

    const infoGridItem = (
      <Grid item xs={7} sm={7} md={7}>
        {itemInfo}
      </Grid>
    );

    return (
      <Grid container item xs={12} sm={12} md={12} spacing={3}>
        {getHorizontalItem(layout, idx, pictureGridItem, infoGridItem)}

        {getHorizontalItem(layout, idx, infoGridItem, pictureGridItem)}
      </Grid>
    );
  } else {
    return (
      <Grid item md={layout === 3 ? 4 : 6} xs={12} sm={12}>
        <div className="frontendNewsItem">
          {picture}

          {itemInfo}
        </div>
      </Grid>
    );
  }
}
