import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  newsHover: {
    transition: 'color 0.3s ease',
    '&:hover': {
      opacity: 0.8,
      textShadow: '1px 1px rgba(255,255,255,0.8)',
      color: ({ color }) => {
        return color;
      },
    },
  },
});

export default function NewsPublicoItemInfo({ news, color, isSection }) {
  const classes = useStyles({ color });

  const title = news?.Title;
  const subtitleClassName = `newsItemSubtitle ${isSection ? 'section' : 'homepage'}`;

  return (
    <>
      <a className={`${classes.newsHover} slotItemTitle`} href={news?.OriginalURL}>
        {title}
      </a>

      <p className={subtitleClassName}>{news?.Date}</p>
    </>
  );
}
