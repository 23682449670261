import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const login = (email, password) => {
  const data = {
    identifier: email,
    password: password
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.AUTH}/local`,
    data
  );
}