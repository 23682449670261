import React from 'react';
import { Grid } from '@mui/material';
import HeadlightItem from './HeadlightItem';

export default function Layout2({ headlight, layout, school, isMobile }) {
  const props = {
    layout: layout,
    school: school
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <HeadlightItem news={headlight?.news1} size="big" isMobile={isMobile} {...props} />
      </Grid>
    </Grid>
  );
}