import React from 'react';
import MainHeader from './MainHeader';
// import SecondaryHeader from './SecondaryHeader';

import './Header.css';

export default function Header(props) {
  const { school, headerCollapsed, isMobile } = props;
  const { color1, color2 } = props;

  const mainColor = school?.palette?.color.main;
  const secondaryColor = school?.palette?.color.secondary;

  return (
    <div
      id="trueHeader"
      className="trueHeader"
      style={{ position: headerCollapsed ? 'fixed' : (isMobile === true ? 'fixed' : 'relative')}}
    >
      {color1 === undefined && color2 === undefined ? (
        <div>
          {/* <SecondaryHeader mainColor={mainColor} /> */}
          <MainHeader mainColor={mainColor} secondaryColor={secondaryColor} {...props} />
        </div>
      ) : (
        <div>
          {/* <SecondaryHeader mainColor={color1} /> */}
          <MainHeader mainColor={color1} secondaryColor={color2} {...props} />
        </div>
      )}
      {/* <SecondaryHeader mainColor={mainColor} />
      <MainHeader mainColor={mainColor} secondaryColor={secondaryColor} {...props} /> */}
    </div>
  );
}
