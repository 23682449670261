import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const refreshSession = () => {
  return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.USERS}/me`, null, true);
};

export const getUsersBySchool = (schoolId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.USERS}?school=${schoolId}&_limit=-1`,
    null,
    true,
  );
};

export const getUserInfo = (userId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.USERS}/${userId}`,
    null,
    true,
  );
};

export const getUserByToken = (tokenId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.USERS}/token/${tokenId}`,
  );
};

export const getUserByPasswordToken = (tokenId) => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.USERS}/password-token/${tokenId}`,
  );
};

export const updateUserInfo = (
  userId,
  email,
  name,
  surname,
  role,
  confirmed,
  blocked,
  isCoordinator,
) => {
  const data = {
    username: email,
    email: email,
    name: name,
    surname: surname,
    role: role,
    confirmed: confirmed,
    blocked: blocked,
  };

  if (isCoordinator === true || isCoordinator === false) {
    data.isCoordinator = isCoordinator;
  }

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.USERS}/${userId}`,
    data,
    true,
  );
};

export const deleteUser = (userId) => {
  return fetchRequest(
    HTTPMethod.DELETE,
    `${apiInfo.base}/${Resource.USERS}/${userId}`,
    null,
    true,
  );
};

export const updateMyInfo = (firstName, lastName, email) => {
  const data = {
    name: firstName,
    surname: lastName,
    username: email,
    email: email,
  };

  return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.USERS}`, data, true);
};

export const updatePassword = (oldPassword, password) => {
  const data = {
    oldPassword: oldPassword,
    password: password,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.USERS}/password`,
    data,
    true,
  );
};

export const confirmAccount = (token, firstName, lastName, email, password) => {
  const data = {
    name: firstName,
    surname: lastName,
    email: email,
    password: password,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.USERS}/token/${token}`,
    data,
  );
};

export const confirmUpdatePassword = (tokenId, password) => {
  const data = {
    password: password,
  };
  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.USERS}/password-token/${tokenId}`,
    data,
  );
};

export const createUser = (email, name, surname, role, school) => {
  const data = {
    email: email,
    name: name,
    surname: surname,
    role: role,
    school: school,
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.USERS}/create`,
    data,
    true,
  );
};

export const setPassword = (userId, password) => {
  const data = {
    password: password,
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.USERS}/${userId}`,
    data,
    true,
  );
};

export const resendEmail = (name, surname, email, role) => {
  const data = {
    email: email,
    name: name,
    surname: surname,
    role: role,
  };

  return fetchRequest(
    HTTPMethod.POST,
    `${apiInfo.base}/${Resource.USERS}/resendemail`,
    data,
    true,
  );
};

export const updateOnboarding = (onboarding) => {
  const data = {
    onboarding: onboarding,
  };

  return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.USERS}`, data, true);
};

export const resetPasswordTokenToNull = (userId, passwordtoken) => {
  const data = {
    resetPasswordToken: passwordtoken,
  };

  return fetchRequest(
    HTTPMethod.PUT,
    `${apiInfo.base}/${Resource.USERS}/null-token/${userId}`,
    data,
    true,
  );
};
